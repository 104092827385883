import { Form, Formik } from "formik";
import React, { forwardRef, Ref, useState } from 'react';
import useDispatch from 'src/hooks/useDispatch';
import useForwardRef from 'src/hooks/useForwardRef';
import { showError } from 'src/redux/actions/snackbars';
import { createPref } from 'src/redux/actions/user';
import Preference, { PreferenceType } from 'src/types/model/Preference';
import * as Yup from "yup";
import Button from '../../button/Button';
import Dialog, { DialogRef } from '../../dialog/Dialog';
import FormikInput from '../../input/FormikInput';
import styles from './CustomExportDialog.module.scss';

type Props = {
    filter?: string;
    onFinish?: (result: Preference) => void;
}

const schemaValidation = Yup.object({
    title: Yup.string().required('Title is required'),
});

function SaveFilterDialog(props: Props, ref: Ref<DialogRef>) {
    const { filter, onFinish } = props;
    const dispatch = useDispatch();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>();

    const handleSubmit = async (values: { title: string }) => {
        if (!filter) {
            dispatch(showError('Nothing to save, no filter has been set or updated'));
            return;
        }
        const pref = {
            type: PreferenceType.Filter,
            name: values.title,
            value: filter,
        } as Preference;
        try {
            setLoading(true);
            const result = await dispatch(createPref(pref));
            dialogRef.current?.hide();
            onFinish?.(result);
        } catch (error) {
            dispatch(showError(`Failed to save fav filter: ${error.message}`));
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            dialogRef={dialogRef}
            header="Save filter preference"
        >
            <Formik
                onSubmit={handleSubmit}
                validationSchema={schemaValidation}
                initialValues={{ title: '' }}
            >
                {({ isValid, dirty }) => (
                    <Form className={styles.container}>
                        {/* <div className={styles.description}>
                            some descriptions here
                        </div> */}
                        <FormikInput
                            name="title"
                            label="Title of saved filter"
                        />
                        <div className={styles.footer}>
                            <Button plainLink onClick={() => dialogRef.current?.hide()} disabled={loading}>Cancel</Button>
                            <Button type="submit" disabled={loading || !isValid || !dirty} loading={loading}>save</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default forwardRef(SaveFilterDialog);