/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { v4 as uuid } from "uuid";
import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";
import { ReduxDispatch } from "../../hooks/useDispatch";
import createAction from "./helpers/createAction";

export enum Types {
    ADD_SNACKBAR = "snackbars:ADD_SNACKBAR",
    REMOVE_SNACKBAR = "snackbars:REMOVE_SNACKBAR",
}

export const addSnackbar = createAction<SnackbarConfig>(Types.ADD_SNACKBAR);
export const removeSnackbar = createAction<string>(Types.REMOVE_SNACKBAR);

export const Actions = {
    [Types.ADD_SNACKBAR]: addSnackbar,
    [Types.REMOVE_SNACKBAR]: removeSnackbar,
};
export type Actions = typeof Actions;


export function showNotification(text: string, extraConfig = {}) {
    return (dispatch: ReduxDispatch) => {
        const config = {
            text,
            id: uuid(),
            ...extraConfig,
        };

        dispatch(addSnackbar(config));
    };
}

export function showSuccess(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, success: true }));
    };
}

export function showWarning(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, warning: true }));
    };
}

export function showError(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, error: true }));
    };
}


