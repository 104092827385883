import { Formik } from "formik";
import { Column } from "material-table";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountReviewDialog from "src/components/widgets/accountReviewDialog/AccountReviewDialog";
import { DialogRef } from "src/components/widgets/dialog/Dialog";
import Header from "src/components/widgets/header/Header";
import FormikSelect from "src/components/widgets/input/FormikSelect";
import { SelectOption } from "src/components/widgets/input/Select";
import Options from "src/components/widgets/more/Options";
import PagingTable, { PagingTableRef } from "src/components/widgets/pagingTable/PagingTable";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import useUserRoles from "src/hooks/useUserRoles";
import AccountsAPI from "src/redux/actions/api/accounts";
import { getAccountStatusChangeHistory, setAccountStatusAudits } from "src/redux/actions/audit";
import { PagingTableFilter } from "src/redux/reducers/tables";
import ListResult from "src/types/ListResult";
import AmotaiAccount, { AccountStatus, AccountType } from "src/types/model/AmotaiAccount";
import Audit from "src/types/model/Audit";
import BuyerClient from "src/types/model/BuyerClient";
import { getAccountStatusHistory } from "src/util/utils";
import styles from "./Businesses.module.scss";

type Props = {
    status: AccountStatus,
    title: string,
    subtitle: string,
}

export default function Businesses(props: Props) {
    const { status, title, subtitle } = props;
    const history = useHistory();
    const reviewDialogRef = useRef<DialogRef>(null);
    const tableRef = useRef<PagingTableRef<object>>(null);
    const [count, setCount] = useState<number | null>(null);
    const [updatingAccount, setUpdatingAccount] = useState<AmotaiAccount>();
    const { isSuperAdmin } = useUserRoles();
    const [loading, setLoading] = useState<boolean>(false);
    const [viewType, setViewType] = useState<AccountType>(AccountType.SUPPLIER);
    const accountsStatus = useSelector<{ [accountId: number]: Audit[] }>(state => state.audits.account.status);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const count = await AccountsAPI.getAccountsCount({
                status, type: viewType
            });
            setCount(count);
            setLoading(false);
        })();
    }, [status, viewType]);

    useEffect(() => {
        tableRef.current?.refresh();
    }, [viewType]);

    const navigateToAccount = (account: AmotaiAccount) => {
        if (account.type === AccountType.SUPPLIER) {
            history.push(`/suppliers/${account.id}`);
        } else if (account.type === AccountType.BUYER_CLIENT) {
            history.push(`/buyers/${account.id}`);
        }
    };

    const onChangeStatusPress = (account: AmotaiAccount) => {
        setUpdatingAccount(account);
        reviewDialogRef?.current?.show();
    };

    const onStatusSubmit = async () => {
        setLoading(true);
        if (updatingAccount) {
            dispatch(setAccountStatusAudits({
                accountId: updatingAccount.id,
                audits: [],
            }));
        }
        const count = await AccountsAPI.getAccountsCount({ status, type: viewType });
        setCount(count);
        setLoading(false);
        tableRef?.current?.refresh();
    };

    const getQuery = async (queryFilter: PagingTableFilter): Promise<ListResult<AmotaiAccount>> => {
        const { limit, cursor } = queryFilter;
        const results = await AccountsAPI.getAccounts({ status, type: viewType }, cursor, limit);
        await Promise.allSettled(results.items.map(({ id }) => {
            if (!accountsStatus[id]?.length) {
                return dispatch(getAccountStatusChangeHistory(id));
            }
            return Promise.resolve();
        }));
        return results;
    };

    const columns: Column<AmotaiAccount>[] = useMemo(() => {
        const getDates = (account?: AmotaiAccount) => {
            let title = 'Submitted at';
            let value = account ? moment(account.createdAt).format("DD/MM/YY") : undefined;
            let valueDate = account ? moment(account.createdAt) : undefined;
            const history = account ? getAccountStatusHistory(accountsStatus[account.id]) : undefined;
            const { declinedAt, deletedAt, onholdAt } = history ?? {};
            switch (status) {
                case AccountStatus.DECLINED:
                    title = 'Declined at';
                    value = declinedAt ? moment(declinedAt).format("DD/MM/YY") : 'NA';
                    valueDate = declinedAt ? moment(declinedAt) : undefined;
                    break;
                case AccountStatus.DELETED:
                    title = 'Deleted at';
                    value = deletedAt ? moment(deletedAt).format("DD/MM/YY") : 'NA';
                    valueDate = deletedAt ? moment(deletedAt) : undefined;
                    break;
                case AccountStatus.DISABLED:
                    title = 'On hold at';
                    value = onholdAt ? moment(onholdAt).format("DD/MM/YY") : 'NA';
                    valueDate = onholdAt ? moment(onholdAt) : undefined;
                    break;
                default:
                    break;
            }
            return { title, value, valueDate };
        };
        const cols: Column<AmotaiAccount>[] = [
            {
                title: "Business Name",
                field: "name"
            },
            {
                title: "Primary Contact",
                field: "mainContact.name"
            },
            {
                title: "Email",
                field: "mainContact.email"
            },
            {
                title: getDates().title,
                render: (account) => getDates(account).value,
                customSort: (a, b) => {
                    const x = getDates(a).valueDate;
                    const y = getDates(b).valueDate;
                    if (!x) {
                        return 1;
                    }
                    if (!y) {
                        return -1;
                    }
                    return x.isBefore(y) ? -1 : 1;
                }
            },
            {
                render: (account) => (
                    <Options<AmotaiAccount> data={account}
                        optionButtons={[
                            {
                                onPress: () => navigateToAccount(account),
                                buttonText: isSuperAdmin ? "View/Edit" : "View",
                            },
                            {
                                onPress: isSuperAdmin ? () => onChangeStatusPress(account) : undefined,
                                buttonText: "Change status",
                            }
                        ]} />
                )
            }
        ];
        if (viewType === AccountType.BUYER_CLIENT) {
            cols.splice(cols.length - 1, 0, {
                title: 'SaaS Plan',
                render: account => {
                    const { saaSPlanName, funderBuyer, iwiBuyer, } = account as BuyerClient;
                    if (saaSPlanName) {
                        return saaSPlanName;
                    }
                    if (funderBuyer) {
                        return 'Funder';
                    } else if (iwiBuyer) {
                        return 'Iwi';
                    }
                    return 'Unavailable';
                },
                customFilterAndSearch: (term, buyer) => {
                    const field: string = (buyer as BuyerClient).saaSPlanName ?? "Unavailable";
                    const escapeRegex = (term ?? "").replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
                    return new RegExp(escapeRegex, "i").test(field);
                },
                customSort: (a, b) => ((a as BuyerClient).saaSPlanName ?? "Unavailable")
                    .localeCompare((b as BuyerClient).saaSPlanName ?? "Unavailable")
            });
        }
        return cols;
        //eslint-disable-next-line
    }, [viewType, status, accountsStatus])

    const desc = useMemo((): string => {
        if (!count) {
            return "You're up to date";
        }
        switch (status) {
            case AccountStatus.PENDING_REVIEW:
                return `You have ${count} new accounts for review`;
            case AccountStatus.DISABLED:
            case AccountStatus.DECLINED:
            case AccountStatus.DELETED:
            default:
                return `You have ${count} accounts for review`;
        }
    }, [status, count]);

    return (
        <div className={styles.overview}>
            <Header title={title} />
            <div className={styles.subtitle}>
                <span>{subtitle}</span>
                <Formik
                    initialValues={{ viewType: AccountType.SUPPLIER }}
                    onSubmit={() => {
                        /** */
                    }}
                    enableReinitialize
                >
                    <FormikSelect name="viewType" options={viewTypeOpts} onChange={(e) => setViewType((e.target as any).value)} />
                </Formik>
            </div>
            {!loading && <div className={styles.subsubtitle}>{desc}</div>}
            <PagingTable<AmotaiAccount, PagingTableFilter>

                id={"accounts"}
                tableRef={tableRef}
                columns={columns}
                getData={getQuery} />
            <AccountReviewDialog ref={reviewDialogRef} onStatusSubmit={onStatusSubmit} account={updatingAccount} />
        </div>
    );
}
const viewTypeOpts: SelectOption[] = [
    { name: 'Suppliers', value: AccountType.SUPPLIER },
    { name: 'Buyers', value: AccountType.BUYER_CLIENT },
];

