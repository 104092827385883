import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";
import { Column, MaterialTableProps } from "material-table";
import React, { Dispatch, Ref, SetStateAction, useCallback, useEffect, useImperativeHandle, useMemo, ReactNode } from "react";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import useDispatch from "../../../hooks/useDispatch";
import usePrevious from "../../../hooks/usePrevious";
import useSafeState from "../../../hooks/useSafeState";
import useSelector from "../../../hooks/useSelector";
import { showError } from "../../../redux/actions/snackbars";
import { setTableState } from "../../../redux/actions/tables";
import { INITIAL_TABLE_STATE, PagingTableFilter, PagingTableState } from "../../../redux/reducers/tables";
import ListResult from "../../../types/ListResult";
import Table from "../table/Table";
import { values } from "lodash";
import styles from './PagingTable.module.scss';
import { Button, IconButton, Select, MenuItem } from "@material-ui/core";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { PageSizeOptions } from "src/util/constants";

export type DataFilter<F extends PagingTableFilter> = {
    cursor: string | null;
} & F;

type Props<T extends Object, F extends PagingTableFilter> = {
    id: string;
    tableRef?: Ref<PagingTableRef<T, F>>;
    title?: string | React.ReactElement<any>;
    getData: (filter: DataFilter<F>) => Promise<ListResult<T>>;
    columns: Column<T>[];
    onError?: (err: any) => void;
    search?: boolean;
    topTools?: ReactNode;
    onCheckRow?: (rowData: T) => void;
} & Omit<MaterialTableProps<T>, "columns" | "data">;

export type PagingTableRef<T extends object, F extends PagingTableFilter = PagingTableFilter> = {
    refresh: () => void;
    getFilter: () => F;
    setFilter: (newFilter: F) => void;
    setState: Dispatch<SetStateAction<PagingTableState<T, F>>>;
};

export default function PagingTable<T extends object, F extends PagingTableFilter = PagingTableFilter>(props: Props<T, F>) {
    const { id, tableRef, title, getData, columns, onError, options, search, topTools, ...otherProps } = props;

    const dispatch = useDispatch();
    const initialTableState: PagingTableState<T, F> = useSelector((state) => state.tables[id]);
    const [state, setState] = useSafeState<PagingTableState<T, F>>(initialTableState || INITIAL_TABLE_STATE);
    const [loading, setLoading] = useSafeState(!initialTableState?.lastUpdatedAt);
    const { filter, pages, cursors, page } = state || INITIAL_TABLE_STATE;

    const getNewData = async (pageIndex: number, nextCursor: string | null) => {

        try {
            if (pageIndex !== 0 && !nextCursor) {
                return;
            }
            setLoading(true);
            const { items, nextCursor: cursor } = await getData({ ...filter, cursor: nextCursor });

            if (pageIndex === 0 && filter.limit && items.length !== filter.limit && !nextCursor) {
                // no limit applied on api, retrieve all in one time, 
                // or the first page is the last page
                const _pages: T[][] = [];
                for (let i = 0; i < items.length; i += filter.limit) {
                    const content: T[] = items.slice(i, i + filter.limit);
                    _pages.push(content);
                }
                setState(oldState => {
                    oldState.pages = _pages;
                    return oldState;
                });
            } else {
                setState((oldState) => {
                    const newCursors = [...oldState.cursors];
                    if (cursor) {
                        newCursors.push(cursor);
                    }
                    const newPages = [...oldState.pages];
                    newPages.push(items || []);
                    const state = {
                        page: oldState.page,
                        cursors: newCursors,
                        pages: newPages,
                        filter: oldState.filter,
                        lastUpdatedAt: DateTime.local().toISO(),
                    };
                    return state;
                });
            }

        } catch (err) {

            dispatch(showError(err.message));
        }

        setLoading(false);
    };

    useEffect(() => {
        setState(initialTableState);
    }, [id, /* log, setState */]);

    const prevState = usePrevious(state);
    useEffect(() => {
        dispatch(setTableState({
            tableId: id,
            state,
        }));
    }, [isEqual(state, prevState), /* dispatch, id, log, state */]);

    useAsyncEffect(async () => {
        setState({
            ...INITIAL_TABLE_STATE,
            filter,
        });
        await getNewData(0, null);
    }, [filter], onError);

    const onFilterChange = (newFilter: F) => {

        setState((oldState) => ({
            ...oldState,
            filter: newFilter,
        }));
    };

    const onChangePage = (newPage: number) => {

        const hasNextPage = !!pages[newPage];
        setState((oldState) => ({
            ...oldState,
            page: newPage,
        }));
        if (page < newPage && !hasNextPage) {
            const cursor = cursors[page];
            getNewData(newPage, cursor).catch(onError);
        }
    };

    const onChangeRowsPerPage = (rowPerPage: number) => {

        onFilterChange({
            ...filter,
            limit: rowPerPage,
        });
    };

    const getFilter = useCallback(() => {
        return filter;
    }, [filter]);

    const refresh = useCallback(async () => {
        setState({
            ...INITIAL_TABLE_STATE,
            filter,
        });
        await getNewData(0, null);
    }, [filter, getNewData]);

    const allPages = values(pages).reduce((all, items) => {
        return all.concat(items);
    }, []);
    const hasCursor = !!cursors[page];
    const nextPage = pages[page + 1];
    const hasNextPage = !!nextPage;
    const nextPageEmpty = hasNextPage && !nextPage.length;
    if ((hasCursor || loading) && !nextPageEmpty) {
        allPages.push({} as T);
    }


    useImperativeHandle<PagingTableRef<T, F>, PagingTableRef<T, F>>(tableRef, () => ({
        refresh,
        getFilter,
        setFilter: onFilterChange,
        setState,
    }));

    return (
        <div>
            <div className={topTools ? styles.top_tool_bar : undefined}>
                {topTools}
                <div className={styles.top_pagination}>
                    <Pagination
                        data={allPages}
                        current={page}
                        size={filter.limit}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onChangePage={onChangePage}
                    />
                </div>
            </div>
            <Table<T>
                {...otherProps}
                title={title}
                isLoading={loading}
                columns={columns}
                data={pages[page]}
                onChangePage={onChangePage}
                page={page}

                onChangeRowsPerPage={onChangeRowsPerPage}
                options={{
                    initialPage: page,
                    showTitle: !!title,
                    pageSize: filter.limit,
                    emptyRowsWhenPaging: false,
                    rowStyle: (transaction): React.CSSProperties => {
                        if (transaction?.id) {
                            return {};
                        }
                        return { display: "none" };
                    },
                    ...options,
                    paging: false,
                }}
            />
            <div className={styles.bottom_pagination}>
                <Pagination
                    data={allPages}
                    current={page}
                    size={filter.limit}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                />
            </div>
        </div>
    );
}

type PaginationProps = {
    current: number,
    size: number,
    data: any[],
    onChangeRowsPerPage: (rowPerPage: number) => void,
    onChangePage: (newPage: number) => void,
}
function Pagination(props: PaginationProps) {
    const { current, size, onChangeRowsPerPage, onChangePage, data } = props;
    const maxPages = Math.ceil(data.length / size) - 1;
    const buttons = useMemo(() => {
        const start = Math.max(current - 1, 0);
        const end = Math.min(maxPages, current + 1);
        const _buttons: Array<ReactNode> = [];
        for (let p = start; p <= end; p++) {
            _buttons.push(
                <Button
                    className={styles.button}
                    size="small"
                    disabled={p === current}
                    variant={p === current ? "contained" : "text"}
                    onClick={() => onChangePage(p)}
                    key={p}
                >
                    {p + 1}
                </Button>
            );
        }
        return _buttons;
    }, [data.length, current]);

    const selectRenderer = (value: number) => {
        return (
            <div className={styles.select_renderer}>
                {`${value} rows `}
            </div>
        );
    };

    return (
        <div className={styles.pagination_container}>
            <Select
                value={size}
                renderValue={selectRenderer}
                onChange={e => onChangeRowsPerPage(e.target.value as number)}
            >
                {PageSizeOptions.map(s =>
                    <MenuItem key={s} value={s}>{s}</MenuItem>
                )}
            </Select>
            <IconButton onClick={() => onChangePage(current - 1)} disabled={current === 0}>
                <ChevronLeft />
            </IconButton>
            {buttons}
            <IconButton onClick={() => onChangePage(current + 1)} disabled={current >= maxPages}>
                <ChevronRight />
            </IconButton>
        </div>
    );
}
