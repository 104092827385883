import MenuItem from "@material-ui/core/MenuItem";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import InfoRounded from "@material-ui/icons/InfoRounded";
import classNames from "classnames";
import { FieldInputProps } from "formik";
import React, { ReactNode, useCallback, useRef, useState, useEffect } from "react";
import Popover, { PopoverRef } from "../popover/Popover";
import { ChangeEvent } from "./Cleave";
import styles from "./Input.module.scss";
import { SelectOption } from "./Select";


export type InputProps = {
    options?: SelectOption[];
    value?: string | number | undefined | null;
    info?: ReactNode;
    containerClassName?:string;
    field?: FieldInputProps<string | number>;
    numberOnly?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<TextFieldProps, "name" | "value" | "variant" | "onChange">;

export default function Input(props: InputProps) {
    const { options, info, containerClassName, field, InputProps, InputLabelProps, SelectProps, disabled, multiline, fullWidth, numberOnly, ...otherProps } = props;
    const { value } = props;
    const [myValue, setMyValue] = useState<string|number|null|undefined>(value);
    useEffect(() => {
        setMyValue(value ?? '');
    }, [value]);

    const isSelect = !!options?.length;

    const popoverRef = useRef<PopoverRef>(null);

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (numberOnly && (/[^\d]+/gi).test(e.target.value)) {
            return;
        }
        setMyValue(e.target.value);
        props.onChange?.(e);
    }, [props.onChange, numberOnly]);

    const onInfoPress = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
        popoverRef.current?.show(e.currentTarget);
    }, [popoverRef]);

    const label = (props.label || info) && (
        <span className={styles.label_inner}>
            {props.label && <span className={styles.label_label}>{props.label}</span>}
            {info && <span className={styles.info} onClick={onInfoPress} role={"button"}><InfoRounded /></span>}
        </span>
    );

    const className = classNames(styles.input, {
        [styles.multiline]: multiline,
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
    }, props.className);

    return (
        <div className={containerClassName}>
            <TextField
                {...field}
                {...otherProps}
                fullWidth={fullWidth}
                multiline={multiline}
                disabled={disabled}
                label={label}
                className={className}
                InputLabelProps={{
                    ...InputLabelProps,
                    classes: {
                        ...InputLabelProps?.classes,
                        root: classNames(styles.label, InputLabelProps?.classes?.root),
                    },
                }}
                InputProps={{
                    ...InputProps,
                    classes: {
                        ...InputProps?.classes,
                        root: classNames(styles.input_root, InputProps?.classes?.root),
                    },
                }}
                value={myValue}
                select={isSelect}
                onChange={onChange}
                SelectProps={{
                    ...SelectProps,
                    classes: {
                        ...SelectProps?.classes,
                        root: classNames(styles.select_root, SelectProps?.classes?.root),
                    },
                    MenuProps: {
                        ...SelectProps?.MenuProps,
                        classes: {
                            paper: classNames(styles.select_menu, SelectProps?.MenuProps?.classes?.paper),
                        },
                    },
                }}>
                {isSelect &&
                options!.map((option) => (
                    <MenuItem key={option.value || "null"} value={option.value || "null"}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            {info && <Popover popoverRef={popoverRef} className={styles.info_popover}>{info}</Popover>}
        </div>
    );
}
