/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";
import { Actions, Types } from "../actions/snackbars";

const initialState: SnackbarConfig[] = [];

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function snackbars(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.ADD_SNACKBAR: {
            const { payload } = action as ActionValueTypes<Types.ADD_SNACKBAR>;

            if (!payload) {
                return state;
            }

            return [...state, payload];
        }
        case Types.REMOVE_SNACKBAR: {
            return [...state].filter((snackbar) => {
                return snackbar.id !== action.payload;
            });
        }
        default: {
            return state;
        }
    }
}
