import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import { Form as FormikForm, Formik, FormikProps, FormikValues } from "formik";
import React, { Dispatch, ReactElement, Ref, SetStateAction, useCallback, useImperativeHandle, useState } from "react";
import Button from "../button/Button";
import ErrorBlock from "../errorBlock/ErrorBlock";
import styles from "./FormDialog.module.scss";

export type FormDialogProps<FormValues> = {
    children: (formikProps: FormikProps<FormValues>) => ReactElement;

    dialogRef: Ref<FormDialogRef>;
    className?: string;

    initialShown?: boolean;
    isUpdating?: boolean;
    createAction?: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => (Promise<any> | void)) | null;
    updateAction?: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => (Promise<any> | void)) | null;
    deleteAction?: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => (Promise<any> | void)) | null;

    createText?: string;
    updateText?: string;
    confirmText?: string;
    cancelText?: string;

    validationSchema?: any;
    initialValues: FormValues;
    header?: string;
    createHeader?: string;
    updateHeader?: string;
    deleteHeader?: string;

    onSuccess?: () => void;
    onClose?: (formikProps: FormikProps<FormValues>) => void;

    large?: boolean;
};

export type FormDialogRef = {
    show: () => void;
    showDelete: () => void;
    hide: () => void;
};

type State = {
    shown: boolean;
    deleting: boolean;
    closeOnCancel: boolean;
    error: null | string;
}

type FormProps<FormValues extends FormikValues = FormikValues> = FormDialogProps<FormValues> & {
    formikProps: FormikProps<FormValues>;
    state: State;
    setState: Dispatch<SetStateAction<State>>
};

function Form<FormValues extends FormikValues = FormikValues>(props: FormProps<FormValues>) {
    const {
        children,
        isUpdating,
        formikProps,
        state,
        setState,
        createAction,
        updateAction,
        deleteAction,

        createHeader,
        updateHeader,
        deleteHeader,

        createText,
        updateText,
        confirmText,
        cancelText,
        onSuccess,
        large,
    } = props;
    const { values, handleReset, isValid, dirty } = formikProps;

    const { shown, deleting, closeOnCancel, error, } = state;

    const createUpdateText = isUpdating ? (updateText || "Update") : (createText || "Create");
    const createUpdateDeleteText = deleting ? (confirmText || "Confirm") : createUpdateText;

    const createUpdateHeader = isUpdating ? updateHeader : createHeader;
    const createUpdateDeleteTitle = deleting ? deleteHeader : createUpdateHeader;
    const title = createUpdateDeleteTitle ?? props.header;

    const [loading, setLoading] = useState(false);

    const onDeletePress = useCallback(() => {
        setState((s) => ({ ...s, deleting: true }));
    }, [setState]);

    const onClose = useCallback(() => {
        setState((s) => ({ ...s, shown: false }));
        setTimeout(() => {
            handleReset();
            setState({
                shown: false,
                deleting: false,
                closeOnCancel: false,
                error: null,
            });
            setLoading(false);
            props.onClose?.(formikProps);
        }, 165);
    }, [formikProps, handleReset, props.onClose, setState]);

    const onCancelPress = useCallback(() => {
        handleReset();
        if (deleting && !closeOnCancel) {
            setState((s) => ({ ...s, deleting: false }));
        } else {
            onClose();
        }
    }, [deleting, closeOnCancel, handleReset, onClose, setState]);

    const onSubmit = useCallback(async (e: React.MouseEvent | React.FormEvent) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (deleting) {
                if (deleteAction) {
                    await deleteAction(values, formikProps);
                }
            } else if (isUpdating) {
                if (updateAction) {
                    await updateAction(values, formikProps);
                }
            } else if (createAction) {
                await createAction(values, formikProps);
            }
            onSuccess?.();
            onClose();
        } catch (err) {
            const newError = err?.response?.data.message || err.message;
            setState((s) => ({ ...s, error: newError }));
        }
        setLoading(false);
    }, [values, formikProps, setLoading, deleting, deleteAction, isUpdating, updateAction, createAction, onSuccess, onClose, setState]);

    const className = classNames(styles.dialog, {
        [styles.large]: large && !deleting,
    }, props.className);
    return (
        <MuiDialog
            classes={{
                paper: className,
            }}
            open={shown}
            onClose={onClose}
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}>
            <FormikForm onSubmit={onSubmit}>
                <DialogTitle>
                    {deleting ? "Confirm Deletion" : title}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    {deleting ? <span>Are you sure this cannot be undone?</span> : children(formikProps)}
                    <ErrorBlock error={error} />
                </DialogContent>
                <DialogActions className={styles.buttons}>
                    {isUpdating && deleteAction && !deleting ? (
                        <Button onClick={onDeletePress} plain disabled={loading}>
                            Delete
                        </Button>
                    ) : (
                        <div />
                    )}
                    <div className={styles.buttonsRight}>
                        <Button onClick={onCancelPress} plain disabled={loading}>
                            {cancelText || "Cancel"}
                        </Button>
                        <Button disabled={!deleting && (!isValid || !dirty)}
                            loading={loading}
                            red={deleting}
                            onClick={onSubmit} type={"submit"}>
                            {createUpdateDeleteText}
                        </Button>
                    </div>
                </DialogActions>
            </FormikForm>
        </MuiDialog>
    );
}

export default function FormDialog<FormValues extends FormikValues = FormikValues>(props: FormDialogProps<FormValues>) {
    const { dialogRef, validationSchema, initialValues, initialShown } = props;

    const [state, setState] = useState<State>({
        shown: initialShown ?? false,
        deleting: false,
        closeOnCancel: false,
        error: null,
    });

    useImperativeHandle(dialogRef, () => ({
        show: () => {
            setState((s) => ({ ...s, shown: true }));
        },
        showDelete: () => {
            setState((s) => ({ ...s, shown: true, deleting: true, closeOnCancel: true }));
        },
        hide: () => {
            setState((s) => ({ ...s, shown: false }));
        },
    }));

    const onSubmit = useCallback(() => {
        /** */
    }, []);

    return (
        <Formik<FormValues>
            onSubmit={onSubmit}
            validationSchema={!state.deleting && validationSchema}
            validateOnChange
            enableReinitialize
            initialValues={initialValues}>
            {(formikProps) => (
                <Form
                    {...props}
                    formikProps={formikProps}
                    state={state}
                    setState={setState}
                />
            )}
        </Formik>
    );
}
