import React, { useState, useMemo } from "react";
import * as Yup from "yup";
import classNames from "classnames";
import { RouteComponentProps } from "react-router-dom";
import _values from "lodash/values";
import { Form, Formik } from "formik";
import BusinessContactFieldArray from "src/components/widgets/formikFields/BusinessContactFieldArray";
import styles from "../../SupplierDetail.module.scss";
import Header from "../../../../../../widgets/header/Header";
import Button from "../../../../../../widgets/button/Button";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import useSelector from "../../../../../../../hooks/useSelector";
import BusinessContact from "../../../../../../../types/model/BusinessContact";
import { showError } from "../../../../../../../redux/actions/snackbars";
import useDispatch from "../../../../../../../hooks/useDispatch";
import FormikFileUpload from "../../../../../../widgets/input/FormikFileUpload";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import { getBusinessContacts, updateBusinessContacts } from "../../../../../../../redux/actions/businessContacts";
import { updateSupplier } from "../../../../../../../redux/actions/suppliers";
import Loading from "../../../../../loading/Loading";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";

type RouteParams = {
    accountId: string;
}

export type SupplierBusinessProfileValues = {
    logo: string;
    description: string;
    website: string;
    facebookLink: string;
    twitterLink: string;
    youtubeLink: string;
    instagramLink: string;
    linkedInLink: string;
    pinterestLink: string;
    businessContacts: BusinessContact[]
}

const validationSchema = Yup.object({
    logo: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    website: Yup.string(),
    businessContacts: Yup.array().of(Yup.object({
        name: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        email: Yup.string().email("Not a valid email address").required("Required"),
        phoneNumber: Yup.string().required("Required"),
        avatar: Yup.string()
    }))
});

export default function Profile(props: RouteComponentProps<RouteParams>) {
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const id = props?.match?.params?.accountId;
    const suppliers = useSelector((state) => state.suppliers);
    const businessContactObjects = useSelector((state) => state.businessContacts.businessContactObjects);
    const dispatch = useDispatch();
    const businessContacts = _values(businessContactObjects).filter((contact: BusinessContact) => contact.account === Number(id));
    const supplier = suppliers[id];
    const { isSuperAdmin, isRegionalManager } = useUserRoles();
    const eligable = useMemo(() => isSuperAdmin || isRegionalManager, [isSuperAdmin, isRegionalManager]);

    useMountEffect(async () => {
        if (!businessContacts.length) {
            await dispatch(getBusinessContacts(Number(id)));
        }
        setLoading(false);
    });

    const onSubmit = async (values: SupplierBusinessProfileValues) => {
        try {
            setLoading(true);
            await dispatch(updateSupplier({
                ...supplier,
                ...values
            }));
            await dispatch(updateBusinessContacts(Number(id), values.businessContacts.map((b) => ({
                ...b, account: Number(id)
            }))));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const initialValues = {
        logo: supplier.logo || "",
        description: supplier.description || "",
        website: supplier.website || "",
        facebookLink: supplier.facebookLink || "",
        twitterLink: supplier.twitterLink || "",
        youtubeLink: supplier.youtubeLink || "",
        instagramLink: supplier.instagramLink || "",
        linkedInLink: supplier.linkedInLink || "",
        pinterestLink: supplier.pinterestLink || "",
        businessContacts: businessContacts || [],
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Formik initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}>
            {(formikBag) => {
                const toggleEdit = async () => {
                    if (editing && formikBag.dirty) {
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };

                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                // console.log("VALUES", formikBag.values);
                return (
                    <Form className={styles.form}>
                        <Header title={`${supplier.name}`}
                            action={eligable ? (
                                <Button uppercase loading={loading || formikBag.isSubmitting}
                                    onClick={toggleEdit}>{actionText}</Button>
                            ) : undefined}
                            subnodes={<AccountHistory account={supplier} />} />
                        <SectionHeader title={"Business Profile"} />
                        <div className={styles.form_section}>
                            <FormikFileUpload name={"logo"}
                                labelClassName={styles.file_input}
                                preview={supplier.logo || ""}
                                label={"Business logo"}
                                disabled={!editing}
                                imageClassName={styles.logo_image} />
                            <FormikInput name={"description"}
                                label={"Business description"}
                                multiline
                                disabled={!editing}
                                className={styles.paragraph_input} />
                        </div>

                        <div className={styles.form_section}>
                            <SectionHeader title={"Social Media"} />
                            <FormikInput name={"website"}
                                inputClassname={classNames(styles.half_input, styles.first)}
                                disabled={!editing}
                                label={"Main business website"} />
                            <FormikInput name={"facebookLink"}
                                inputClassname={styles.half_input}
                                disabled={!editing}
                                label={"Facebook (optional)"} />
                            <FormikInput name={"instagramLink"}
                                inputClassname={classNames(styles.half_input, styles.first)}
                                disabled={!editing}
                                label={"Instagram (optional)"} />
                            <FormikInput name={"twitterLink"}
                                inputClassname={styles.half_input}
                                disabled={!editing}
                                label={"Twitter (optional)"} />
                            <FormikInput name={"linkedInLink"}
                                inputClassname={classNames(styles.half_input, styles.first)}
                                disabled={!editing}
                                label={"LinkedIn (optional)"} />
                            <FormikInput name={"youtubeLink"}
                                inputClassname={styles.half_input}
                                disabled={!editing}
                                label={"Youtube (optional)"} />
                            <FormikInput name={"pinterestLink"}
                                inputClassname={classNames(styles.half_input, styles.first)}
                                disabled={!editing}
                                label={"Pinterest (optional)"} />
                        </div>
                        <div className={styles.form_section}>
                            <SectionHeader title={"Preferred contacts"} />
                            <BusinessContactFieldArray disabled={!editing} />
                        </div>
                    </Form>

                );
            }}
        </Formik>
    );
}