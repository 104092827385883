import React, { useState, useRef } from 'react';
import { Formik } from "formik";
import FormikSelect from "./FormikSelect";
import { REGIONS_NO_NATIONWIDE } from 'src/util/constants';
import { simpleArrayCompare } from 'src/util/utils';

const defaultRegionBasedOption = 'Region based (default Nationwide)';
const allRegionBasedOptions = [defaultRegionBasedOption, ...REGIONS_NO_NATIONWIDE];

type Props = {
    containerClassName?: string;
    onFinish: (basedRegions: string[]) => void;
}

export default function RegionsSelect(props: Props) {
    const { containerClassName, onFinish } = props;
    const basedRegionsRef = useRef<string[]>(allRegionBasedOptions);
    const [, update] = useState<number>(Math.random());

    const onRegionsSelected = (e: any) => {
        const values = e.target.value as string[];
        if (values.length >= 1 && values.includes(defaultRegionBasedOption)) {
            if (!basedRegionsRef.current.includes(defaultRegionBasedOption)) {
                // default-all option is the new select, select every option
                basedRegionsRef.current = allRegionBasedOptions;
            } else {
                // other options in so remove the default-all option
                basedRegionsRef.current = values.filter(v => v !== defaultRegionBasedOption);
            }
        } else if (!values.includes(defaultRegionBasedOption)
            && basedRegionsRef.current.includes(defaultRegionBasedOption)
            && simpleArrayCompare(values, REGIONS_NO_NATIONWIDE)) {
            // default option unchecked, unselect everything
            basedRegionsRef.current = [];
        } else if (values.length === 0) {
            basedRegionsRef.current = allRegionBasedOptions;
        } else {
            basedRegionsRef.current = values;
        }
        update(Math.random());
    };

    const onSelected = () => {
        if (basedRegionsRef.current.length === 0) {
            // default to select all when nothing selected, api only parse when something selected
            basedRegionsRef.current = allRegionBasedOptions;
            update(Math.random());
        }
        onFinish(basedRegionsRef.current.filter(v => v !== defaultRegionBasedOption)); //remove the default region which stands for nothing
    };

    return (
        <Formik
            initialValues={{ regions: basedRegionsRef.current }}
            onSubmit={() => { /** */ }}
            enableReinitialize
        >
            <FormikSelect
                name="regions"
                onClose={onSelected}
                options={[defaultRegionBasedOption, ...REGIONS_NO_NATIONWIDE]}
                multiple
                onChange={onRegionsSelected}
                defaultValue={defaultRegionBasedOption}
                containerClassName={containerClassName} />
        </Formik>
    );
}