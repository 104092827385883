import React, { useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Category, CategoryType } from "../../../../../../types/model/Category";
import { DialogRef } from "../../../../../widgets/dialog/Dialog";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import AddCategoryDialog from "../../../../../widgets/addCategoryDialog/AddCategoryDialog";
import Header from "../../../../../widgets/header/Header";
import Table from "../../../../../widgets/table/Table";
import { showError } from "../../../../../../redux/actions/snackbars";
import Loading from "../../../../loading/Loading";
import Button from "../../../../../widgets/button/Button";
import { createCategory, getMainCategories, getSubCategories } from "../../../../../../redux/actions/categories";
import useSelector from "../../../../../../hooks/useSelector";
import Options from "../../../../../widgets/more/Options";
import DeleteCategoryDialog from "../../../../../widgets/deleteCategoryDialog/DeleteCategoryDialog";
import FormikInput from "../../../../../widgets/input/FormikInput";
import styles from "./SubCategories.module.scss";

function SubCategories() {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const addSubCategoryDialog = useRef<DialogRef>(null);
    const deleteCategoryDialog = useRef<DialogRef>(null);
    const mainCategories = useSelector((state) => state.categories.main);
    const subCategories = useSelector((state) => state.categories.sub);
    const { categoryId } = useParams<{ categoryId: string }>();
    const [mainCategory] = mainCategories.filter((c) => c.id === Number(categoryId || ""));

    const history = useHistory();
    const dispatch = useDispatch();

    useMountEffect(async () => {
        try {
            await dispatch(getMainCategories());
            await dispatch(getSubCategories(Number(categoryId)));
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    });

    const onUpdateNameSubmit = async (values: any) => {
        try {
            await dispatch(createCategory({ ...mainCategory, name: values.name, type: CategoryType.MAIN }));
        } catch (e) {
            console.log("err", e.message);
        }
    };

    const onAddClick = () => {
        addSubCategoryDialog.current?.show();
    };

    const onEditPress = (category: Category) => {
        history.push(`/categories/${categoryId}/sub/${category.id}`);
    };

    const onDeletePress = (category: Category) => {
        setSelectedCategory(category);
        deleteCategoryDialog.current?.show();
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Header title={"Sub-Categories"} action={<Button onClick={onAddClick}>Add sub-category</Button>} />
            <Formik initialValues={{ name: mainCategory?.name }} onSubmit={onUpdateNameSubmit}
                validationSchema={Yup.object({ name: Yup.string().required() })}>
                {({ isValid, dirty, isSubmitting }) => {
                    return (
                        <Form>
                            <div className={styles.edit_name_wrapper}>
                                <FormikInput name={"name"} label={"Edit Category name"} />
                                <Button type={"submit"} disabled={!dirty || !isValid || isSubmitting}>Submit</Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <Table columns={[
                { title: "ID", field: "id" },
                { title: "Name", field: "name" },
                {
                    render: s => (
                        <div style={{ textAlign: "right" }}>
                            <Options<Category> data={s}
                                optionButtons={[
                                    {
                                        onPress: () => onEditPress(s),
                                        buttonText: "View/Edit",
                                    },
                                    {
                                        onPress: () => onDeletePress(s),
                                        buttonText: "Delete",
                                    }
                                ]}
                            />
                        </div>
                    )
                }
            ]} data={subCategories} options={{ pageSize: 50 }} />
            <AddCategoryDialog ref={addSubCategoryDialog} header={"Add new sub-category"} type={CategoryType.SUB}
                parent={categoryId} />
            <DeleteCategoryDialog ref={deleteCategoryDialog} type={CategoryType.SUB} parent={categoryId}
                category={selectedCategory} />
        </div>
    );
}

export default SubCategories;