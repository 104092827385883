import SaaSPlan, { UpdateSaaSPlanRequest } from "src/types/model/SaaSPlan";
import ListResult from "src/types/ListResult";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getActivePlans(): Promise<SaaSPlan[]> {
    return get("v1/plans/active");
}

function getPlans(limit: number, cursor?: string | null): Promise<ListResult<SaaSPlan>> {
    return get("v1/admin/plans", {
        params: {
            limit,
            cursor
        }
    });
}

function createSaaSPlan(plan: SaaSPlan): Promise<SaaSPlan> {
    return post("v1/admin/plans", plan);
}

function updateSaaSPlan(planId: number, dto: UpdateSaaSPlanRequest): Promise<SaaSPlan> {
    return put(`v1/admin/plans/${planId}`, dto);
}

const SaaSPlanAPI = {
    getActivePlans,
    getPlans,
    createSaaSPlan,
    updateSaaSPlan,
};

export default SaaSPlanAPI;