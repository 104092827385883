import React, { forwardRef, Ref, useState } from "react";
import Dialog, { DialogRef } from "../dialog/Dialog";
import useForwardRef from "../../../hooks/useForwardRef";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import { deleteCategory, getSubCategories, getSubSubCategories } from "../../../redux/actions/categories";
import { CategoryType } from "../../../types/model/Category";

function DeleteCategoryDialog(props: any, ref: Ref<DialogRef>) {
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onConfirmPress = async () => {
        setLoading(true);
        try {
            await dispatch(deleteCategory(props.category.id));
            if (props.parent) {
                if (props.category.type === CategoryType.SUB) {
                    await dispatch(getSubCategories(props.parent));
                } else if (props.category.type === CategoryType.SUB_SUB) {
                    await dispatch(getSubSubCategories(props.parent));
                }
            }
            dialogRef.current?.hide();
        } catch (e) {
            dispatch(showError(e.message));
        }
        setLoading(false);
    };

    const onNegativePress = () => {
        dialogRef.current?.hide();
    };

    return (
        <Dialog dialogRef={dialogRef}
                positiveText={"Confirm"}
                onPositivePress={onConfirmPress}
                negativeText={"Cancel"}
                onNegativePress={onNegativePress}
                loading={loading}
                disableBackdropClick={loading}>
            Are you sure you want to delete this category?
        </Dialog>
    );
}

export default forwardRef(DeleteCategoryDialog);
