import { useFormikContext } from "formik";
import React, { ChangeEvent } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import styles from './FormikBooleanRadio.module.scss';

export type Props = {
    disabled?: boolean;
    label?: string;
    trueLabel?: string;
    falseLabel?: string;
    fieldName: string
}

export default function FormikBooleanRadio({ disabled, label, trueLabel, falseLabel, fieldName }: Props) {
    const { values, setFieldValue } = useFormikContext<object & {[key: string]: boolean}>();
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue(fieldName, JSON.parse(event.target.value));
    };

    return  <div className={styles.event}>
                <div className={styles.label}>{label}</div>
                <RadioGroup value={values[fieldName] ?? false} onChange={onChange}>
                    <div className={styles.radios}>
                        <FormControlLabel value={false} control={<Radio />} label={falseLabel ?? 'No'} disabled={disabled} />
                        <FormControlLabel value={true} control={<Radio />} label={trueLabel ?? 'Yes'} disabled={disabled} />
                    </div>
                </RadioGroup>
            </div>;
}