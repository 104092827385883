import { AccountStatus } from "./AmotaiAccount";

export default interface Audit {
    id: number;
    entity: 'Account' | 'User' | 'SaaSSubscription' | 'BusinessOwner' | 'BusinessContact' | 'BusinessReference' | 'Note';
    entityKey: string;
    updatedAt: string;
    updatedBy: string;
    details: { [key: string]: any };
    prev?: { [key: string]: any };
    linkedEntity?: 'Account' | 'User' | 'SaaSSubscription';
    linkedEntityKey?: string;
};;;;;;;;;;

export interface AccountStatusAudit extends Audit {
    details: {
        status: AccountStatus
    }
}

export const SpecialAccountAuditFields = [
    'businessOwner',
    'businessContact',
    'businessReference',
    'note',
    'saaSSubscription',
    'accountUser',
];

export const AccountAuditUserIdFields = [
    'mainContact',
    'accountUser',
    'assignedTo',
];

export const HiddenFields = [
    'note',
];

export const HiddenEntities = [
    'Note',
];