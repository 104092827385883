import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, InputAdornment, Paper } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import { Field, FormikProps, useField, useFormikContext } from "formik";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import InputSelect from "src/components/widgets/input/InputSelect";
import useSelector from "src/hooks/useSelector";
import { Category, SubCategory, SubSubCategory } from "src/types/model/Category";
import Button from "../../button/Button";
import { CheckboxGroupItem } from "../FormikCheckboxGroup";
import Input from "../Input";
import { SelectOption } from "../Select";
import styles from "./FormikCategorySelect.module.scss";

type BusinessCategoryFormValues = {
    mainCategories?: Category[];
    subCategories?: SubCategory[];
    subSubCategories?: SubSubCategory[];
}

type Props = {
    disabled: boolean,
}

export default function FormikCategorySelect(props: Props) {
    const { disabled } = props;
    const categories = useSelector<Category[]>((state) => state.categories.main);
    const { values }: FormikProps<BusinessCategoryFormValues> = useFormikContext();
    const { mainCategories, subCategories, subSubCategories } = values;
    const [, , mainHelpers] = useField("mainCategories");
    const [, , subHelpers] = useField("subCategories");
    const [, , subSubHelpers] = useField("subSubCategories");
    const [expendStates, setExpendStates] = useState<{ [name: string]: boolean }>({});

    const allCats = useSelector(state => state.categories);

    const allOptions = useMemo((): SelectOption[] => {
        const { main, sub, subSub } = allCats;
        const selectedMainIds = mainCategories?.map(c => c.id) ?? [];
        const selectedSubIds = subCategories?.map(c => c.id) ?? [];
        const selectedSubSubIds = subSubCategories?.map(c => c.id) ?? [];
        return main.filter(c => !selectedMainIds.includes(c.id))
            .map(({ name, id: value }) => ({ name, label: name, value }))
            .concat(
                sub.filter(c => !selectedSubIds.includes(c.id))
                    .map(({ name, id: value }) => ({ name, label: name, value }))
            )
            .concat(
                subSub.filter(c => !selectedSubSubIds.includes(c.id))
                    .map(({ name, id: value }) => ({ name, label: name, value }))
            );
    }, [allCats, mainCategories, subCategories, subSubCategories]);

    const onSearchSelected = (idstr: string) => {
        const { main, sub, subSub } = allCats;
        const id = Number(idstr);
        let _mainCategory = main.find(mc => mc.id === id);
        if (!_mainCategory) {
            let _subCategory = sub.find(sc => sc.id === id);
            if (!_subCategory) {
                //missed
                const _subSubCategory = subSub.find(ssc => ssc.id === id)!; //it must be there!`
                _subCategory = sub.find(sc => sc.id === _subSubCategory.parent)!;
                _mainCategory = main.find(mc => mc.id === _subCategory!.parent)!;
                //no duplicate check, sub-sub will be one and only one
                subSubHelpers.setValue([_subSubCategory, ...(subSubCategories ?? [])]);
            } else {
                //hit
                _mainCategory = main.find(mc => mc.id === _subCategory!.parent)!;
            }
            if (_subCategory && !subCategories?.find(sc => sc.id === _subCategory!.id)) {
                subHelpers.setValue([_subCategory, ...(subCategories ?? [])]);
            }
        }
        if (_mainCategory && !mainCategories?.find(mc => mc.id === _mainCategory!.id)) {
            mainHelpers.setValue([_mainCategory, ...(mainCategories ?? [])]);
            setExpendStates(prev => {
                const next = { ...prev };
                next[_mainCategory!.name] = true;
                return next;
            });
        }
    };

    const onSubCategoryChange = (sub: SubCategory) => {
        const selectedSub = subCategories?.find((subCat) => subCat.id === sub.id);
        const ssids = sub.subSubCategories?.map((subSubCat) => subSubCat.id);
        if (!selectedSub) {
            // clear subSub categories from the existing selection
            const _subSubCategories = subSubCategories?.filter((subSubCat) => !ssids?.includes(subSubCat.id));
            subSubHelpers.setValue(_subSubCategories);
        }
    };

    const onSubSubCategoryChange = (subSub: SubSubCategory) => {
        const parentSubCat = categories.map(c => c.subCategories).flat().find(s => s?.id === subSub.parent)!;
        const isParentSelected = !!subCategories?.find(s => s.id === parentSubCat.id);
        const isChildSelected = !!subSubCategories?.find((subSubCat) => subSubCat.id === subSub.id);
        if (!isParentSelected && isChildSelected) {
            // have selected a subSub category without the parent subCategory added, select it now
            subHelpers.setValue([...(subCategories ?? []), parentSubCat]);
        }
    };

    const onRemoveSinglePrimary = (_cat: Category, idx: number) => {
        if (!_cat.id) {
            //not assign anything yet, still empty choice
            const _mc = [...(mainCategories ?? [])];
            _mc.splice(idx, 1);
            mainHelpers.setValue(_mc);
            return;
        }
        const cat = categories.find(c => c.id === _cat.id)!;
        const sids = cat.subCategories?.map(s => s.id);
        const ssids = cat.subCategories?.map(s => s.subSubCategories?.map(ss => ss.id)).flat();
        const _subCategories = subCategories?.filter(sub => !sids?.includes(sub.id));
        const _subSubCategories = subSubCategories?.filter(subsub => !ssids?.includes(subsub.id));
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(mainCategories?.filter(m => m.id !== cat.id));
    };

    const toggleExpendStates = (name: string) => {
        setExpendStates(prev => {
            const next = { ...prev };
            next[name] = !next[name];
            return next;
        });
    };

    return <>
        <InputSelect
            containerClassName={styles.full_input}
            placeholder="Search in all categories"
            options={allOptions}
            value={''}
            onChange={onSearchSelected}
            disabled={disabled}
        />
        {mainCategories?.map((cat, idx) =>
            <div key={cat.id} className={styles.industry_form}>
                <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    className={styles.category_container}
                    expanded={expendStates[cat.name]}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => toggleExpendStates(cat.name)}
                    >
                        <div className={styles.category_name}>{cat.name}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {cat.subCategories && (
                            <SubCategoriesWrapper
                                disabled={disabled}
                                onSubCategoryChange={onSubCategoryChange}
                                onSubSubCategoryChange={onSubSubCategoryChange}
                                subCategories={cat.subCategories}
                                parentName={cat.name}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
                <div className={styles.remove_cat_button}>
                    <Button plain onClick={() => onRemoveSinglePrimary(cat, idx)} disabled={disabled}>{`- Remove ${cat.name ?? ''}`}</Button>
                </div>
            </div>
        )}
    </>;
}

function SubCategoriesWrapper(props: {
    subCategories: SubCategory[],
    parentName: string,
    onSubCategoryChange: (sub: SubCategory) => void,
    onSubSubCategoryChange: (subSub: SubSubCategory) => void,
    disabled: boolean,
}) {
    const { subCategories, parentName, onSubCategoryChange, onSubSubCategoryChange, disabled } = props;
    const [keyword, setAsLowerCaseKeyword] = useState<string>(); //make sure the keyword always in lower case
    const subFilter = (sub: SubCategory) => {
        if (!keyword) {
            return true;
        }
        const { name, subSubCategories } = sub;
        //check if sub name contains
        //if sub name not contains, check if subsub contains
        //NB: sub-sub must be filterred if sub-sub name matches any
        return name.toLowerCase().includes(keyword) || subSubCategories?.find(ssc => ssc.name.toLowerCase().includes(keyword));
    };

    return (
        <div className={styles.wrap_container}>
            <SearchControl
                placeholder={`Search under ${parentName}`}
                onTextChange={setAsLowerCaseKeyword}
                onClear={() => setAsLowerCaseKeyword(undefined)}
                keyword={keyword}
            />
            {subCategories.filter(subFilter)
                .map((sub, key) =>
                    <SubCategoryView key={`sub_${key}`}
                        disabled={disabled}
                        sub={sub}
                        keyword={keyword}
                        onSubCategoryChange={onSubCategoryChange}
                        onSubSubCategoryChange={onSubSubCategoryChange} />
                )
            }
        </div>
    );
}

function SubCategoryView(props: {
    sub: SubCategory,
    onSubCategoryChange: (sub: SubCategory) => void,
    onSubSubCategoryChange: (subSub: SubSubCategory) => void,
    disabled: boolean,
    keyword?: string,
}) {
    const { sub, onSubCategoryChange, onSubSubCategoryChange, disabled, keyword } = props;
    const { subSubCategories, name: subName } = sub;
    const [expended, setExpended] = useState<boolean>(false);
    const [subsubHits, setSubsubHits] = useState<SubSubCategory[]>();

    useEffect(() => {
        const hits = subSubCategories?.filter(({ name }) => !keyword || name.toLowerCase().includes(keyword));
        setSubsubHits(hits);
        setExpended(!!hits && !!subSubCategories && hits.length < subSubCategories.length);
    }, [keyword, subSubCategories]);

    const onExpendClick = () => {
        setExpended(state => !state);
    };

    if (!subSubCategories?.length) {
        return <Paper className={styles.plain_subindustry_row}>
            <Field disabled={disabled} component={CheckboxGroupItem} name={"subCategories"} label={subName} value={sub} />
        </Paper>;
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }} className={styles.category_container} expanded={expended}>
            <AccordionSummary
                onClick={onExpendClick}
                expandIcon={<ExpandMoreIcon />}
                className={styles.category_item}
            >
                <FormControlLabel
                    className={styles.category_label}
                    aria-label={"Sub Industry"}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                        <Field name={"subCategories"}>
                            {(bags: any) => (
                                <CheckboxGroupItem
                                    {...bags}
                                    value={sub}
                                    disabled={disabled}
                                    sideEffect={() => onSubCategoryChange(sub)}
                                />
                            )}
                        </Field>
                    )}
                    label={subName}
                />
            </AccordionSummary>

            <AccordionDetails>
                <div className={styles.sub_container}>
                    {subsubHits?.map((subSub, key) => (
                        <Field key={`subsub_${key}`}
                            name={"subSubCategories"}>
                            {(bags: any) => {
                                return <CheckboxGroupItem {...bags}
                                    value={subSub}
                                    disabled={disabled}
                                    sideEffect={() => onSubSubCategoryChange(subSub)}
                                    label={subSub.name}
                                />;
                            }}
                        </Field>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

function SearchControl(props: {
    onTextChange: (text: string) => void,
    onClear: () => void,
    placeholder?: string,
    keyword?: string,
}) {
    const { onTextChange, onClear, placeholder, keyword } = props;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onTextChange(e.target.value?.toLowerCase());
    };
    return (
        <Input
            className={styles.search}
            placeholder={placeholder}
            onChange={onChange}
            value={keyword}
            InputProps={{
                startAdornment: <div>
                    <InputAdornment position="start">
                        <FilterListIcon />
                    </InputAdornment>
                </div>,
                endAdornment: <div className={classnames(styles.clear_btn, { [styles.clear_btn_none]: !keyword })} onClick={onClear}>
                    <InputAdornment position="end">
                        <ClearIcon />
                    </InputAdornment>
                </div>,
            }}
        />
    );
}