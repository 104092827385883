import { Column } from 'material-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/routes/loading/Loading';
import Button from 'src/components/widgets/button/Button';
import Header from 'src/components/widgets/header/Header';
import Table from 'src/components/widgets/table/Table';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getAllRegions } from 'src/redux/actions/suppliers';
import { nullRegion, RegionAccounts } from 'src/types/model/AmotaiAccount';
import styles from './Suppliers.module.scss';



export default function Suppliers() {
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const regionSuppliers = useSelector(state => state.suppliers.regionSuppliers);

    const columns:Column<RegionAccounts>[] = useMemo(() => [
        {
            title: 'Region',
            render: rs => <div  className={styles.link}
                                onClick={() => history.push(`/dashboard/suppliers/${rs.region ?? nullRegion}`)}>
                            {rs.region ?? nullRegion}
                        </div>,
            customSort: (a, b) => (a.region ?? nullRegion).localeCompare(b.region ?? nullRegion)
        },
        {
            title: 'No.operating',
            field: 'number'
        }
    ], [history]);

    const totalActive = useMemo(() => {
        const val = Object.values(regionSuppliers);
        let cnt = 0;
        val.forEach(v => {
 cnt += v.number; 
});
        return cnt;
    }, [regionSuppliers]);

    useEffect(() => {
        (async () => {
            try {
                await dispatch(getAllRegions());
            } catch (error) {
                console.log('ERROR', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch]);

    const showDatastudio = () => {
        window.open("https://datastudio.google.com/reporting/666bf523-ecc2-4f0b-aede-1a9b2eecf2fb");
    };

    if (loading) {
        return <Loading />;
    }

    return <div className={styles.container}>
        <Header title="Supplier Overview" action={<Button onClick={showDatastudio}>View in data studio</Button>} />
        <div className={styles.banner}>
            <div className={styles.bannerTitle}>{totalActive}</div>
            <div className={styles.bannerSubtitle}>Total Active Suppliers</div>
        </div>
        <div className={styles.subtitle}>Region Of Operation</div>
        <Table<RegionAccounts>
            data={Object.values(regionSuppliers)}
            columns={columns}
            options={{ paging: false }}
        />
    </div>;
}
