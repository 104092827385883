import { Actions, Types } from "../actions/saaSPlans";
import SaaSPlan from "src/types/model/SaaSPlan";

const initialState: {
    [key:number]: SaaSPlan;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function saaSPlans(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_PLANS: {
            const { payload } = action as ActionValueTypes<Types.SET_PLANS>;
            const plans = payload ?? [] as SaaSPlan[];
            const newState = { ...state };
            plans.forEach(p => newState[p.id] = p);
            return {
                ...newState
            };
        }
        case Types.SET_PLAN: {
            const plan = (action as ActionValueTypes<Types.SET_PLAN>).payload!;
            const newState = { ...state };
            newState[plan.id] = plan;
            return {
                ...state,
            };
        }
        default: {
            return state;
        }
    }
}