import React, { useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import useAsyncEffect from "../../../../../hooks/useAsyncEffect";
import useDispatch from "../../../../../hooks/useDispatch";
import { getEvent } from "../../../../../redux/actions/events";
import Button from "../../../../widgets/button/Button";
import SideBarContainer from "../../../../widgets/sideBarContainer/SideBarContainer";
import Loading from "../../../loading/Loading";
import styles from "./AmotaiEvent.module.scss";
import useSelector from "src/hooks/useSelector";
import RSVPs from "./routes/rsvps/RSVPs";
import Details from "./routes/details/Details";

type RouteParams = {
    eventId: string;
}
export type EventProps = {} & RouteComponentProps<RouteParams>

export default function AmotaiEvent(props: EventProps) {
    const { eventId } = props.match.params;
    const isNew = Number.isNaN(Number(eventId));
    const dispatch = useDispatch();
    const event = useSelector((state) => state.events[eventId]);
    const hasEvent = !!event;
    const [loading, setLoading] = useState(!hasEvent);

    useAsyncEffect(async () => {
        if (hasEvent) {
            return;
        }
        if (!isNew) {
            await dispatch(getEvent(eventId));
        }
        setLoading(false);
    });


    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <SideBarContainer className={styles.event}
            sideBar={(
                <>
                    <Button to={`/events/${eventId}`}>Event Details</Button>
                    <Button to={`/events/${eventId}/rsvps`}>RSVPs</Button>
                </>
            )}>
            <Switch>
                <Route path={"/events/:eventId/rsvps"} component={RSVPs} />
                <Route path={"/events/:eventId"} component={Details} />
            </Switch>
        </SideBarContainer>
    );
}
