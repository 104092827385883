import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import BusinessOwnerAPI from "./api/businessOwner";
import BusinessOwner from "../../types/model/BusinessOwner";

export enum Types {
    SET_BUSINESS_OWNERS = "businessOwners:SET_BUSINESS_OWNERS",
    UPD_BUSINESS_OWNERS = "businessOwners:UPD_BUSINESS_OWNERS"
}

const setBusinessOwners = createAction<BusinessOwner[]>(Types.SET_BUSINESS_OWNERS);
const updBusinessOwners = createAction<{accountId: number, owners: BusinessOwner[]}>(Types.UPD_BUSINESS_OWNERS);


export const Actions = {
    [Types.SET_BUSINESS_OWNERS]: setBusinessOwners,
    [Types.UPD_BUSINESS_OWNERS]: updBusinessOwners,
};

export type Actions = typeof Actions;

export const getBusinessOwners = wrapper(BusinessOwnerAPI.getOwners, {
    action: setBusinessOwners
});

export const createBusinessOwners = wrapper(BusinessOwnerAPI.createOwners, {
    action: setBusinessOwners
});

export const updateBusinessOwners = wrapper(BusinessOwnerAPI.updateOwners, {
    action: (payload, params) => {
        return updBusinessOwners({ accountId: params[0], owners: payload });
    }
});