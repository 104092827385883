import React, { useState, useRef, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useSelector from "src/hooks/useSelector";
import Header from "src/components/widgets/header/Header";
import Button from "src/components/widgets/button/Button";
import { Formik, FormikValues } from "formik";
import FormikInput from "src/components/widgets/input/FormikInput";
import FormikDatePicker from "src/components/widgets/input/FormikDatePicker";
import AmotaiEvent from "src/types/model/AmotaiEvent";
import { createOrUpdateEvent } from "src/redux/actions/events";
import useDispatch from "src/hooks/useDispatch";
import classNames from "classnames";
import { CATEGORIES, REGIONS } from "src/util/constants";
import EventFor from "./components/EventFor";
import Uploader from "./components/Uploader";
import { isPast } from "../../../eventsOverview/AmotaiEvents";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import styles from "../../../supplierDetail/SupplierDetail.module.scss";
import EventDescription from "./components/EventDescription";
import FormikSelect from "../../../../../../widgets/input/FormikSelect";
import FormikNumberInput from "../../../../../../widgets/input/FormikNumberInput";
import { InputAdornment } from "@material-ui/core";

type RouteParams = {
    eventId: string;
}
export type DetailsProps = {} & RouteComponentProps<RouteParams>;

export default function Details(props: DetailsProps) {
    const { eventId } = props.match.params;
    const isNew = Number.isNaN(Number(eventId));
    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef<FormikValues | null>(null);
    const event: AmotaiEvent = useSelector(state => state.events[eventId]) || { date: new Date(), dateRsvp: new Date() };
    const { name } = event;
    const [isEditing, setEditing] = useState(isNew);
    const [error, setError] = useState<string>();
    const [eventEditable, setEventEditable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setEventEditable(isNew || !isPast(event.date));
    }, [isNew, event, setEventEditable]);

    const handleSubmit = async (data: AmotaiEvent) => {
        data.price *= 100;
        console.log("Creating event: ", data);
        try {
            setLoading(true);
            await dispatch(createOrUpdateEvent(data));
            setLoading(false);
            setEditing(false);
            history.replace("/events");
        } catch (err) {
            setError(err.message);
        }
    };
    const onSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    const onCancel = () => {
        if (isNew) {
            history.goBack();
        } else {
            setEditing(false);
        }
    };

    return (
        <div className={styles.details}>

            <Header title={name}
                    action={(isEditing ? (
                                <><Button plain onClick={onCancel} disabled={loading}>Cancel</Button> <Button
                                    onClick={onSubmit} loading={loading}>Save</Button></>
                            ) :
                            eventEditable && <Button onClick={() => setEditing((state) => !state)}>Edit</Button>
                    )}
                /* {isNew ? `Create new event` : name} */
            />

            <Formik<AmotaiEvent> initialValues={{ ...event, price: event.price / 100 }} onSubmit={handleSubmit} innerRef={ref => formRef.current = ref}>
                <div>
                    <SectionHeader title={"Event Details"} />
                    <div className={styles.form}>
                        <FormikInput name={"name"} label={"Event Name"} disabled={!isEditing} required
                                     inputClassname={classNames(styles.half_input, styles.first)} />
                        <FormikDatePicker name={"date"} label={"Event Date"} disabled={!isEditing} required includeTime
                                          className={styles.half_input} />
                        <FormikSelect name={"region"}
                                      label={"Region"}
                                      disabled={!isEditing}
                                      options={REGIONS.map(v => ({ name: v, value: v }))}
                                      required
                                      containerClassName={classNames(styles.half_input, styles.first)} />
                        <FormikDatePicker name={"dateRsvp"} label={"RSVP By"} disabled={!isEditing} required
                                          className={styles.half_input} />
                        <FormikInput name={"price"} label={"Price Per Person"}
                                        InputProps={{ startAdornment: (
                                            <InputAdornment position={"start"}>$</InputAdornment>
                                        ) }}
                                             disabled={!isEditing} required
                                             inputClassname={classNames(styles.half_input, styles.first)} />
                        <FormikSelect name={"category"} label={"Category"} disabled={!isEditing}
                                      options={["All categories", ...Object.keys(CATEGORIES)].map(v => ({
                                          name: v,
                                          value: v
                                      }))}
                                      required
                                      containerClassName={styles.half_input} />
                        {/* <div className={styles.full_input}>
                            <Location name={"address"} label={"Location"} disabled={!isEditing} />
                        </div> */}
                        <FormikInput name={"address"} label={"Location"} disabled={!isEditing} required
                                     inputClassname={styles.full_input} />
                        <FormikNumberInput name={"capacity"}
                                           label={"Event Capacity"}
                                           disabled={!isEditing}
                            // required
                                           inputClassname={classNames(styles.half_input, styles.first)} />
                        <div className={styles.half_input}>
                            <Uploader label={"Event Image"} disabled={!isEditing} />
                        </div>
                        <FormikInput name={"link"} label={"Event external link"} disabled={!isEditing}
                                     inputClassname={classNames(styles.half_input, styles.first)} />
                        <div className={styles.half_input}>
                            <EventFor label={"Event For"} disabled={!isEditing} />
                        </div>
                        <div className={styles.full_input}>
                            <EventDescription name={"description"} label={"Event Detail"} disabled={!isEditing} />
                        </div>
                        <div className={styles.full_input}>
                            <FormikInput name={"termsAndConditions"} multiline fullWidth
                                         label={"Terms and Conditions"}
                                         disabled={!isEditing} required />
                        </div>
                        {!!error && <span>{error}</span>}
                    </div>
                </div>
            </Formik>
            {isEditing && (
                <div className={styles.footer_btns}>
                    <Button onClick={onSubmit} loading={loading}>Save</Button>
                    <Button onClick={onCancel} plain disabled={loading}>Cancel</Button>
                </div>
            )}
        </div>
    );
}