import React, { useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Audits from "src/components/widgets/account/audits/Audits";
import AmotaiConnections from "src/components/widgets/account/connections/AmotaiConnections";
import useAsyncEffect from "src/hooks/useAsyncEffect";
import useUserRoles from "src/hooks/useUserRoles";
import BuyerClient from "src/types/model/BuyerClient";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import { getClientBuyer } from "../../../../../redux/actions/clientsBuyers";
import Notes from "../../../../widgets/account/notes/Notes";
import Users from "../../../../widgets/account/users/Users";
import Button from "../../../../widgets/button/Button";
import SideBarContainer from "../../../../widgets/sideBarContainer/SideBarContainer";
import Loading from "../../../loading/Loading";
import styles from "./ClientBuyerDetail.module.scss";
import Billing from "./routes/billing/Billing";
import Details from "./routes/details/Details";
import Profile from "./routes/profile/Profile";

type RouteParams = {
    clientBuyerId: string;
}
export type ClientBuyerProps = {} & RouteComponentProps<RouteParams>

export default function ClientBuyerDetail(props: ClientBuyerProps) {
    const { match } = props;
    const { params } = match;
    const { clientBuyerId } = params;

    const dispatch = useDispatch();
    const clientBuyer = useSelector<BuyerClient>((state: any) => state.clientsBuyers[clientBuyerId]);
    const [loading, setLoading] = useState(true);
    const { isAdmin, isRegionalManager, isSuperAdmin } = useUserRoles();

    useAsyncEffect(() => {
        (async () => {
            try {
                if (!clientBuyer) {
                    await dispatch(getClientBuyer(clientBuyerId));
                }
            } catch (error) {
                //ignore
            } finally {
                setLoading(false);
            }
        })();
    }, []);


    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <SideBarContainer className={styles.client_buyer}
            sideBar={(
                <>
                    <Button to={`/buyers/${clientBuyerId}`}>Business Details</Button>
                    <Button to={`/buyers/${clientBuyerId}/profile`}>Business Profile</Button>
                    <Button to={`/buyers/${clientBuyerId}/users`}>Users</Button>
                    <Button to={`/buyers/${clientBuyerId}/billings`}>Billing information</Button>
                    <Button to={`/buyers/${clientBuyerId}/connections`}>Connections</Button>
                    <Button to={`/buyers/${clientBuyerId}/notes`}>Notes</Button>
                    {(isAdmin || isRegionalManager || isSuperAdmin) && <Button to={`/buyers/${clientBuyerId}/change-logs`}>Change logs</Button>}
                </>
            )}>
            <Switch>
                <Route path={"/buyers/:accountId/notes"} component={() => <Notes<BuyerClient> amotaiAccount={clientBuyer} />} />
                <Route path={"/buyers/:accountId/users"} component={Users} />
                <Route path={"/buyers/:accountId/connections"} component={() => <AmotaiConnections<BuyerClient> amotaiAccount={clientBuyer} />} />
                <Route path={'/buyers/:clientBuyerId/billings'} component={Billing} />
                <Route path={"/buyers/:clientBuyerId/profile"} component={Profile} />
                <Route path={"/buyers/:accountId/change-logs"} component={Audits} />
                <Route path={"/buyers/:clientBuyerId"} component={Details} />
            </Switch>
        </SideBarContainer>
    );
}
