import React, { useRef, useCallback, MouseEvent } from "react";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover, { PopoverRef } from "../popover/Popover";
import Button from "../button/Button";
import styles from "./Options.module.scss";

type Props<T> = {
    data: T,
    optionButtons?: OptionButton<T>[]
}
export type OptionButton<T> = {
    onPress?: (data: T) => void,
    buttonText: string,
    disabled?: boolean,
    red?: boolean,
}

export default function Options<T>(props: Props<T>) {
    const { data, optionButtons } = props;
    const ref = useRef<PopoverRef | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        ref.current?.show();
    };
    const onPressHandler = useCallback((o: OptionButton<any>) => {
        ref.current?.hide();
        o.onPress?.(data);
    }, [data]);

    return (
        <>
            <IconButton onClick={handleClick}> <MoreVertIcon /> </IconButton>
            <Popover popoverRef={ref}
                     className={styles.popover_options}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: "bottom",
                         horizontal: "right",
                     }}>
                {optionButtons && (
                    optionButtons.map((optionButton, index) => {
                        if (optionButton.onPress) {
                            return (
                                <Button className={classNames(styles.btns, { [styles.red]: optionButton.red })}
                                        disabled={optionButton.disabled}
                                        key={`option-button-${index + 1}`}
                                        onClick={() => onPressHandler(optionButton)}>
                                    {optionButton.buttonText ?? ""}
                                </Button>
                            );
                        }
                        return <></>;
                    })
                )

                }
            </Popover>
        </>
    );
}