import ErrorIcon from '@material-ui/icons/Error';
import React, { ReactNode } from 'react';
import { renderToString } from "react-dom/server";
import ReactTooltip from 'react-tooltip';
import styles from './Tips.module.scss';

type Props = {
    tips: string,
    icon?: ReactNode,
}

export default function Tips(props: Props) {
    const { tips, icon } = props;
    const href = /https?:\/\//.test(tips) ? tips : undefined;
    const content = renderToString(
        href ?
            <a className={styles.tip_link} href={href} target="_blank" rel="noopener noreferrer">{tips}</a> :
            <span>{tips}</span>
    );
    return (
        <>
            <div data-html data-tip={content}>
                {icon ?? <ErrorIcon />}
            </div>
            <ReactTooltip
                place="bottom"
                effect="solid"
                multiline
                clickable
                className={styles.tooltip}
            />
        </>
    );
}