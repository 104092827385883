import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import AssignManagerDialog from "src/components/widgets/assignManagerDialog/AssignManagerDialog";
import DeleteAccountDialog from "src/components/widgets/dialog/accountDialog/DeleteAccountDialog";
import { DialogRef } from "src/components/widgets/dialog/Dialog";
import FormikDatePicker from "src/components/widgets/input/FormikDatePicker";
import Select from "src/components/widgets/input/Select";
import useUserRoles from "src/hooks/useUserRoles";
import { showError } from "src/redux/actions/snackbars";
import { getInductableUsers, getRegionalManagers } from "src/redux/actions/users";
import { AccountStatus, InductStatus } from "src/types/model/AmotaiAccount";
import AmotaiUser from "src/types/model/AmotaiUser";
import BuyerClient from "src/types/model/BuyerClient";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { getMainCategories } from "../../../../../../../redux/actions/categories";
import { inductClientBuyer, updateClientBuyer } from "../../../../../../../redux/actions/clientsBuyers";
import Address from "../../../../../../../types/model/Address";
import { Category } from "../../../../../../../types/model/Category";
import {
    ANNUAL_ADDRESSABLE_SPEND,
    ANNUAL_INDIRECT_SPEND,
    BUYER_TURNOVER,
    HEALTH_AND_SAFETY,
    INSURANCES,
    LEGAL_STRUCTURE,
    REGIONS, REGIONS_NO_NATIONWIDE
} from "../../../../../../../util/constants";
import Button from "../../../../../../widgets/button/Button";
import Header from "../../../../../../widgets/header/Header";
import FormikAddressInput from "../../../../../../widgets/input/FormikAddressInput";
import FormikCheckboxGroup from "../../../../../../widgets/input/FormikCheckboxGroup";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import FormikNumberInput from "../../../../../../widgets/input/FormikNumberInput";
import FormikSelect from "../../../../../../widgets/input/FormikSelect";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import Loading from "../../../../../loading/Loading";
import styles from "./Details.module.scss";

type RouteParams = {
    clientBuyerId: string;
};

export type BuyerClientFormValues = {
    inductedAt?: string;
    inductedBy?: string;
    name: string;
    legalStructure: string;
    // category: number | null;
    categoryName: string | null;
    size: number;
    regions: string[];
    postalAddress: string;
    physicalAddress: string;
    physicalAddressInput: string;
    physicalAddressComponents: Address;
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    socialProcurementInitiatives: string;
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];
    iwiBuyer: boolean;
    regionBased: string;
};

const BuyerClientValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    size: Yup.number().required("Required"),
    regions: Yup.array().min(1, "At least one region is required"),
    postalAddress: Yup.string(),
    physicalAddress: Yup.string(),
    physicalAddressComponents: Yup.object().required("Required").nullable(),
    annualTurnover: Yup.string().required("Required"),
    annualAddressableSpend: Yup.string().required("Required"),
    approxAnnualIndirectSpend: Yup.string().required("Required"),
    socialProcurementInitiatives: Yup.string().required("Required"),
    minimumLevelOfInsuranceForSubContract: Yup.string().required("Required"),
    healthAndSafetyForSubContract: Yup.array().min(1, "At least one option must be selected"),
    regionBased: Yup.string().required("Required")
});

export default function Details(props: RouteComponentProps<RouteParams>) {
    const id: any = props?.match?.params?.clientBuyerId!; //id must be exists, parent component has already loaded
    const categories = useSelector<Category[]>(state => state.categories.main);
    const assignManagerDialogRef = useRef<DialogRef>(null);
    const buyer: BuyerClient = useSelector((state: any) => state.clientsBuyers[id]);
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [inductStatus, setInductStatus] = useState<string>(buyer.inductedAt ? InductStatus.INDUCTED : InductStatus.UNINDUCTED);
    const [inductHelperText, setInductHelperText] = useState<string>();
    const dispatch = useDispatch();
    const { isAdmin, isSuperAdmin, isRegionalManager } = useUserRoles();
    const regionalManagers = useSelector(state => state.managers.regionalManagers);
    const inductableUsers = useSelector(state => state.users.inductableUsers);
    const deleteDialog = useRef<DialogRef | null>(null);
    const history = useHistory();

    const categoryOptions = useMemo(() => categories.map(category => category.name), [categories]);

    const [regionalManager, setRegionalManager] = useState<AmotaiUser>(regionalManagers[buyer.assignedTo]);

    useMountEffect(async () => {
        try {
            if (Object.keys(regionalManagers).length === 0) {
                const result = await dispatch(getRegionalManagers());
                const _managers = result.items.find(m => m.id === buyer.assignedTo);
                if (_managers) {
                    setRegionalManager(_managers);
                }
            }
            if (!categories || !categories.length) {
                await dispatch(getMainCategories());
            }
            if (!inductableUsers) {
                dispatch(getInductableUsers()); //don't await as this value is not required immediately
            }
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const inductableUsersOption = useMemo((): Array<{ label: string, value: string }> => {
        if (!inductableUsers) {
            return [];
        }
        return inductableUsers
            .sort((a, b) => (a.name ?? 'z').localeCompare(b.name ?? 'z'))
            .map(u => ({
                label: `${u.name ?? '-'} (${u.email})`,
                value: u.id,
            })) ?? [];
    }, [inductableUsers]);

    const inductedBy = useMemo((): string | undefined => {
        return buyer.inductedBy ? inductableUsers?.find(u => u.id === buyer.inductedBy)?.name : undefined;
    }, [inductableUsers, buyer]);

    const initialCategory = buyer.category?.name ?? null;
    const accountStatusText = useMemo(() => {
        const { suspensionMessage, reviewMessage } = buyer;
        let status = buyer.status?.toLowerCase().replace(/^\w/, v => v.toUpperCase()).replace(/_\w?/, v => v.toUpperCase()).replace('_', ' ');
        // const statusAt = (statusUpdatedAt ? `on ${new Date(statusUpdatedAt).toLocaleDateString("en-NZ")}` : '');
        let message = '';
        switch (buyer.status) {
            case AccountStatus.DISABLED: //suspension message
                status = `On Hold`;
                message = `: ${suspensionMessage ?? 'No reason appended'}`;
                break;
            case AccountStatus.DECLINED: //review message
                message = `: ${reviewMessage ?? 'No reason appended'}`;
                break;
            default:
                break;
        }

        return <span>This account is <b>{status}</b>{message}</span>;
    }, [buyer]);

    const initialValues: BuyerClientFormValues = {
        inductedAt: buyer.inductedAt,
        inductedBy: buyer.inductedBy,
        name: buyer.name || "",
        legalStructure: buyer.legalStructure || "",
        categoryName: initialCategory || null,
        size: buyer.size || 0,
        regions: buyer.regions || [],
        postalAddress: buyer.postalAddress || "",
        physicalAddress: buyer.physicalAddress || "",
        physicalAddressInput: buyer.physicalAddressComponents?.formattedAddress || "",
        physicalAddressComponents: buyer.physicalAddressComponents || null,
        annualTurnover: buyer.annualTurnover || "",
        annualAddressableSpend: buyer.annualAddressableSpend || "",
        approxAnnualIndirectSpend: buyer.approxAnnualIndirectSpend || "",
        socialProcurementInitiatives: buyer.socialProcurementInitiatives || "",
        minimumLevelOfInsuranceForSubContract: buyer.minimumLevelOfInsuranceForSubContract || "",
        healthAndSafetyForSubContract: buyer.healthAndSafetyForSubContract || [],
        iwiBuyer: buyer.iwiBuyer,
        regionBased: buyer.regionBased,
    };

    const onSubmit = async (values: BuyerClientFormValues) => {
        try {
            setSubmitting(true);
            if (isAdmin || isRegionalManager) {
                await dispatch(inductClientBuyer(buyer.id, { inductedAt: values.inductedAt, inductedBy: values.inductedBy }));
            } else {
                const { inductedBy, inductedAt } = values;
                const category = categories.find(c => c.name === values.categoryName) ?? null;
                await dispatch(updateClientBuyer({
                    ...buyer,
                    ...values,
                    category,
                    inductedAt,
                    inductedBy,
                }));
            }
            setSubmitting(false);
        } catch (e) {
            dispatch(showError(e.message));
            setSubmitting(false);
            setLoading(false);
        }
    };

    const onDeleteFinish = useCallback(() => {
        history.push('/buyers');
    }, [history]);

    if (submitting || loading) {
        return <Loading />;
    }

    const onAssignManager = () => {
        assignManagerDialogRef.current?.show();
    };
    const addManagerButton = !isSuperAdmin ?
        null :
        (
            <Button plain className={styles.add_rm_button} onClick={onAssignManager}>
                Assign a buyer manager
            </Button>
        );

    const isIwi = !!buyer?.iwiBuyer;
    const disableFields = !editing || isAdmin || isRegionalManager;
    return (
        <>
            <Formik<BuyerClientFormValues> validationSchema={BuyerClientValidationSchema}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}>
                {(formikBag) => {
                    const toggleEdit = async () => {
                        if (editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyForSubContract)) {
                            setInductHelperText(undefined);
                            if (inductStatus === InductStatus.INDUCTED && (!formikBag.values.inductedAt || !formikBag.values.inductedBy)) {
                                setInductHelperText("Inducted at/by fields should not be empty");
                                return;
                            }
                            await onSubmit(formikBag.values);
                        }

                        setEditing(!editing);
                        if (!editing) {
                            formikBag.resetForm();
                        }
                    };

                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyForSubContract) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }
                    const inductContainerClassnames = classNames(styles.induct_container, {
                        [styles.induct_active]: !!buyer.inductedAt && !editing,
                        [styles.induct_editing]: editing
                    });

                    return (
                        <Form>
                            <Header title={`${buyer.name}`}
                                action={isSuperAdmin || isAdmin || isRegionalManager ? (
                                    <Button uppercase loading={submitting || formikBag.isSubmitting}
                                        onClick={toggleEdit}>{actionText}</Button>
                                ) : undefined}
                                subnodes={<AccountHistory account={buyer} />} />
                            <div className={styles.account_status}>
                                {accountStatusText}
                            </div>
                            <div className={inductContainerClassnames}>
                                <div className={styles.induct}>
                                    <span>This account &nbsp;</span>
                                    {editing ? (
                                        <div className={styles.pickers}>
                                            <Select options={[InductStatus.INDUCTED, InductStatus.UNINDUCTED]} noPadding
                                                containerClassName={styles.selector}
                                                defaultValue={buyer.inductedAt ? InductStatus.INDUCTED : InductStatus.UNINDUCTED}
                                                onChange={(iStatus: string) => {
                                                    if (iStatus === InductStatus.UNINDUCTED) {
                                                        formikBag.setFieldValue("inductedAt", undefined);
                                                        formikBag.setFieldValue('inductedBy', undefined);
                                                    }
                                                    setInductStatus(iStatus);
                                                }} />
                                            {inductStatus === InductStatus.INDUCTED && (
                                                <div className={styles.induct_by_at}>
                                                    <FormikDatePicker dateStringOnly dateStringFormat={"DD/MM/YYYY"}
                                                        name={"inductedAt"}
                                                        className={styles.date_picker}
                                                        onChange={() => setInductHelperText(undefined)} />
                                                    <FormikSelect name={"inductedBy"} placeholder="please select"
                                                        options={inductableUsersOption}
                                                        containerClassName={styles.selector_by}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            <b><span>{buyer.inductedAt ? "was" : "has not been"}</span></b>
                                            <span>&nbsp; inducted</span>
                                            {!!buyer.inductedAt && <>
                                                <span>&nbsp; on &nbsp;<b>{buyer.inductedAt}</b></span>
                                                {!!inductedBy && <span>&nbsp; by &nbsp;<b>{inductedBy}</b></span>}
                                            </>}
                                        </div>
                                    )}
                                </div>
                                {inductHelperText && <span className={styles.induct_helper}>{inductHelperText}</span>}
                            </div>
                            {(isAdmin || isSuperAdmin || isRegionalManager) && (
                                <div className={styles.regional_manager_section}>
                                    <SectionHeader title={"Buyer Manager"} action={addManagerButton} />
                                    <span>
                                        {regionalManager?.name ?? "This account does not have a buyer manager assigned."}
                                    </span>
                                </div>
                            )}
                            <SectionHeader title={"Business Details"} />
                            <div className={styles.form}>
                                {buyer.iwiAffiliations && buyer.iwiAffiliations.length > 0 && (
                                    <div className={styles.iwi_affiliation}>
                                        <div className={styles.label}>Iwi name</div>
                                        {buyer.iwiAffiliations.join("")}
                                    </div>
                                )}
                                <div className={styles.form_section}>
                                    <FormikInput name={"name"}
                                        label={"Organisation Name"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={disableFields} />
                                    <FormikSelect name={"legalStructure"}
                                        label={"Structure"}
                                        containerClassName={styles.half_input}
                                        options={LEGAL_STRUCTURE}
                                        disabled={disableFields} />
                                    {!isIwi && (
                                        <FormikSelect name={"categoryName"}
                                            label={"Primary Industry"}
                                            containerClassName={styles.full_input}
                                            options={categoryOptions}
                                            disabled={disableFields} />
                                    )}
                                    <FormikInput name={"postalAddress"}
                                        inputClassname={styles.full_input}
                                        disabled
                                        placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                                        label={"Postal Address Old"}
                                    />
                                    <FormikInput name={"postalAddress"}
                                        inputClassname={classNames(styles.full_input)}
                                        placeholder={formikBag.values.postalAddress}
                                        disabled={disableFields}
                                        label={"Postal Address"} />
                                    <FormikInput name={"physicalAddress"}
                                        inputClassname={styles.full_input}
                                        disabled
                                        placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                                        label={"Physical Address Old"}
                                    />
                                    <FormikAddressInput name={"physicalAddressComponents"}
                                        formattedName={"physicalAddressInput"}
                                        inputClassname={classNames(styles.full_input)}
                                        disabled={disableFields}
                                        label={"Physical Address"} />
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Regions"} />
                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Regions of operation</label>
                                        </div>
                                        <FormikCheckboxGroup name={"regions"} disabled={disableFields} stringItems={REGIONS} />
                                    </div>

                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Region based</label>
                                        </div>
                                        <FormikSelect name={"regionBased"} disabled={disableFields}
                                            options={REGIONS_NO_NATIONWIDE} />
                                    </div>
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Financial & Employment"} />
                                    <FormikNumberInput name={"size"}
                                        label={"Size of workforce (Total number of Employees)"}
                                        disabled={disableFields}
                                        inputClassname={styles.full_input} />
                                    <FormikSelect name={"annualTurnover"}
                                        label={"Annual turnover"}
                                        options={BUYER_TURNOVER}
                                        disabled={disableFields}
                                        containerClassName={classNames(styles.half_input, styles.first)}
                                    />
                                    <FormikSelect name={"annualAddressableSpend"}
                                        label={"Annual addressable spend"}
                                        options={ANNUAL_ADDRESSABLE_SPEND}
                                        disabled={disableFields}
                                        containerClassName={styles.half_input}
                                    />
                                    <FormikSelect name={"approxAnnualIndirectSpend"}
                                        label={"Approx annual indirect spend"}
                                        options={ANNUAL_INDIRECT_SPEND}
                                        disabled={disableFields}
                                    />

                                    <FormikInput name={"socialProcurementInitiatives"}
                                        multiline
                                        label={"Existing Social Procurement Initiatives"}
                                        inputClassname={styles.full_text_input}
                                        disabled={disableFields} />
                                </div>

                                <div className={styles.form_section}>
                                    <SectionHeader title={"Subcontractor Requirements"} />
                                    <div
                                        className={classNames(styles.form_section, styles.health_safety_container)}>
                                        <label>
                                            Minimum Health and Safety Prequalifications Required for Subcontactors
                                        </label>
                                        <FormikCheckboxGroup name={"healthAndSafetyForSubContract"}
                                            stringItems={HEALTH_AND_SAFETY}
                                            disabled={disableFields} />
                                    </div>
                                    <FormikSelect name={"minimumLevelOfInsuranceForSubContract"}
                                        options={INSURANCES}
                                        label={"Minimum Level of Insurance Required for Subcontractors"}
                                        disabled={disableFields} />
                                </div>
                            </div>
                            <AssignManagerDialog ref={assignManagerDialogRef} account={buyer} onFinish={setRegionalManager} />

                        </Form>
                    );
                }}
            </Formik>

            {isSuperAdmin && buyer.status !== AccountStatus.DELETED &&
                <div style={{ textAlign: "right" }}>
                    <Button red onClick={() => deleteDialog.current?.show()} disabled={!editing}>Delete account</Button>
                </div>}
            <DeleteAccountDialog ref={deleteDialog} account={buyer} onFinish={onDeleteFinish} />
        </>
    );
}
