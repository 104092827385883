import BuyerClient from "./BuyerClient";
import Supplier from "./Supplier";

export enum SystemRole {
    USER = "USER",
    SUPPLIER_VIEWER = "SUPPLIER_VIEWER",
    REGIONAL_MANAGER = "REGIONAL_MANAGER",
    ADMIN = "ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
}

export const AmotaiAdmins = [SystemRole.REGIONAL_MANAGER, SystemRole.ADMIN, SystemRole.SUPER_ADMIN];

export enum AccountRole {
    USER = "USER",
    ADMIN = "ADMIN",
}

export enum UserStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
    PENDING_INDUCTION = "PENDING_INDUCTION"
}

export default interface AmotaiUser {
    id: string;
    email: string;
    systemRole: SystemRole;

    account: number;
    accountRole: AccountRole
    jobTitle: string;
    assignedRegions: string[];
    phoneNumber: string;
    mobileNumber?: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    regions?: string[];
    assignedBusinessCount?: number;
    avatar: string;

    status: UserStatus;
    name: string;

    inductedBusiness?: Array<Supplier | BuyerClient>;
    uninductedBusiness?: Array<Supplier | BuyerClient>;

};;;;;;;;;;