/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { Dispatch, SetStateAction, useState } from "react";
import useMountState from "./useMountState";

export default function useSafeState<S>(initialState: (S | (() => S))): [S, Dispatch<SetStateAction<S>>] {
    const [state, setState] = useState<S>(initialState);
    const mounted = useMountState();

    const setStateSafe = (newState: S | ((prevState: S) => S)) => {
        if (!mounted.current) {
            return;
        }
        setState(newState);
    };

    return [state, setStateSafe];
}
