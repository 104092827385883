import AmotaiUser from "src/types/model/AmotaiUser";
import ListResult from "../../../types/ListResult";
import AmotaiAccount, { AccountStatus, AccountType } from "../../../types/model/AmotaiAccount";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

// function getAllAccounts(limit: number, cursor?: string | null): Promise<ListResult<AmotaiAccount>> {
//     return get("/v1/admin/accounts/all", { params: { limit, cursor } });
// }

// function getPendingCount(type?: string): Promise<number> {
//     return get("/v1/admin/accounts/pending", { params: { type } });
// }

function changeAccountStatus(accountId: number, status: AccountStatus): Promise<AmotaiAccount> {
    return put(`/v1/admin/account/${accountId}/status?status=${status}`);
}

// function searchByTypeAndKeyword<T extends AmotaiAccount>(accountType: AccountType, keyword: string,
//     limit: number, cursor?: string | null): Promise<ListResult<T>> {
//     return get("/v1/directory", { params: { type: accountType.toLowerCase(), keyword, limit, cursor } });
// }

function filterAccounts<T extends AmotaiAccount>(accountType: AccountType): Promise<Array<T>> {
    return get(`v1/admin/accounts/${accountType}`);
}

function getUserAssignedAccounts(userId: string): Promise<Array<AmotaiAccount>> {
    return get(`v1/admin/users/${userId}/accounts`);
}

function getFilteredAccounts(assignedTo?: string, region?: string, type?: string, name?: string, cursor?: string, limit?: number): Promise<ListResult<AmotaiAccount>> {
    const params = {
        assignedTo,
        region,
        type,
        name,
        cursor,
        limit
    };
    return get("v1/admin/accounts/filtered", { params });
}

function getAccountById(accountId: number): Promise<AmotaiAccount> {
    return get(`/v1/accounts/${accountId}`);
}

function updateAccountMainContact(accountId: number, userId: string): Promise<AmotaiAccount> {
    return put(`v1/accounts/${accountId}/main-contact/${userId}`);
}

function getAccountAdmins(accountId: number): Promise<AmotaiUser[]> {
    return get(`v1/accounts/${accountId}/admins`);
}

function deleteAccountByAdmin(accountId: number): Promise<void> {
    return del(`v1/accounts/${accountId}`);
}

function queryAccountsByAdmin(request: { keyword?: string | null, basedRegions: string[], type: AccountType }, limit: number, cursor?: string | null): Promise<ListResult<AmotaiAccount>> {
    return post("v1/admin/accounts", request, { limit, cursor });
}

function getAccounts(request: { type?: AccountType, status?: AccountStatus }, cursor?: string | null, limit?: number): Promise<ListResult<AmotaiAccount>> {
    return get('v1/admin/accounts', {
        params: {
            ...request,
            cursor,
            limit,
        }
    });
}

function getAccountsCount(params: { type?: AccountType, status?: AccountStatus }): Promise<number> {
    return get('v1/admin/accounts/count', { params });
}

const AccountsAPI = {
    // getAllAccounts, //todo: remove
    // getPendingCount,
    changeAccountStatus,
    getFilteredAccounts,
    getUserAssignedAccounts,
    filterAccounts,
    getAccountById,
    updateAccountMainContact,
    getAccountAdmins,
    deleteAccountByAdmin,
    queryAccountsByAdmin,
    getAccounts,
    getAccountsCount,
};

export default AccountsAPI;