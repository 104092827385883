import SaaSSubscription, { SaaSSubscriptionUpdateRequest } from "../../../types/model/SaaSSubscription";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getSubscriptionForAccount(accountId: number | string): Promise<SaaSSubscription> {
    return get(`v1/accounts/${accountId}/subscription`);
}

function updateSubscriptionAdmin(accountId: number | string, request: SaaSSubscriptionUpdateRequest) {
    return put(`v1/accounts/${accountId}/subscription`, request);
}

function updateNextBillingDateAdmin(subscriptionId: number | string, extendTo: string) {
    return put(`v1/admin/subscriptions/${subscriptionId}/term`, undefined, { extendTo });
}

function createSubscriptionAdmin(accountId: number, planId: number, payByInvoice: boolean = false) {
    return post(`v1/admin/accounts/${accountId}/plans/${planId}/subscription?payByInvoice=${payByInvoice}`,);
}

function adminChargeSubscription(subscriptionId: number): Promise<SaaSSubscription> {
    return post(`v1/admin/subscriptions/${subscriptionId}`);
}

const SaaSSubscriptionsAPI = {
    getSubscriptionForAccount,
    updateSubscriptionAdmin,
    updateNextBillingDateAdmin,
    createSubscriptionAdmin,
    adminChargeSubscription,
};

export default SaaSSubscriptionsAPI;