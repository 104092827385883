import React, { useMemo, useCallback } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact, RenderElementProps, RenderLeafProps } from "slate-react";
import Box from "@material-ui/core/Box";

type Props = {
    value: string
}
enum Format {
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
    code = 'code',
    quote = 'quote',
    numbered = 'numbered',
    bulleted = 'bulleted',
    list = 'list',
    heading1 = 'heading1',
    heading2 = 'heading2',
    heading3 = 'heading3',
    heading4 = 'heading4',
    heading5 = 'heading5',
}

export default function RichEditorViewer({ value }: Props) {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(createEditor()), []);
    return (
        <Box p={1} m={2}>
            <Slate editor={editor} value={deserialize(value)} onChange={() => { /**/ }}>
                <Editable
                    readOnly
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </Box>
    );
};

export const Element = ({ attributes, children, element }: RenderElementProps) => {
    switch (element.type) {
        case Format.quote:
            return <blockquote {...attributes}>{children}</blockquote>;
        case Format.bulleted:
            return <ul {...attributes}>{children}</ul>;
        case Format.numbered:
            return <ol {...attributes}>{children}</ol>;
        case Format.list:
            return <li {...attributes}>{children}</li>;
        case Format.heading1:
            return <h1 {...attributes}>{children}</h1>;
        case Format.heading2:
            return <h2 {...attributes}>{children}</h2>;
        case Format.heading3:
            return <h3 {...attributes}>{children}</h3>;
        case Format.heading4:
            return <h4 {...attributes}>{children}</h4>;
        case Format.heading5:
            return <h5 {...attributes}>{children}</h5>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const deserialize = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (error) {
        return [{ type: "paragraph", children: [{ text: "" }] }];
    }
};