import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import BusinessContactAPI from "./api/businessContacts";
import BusinessContact from "../../types/model/BusinessContact";

export enum Types {
    SET_BUSINESS_CONTACTS = "businessContacts:SET_BUSINESS_CONTACTS"
}

export const setBusinessContacts = createAction<BusinessContact[]>(Types.SET_BUSINESS_CONTACTS);

export const Actions = {
    [Types.SET_BUSINESS_CONTACTS]: setBusinessContacts
};

export type Actions = typeof Actions;

export const getBusinessContacts = wrapper(BusinessContactAPI.getBusinessContacts, {
    action: setBusinessContacts
});

export const createBusinessContacts = wrapper(BusinessContactAPI.createBusinessContacts, {
    action: setBusinessContacts
});

export const updateBusinessContacts = wrapper(BusinessContactAPI.updateBusinessContacts, {
    action: setBusinessContacts
});
