import AmotaiEvent, { EventRSVP as EventRsvp } from "src/types/model/AmotaiEvent";
import get from "./util/get";
import ListResult from "src/types/ListResult";
import post from "./util/post";
import del from "./util/del";
/**
 * get paging events
 * @param onGoing true to get active events, false to get expired/past events
 * @param limit 
 * @param cursor 
 */
function getEvents(onGoing: boolean, limit: number, cursor?: string | null):Promise<ListResult<AmotaiEvent>> {
    return get(`/v1/events`, {
        params: {
            onGoing,
            limit,
            cursor
        }
    });
}
function getEvent(eventId:string):Promise<AmotaiEvent> {
    return get(`/v1/events/${eventId}`);
}
function createOrUpdateEvent(eventDTO: AmotaiEvent):Promise<AmotaiEvent> {
    return post(`/v1/events`, eventDTO);
}
function deleteEvent(eventId:string):Promise<AmotaiEvent> {
    return del(`/v1/events/${eventId}`);
}
function getEventRsvps(eventId:string, limit: number, cursor?: string | null):Promise<ListResult<EventRsvp>> {
    return get(`v1/events/${eventId}/registrations`, {
        params: {
            limit,
            cursor
        }
    });
}

const EventAPI = {
    getEvents,
    getEvent,
    createOrUpdateEvent,
    deleteEvent,
    getEventRsvps,
};
export default EventAPI;