/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { ThemeOptions } from "@material-ui/core";


const theme: ThemeOptions = {
    typography: {
        fontFamily: "Poppins",
    },
    spacing: 9,
    palette: {
        primary: {
            main: "#00354E",
        },
        secondary: {
            main: "#008997",
        },
    },
    overrides: {
        // MuiSvgIcon: {
        //     root: {
        //         color: "#444444"
        //     },
        // },
        // MuiInputBase: {
        //     input: {
        //         height: 34,
        //     },
        // },
        // MuiInput: {
        //     root: {
        //         border: "1px solid #CDCDCD",
        //         borderRadius: 4,
        //         minWidth: 204,
        //     },
        //     input: {
        //         minHeight: 28,
        //         fontSize: 16,
        //         padding: "0px 9px",
        //         boxShadow: "0 .125rem .625rem rgba(90, 97, 105, .12)",
        //     },
        //     formControl: {
        //         "label + &": {
        //             marginTop: 20,
        //         },
        //     },
        // },
        // MuiFormControl: {
        //     root: {
        //         display: "flex",
        //     },
        // },
        // MuiInputAdornment: {
        //     root: {},
        //     positionStart: {
        //         marginRight: 9,
        //         marginLeft: 9,
        //     },
        //     positionEnd: {
        //         marginRight: 9,
        //         marginLeft: 9,
        //     },
        //
        // },
        // MuiSelect: {
        //     select: {
        //         display: "flex",
        //         alignItems: "center",
        //         minWidth: 171,
        //     },
        //     selectMenu: {
        //         height: undefined,
        //     },
        // },
        // MuiInputLabel: {
        //     root: {
        //         fontWeight: "bold",
        //         color: "#444444"
        //     },
        // },
        // MuiFormLabel: {
        //     root: {
        //         color: "#444444"
        //     },
        // },
        // MuiTabs: {
        //     indicator: {
        //         backgroundColor: "#1a73e8",
        //     },
        // },
        // MuiIconButton: {
        //
        // },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
        MuiButton: {
            variant: "contained"
        },
    },
};

export default theme;
