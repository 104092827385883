/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { PropsWithChildren } from "react";
import useSelector from "../../../hooks/useSelector";
import Snackbar from "./Snackbar";
import styles from "./SnackbarContainer.module.scss";

export default function SnackbarContainer({ children }: PropsWithChildren<{}>) {
    const snackbars = useSelector((state) => state.snackbars);
    return (
        <>
            {children}
            <div className={styles.snackbar_container}>
                {snackbars.map((config) => (
                    <Snackbar key={config.id} config={config} />
                ))}
            </div>
        </>
    );
}
