import Address from "./Address";
import BusinessContact from "./BusinessContact";
import BuyerClient from "./BuyerClient";
import { Category } from "./Category";
import Supplier from "./Supplier";

export enum AccountType {
    BUYER_CLIENT = "BUYER_CLIENT",
    SUPPLIER = "SUPPLIER",
}

export enum AccountStatus {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    DISABLED = "DISABLED",
    DECLINED = "DECLINED",
    PENDING_REVIEW = "PENDING_REVIEW",
    AWAITING_PAYMENT = "AWAITING_PAYMENT",
}

export const VALID_ACCOUNT_STATUS = [
    AccountStatus.ACTIVE,
    AccountStatus.AWAITING_PAYMENT,
    AccountStatus.PENDING_REVIEW
];

export enum PaymentType {
    STRIPE = "STRIPE",
    INVOICE = "INVOICE",
}


export default interface AmotaiAccount {
    id: number;
    type: AccountType;
    status: AccountStatus;
    stripeCustomerId: string;
    name: string;
    legalStructure: string;
    phoneNumber: string;
    email: string;
    description: string;
    logo: string;
    mainContact?: BusinessContact;
    assignedTo: string;
    sourceToken: string;
    invoiceBuyer: boolean;
    funderBuyer: boolean;
    iwiBuyer: boolean;
    regions: string[];
    category: Category;
    createdAt: string;
    reviewMessage: string | null;
    suspensionMessage: string | null;
    paymentType: PaymentType;
    inductedAt?: string;
    inductedBy?: string;
    postalAddress?: string;
    physicalAddressComponents: Address;
    regionBased: string;
};;;;;;;;;;

export enum InductStatus {
    INDUCTED = "has been inducted",
    UNINDUCTED = "has not been inducted"
}

export interface RegionAccounts {
    region: string;
    number: number;
    accounts?: Array<Supplier | BuyerClient>
}

export const nullRegion = "Others";


export type InviteUserDTO = {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    phoneNumber: string;
    jobTitle: string;
    accountRole: string;
}
