import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getMe, setMe } from "src/redux/actions/user";
import useDispatch from "../hooks/useDispatch";
import useMountEffect from "../hooks/useMountEffect";
import Dashboard from "./routes/dashboard/Dashboard";
import Loading from "./routes/loading/Loading";
import Login from "./routes/login/Login";
import ForgotPassword from "./routes/forgot-password/ForgotPassword";
import { AmotaiAdmins } from "../types/model/AmotaiUser";
import InvitedUser from "./routes/invitedUser/InvitedUser";
import { getAuth, User } from "firebase/auth";
import Receipt from "./widgets/receipt/Receipt";
import useUserRoles from "src/hooks/useUserRoles";

export type MainProps = {}

export default function Main() {

    const dispatch = useDispatch();
    const { user } = useUserRoles();
    const [loading, setLoading] = useState(true);
    const onAuthStateChanged = async (firebaseUser: User | null) => {
        if (firebaseUser) {
            try {
                const user = await dispatch(getMe());

                if (!user || !AmotaiAdmins.includes(user.systemRole)) {
                    dispatch(setMe(null));
                }

            } catch (e) {
                console.log("error", e);
            }
        }

        setLoading(false);
    };

    useMountEffect(() => {
        getAuth().onAuthStateChanged(onAuthStateChanged);
    });

    return (
        <Router>
            <Switch>
                {loading && <Route path={"*"} component={Loading} />}
                <Route path={"/receipt"} component={Receipt} />
                {!user ? (
                    <Switch>
                        <Route path={"/user-invited"} component={InvitedUser} />
                        <Route path={"/forgot-password"} component={ForgotPassword} />
                        <Route path={"*"} component={Login} />
                    </Switch>
                )
                    : (
                        <Switch>
                            <Route path={"/forgot-password"} component={ForgotPassword} />
                            <Route path={"*"} component={Dashboard} />
                        </Switch>
                    )
                }
            </Switch>
        </Router>
    );
}
