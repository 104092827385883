import { Form, Formik } from "formik";
import React, { Ref, useState, useMemo } from "react";
import * as Yup from "yup";
import classNames from "classnames";
import Dialog, { DialogRef } from "../dialog/Dialog";
import AmotaiAccount, { AccountStatus, AccountType } from "../../../types/model/AmotaiAccount";
import Button from "../button/Button";
import Supplier from "../../../types/model/Supplier";
import useForwardRef from "../../../hooks/useForwardRef";
import FormikSelect from "../input/FormikSelect";
import styles from "./AccountReviewDialog.module.scss";
import FormikInput from "../input/FormikInput";
import AccountsAPI from "../../../redux/actions/api/accounts";
import useDispatch from "../../../hooks/useDispatch";
import { updateSupplier } from "../../../redux/actions/suppliers";
import { updateClientBuyer } from "../../../redux/actions/clientsBuyers";
import BuyerClient from "../../../types/model/BuyerClient";

type Props = {
    account: AmotaiAccount | Supplier | BuyerClient | undefined;
    onStatusSubmit?: () => void;
}

type StatusChangeFormValues = {
    newStatus: AccountStatus;
    reviewMessage: string | null;
    suspensionMessage: string | null;
}

const validationSchema = Yup.object({
    suspensionMessage: Yup.string().nullable()
        .when("newStatus", {
            is: AccountStatus.DISABLED,
            then: Yup.string().required('Required'),
        }),
    reviewMessage: Yup.string().nullable()
        .when("newStatus", {
            is: AccountStatus.DECLINED,
            then: Yup.string().required('Required')
        }),
    newStatus: Yup.string()
});

function AccountReviewDialog(props: Props, ref: Ref<DialogRef>) {
    const { account, onStatusSubmit } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);


    const submitStatusChange = async (values: StatusChangeFormValues) => {
        setLoading(true);

        try {
            const updatedAccount = await AccountsAPI.changeAccountStatus(props?.account?.id!, values.newStatus);
            if (updatedAccount.type === AccountType.BUYER_CLIENT) {
                const accountDTO = updatedAccount as BuyerClient;
                accountDTO.reviewMessage = values.reviewMessage;
                accountDTO.suspensionMessage = values.suspensionMessage;

                await dispatch(updateClientBuyer(accountDTO));
            } else if (updatedAccount.type === AccountType.SUPPLIER) {
                const accountDTO = updatedAccount as Supplier;
                accountDTO.reviewMessage = values.reviewMessage;
                accountDTO.suspensionMessage = values.suspensionMessage;

                await dispatch(updateSupplier(accountDTO));
            }

            if (onStatusSubmit) {
                onStatusSubmit();
            }

            dialogRef?.current?.hide();
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const onCancel = () => {
        dialogRef?.current?.hide();
    };

    const decliningMessageOptions = useMemo((): string[] => {
        let options: string[] = [];
        if (account?.type === AccountType.BUYER_CLIENT) {
            options = [
                'Registered under incorrect membership',
                'Risk, integrity, and conflicts policy',
                'Duplicate registration',
                'Withdrawn from membership',
            ];
        } else if (account?.type === AccountType.SUPPLIER) {
            options = [
                'Ownership criteria not been meet at initial registration',
                'Ownership criteria not been meet at annual review',
                'Business not indigenous owned as per our criteria',
                'Registered under incorrect membership',
                'Change in indigenous ownership',
                'Risk, integrity, and conflicts policy',
                'Duplicate registration',
            ];
        }
        return options;
    }, [account]);

    return (
        <Dialog className={styles.dialog}
                dialogRef={dialogRef}
                header={"CHANGE STATUS"}>
            <Formik initialValues={{ reviewMessage: null, suspensionMessage: null, newStatus: account?.status! }}
                    onSubmit={submitStatusChange}
                    validationSchema={validationSchema}>
                {({ values, dirty, isValid, isSubmitting }) => {

                    let buttonText = "Submit";
                    switch (values.newStatus) {
                        case AccountStatus.ACTIVE:
                            buttonText = "Activate";
                            break;
                        case AccountStatus.DISABLED:
                            buttonText = "On hold";
                            break;
                        case AccountStatus.PENDING_REVIEW:
                            buttonText = "In review";
                            break;
                        default:
                            break;
                    }
                    const currentStatus = account?.status;
                    const newStatus = values.newStatus !== currentStatus ? values.newStatus : undefined;

                    return (
                        <Form>
                            <FormikSelect name={"newStatus"}
                                          label={"Status"}
                                          containerClassName={classNames({ [styles.hide_input]: values.newStatus !== account?.status })}
                                          options={[
                                              { name: "Active", value: AccountStatus.ACTIVE },
                                              { name: "On-hold", value: AccountStatus.DISABLED },
                                              { name: "Declined", value: AccountStatus.DECLINED },
                                              { name: "In review", value: AccountStatus.PENDING_REVIEW }
                                          ]} />
                            <div className={styles.dialog_content}>
                                {newStatus === AccountStatus.ACTIVE && (
                                    <p>Are you sure you would like to activate this account? They will be displayed on
                                        the directory
                                        for
                                        businesses to discover</p>
                                )}

                                {newStatus === AccountStatus.DISABLED && (
                                    <>
                                        <p>Are you sure you would like to put this account on hold? They will be removed from the directory and users will not be able to log into their accounts until reactivated.</p>
                                        <FormikInput name={"suspensionMessage"} multiline
                                                     label={"Reason for moving account to an on hold status"} inputClassname={styles.full_input} />
                                    </>
                                )}

                                {newStatus === AccountStatus.DECLINED && (
                                    <>
                                        <p>Are you sure you would like to put this account into review? They will be
                                            removed from the directory until they have been re-activated. You can
                                            provide some details below to help them correct their application.</p>
                                        <FormikSelect name={"reviewMessage"} 
                                                    options={decliningMessageOptions}
                                                    label={"Reason for declining application"} 
                                                    containerClassName={styles.full_input} />
                                    </>
                                )}

                                <div className={styles.dialog_btns}>
                                    <Button regular
                                            disabled={!dirty || !isValid}
                                            type={"submit"}
                                            loading={isSubmitting || loading}>{buttonText}</Button>
                                    <Button plainLink onClick={onCancel}>Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default React.forwardRef(AccountReviewDialog);