export enum CategoryType {
    MAIN = "MAIN",
    SUB = "SUB",
    SUB_SUB = "SUB_SUB",
}

export interface Category {
    id: number;
    name: string;
    type: CategoryType;
    subCategories?: SubCategory[];
    parent: number;
}

export interface SubCategory {
    id: number;
    name: string;
    parent: number;
    subSubCategories?: SubSubCategory[];
    type: CategoryType
}

export interface SubSubCategory {
    id: number;
    name: string;
    parent: number;
    type: CategoryType;
}