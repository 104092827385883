import moment from "moment/moment";
import qs from "qs";
import React from "react";
import Logo from 'src/resources/images/png/receipt-logo@2x.png';
import { SaaSPlanPeriod } from "src/types/model/SaaSPlan";
import { decodeSpecilaChars } from "src/util/url";
import { currency } from "src/util/utils";
import styles from "./Receipt.module.scss";

export type ReceiptParams = {
    businessName: string;
    businessAddress: string;
    invoiceDate: string;
    invoiceNo: string;
    reference: string;
    saasPlan: string;
    metaData: any;
    total: number;
}

enum ParseError {
    TYPE,
    DECODE,
    MISSING_META
}

export default function Receipt() {
    const { params } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const err = (e:ParseError) => <div>Cannot generate receipt, please contact admin. Error: {e}</div>;
    let decoded = '';
    if (params && typeof params === 'string') {
        decoded = decodeURI(decodeSpecilaChars(params));
    } else {
        return err(ParseError.TYPE);
    }
    let values = null;
    try {
        values = JSON.parse(decoded);
    } catch (error) {
        return err(ParseError.DECODE);
    }
    const total = values.total ? currency((values.total / 100).toFixed(2)) : null;

    if (!values?.metaData) {
        return err(ParseError.MISSING_META);
    }
    const { type, period, eventName } = values.metaData;

    let membershipDescription;
    if (period === SaaSPlanPeriod.ANNUAL) {
        membershipDescription = "Annual membership with Amotai";
    } else if (period === SaaSPlanPeriod.MONTHLY) {
        membershipDescription = "Monthly membership with Amotai";
    }

    return (
        <div className={styles.receipt_container}>
            <div className={styles.header_container}>
                <img src={Logo} alt={"Te Kaunihera o Tāmaki Makaurau"} />
                <div className={styles.header_details}>
                    <div className={styles.customer}>
                        <p>{values.businessName}</p>
                        <p>{values.businessAddress}</p>
                    </div>
                    <div className={styles.council}>
                        <p>Private Bag 92300</p>
                        <p>Victoria Street West, Auckland 1142</p>
                        <p>(09) 301 0101 | aucklandcouncil.govt.nz</p>
                        <p>GST Number: 104-736-998</p>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <h2>Tax Invoice</h2>
                <div className={styles.inner_content}>
                    <div className={styles.left}>
                        <div className={styles.content_section}>
                            <p className={styles.section_label}>Invoice Date</p>
                            <p>{moment(values.invoiceDate).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className={styles.content_section}>
                            <p className={styles.section_label}>Invoice No.</p>
                            <p className={styles.reference_number}>{values.invoiceNo}</p>
                        </div>
                        <div className={styles.content_section}>
                            <p className={styles.section_label}>Customer reference</p>
                            <p className={styles.reference_number}>{values.reference}</p>
                        </div>
                        <div className={styles.content_section}>
                            <p style={{ fontSize: "14px" }}>All items are GST inclusive<br />
                                unless marked with *</p>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.content_section}>
                            <p className={styles.section_label}>Membership type</p>
                            <p>{`${values.saasPlan} membership`}</p>
                        </div>
                        <div className={styles.content_section}>
                            <p className={styles.section_label}>Description</p>
                            {
                                type === "EVENT"
                                    ? (<p>{`Payment for ${eventName}`}</p>)
                                    : (
                                        <>
                                            <p>{membershipDescription}</p>
                                            {/*{startDate &&*/}
                                            {/*<p>{`Charge dates: ${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format("DD/MM/YYYY")}`}</p>}*/}
                                        </>
                                    )
                            }
                        </div>
                        <hr />
                        <div className={styles.content_row}>
                            <p className={styles.section_label}>Total Amount</p>
                            <p>{`$${total}`}</p>
                        </div>
                        <div className={styles.content_row}>
                            <p>Amount Paid</p>
                            <p>{`$${total}`}</p>
                        </div>
                        <hr className={styles.grey} />
                        <div className={styles.content_row}>
                            <p>Balance Due</p>
                            <p>$0.00</p>
                        </div>

                    </div>
                </div>

            </div>
            <div className={styles.footer_container} />
        </div>
    );

}
