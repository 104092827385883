import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import StarIcon from '@material-ui/icons/Star';
import classnames from 'classnames';
import { Column } from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { renderToString } from "react-dom/server";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loading from 'src/components/routes/loading/Loading';
import PagingTable, { PagingTableRef } from "src/components/widgets/pagingTable/PagingTable";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import AccountsAPI from "src/redux/actions/api/accounts";
import { getAllAccountsStatusHistory } from 'src/redux/actions/audit';
import { getAllNotes } from "src/redux/actions/notes";
import { showError, showSuccess } from "src/redux/actions/snackbars";
import { deleteMyPref, getMyPrefs } from "src/redux/actions/user";
import { PagingTableFilter } from "src/redux/reducers/tables";
import { AccountType } from "src/types/model/AmotaiAccount";
import BuyerClient from "src/types/model/BuyerClient";
import { Category } from 'src/types/model/Category';
import Note from "src/types/model/Note";
import Preference, { PreferenceType } from "src/types/model/Preference";
import Supplier from "src/types/model/Supplier";
import exportXLS from "src/util/excel";
import { buildCSVData, buyer_default_checked, buyer_extra, Data, ExportAccountData, supplier_default_checked, supplier_extra } from "src/util/export";
import { getAccountStatusHistory } from 'src/util/utils';
import ListResult from "../../../../types/ListResult";
import Button from "../../button/Button";
import ButtonMenu from "../../buttonMenu/ButtonMenu";
import CheckBox from "../../checkBox/CheckBox";
import { DialogRef } from "../../dialog/Dialog";
import CustomExportDialog from "./CustomExportDialog";
import styles from "./ExportView.module.scss";
import FilterView, { FilterViewRef } from "./FilterView";
import SaveFilterDialog from "./SaveFilterDialog";


const exportHeaderInfo = { businessName: 'Business name', projectName: 'Project name', date: 'Date' };

type TableData = {
    notes?: { [noteId: number]: Note },
} & Data;

export default function ExportView(props: RouteComponentProps<{ account_type: "supplier" | "buyer_client" }>) {
    const { match } = props;
    const { params } = match;
    const { account_type } = params;
    const history = useHistory();
    const dispatch = useDispatch();
    const [altPressed, setAltPressed] = useState<boolean>(false);

    const [filteredResult, setFilteredResult] = useState<Array<Data>>();
    const selectedResults = filteredResult?.filter(r => r.tableData.checked);

    const tableRef = useRef<PagingTableRef<Data>>(null);
    const [allAccounts, setAllAccounts] = useState<Array<ExportAccountData>>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const filterViewRef = useRef<FilterViewRef>(null);
    const fieldDialogRef = useRef<DialogRef>(null);
    const saveFilterDialogRef = useRef<DialogRef>(null);
    const [filter, setFilter] = useState<string>();
    const [filterViewValue, setFilterViewValue] = useState<string>();
    const searchPrefs = useSelector(state => state.user.preferences)[PreferenceType.Filter]
        ?.sort((a, b) => a.name.localeCompare(b.name)) as Preference[] | undefined;
    const [loading, setLoading] = useState<boolean>(!!selectedResults?.length);
    const [filterLoading, setFilterLoading] = useState<boolean>(true);
    const [historyLoading, setHistoryLoading] = useState<boolean>(false);
    const [enableSave, setEnableSave] = useState<boolean>(false);
    const [enableReset, setEnableReset] = useState<boolean>(false);
    const [selectedPref, setSelectedPref] = useState<Preference>();
    const notes = useSelector((state: any) => state.notes);
    const allAccountStatusHistory = useSelector(state => state.audits.account.status);

    if (account_type !== "supplier" && account_type !== "buyer_client") {
        history.goBack();
    }

    const accountType = useMemo((): AccountType => {
        switch (account_type) {
            case "supplier":
                return AccountType.SUPPLIER;
            case "buyer_client":
            default:
                return AccountType.BUYER_CLIENT;
        }
    }, [account_type]);

    useEffect(() => {
        (async () => {
            if (!searchPrefs?.length) {
                dispatch(getMyPrefs());
            }
            if (accountType) {
                try {
                    setLoading(true);
                    await dispatch(getAllNotes(true));
                    const accounts = await AccountsAPI.filterAccounts<Supplier | BuyerClient>(accountType);
                    setFilteredResult(accounts.map(a => ({ ...a, tableData: { checked: true } })));
                    setAllAccounts(accounts);
                    setRefresh(true);
                } catch (error) {
                    dispatch(showError(`Failed to load accounts: ${error.message ?? ''}`));
                } finally {
                    setLoading(false);
                }
            }
        })();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setHistoryLoading(true);
                await dispatch(getAllAccountsStatusHistory());
            } catch (error) {
                dispatch(showError(`Failed to load accounts histroy: ${error.message ?? ''}`));
            } finally {
                setHistoryLoading(false);
            }
        })();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.keys(allAccountStatusHistory ?? {}).length) {
            setFilteredResult(data =>
                data?.map(d => {
                    const statuses = allAccountStatusHistory[d.id];
                    if (statuses?.length) {
                        const { approvedAt, declinedAt, deletedAt, onholdAt } = getAccountStatusHistory(statuses);
                        return { ...d, approvedAt, declinedAt, deletedAt, onholdAt };
                    }
                    return d;
                })
            );
        }
        //eslint-disable-next-line
    }, [allAccountStatusHistory, filteredResult?.length]);


    useEffect(() => {
        const handler = (ev: MouseEvent) => {
            setAltPressed(ev.altKey);
        };
        window.addEventListener('mousemove', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
        };
    }, []);

    useEffect(() => {
        if (!!filteredResult && !!tableRef.current && refresh) {
            tableRef.current.refresh();
            setRefresh(false);
        }
    }, [filteredResult, tableRef, refresh]);

    const getQuery = () => {
        return new Promise<ListResult<TableData>>((resolve) => {
            const result: ListResult<TableData> = {
                items: filteredResult?.map(v => ({ ...v, selected: true, notes: notes[v.id] })) ?? [],
                limit: null
            };
            resolve(result);
        });
    };

    const onRowDataChecked = (datas: TableData[], rowData: TableData) => {
        if (!rowData) {
            // select/unselect all
            setFilteredResult(state => state?.map(v => ({ ...v, tableData: { checked: !!datas?.length } })));
        } else {
            setFilteredResult(state => state?.map(v => {
                if (v.id === rowData?.id) {
                    v.tableData = { checked: !v.tableData.checked };
                }
                return v;
            }));
        }
    };

    const updateFilterResult = (accounts: Array<ExportAccountData>) => {
        const result = accounts.map(acc => ({ ...acc, tableData: { checked: true } }));
        setFilteredResult(result);
        setRefresh(true);
    };

    const columns = useMemo(() => {
        let _columns: Column<TableData>[] = [
            {
                render: s => s.notes ?
                    <Tips
                        tips={Object.values(s.notes).map(n => n.details).join(', ')}
                        href={`${window.location.origin}/${s.type === AccountType.SUPPLIER ? 'suppliers' : 'buyers'}/${s.id}/notes`}
                    />
                    : null,
                width: '5%'
            },
            {
                title: "Business Name",
                field: "name"
            },
            {
                title: "Primary Contact",
                render: s => s.mainContact?.name
            },
            {
                title: "Primary Contact Email",
                render: s => s.mainContact?.email
            },
        ];
        if (accountType === AccountType.SUPPLIER) {
            _columns = _columns.concat([
                {
                    title: "Categories",
                    render: s => <SupplierCategories supplier={s as Supplier} />
                },
                // {
                //     title: "Sub Category",
                //     render: s => (s as Supplier).subCategories?.map(c => c.name).join(",")
                // },
            ]);
        }
        _columns.push({
            title: "Regions Operating",
            render: s => s.regions?.join(",")
        });
        return _columns;
    }, [accountType]);

    const goBack = useCallback(() => {
        if (history.length > 3) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [history]);

    const onRawDataExport = async () => {
        const fields = accountType === AccountType.SUPPLIER ?
            supplier_default_checked.concat(supplier_extra) :
            buyer_default_checked.concat(buyer_extra);
        if (!selectedResults?.length) {
            dispatch(showError("Nothing to export as nothing selected"));
            return;
        }
        try {
            const data = buildCSVData(accountType, fields, selectedResults);
            const filename = accountType === AccountType.SUPPLIER ? "suppliers_raw_export" : "buyers_raw_export";
            const sheetname = accountType === AccountType.SUPPLIER ? "Raw Suppliers" : "Raw Buyers";
            await exportXLS({ data, info: exportHeaderInfo, filename, sheetname });
            dispatch(showSuccess('Data has been exported successfully'));
        } catch (error) {
            dispatch(showError(error.message));
        }
    };

    const onContactListExport = async () => {
        const fields = accountType === AccountType.SUPPLIER ? supplier_default_checked : buyer_default_checked;
        if (!selectedResults?.length) {
            dispatch(showError("Nothing to export as nothing selected"));
            return;
        }
        try {
            const data = buildCSVData(accountType, fields, selectedResults);
            const filename = accountType === AccountType.SUPPLIER ? "suppliers_contacts_export" : "buyers_contacts_export";
            const sheetname = accountType === AccountType.SUPPLIER ? "Supplier Contacts List" : "Buyer Contacts List";
            await exportXLS({ data, info: exportHeaderInfo, filename, sheetname });
            dispatch(showSuccess('Data has been exported successfully'));
        } catch (error) {
            dispatch(showError(error.message));
        }
    };

    const onCustomExport = () => {
        fieldDialogRef.current?.show();
    };

    const onSaveFav = () => {
        if ((searchPrefs?.length ?? 0) >= 10) {
            dispatch(showError('You can only have up to 10 favourites'));
            return;
        }
        const f = filterViewRef.current?.getCurrentFilterValues();
        setFilter(f);
        saveFilterDialogRef.current?.show();
    };

    const onFavFilterLoad = (pref: Preference, checked: boolean) => {
        if (checked) {
            setSelectedPref(pref);
            setFilterViewValue(pref.value);
        } else {
            setSelectedPref(undefined);
            setFilterViewValue(undefined);
        }
    };

    const onFavFilterDelete = async (pref: Preference) => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            await dispatch(deleteMyPref(pref.id));
        } catch (error) {
            dispatch(showError(`Failed to delete: ${error.message}`));
        } finally {
            setLoading(false);
        }
    };

    const onReset = () => {
        filterViewRef.current?.reset();
        setSelectedPref(undefined);
        setFilterViewValue(undefined);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.back} onClick={goBack}>&lt;&lt; Back</div>
                <div className={styles.content}>
                    <h3>Filter and export</h3>
                    <div className={styles.controls}>
                        {!(loading || filterLoading || !enableSave) && <Button
                            borderdark
                            startIcon={<StarIcon />}
                            onClick={onSaveFav}
                            disabled={loading || filterLoading || !enableSave}
                        >
                            save filter
                        </Button>}
                        <ButtonMenu
                            onPrimaryClick={onContactListExport}
                            borderdark
                            disabled={loading || filterLoading}
                            subMenuLoading={historyLoading}
                            name="export contact list"
                            items={[
                                {
                                    name: 'Export raw data',
                                    onClick: onRawDataExport,
                                },
                                {
                                    name: 'Custom export',
                                    onClick: onCustomExport,
                                }
                            ]}
                        />
                        <Button
                            onClick={() => filterViewRef.current?.submit()}
                            disabled={loading || filterLoading}
                        >
                            filter data
                        </Button>
                        {!(loading || filterLoading || !enableReset) && <Button
                            plainLink onClick={onReset}
                            disabled={loading || filterLoading || !enableReset}
                        >
                            reset
                        </Button>}
                    </div>
                </div>
                {!searchPrefs?.length ? null :
                    <div className={styles.title}>
                        My filters
                    </div>
                }
                <div className={styles.filters}>
                    {searchPrefs?.map(p => {
                        const selected = !!selectedPref?.id && selectedPref.id === p.id;
                        return (
                            <div className={classnames(styles.pill, { [styles.pill_select]: selected })}>
                                <CheckBox
                                    label={<div title={p.name} className={styles.fav_label}>{p.name}</div>}
                                    checked={selected}
                                    onChange={(_, checked) => onFavFilterLoad(p, checked)}
                                    disabled={loading || filterLoading}
                                />
                                <div className={classnames(styles.delete, { [styles.loading]: loading || filterLoading })}
                                    onClick={() => onFavFilterDelete(p)}
                                >
                                    <DeleteIcon />
                                </div>
                            </div>

                        );
                    })}
                </div>
                <div className={styles.bottom} />
            </div>
            {allAccounts.length > 0 &&
                <FilterView
                    filterViewRef={filterViewRef}
                    accountType={accountType}
                    allAccounts={allAccounts}
                    setFilteredResult={updateFilterResult}
                    filterValues={filterViewValue}
                    onFilterLoaded={() => setFilterLoading(false)}
                    onFilterChange={setEnableSave}
                    onInitValueChange={setEnableReset}
                />
            }
            <h4 className={styles.table_title}>{`Results (${selectedResults?.length ?? '...'})`}</h4>
            {(allAccounts.length > 0) ?
                (<div className={styles.csv_links}>
                    {altPressed && <CSVLink data={selectedResults?.map(a => ({ id: String(a.id) })) ?? []}
                        className={styles.link_right}
                        filename="ids.csv">
                        Export ids
                    </CSVLink>}
                </div>)
                : <Loading />}
            <PagingTable<TableData, PagingTableFilter>
                id={"export"}
                tableRef={tableRef}
                columns={columns}
                options={{
                    paging: false,
                    search: false,
                    searchFieldStyle: {},
                    selection: true,
                }}
                getData={getQuery}
                onSelectionChange={onRowDataChecked}
            />
            <CustomExportDialog
                ref={fieldDialogRef}
                accountType={accountType}
                exportHeaderInfo={exportHeaderInfo}
                selectedResults={selectedResults}
            />
            <SaveFilterDialog
                ref={saveFilterDialogRef}
                filter={filter}
                onFinish={setSelectedPref}
            />
        </>
    );
}

function Tips(props: {
    tips: string,
    href: string,
}) {
    const { tips, href } = props;
    const content = renderToString(
        <a className={styles.tip_link} href={href} target="_blank" rel="noopener noreferrer">{tips}</a>
    );
    return (
        <>
            <div data-html data-tip={content}>
                <ErrorIcon />
            </div>
            <ReactTooltip
                place="bottom"
                effect="solid"
                multiline
                clickable
                className={styles.tooltip}
            />
        </>
    );
}

function SupplierCategories(props: { supplier: Supplier }) {
    const { supplier } = props;
    const { mainCategories, subCategories, subSubCategories } = supplier as Partial<Supplier>;
    const categories: Category[] = mainCategories?.map(mc => ({
        ...mc,
        subCategories: subCategories?.filter(sc => sc.parent === mc.id)
            .map(sc => ({
                ...sc,
                subSubCategories: subSubCategories?.filter(ssc => ssc.parent === sc.id)
            }))
    })) ?? [];
    return (
        <div className={styles.supplier_categories}>
            {categories.map(mc => (
                <div className={styles.main}>
                    <span>{mc.name}</span>
                    {mc.subCategories?.map(sc => (
                        <div className={styles.sub}>
                            <span>{sc.name}</span>
                            {sc.subSubCategories?.map(ssc => (
                                <div className={styles.subsub}>{ssc.name}</div>
                            ))}
                        </div>
                    ))}

                </div>
            ))}
        </div>
    );
}