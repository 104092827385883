import AmotaiUser from "../../../types/model/AmotaiUser";
import get from "./util/get";
import put from "./util/put";
import post from "./util/post";
import Preference from "src/types/model/Preference";
import del from "./util/del";

function getMe(): Promise<AmotaiUser> {
    return get("/v1/me");
}

function updateMe(user: AmotaiUser): Promise<AmotaiUser> {
    return put("/v1/me", user);
}

function reinviteUser(email: string) {
    return post("/v1/accounts/users/reinvite", { email });
}

function createPref(pref: Preference): Promise<Preference> {
    return post('/v1/me/preferences', pref);
}

function getMyPrefs(): Promise<Preference[]> {
    return get('/v1/me/preferences');
}

function deleteMyPref(prefId: number): Promise<void> {
    return del(`/v1/me/preferences/${prefId}`);
}

const UserAPI = {
    getMe,
    updateMe,
    reinviteUser,
    createPref,
    getMyPrefs,
    deleteMyPref,
};

export default UserAPI;
