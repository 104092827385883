import get from "./util/get";


function getAddressSuggestion(string: string, countries?: string[], types?: string): Promise<any> {
    return get("/v1/address/search", { params: { text: string, countries: countries, types } });
}

const AddressAPI = {
    getAddressSuggestion
};

export default AddressAPI;
