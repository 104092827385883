import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import SaaSPlanAPI from "src/redux/actions/api/saaSPlans";
import { getMainCategories } from "src/redux/actions/categories";
import { AccountStatus, AccountType, PaymentType } from "src/types/model/AmotaiAccount";
import BusinessContact from "src/types/model/BusinessContact";
import { Category, SubCategory, SubSubCategory } from "src/types/model/Category";
import Supplier, { OwnershipType } from "src/types/model/Supplier";
import {
    ANNUAL_ADDRESSABLE_SPEND,
    ANNUAL_INDIRECT_SPEND,
    BUYER_TURNOVER,
    HEALTH_AND_SAFETY,
    INSURANCES, IWI_GROUPS,
    LEGAL_STRUCTURE, PASIFIKA_GROUPS,
    REGIONS,
    REGIONS_NO_NATIONWIDE,
    SUPPLIER_TURNOVER,
    YearOptions
} from "src/util/constants";
import { ExportAccountData } from "src/util/export";
import { strarrContainsAnyOfStrarr } from "src/util/utils";
import * as Yup from "yup";
import Loading from "../../../routes/loading/Loading";
import FormikCategorySelect from "../../input/categorySelect/FormikCategorySelect";
import FormikCheckboxGroup from "../../input/FormikCheckboxGroup";
import FormikDatePicker from "../../input/FormikDatePicker";
import FormikInput from "../../input/FormikInput";
import FormikSelect from "../../input/FormikSelect";
import { SelectOption } from "../../input/Select";
import styles from "./ExportView.module.scss";

type Props = {
    accountType: AccountType,
    containerClassName?: string,
    allAccounts: Array<ExportAccountData>,
    setFilteredResult: (result: Array<ExportAccountData>) => void,
    filterViewRef: Ref<FilterViewRef>,
    filterValues?: string,
    onFilterLoaded?: () => void,
    onFilterChange?: (changed: boolean) => void,
    onInitValueChange?: (changed: boolean) => void,
};

export interface FilterViewRef {
    reset: () => void;
    submit: () => Promise<void>;
    getCurrentFilterValues: () => string;
}

export default function FilterView(props: Props) {
    const { accountType, allAccounts, setFilteredResult, filterViewRef, filterValues, onFilterLoaded, onFilterChange, onInitValueChange } = props;
    const categories = useSelector<Category[]>((state) => state.categories.main);
    const categoryOptions = categories.map(c => c.name);
    const [planNames, setPlanNames] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const formRef = useRef<FormikProps<AccountFilter> | null>(null);
    const [initValue, setInitValue] = useState<AccountFilter>(INIT_VALUE);
    const [valueChanged, setValueChanged] = useState<boolean>(false);
    const [initValueChanged, setInitValueChanged] = useState<boolean>(false);

    useImperativeHandle(filterViewRef, () => ({
        submit: async () => {
            await formRef.current?.submitForm();
        },
        reset: () => {
            setInitValue(INIT_VALUE);
            formRef.current?.resetForm();
        },
        getCurrentFilterValues: () => {
            return JSON.stringify(formRef.current?.values ?? INIT_VALUE);
        },
    }));

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(getMainCategories());
            if (accountType === AccountType.BUYER_CLIENT) {
                const result = await SaaSPlanAPI.getPlans(10000);
                setPlanNames(result.items.map(p => p.name).sort());
            }
            setLoading(false);
            onFilterLoaded?.();
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (filterValues) {
            const v = JSON.parse(filterValues) as AccountFilter;
            setInitValue(v);
        }
    }, [filterValues]);

    const handleReset = useCallback(() => {
        setFilteredResult(allAccounts);
    }, [allAccounts, setFilteredResult]);

    const handleSubmit = useCallback((f: AccountFilter, helper: FormikHelpers<AccountFilter>) => {

        let result = allAccounts;

        Object.keys(f).forEach(key => {
            if (!f[key] || result.length === 0 || f[key] === "" || f[key].length === 0 || (typeof f[key] === "object" && Object.keys(f[key]).length === 0)) {
                return;
            }

            if (exactMatches.includes(key)) {
                if (key === "saaSPlanName" && f[key] === NO_PLAN) {
                    // dealing with 'no plan option
                    result = result.filter(a => !a[key]);
                } else {
                    result = result.filter(a => a[key] === f[key]);
                }
            } else if (key === "ownershipType" && accountType === AccountType.SUPPLIER) {
                if ((f.ownershipType ?? []).length > 0) {
                    const selectedOwnershipTypes = f.ownershipType!.map(name => OWNERSHIP_OPT.find(o => o.name === name)!.value);
                    result = result.filter((a: Supplier) => a.ownershipType && selectedOwnershipTypes.includes(a.ownershipType));
                }
            } else if (Object.keys(objectKeywordMatches).includes(key)) {
                if (key === objectKeywordMatches.mainContact && f[key]) {
                    result = result.filter(a => {
                        const mainContact = a.mainContact ?? {} as BusinessContact;
                        const t = `${mainContact.email ?? ""}${mainContact.name ?? ""}${mainContact.mobileNumber ?? mainContact.phoneNumber ?? ""}` ?? "";
                        const escapeRegex = (f[key] as string).replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
                        return new RegExp(escapeRegex, "i").test(t);
                    });
                }
                if (key === objectKeywordMatches.category) {
                    result = result.filter(a => {
                        return (a.category?.name ?? "") === f.category;
                    });
                }
            } else if (supplierCategoryMatcher.includes(key) && accountType === AccountType.SUPPLIER) {
                const { mainCategories: fmains, subCategories: fsubs, subSubCategories: fsubsubs } = f;
                const fcats = fmains?.map(c => ({
                    ...c,
                    subCategories: fsubs?.filter(s => s.parent === c.id).map(s => ({
                        ...s,
                        subSubCategories: fsubsubs?.filter(ss => ss.parent === s.id)
                    }))
                }));
                result = result.filter((account: Supplier) => {
                    const { mainCategories: amains, subCategories: asubs, subSubCategories: asubsubs } = account as Partial<Supplier>;
                    if (!fcats?.length) {
                        return true; //no cat filter
                    }
                    for (const fcat of fcats) {
                        //first filter on main
                        if (!amains?.find(m => m.id === fcat.id)) {
                            //not match main so not going to match subs, try next cat
                            continue;
                        }
                        if (!fcat.subCategories?.length) {
                            //match main, no sub, return true, no need to filter on other criterias
                            return true;
                        }
                        //continue if sub present
                        const submatch = strarrContainsAnyOfStrarr(fcat.subCategories!.map(s => s.name), asubs?.map(s => s.name));
                        if (!submatch) {
                            //not match with current filter, try next
                            continue;
                        }
                        //match subsub under current main cat
                        const fsubsubs = fcat.subCategories!.map(s => s.subSubCategories ?? []).flat();
                        if (!fsubsubs.length) {
                            //no subsub to match, so far sub matched, return true
                            return true;
                        }
                        const subsubmatch = strarrContainsAnyOfStrarr(fsubsubs.map(c => c.name), asubsubs?.map(c => c.name));
                        if (subsubmatch) {
                            return true; //subsub and all parents are matched
                        }
                        //otherwise continue to next loop
                    }
                    //continue if subsub present
                    return false;
                });
            } else if (numberGE.includes(key)) {
                result = result.filter(a => {
                    const n = parseInt(a[key] ?? "0", 10);
                    const ge = parseInt(f[key], 10);
                    return !Number.isNaN(n) && !Number.isNaN(ge) && n >= ge;
                });
            } else if (dateMatches.includes(key)) {
                if (f[key]) {
                    const expVal = moment(f[key]).local().format("YYYY-MM-DD");
                    result = result.filter(a => {
                        // if (!a[key]) return false;
                        if (!a.createdAt) {
 return false; 
}
                        const realVal = moment(a.createdAt).local().format("YYYY-MM-DD");
                        if (key === "createdAtFrom") {
                            return realVal >= expVal;
                        }
                        if (key === "createdAtTo") {
                            return realVal <= expVal;
                        }
                        return false;
                    });
                }
            } else if (key === 'regions') {
                const filterRegions = f.regions?.length && !f.regions.includes("Nationwide") ?
                    [...f.regions, "Nationwide"] : f.regions;
                if (filterRegions) {
                    result = result.filter(a => strarrContainsAnyOfStrarr(a.regions, filterRegions));
                }
            } else if (stringArrayToStringArrayMatchAny.includes(key)) {
                if ((f[key] ?? []).length > 0) {
                    result = result.filter(a => strarrContainsAnyOfStrarr(f[key], a[key]));
                }
            } else if (stringArrayMatchAny.includes(key)) {
                result = result.filter(a => ((a[key] as string[]) ?? []).includes(f[key]));
            } else if (stringArrayToStringMatchAny.includes(key)) {
                if ((f[key] ?? []).length > 0) {
                    result = result.filter(a => (f[key] as string[]).includes(a[key]));
                }
            } else { // string keyword
                result = result.filter(a => new RegExp(f[key], "i").test(a[key] ?? ""));
            }
        });

        setFilteredResult(result);
        helper.setSubmitting(false);
    }, [allAccounts, setFilteredResult, accountType]);

    const accountStatusOpts: SelectOption[] = useMemo(() => [
        { value: "", label: "" },
        { value: AccountStatus.ACTIVE, label: "Active" },
        { value: AccountStatus.DECLINED, label: "Declined" },
        { value: AccountStatus.DELETED, label: "Deleted" },
        { value: AccountStatus.DISABLED, label: "On hold" },
        { value: AccountStatus.PENDING_REVIEW, label: "Pending review" },
        { value: AccountStatus.AWAITING_PAYMENT, label: "Awaiting payment" },
    ], []);

    return (
        <div className={props.containerClassName}>
            <Formik<AccountFilter>
                innerRef={ref => {
 formRef.current = ref; 
}}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                onReset={handleReset}
                initialValues={initValue}
                enableReinitialize
            >
                {({ dirty, values }) => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (valueChanged !== dirty) {
                        setValueChanged(dirty);
                        onFilterChange?.(dirty);
                    }
                    const _initValuesChanged = JSON.stringify(values) !== JSON.stringify(INIT_VALUE);
                    if (initValueChanged !== _initValuesChanged) {
                        setInitValueChanged(_initValuesChanged);
                        onInitValueChange?.(_initValuesChanged);
                    }
                    return (
                        <Form className={styles.form}>
                            <FormikSelect name={"status"} noPadding label={"Account Status"}
                                containerClassName={classNames(styles.half_input, styles.first)}
                                options={accountStatusOpts} />
                            <FormikSelect name={"legalStructure"} label={"Legal Structure"}
                                containerClassName={styles.half_input}
                                options={LEGAL_STRUCTURE} />
                            <FormikInput name={"name"} label={"Business Name"}
                                inputClassname={classNames(styles.first, styles.half_input)} />

                            <FormikInput name={"mainContact"} label={"Main Contact Name/Email/Phone"}
                                inputClassname={styles.half_input} />

                            <FormikDatePicker name={"createdAtFrom"} label={"Created At (from)"}
                                className={classNames(styles.first, styles.half_input)} />
                            <FormikDatePicker name={"createdAtTo"} label={"Created At (to)"}
                                className={styles.half_input} />
                            {accountType === AccountType.BUYER_CLIENT &&
                                <FormikSelect name={"category"} label={"Main Category"}
                                    containerClassName={classNames(styles.first, styles.half_input)} options={categoryOptions} />}

                            {accountType === AccountType.SUPPLIER && (
                                <>
                                    <div>
                                        <label className={styles.full_input}>Categories</label>
                                        <div className={styles.full_input}>
                                            <FormikCategorySelect disabled={false} />
                                        </div>
                                    </div>
                                    <FormikInput name={"legalName"} label={"Legal Business Name"}
                                        inputClassname={classNames(styles.half_input, styles.first)} />
                                    <FormikSelect name={"yearEstablished"} label={"Year Established"}
                                        containerClassName={styles.half_input}
                                        options={YearOptions()} />
                                    <FormikInput name={"maoriEmployees"} number label={"Minimum Māori employees"}
                                        inputClassname={classNames(styles.half_input, styles.first)} />
                                    <FormikInput
                                        name={"pasifikaEmployees"}
                                        number
                                        label={"Minimum Pasifika employees"}
                                        inputClassname={styles.half_input}
                                    />
                                    <FormikInput
                                        name={"nonMaleEmployees"}
                                        number
                                        label={"Minimum Female employees"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                    />
                                    <FormikInput
                                        name={"employeesUnderTwentyFour"}
                                        number
                                        label={"Minimum Employees under 24"}
                                        inputClassname={styles.half_input}
                                    />
                                    <FormikInput
                                        name={"totalFTE"}
                                        number
                                        label={"Minimum FTE"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                    />
                                    <Accordion key={"ownershipType"} TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Ownership type
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"ownershipType"}
                                                    stringItems={OWNERSHIP_OPT.map(o => o.name)} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"healthAndSafetyQualifications"}
                                        TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                health and safety qualifications
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"healthAndSafetyQualifications"}
                                                    stringItems={HEALTH_AND_SAFETY} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"publicLiabilityInsurance"}
                                        TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Public Liability
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"publicLiabilityInsurance"}
                                                    stringItems={INSURANCES} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"professionalIndemnity"} TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Professional Indemnity Insurance
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"professionalIndemnity"}
                                                    stringItems={INSURANCES} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"turnoverInLastFinancialYear"}
                                        TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Annual turnover
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"turnoverInLastFinancialYear"}
                                                    stringItems={SUPPLIER_TURNOVER} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"iwiAffiliations"} TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Iwi Affiliations
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"iwiAffiliations"}
                                                    stringItems={IWI_GROUPS} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion key={"pasifikaAffiliations"} TransitionProps={{ unmountOnExit: true }}
                                        className={styles.affiliation_container}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-label={"Expand"}
                                            aria-controls={"additional-actions1-content"}
                                            id={"additional-actions1-header"}
                                            className={styles.affiliation_item}>
                                            <label>
                                                Pasifika Affiliations
                                            </label>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className={styles.checkbox_container}>
                                                <FormikCheckboxGroup name={"pasifikaAffiliations"}
                                                    stringItems={PASIFIKA_GROUPS} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )}

                            {(accountType === AccountType.BUYER_CLIENT) && (
                                <>
                                    {accountType === AccountType.BUYER_CLIENT && (
                                        <FormikSelect name={"paymentType"} noPadding label={"Payment Type"}
                                            options={["", PaymentType.INVOICE, PaymentType.STRIPE]}
                                            //   onChange={onBuyerPaymentTypeChange}
                                            containerClassName={styles.half_input} />
                                    )}
                                    {accountType === AccountType.BUYER_CLIENT && (
                                        <FormikSelect name={"saaSPlanName"} label={"SaaS Plan"}
                                            options={[NO_PLAN, ...planNames]}
                                            containerClassName={classNames(styles.first, styles.half_input)} />
                                    )}
                                    <FormikSelect name={"annualTurnover"} label={"Annual turnover"}
                                        options={BUYER_TURNOVER}
                                        containerClassName={styles.half_input} />
                                    <FormikSelect name={"annualAddressableSpend"} label={"Annual addressable spend"}
                                        options={ANNUAL_ADDRESSABLE_SPEND}
                                        containerClassName={classNames(styles.first, styles.half_input)} />
                                    <FormikSelect name={"approxAnnualIndirectSpend"}
                                        label={"Approx annual indirect spend"}
                                        options={ANNUAL_INDIRECT_SPEND}
                                        containerClassName={styles.half_input} />
                                    <FormikSelect name={"minimumLevelOfInsuranceForSubContract"}
                                        label={"Minimum Level of Insurance Required for Subcontractors"}
                                        options={INSURANCES} containerClassName={classNames(styles.first, styles.half_input)} />
                                    <FormikInput name={"size"} number label={"Minimum size of workforce"}
                                        inputClassname={styles.half_input} />
                                </>
                            )}
                            <Accordion key={"regionBased"} TransitionProps={{ unmountOnExit: true }}
                                className={styles.affiliation_container}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-label={"Expand"}
                                    aria-controls={"additional-actions1-content"}
                                    id={"additional-actions1-header"}
                                    className={styles.affiliation_item}>
                                    <label>
                                        Region based
                                    </label>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={styles.checkbox_container}>
                                        <FormikCheckboxGroup name={"regionBased"}
                                            stringItems={REGIONS_NO_NATIONWIDE} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion key={"regions"} TransitionProps={{ unmountOnExit: true }}
                                className={styles.affiliation_container}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-label={"Expand"}
                                    aria-controls={"additional-actions1-content"}
                                    id={"additional-actions1-header"}
                                    className={styles.affiliation_item}>
                                    <label>
                                        Region operating
                                    </label>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={styles.checkbox_container}>
                                        <FormikCheckboxGroup name={"regions"}
                                            stringItems={REGIONS} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

interface AccountFilter {
    /* general */
    status?: AccountStatus | string | undefined; // selector
    name?: string | undefined; // input
    legalStructure?: string | undefined; // input
    mainContact?: string | undefined; // BusinessContact; //input keyword
    regions?: string[] | undefined; // string[]; //input keyword
    regionBased?: string[] | undefined;
    category?: string | undefined; // selector

    createdAtFrom?: string | undefined; // input
    createdAtTo?: string | undefined; // input

    // common in suplier and buyer
    postalAddress?: string | undefined;
    physicalAddress?: string | undefined;

    /* buyer specific */
    // buyer additionally from account
    paymentType?: PaymentType | string | undefined;
    // buyer specific
    size?: number | string | undefined;
    annualTurnover?: string | undefined;
    annualAddressableSpend?: string | undefined;
    approxAnnualIndirectSpend?: string | undefined;
    minimumLevelOfInsuranceForSubContract?: string | undefined;
    saaSPlanName?: string | undefined;

    /* supplier specific */
    // supplier additionally from account
    mainCategories?: Category[] | undefined;
    subCategories?: SubCategory[] | undefined; // SubCategory[]; //input keyword
    subSubCategories?: SubSubCategory[] | undefined;// SubSubCategory[]; //input keyword
    // supplier specific
    generalPhoneNumber?: string | undefined;
    generalEmail?: string | undefined;
    legalName?: string | undefined;
    website?: string | undefined;
    nzbn?: string | undefined;
    nzCompaniesNumber?: string | undefined;
    yearEstablished?: string | undefined;
    maoriEmployees?: number | string | undefined;
    pasifikaEmployees?: number | string | undefined;
    nonMaleEmployees?: number | string | undefined;
    employeesUnderTwentyFour?: number | string | undefined;
    totalFTE?: number | string | undefined;
    healthAndSafetyQualifications?: string | undefined; // string[];keyword
    turnoverInLastFinancialYear?: string | undefined;
    publicLiabilityInsurance?: string | undefined;
    professionalIndemnity?: string | undefined;
    pasifikaAffiliations?: string | undefined;
    iwiAffiliations?: string | undefined;
    // physicalAddressComponents?: object | undefined;
    ownershipType?: string[] | undefined;
}

const INIT_VALUE: AccountFilter = {
    /* general */
    status: "",
    name: "",
    legalStructure: "",
    mainContact: "",
    regions: undefined,
    regionBased: undefined,
    category: "",

    createdAtFrom: "",
    createdAtTo: "",

    // common in suplier and buyer
    postalAddress: "",
    physicalAddress: "",

    /* buyer specific */
    // buyer additionally from account
    paymentType: "",
    // buyer specific
    size: "",
    annualTurnover: "",
    annualAddressableSpend: "",
    approxAnnualIndirectSpend: "",
    minimumLevelOfInsuranceForSubContract: "",
    saaSPlanName: "",

    /* supplier specific */
    // supplier additionally from account
    mainCategories: [],
    subCategories: [],
    subSubCategories: [],
    // supplier specific
    generalPhoneNumber: "",
    generalEmail: "",
    legalName: "",
    website: "",
    nzbn: "",
    nzCompaniesNumber: "",
    yearEstablished: "",
    maoriEmployees: "",
    pasifikaEmployees: "",
    nonMaleEmployees: "",
    employeesUnderTwentyFour: "",
    totalFTE: "",
    healthAndSafetyQualifications: undefined,
    turnoverInLastFinancialYear: "",
    publicLiabilityInsurance: "",
    professionalIndemnity: "",
    pasifikaAffiliations: "",
    iwiAffiliations: "",
    ownershipType: undefined,
};

const OWNERSHIP_OPT = [
    {
        name: "Māori owned business criteria met",
        value: OwnershipType.MaoriOwned
    },
    {
        name: "Pasifika owned business criteria met",
        value: OwnershipType.PasifikaOwned
    },
    {
        name: "Both Māori & Pasifika owned business criteria met",
        value: OwnershipType.MaoriPasifikaOwned
    },
    {
        name: "Māori & Pasifika ownership combined to meet threshold criteria",
        value: OwnershipType.MaoriPasifikaCombined
    }
];

const validationSchema = Yup.object({
    size: Yup.number().nullable(),
    maoriEmployees: Yup.number().nullable(),
    pasifikaEmployees: Yup.number().nullable(),
    nonMaleEmployees: Yup.number().nullable(),
    employeesUnderTwentyFour: Yup.number().nullable(),
    totalFTE: Yup.number().nullable()
});

const exactMatches = [
    // general
    "status",
    "legalStructure",
    // buyer specific
    "paymentType",
    "annualTurnover",
    "annualAddressableSpend",
    "approxAnnualIndirectSpend",
    "minimumLevelOfInsuranceForSubContract",
    // supplier specific
    "yearEstablished",
    "saaSPlanName",
];

const objectKeywordMatches = {
    // general
    mainContact: "mainContact",
    category: "category"
};

const supplierCategoryMatcher = [
    "mainCategories",
    "subCategories",
    "subSubCategories"
];

const stringArrayToStringArrayMatchAny = [
    "healthAndSafetyQualifications",
    "iwiAffiliations",
    "pasifikaAffiliations",
];

const numberGE = [
    // buyer specific
    "size",
    // supplier specific
    "maoriEmployees",
    "pasifikaEmployees",
    "nonMaleEmployees",
    "employeesUnderTwentyFour",
    "totalFTE",
];

const dateMatches = [
    "createdAtFrom",
    "createdAtTo"
];

/** filter is string, target is string[], match if target array includes filter string */
const stringArrayMatchAny = [
    ""
];

/** filter is string[], target is string, match if target string is in filter string array */
const stringArrayToStringMatchAny = [
    "regionBased",
    "turnoverInLastFinancialYear",
    "professionalIndemnity",
    "publicLiabilityInsurance",
];

const NO_PLAN = "No plan";