import React, { Ref, forwardRef, useState, useMemo, useCallback, useEffect } from 'react';
import Dialog, { DialogRef } from '../../dialog/Dialog';
import useForwardRef from 'src/hooks/useForwardRef';
import CheckBox from '../../checkBox/CheckBox';
import styles from './CustomExportDialog.module.scss';
import Button from '../../button/Button';
import { AccountType } from 'src/types/model/AmotaiAccount';
import { nameBuilder } from 'src/util/utils';
import { supplier_default_checked, buyer_default_checked, supplier_extra, buyer_extra, ExportHeaderInfo, Data, buildCSVData } from 'src/util/export';
import useDispatch from 'src/hooks/useDispatch';
import { showError, showSuccess } from 'src/redux/actions/snackbars';
import exportXLS from 'src/util/excel';

type Props = {
    accountType: AccountType;
    exportHeaderInfo: ExportHeaderInfo;
    selectedResults?: Data[];
}

function CustomExportDialog(props: Props, ref: Ref<DialogRef>) {
    const { accountType, exportHeaderInfo, selectedResults } = props;
    const initValues: { [key: string]: boolean } = {};
    const dispatch = useDispatch();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const [values, setValues] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        (accountType === AccountType.SUPPLIER ? supplier_default_checked : buyer_default_checked)
            .forEach(s => {
 initValues[s] = true; 
});
        (accountType === AccountType.SUPPLIER ? supplier_extra : buyer_extra)
            .forEach(s => {
 initValues[s] = false; 
});
        setValues(initValues);
        // eslint-disable-next-line
    }, []);

    const onCheckBoxValueChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const { value } = e.target;
        const newValues = { ...values };
        newValues[value] = checked;
        setValues(newValues);
    };

    const defaultOptions = useMemo((): Array<{ name: string, value: string }> => {
        return (accountType === AccountType.SUPPLIER ? supplier_default_checked : buyer_default_checked)
            .map(value => ({ name: nameBuilder(value), value }));
    }, [accountType]);

    const extraOptions = useMemo((): Array<{ name: string, value: string }> => {
        return (accountType === AccountType.SUPPLIER ? supplier_extra : buyer_extra)
            .map(value => ({ name: nameBuilder(value), value }));
    }, [accountType]);

    const onCancel = useCallback(() => {
        dialogRef.current?.hide();
    }, [dialogRef]);

    const onExport = async () => {
        if (!selectedResults?.length) {
            dispatch(showError("Nothing to export as nothing selected"));
            return;
        }
        const fields: string[] = [];
        for (const f in values) {
            if (values[f]) {
                fields.push(f);
            }
        }
        if (!fields.length) {
            dispatch(showError("No field selected"));
            return;
        }
        try {
            setLoading(true);
            const data = buildCSVData(accountType, fields, selectedResults);
            const filename = accountType === AccountType.SUPPLIER ? "suppliers_export" : "buyers_export";
            const sheetname = accountType === AccountType.SUPPLIER ? "Suppliers" : "Buyers";
            await exportXLS({ data, info: exportHeaderInfo, filename, sheetname });
            dispatch(showSuccess('Data has been exported successfully'));
            dialogRef.current?.hide();
        } catch (error) {
            dispatch(showError(error.message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            className={styles.dialog}
            dialogRef={dialogRef}
            disableBackdropClick={loading}
            loading={loading}
            header="Custom Export"
            onClose={() => setValues(initValues)}
        >
            <div className={styles.container}>
                <div className={styles.description}>
                    Default exported columns
                </div>
                <div className={styles.block}>
                    {defaultOptions.map(({ name, value }) => (
                        <CheckBox
                            key={value}
                            label={name}
                            value={value}
                            checked={!!values[value]}
                            onChange={onCheckBoxValueChange}
                        />
                    ))}
                </div>
                <div className={styles.description}>
                    Extra columns
                </div>
                <div className={styles.block}>
                    {extraOptions.map(({ name, value }) => (
                        <CheckBox
                            key={value}
                            label={name}
                            value={value}
                            checked={!!values[value]}
                            onChange={onCheckBoxValueChange}
                        />
                    ))}
                </div>
                <div className={styles.footer}>
                    <Button plainLink onClick={onCancel} disabled={loading}>Cancel</Button>
                    <Button onClick={onExport} disabled={loading}>export csv</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default forwardRef(CustomExportDialog);