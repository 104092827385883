import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import Dialog, { DialogRef } from "src/components/widgets/dialog/Dialog";
import FormikSelect from "src/components/widgets/input/FormikSelect";
import useUserRoles from "src/hooks/useUserRoles";
import { updateSupplier } from "src/redux/actions/suppliers";
import Verified from "src/resources/images/svg/verified_tick.svg";
import Supplier, { OwnershipType } from "src/types/model/Supplier";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { getBusinessOwners, updateBusinessOwners } from "../../../../../../../redux/actions/businessOwners";
import { showError } from "../../../../../../../redux/actions/snackbars";
import BusinessOwner from "../../../../../../../types/model/BusinessOwner";
import Button from "../../../../../../widgets/button/Button";
import BusinessOwnerFieldArray from "../../../../../../widgets/formikFields/BusinessOwnerFieldArray";
import Header from "../../../../../../widgets/header/Header";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import Loading from "../../../../../loading/Loading";
import styles from "./Ownership.module.scss";

type RouteParams = {
    accountId: string;
}


export type OwnershipValues = {
    owners: BusinessOwner[],
    ownershipType?: OwnershipType,
    iwiAffiliations?: string[],
    pasifikaAffiliations?: string[],
};

const ownerValidation = Yup.object({
    name: Yup.string().required("A name is required"),
    genderIdentity: Yup.string().required("Required"),
    pctOwned: Yup.number()
        .min(0, "You can't own less than 0% of the company.")
        .max(100, "You can't own more than 100% of the company.")
        .required("Percentage ownership required")
});

const validationSchema = Yup.object({
    owners: Yup.array(ownerValidation).min(1, "At least one owner required"),
});

export default function Ownership(props: RouteComponentProps<RouteParams>) {
    const dispatch = useDispatch();
    const suppliers = useSelector((state) => state.suppliers);
    const id = props?.match?.params?.accountId;
    const supplier: Supplier = suppliers[id];
    const owners = useSelector(state => state.businessOwners[id]);
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { isSuperAdmin } = useUserRoles();
    const ownershipTypeDialog = useRef<DialogRef>(null);

    useMountEffect(async () => {
        if (!owners) {
            await dispatch(getBusinessOwners(Number(id)));
        }

        setLoading(false);
    });

    const onSubmit = async (values: OwnershipValues) => {
        try {
            setLoading(true);
            await dispatch(updateSupplier({
                ...supplier,
                ownershipType: values.ownershipType,
            }));
            await dispatch(updateBusinessOwners(supplier.id, values.owners.map((o) => ({
                ...o,
                account: supplier.id
            }))));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const initialValues: OwnershipValues = {
        owners,
        ownershipType: supplier.ownershipType,
        iwiAffiliations: supplier.iwiAffiliations,
        pasifikaAffiliations: supplier.pasifikaAffiliations,
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Formik<OwnershipValues> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(formikBag) => {

                const toggleEdit = async () => {
                    if (editing && formikBag.dirty) {
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };

                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                const onOwnershipTypeChange = ({ newOwnershipType }: { newOwnershipType?: OwnershipType }) => {
                    formikBag.setFieldValue("ownershipType", newOwnershipType);
                };

                const ownershipText = (): string => {
                    if (!formikBag.values.ownershipType) {
                        return "This business has not officially had their ownership verified";
                    }
                    const text = "This business has";
                    let str = "";

                    switch (formikBag.values.ownershipType) {
                        case OwnershipType.MaoriOwned:
                            str = "met the Māori owned business criteria";
                            break;
                        case OwnershipType.PasifikaOwned:
                            str = "met the Pasifika owned business criteria";
                            break;
                        case OwnershipType.MaoriPasifikaCombined:
                            str = "met the Māori & Pasifika ownership combined to meet threshold criteria";
                            break;
                        case OwnershipType.MaoriPasifikaOwned:
                            str = "met both the Māori & Pasifika owned business criteria";
                            break;
                        default:
                            break;
                    }
                    return `${text} ${str}`;
                };

                const btnText = formikBag.values.ownershipType ? "Update" : "Verify ownership";

                return (
                    <Form>
                        <Header title={`${supplier.name}`}
                            action={isSuperAdmin ? (
                                <Button uppercase loading={loading || formikBag.isSubmitting}
                                    onClick={toggleEdit}>{actionText}</Button>
                            ) : undefined}
                            subnodes={<AccountHistory account={supplier} />} />
                        <SectionHeader title={"Ownership"} />
                        <div className={styles.ownership_type}>
                            <div className={styles.ownership_text}>
                                {formikBag.values.ownershipType && <img className={styles.ownership_badge} alt="ownership verified" src={Verified} />}
                                <div>{ownershipText()}</div>
                            </div>
                            {editing && (
                                <div>
                                    <Button plainLink onClick={() => ownershipTypeDialog.current?.show()}>{btnText}</Button>
                                </div>
                            )}
                        </div>
                        <BusinessOwnerFieldArray disabled={!editing} />
                        <Dialog className={styles.dialog_content}
                            dialogRef={ownershipTypeDialog}
                            header={"CHANGE OWNERSHIP TYPE"}>
                            <Formik<{ newOwnershipType?: OwnershipType }>
                                initialValues={{ newOwnershipType: formikBag.values.ownershipType }}
                                onSubmit={onOwnershipTypeChange}>
                                <Form>
                                    <FormikSelect name={"newOwnershipType"}
                                        label={"Ownership type"}
                                        options={[
                                            { name: "Māori owned business criteria met", value: OwnershipType.MaoriOwned },
                                            { name: "Pasifika owned business criteria met", value: OwnershipType.PasifikaOwned },
                                            { name: "Both Māori & Pasifika owned business criteria met", value: OwnershipType.MaoriPasifikaOwned },
                                            { name: "Māori & Pasifika ownership combined to meet threshold criteria", value: OwnershipType.MaoriPasifikaCombined }
                                        ]} />
                                    <div className={styles.dialog_btns}>
                                        <Button regular type={"submit"} onClick={() => ownershipTypeDialog.current?.hide()}>Update</Button>
                                        <Button plainLink type={"reset"} onClick={() => ownershipTypeDialog.current?.hide()}>Cancel</Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Dialog>
                    </Form>
                );
            }}

        </Formik>
    );

}
