import get from "./util/get";
import put from "./util/put";
import post from "./util/post";
import BusinessContact from "../../../types/model/BusinessContact";
import ListResult from "src/types/ListResult";
import AmotaiConnection from "src/types/model/AmotaiConnection";

function getBusinessContacts(accountId: number): Promise<BusinessContact[]> {
    return get(`/v1/accounts/${accountId}/contacts`);
}

function createBusinessContacts(accountId: number, contacts: Pick<BusinessContact, "name" | "title" | "email" | "phoneNumber">[]): Promise<BusinessContact[]> {
    return post(`/v1/accounts/${accountId}/contacts`, contacts);
}

function updateBusinessContacts(accountId: number, values: BusinessContact[]): Promise<BusinessContact[]> {
    return put(`/v1/accounts/${accountId}/contacts`, values);
}

function getConnectionsInAccount(accountId: number, limit?: number, cursor?: string|null): Promise<ListResult<AmotaiConnection>> {
    return get(`/v1/accounts/connections/accounts/${accountId}`, { params: { limit, cursor } });
}

function getConnectionsNumberInAccount(accountId: number): Promise<number> {
    return get(`/v1/accounts/connections/accounts/${accountId}/number`);
}

const BusinessContactsAPI = {
    getBusinessContacts,
    createBusinessContacts,
    updateBusinessContacts,
    getConnectionsInAccount,
    getConnectionsNumberInAccount
};

export default BusinessContactsAPI;