import Note from "../../types/model/Note";
import { Actions, Types } from "../actions/notes";

const initialState: {
    [accountId: number]: {
        [noteId: number]: Note;
    };
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function notes(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_NOTE: {
            const { payload } = action as ActionValueTypes<Types.SET_NOTE>;
            const { accountId, note } = payload!;
            return {
                ...state,
                [accountId]: {
                    ...(state[accountId] || {}),
                    [note.id]: note,
                },
            };
        }
        case Types.SET_NOTES: {
            const { payload } = action as ActionValueTypes<Types.SET_NOTES>;
            const { accountId, notes: notesList } = payload!;

            const newState = {};

            notesList.forEach((note) => {
                newState[note.id] = note;
            });

            return {
                ...state,
                [accountId]: newState,
            };
        }
        case Types.SET_ALL_NOTES: {
            const { payload } = action as ActionValueTypes<Types.SET_ALL_NOTES>;
            const newState = {};
            payload?.forEach(note => {
                const { account, id } = note;
                const prev = newState[account] ?? {};
                prev[id] = note;
                newState[account] = prev;
            });
            return newState;
        }
        default: {
            return state;
        }
    }
}
