import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Formik, FormikProps, useField, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import Select from "src/components/widgets/input/Select";
import Supplier from "src/types/model/Supplier";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import { getMainCategories } from "../../../../../../../redux/actions/categories";
import { showError } from "../../../../../../../redux/actions/snackbars";
import { updateSupplier } from "../../../../../../../redux/actions/suppliers";
import { Category, SubCategory, SubSubCategory } from "../../../../../../../types/model/Category";
import Button from "../../../../../../widgets/button/Button";
import Header from "../../../../../../widgets/header/Header";
import { CheckboxGroupItem } from "../../../../../../widgets/input/FormikCheckboxGroup";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import Loading from "../../../../../loading/Loading";
import styles from "../../SupplierDetail.module.scss";

type RouteParams = {
    accountId: string;
}

type BusinessCategoryFormValues = {
    mainCategories?: Category[];
    subCategories?: SubCategory[];
    subSubCategories?: SubSubCategory[];
}

const CategoryValidationSchema = Yup.object({
    mainCategories: Yup.array().of(Yup.string().required("Please select a value")).min(1, "At least 1 required"),
    subCategories: Yup.array().of(Yup.string()),
    subSubCategories: Yup.array().of(Yup.string()),
});


export default function BusinessCategory(props: RouteComponentProps<RouteParams>) {
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const id = props?.match?.params?.accountId;
    const suppliers = useSelector((state) => state.suppliers);
    const supplier: Supplier = suppliers[id];
    const { isSuperAdmin, isRegionalManager } = useUserRoles();
    const [fieldError, setFieldError] = useState<string>();
    const eligable = useMemo(() => isSuperAdmin || isRegionalManager, [isSuperAdmin, isRegionalManager]);

    useMountEffect(async () => {
        try {
            await dispatch(getMainCategories());
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onSubmit = async (values: BusinessCategoryFormValues) => {

        try {
            setSubmitting(true);
            await dispatch(updateSupplier({
                ...supplier,
                ...values
            }));
        } catch (e) {
            dispatch(showError(e.message));
        }
        setSubmitting(false);
    };

    const toggleEdit = async (formikBag: FormikProps<BusinessCategoryFormValues>) => {
        if (editing && (formikBag.dirty || formikBag.touched)) {
            const r = await formikBag.validateForm(formikBag.values);
            const _error = Object.values(r)[0] as string;
            if (_error) {
                setFieldError(_error);
                formikBag.setSubmitting(false);
                return;
            }
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }
    const initialValues: BusinessCategoryFormValues = {
        mainCategories: supplier.mainCategories ?? [],
        subCategories: supplier.subCategories ?? [],
        subSubCategories: supplier.subSubCategories ?? [],
    };

    return (
        <Formik<BusinessCategoryFormValues> initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={CategoryValidationSchema}>
            {formik => {
                let actionText = "Edit";
                if (editing) {
                    if (formik.dirty || formik.touched) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>
                        <form>
                            <Header title={`${supplier.name}`}
                                action={eligable ? (
                                    <Button uppercase loading={submitting || formik.isSubmitting}
                                        onClick={() => toggleEdit(formik)}>{actionText}</Button>
                                ) : undefined}
                                subnodes={<AccountHistory account={supplier} />} />
                            <div>
                                <SectionHeader title={"Business Category"} />
                                <div className={styles.form}>
                                    <div className={styles.industry_form}>
                                        {fieldError && <div className={styles.error}>{fieldError}</div>}
                                        <FormikMultipleCategoriesSelect disabled={!editing} maxMainCat={5} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            }}
        </Formik>
    );
}


export function FormikMultipleCategoriesSelect(props: { disabled: boolean, maxMainCat?: number }) {
    const { disabled, maxMainCat } = props;
    const categories = useSelector<Category[]>((state) => state.categories.main);
    const { values }: FormikProps<BusinessCategoryFormValues> = useFormikContext();
    const { mainCategories, subCategories, subSubCategories } = values;
    const [, , mainHelpers] = useField("mainCategories");
    const [, , subHelpers] = useField("subCategories");
    const [, , subSubHelpers] = useField("subSubCategories");

    const mainSelectedIds = useMemo(() => mainCategories?.map(m => m.id) ?? [], [mainCategories]);

    const categoryOptions = useMemo(() => {
        return categories.filter(c => !mainSelectedIds.includes(c.id)).map(c => c.name);
    }, [categories, mainSelectedIds]);


    const onSubCategoryChange = (sub: SubCategory) => {
        const selectedSub = subCategories?.find((subCat) => subCat.id === sub.id);
        const ssids = sub.subSubCategories?.map((subSubCat) => subSubCat.id);
        if (!selectedSub) {
            // clear subSub categories from the existing selection
            const _subSubCategories = subSubCategories?.filter((subSubCat) => !ssids?.includes(subSubCat.id));
            subSubHelpers.setValue(_subSubCategories);
        }
    };

    const onSubSubCategoryChange = (subSub: SubSubCategory) => {
        const parentSubCat = categories.map(c => c.subCategories).flat().find(s => s?.id === subSub.parent)!;
        const isParentSelected = !!subCategories?.find(s => s.id === parentSubCat.id);
        const isChildSelected = !!subSubCategories?.find((subSubCat) => subSubCat.id === subSub.id);
        if (!isParentSelected && isChildSelected) {
            // have selected a subSub category without the parent subCategory added, select it now
            subHelpers.setValue([...(subCategories ?? []), parentSubCat]);
        }
    };

    const onAddSinglePrimary = () => {
        const mainSelectedIds = mainCategories?.map(m => m.id) ?? [];
        const newMainCat = categories.find(c => !mainSelectedIds.includes(c.id));
        if (newMainCat) {
            const _mainCategories = [...(mainCategories ?? []), newMainCat];
            mainHelpers.setValue(_mainCategories);
        }
    };

    const onRemoveSinglePrimary = (_cat: Category) => {
        const cat = categories.find(c => c.id === _cat.id)!;
        const sids = cat.subCategories?.map(s => s.id);
        const ssids = cat.subCategories?.map(s => s.subSubCategories?.map(ss => ss.id)).flat();
        const _subCategories = subCategories?.filter(sub => !sids?.includes(sub.id));
        const _subSubCategories = subSubCategories?.filter(subsub => !ssids?.includes(subsub.id));
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(mainCategories?.filter(m => m.id !== cat.id));
    };

    const onSinglePrimaryChange = (newCatName: string, idx: number) => {
        // remove main, push new main in and clear old/new sub & subSub
        const newCat = categories.find(c => c.name === newCatName)!;
        const oldCat = categories.find(c => c.id === mainCategories?.[idx].id)!;
        const sids = [
            ...(oldCat.subCategories?.map(s => s.id) ?? []),
            ...(newCat.subCategories?.map(s => s.id) ?? [])
        ];
        const ssids = [
            ...(oldCat.subCategories?.map(s => s.subSubCategories?.map(ss => ss.id)).flat() ?? []),
            ...(newCat.subCategories?.map(s => s.subSubCategories?.map(ss => ss.id)).flat() ?? [])
        ];
        const _subCategories = subCategories?.filter(sub => !sids.includes(sub.id));
        const _subSubCategories = subSubCategories?.filter(subsub => !ssids.includes(subsub.id));
        const _mainCategories = mainCategories?.map((prev, i) => i === idx ? newCat : prev);
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(_mainCategories);
    };

    return <>
        {mainCategories?.map(mc => categories.find(c => c.id === mc.id)!).map((cat, idx) =>
            <div key={cat.id} className={styles.industry_form}>
                <Select defaultValue={cat.name}
                    disabled={disabled}
                    label={"Primary Industry"}
                    tooltip={"Select all relevant categories that you work in so that buyers are able to understand the services/works/goods you provide"}
                    onChange={name => onSinglePrimaryChange(name, idx)}
                    options={[...categoryOptions, cat.name]}
                    containerClassName={styles.full_input} />

                {cat.subCategories?.map((sub, key) =>
                    <SubCategoryView key={`sub_${key}`}
                        disabled={disabled}
                        sub={sub}
                        onSubCategoryChange={onSubCategoryChange}
                        onSubSubCategoryChange={onSubSubCategoryChange} />
                )}
                <div className={styles.remove_cat_button}>
                    <Button plain onClick={() => onRemoveSinglePrimary(cat)} disabled={disabled}>{`- Remove ${cat.name}`}</Button>
                </div>
            </div>
        )}
        {categoryOptions.length > 0 && (maxMainCat ? (mainCategories ?? []).length < maxMainCat : true)
            && <Button plain onClick={onAddSinglePrimary} disabled={disabled}>
                {`+ Add ${(mainCategories ?? []).length ? 'another' : 'one'} primary industry`}
            </Button>}
    </>;
}

function SubCategoryView(props: {
    sub: SubCategory,
    onSubCategoryChange: (sub: SubCategory) => void,
    onSubSubCategoryChange: (subSub: SubSubCategory) => void,
    disabled: boolean,
}) {
    const { sub, onSubCategoryChange, onSubSubCategoryChange, disabled } = props;
    if (!sub.subSubCategories?.length) {
        return <Paper className={styles.plain_subindustry_row}>
            <Field disabled={disabled} component={CheckboxGroupItem} name={"subCategories"} label={sub.name} value={sub} />
        </Paper>;
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }} className={styles.category_container}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label={"Expand"}
                aria-controls={"additional-actions1-content"}
                id={"additional-actions1-header"}
                className={styles.category_item}
            >
                <FormControlLabel
                    className={styles.category_label}
                    aria-label={"Sub Industry"}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                        <Field name={"subCategories"}>
                            {(bags: any) => {
                                return <CheckboxGroupItem {...bags} value={sub} disabled={disabled} sideEffect={() => onSubCategoryChange(sub)} />;
                            }}
                        </Field>
                    )}
                    label={sub.name}
                />
            </AccordionSummary>

            <AccordionDetails>
                <div className={styles.subsub_container}>
                    {sub.subSubCategories?.map((subSub, key) => (
                        <Field key={`subsub_${key}`}
                            name={"subSubCategories"}>
                            {(bags: any) => {
                                return <CheckboxGroupItem {...bags}
                                    value={subSub}
                                    disabled={disabled}
                                    sideEffect={() => onSubSubCategoryChange(subSub)}
                                    label={subSub.name}
                                />;
                            }}
                        </Field>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
