/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { nullRegion, RegionAccounts } from "src/types/model/AmotaiAccount";
import { REGIONS_NO_NATIONWIDE } from "src/util/constants";
import Supplier from "../../types/model/Supplier";
import { Actions, Types } from "../actions/suppliers";

const initialState: {
    [key: number]: Supplier;
    regionSuppliers: {[key: string]: RegionAccounts}
} = { regionSuppliers: {} };

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function suppliers(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_SUPPLIER: {
            const { payload } = action as ActionValueTypes<Types.SET_SUPPLIER>;
            const supplier = payload!;
            return {
                ...state,
                [supplier.id]: supplier,
            };
        }
        case Types.SET_SUPPLIERS: {
            const { payload } = action as ActionValueTypes<Types.SET_SUPPLIERS>;
            const newState = { ...state };
            payload!.items.forEach((supplier) => {
                newState[supplier.id] = supplier;
            });

            return newState;
        }
        case Types.SET_ALL_REGIONS: {
            const { payload } = action as ActionValueTypes<Types.SET_ALL_REGIONS>;
            const regionSuppliers: {[key: string]: RegionAccounts} = {};
            REGIONS_NO_NATIONWIDE.forEach(region => {
                regionSuppliers[region] = {
                    region,
                    number: 0,
                } as RegionAccounts;
            });
            payload?.forEach(rs => {
 regionSuppliers[rs.region ?? nullRegion] = { ...rs, region: rs.region ?? nullRegion }; 
});
            return {
                ...state,
                regionSuppliers
            };
        }
        case Types.SET_REGION_SUPPLIERS: {
            const { payload } = action as ActionValueTypes<Types.SET_REGION_SUPPLIERS>;
            const { regionSuppliers } = state;
            const suppliers = payload!.data.items;
            const region = payload!.region ?? nullRegion;
            (regionSuppliers[region] ?? {}).accounts = suppliers;
            return {
                ...state,
                regionSuppliers
            };
        }
        case Types.UPDATE_ASSIGNED_TO: {
            const { payload } = action as ActionValueTypes<Types.UPDATE_ASSIGNED_TO>;
            const { accountId, managerId } = payload!;
            const newState = { ...state };
            newState[accountId].assignedTo = managerId;
            return newState;
        }
        default: {
            return state;
        }
    }
}
