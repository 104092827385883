import React, { KeyboardEvent, useCallback } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from "classnames";
import styles from './AccountSearchControl.module.scss';
import FormikInput from '../../input/FormikInput';

type Props = {
    setKeyword: (keyword: string|undefined) => void,
    placeholder?: string,
    helperText?: string,
}
type SearchForm = {
    keyword: string
}
export default function AccountSearchControl({ setKeyword, placeholder, helperText }: Props) {

    const onSearch = useCallback(({ keyword }:SearchForm) => {
        setKeyword((keyword ?? '').trim());
    }, [setKeyword]);

    const onReset = useCallback(() => {
        setKeyword(undefined);
    }, [setKeyword]);

    return <Formik<SearchForm> initialValues={{ keyword: '' }}
                            validationSchema={Yup.object({ keyword: Yup.string().required(helperText ?? 'Type something to search...') })}
                            onSubmit={onSearch} onReset={onReset} >
            {({ dirty, isValid, isSubmitting, handleSubmit, handleReset }) => {
                return <Form className={styles.form}>
                        <FormikInput name="keyword" placeholder={placeholder} onKeyDown={(e: KeyboardEvent) => {
 if (e.keyCode === 13) {
 handleSubmit(); 
} 
}}
                            InputProps={{
                                startAdornment: <div className={classNames(styles.icon_btn, { [styles.icon_btn_disabled]: !dirty || !isValid || isSubmitting })}
                                                 onClick={() => handleSubmit()}>
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                </div>,
                                endAdornment: <div className={classNames(styles.icon_btn, { [styles.icon_btn_disabled]: !dirty })} onClick={handleReset}>
                                    <InputAdornment position="end">
                                        <ClearIcon />
                                    </InputAdornment>
                                </div>,
                        }} />
                    </Form>; 
}}
    </Formik>;

}