import Note from "../../types/model/Note";
import NotesAPI from "./api/notes";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_NOTE = "notes:SET_NOTE",
    SET_NOTES = "notes:SET_NOTES",
    SET_ALL_NOTES = "notes:SET_ALL_NOTES",
    }

const setNote = createAction<{ note: Note; accountId: number }>(Types.SET_NOTE);
const setNotes = createAction<{ notes: Note[]; accountId: number }>(Types.SET_NOTES);
const setAllNotes = createAction<Note[]>(Types.SET_ALL_NOTES);

export const Actions = {
    [Types.SET_NOTE]: setNote,
    [Types.SET_NOTES]: setNotes,
    [Types.SET_ALL_NOTES]: setAllNotes,
};
export type Actions = typeof Actions;

export const getNotes = wrapper(NotesAPI.getNotes, {
    action: (notes, [accountId]) => setNotes({ notes: notes.items, accountId }),
});

export const createUpdateNote = wrapper(NotesAPI.createUpdateNote, {
    action: (note, [accountId]) => setNote({ note, accountId }),
});

export const deleteNote = wrapper(NotesAPI.deleteNote, {
    action: (notes, [accountId]) => setNotes({ notes, accountId }),
});

export const getAllNotes = wrapper(NotesAPI.getAllNotes, { action: setAllNotes });