import ListResult from "../../../types/ListResult";
import { AccountType, nullRegion, RegionAccounts } from "../../../types/model/AmotaiAccount";
import Supplier from "../../../types/model/Supplier";
import get from "./util/get";
import put from "./util/put";

function getSupplier(supplierId: number | string): Promise<Supplier> {
    return get(`/v1/accounts/${supplierId}`);
}

function updateSupplier(supplier: Supplier): Promise<Supplier> {
    return put(`v1/accounts/supplier/${supplier.id}`, supplier);
}

function inductAccount(accountId: number, dto: { inductedAt?: string, inductedBy?: string }): Promise<Supplier> {
    return put(`v1/admin/account/${accountId}/induct`, dto);
}

function getRegionSuppliersOverview(): Promise<RegionAccounts[]> {
    return get(`v1/admin/accounts/regions/overview`);
}

function getSuppliersInRegion(region: string, limit: number, cursor?: string | null): Promise<{region: string, data: ListResult<Supplier>}> {
    return get('v1/admin/accounts/regions', {
        params: {
            type: AccountType.SUPPLIER,
            region: region === nullRegion ? null : region,
            limit,
            cursor,
        }
    }).then((data:ListResult<Supplier>) => Promise.resolve({ region, data })).catch(Promise.reject);
}

const SuppliersAPI = {
    getSupplier,
    updateSupplier,
    inductAccount,
    getRegionSuppliersOverview,
    getSuppliersInRegion
};

export default SuppliersAPI;
