import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Column } from 'material-table';
import moment from 'moment';
import React, { useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import useUserRoles from 'src/hooks/useUserRoles';
import { getAccountAllHistory } from 'src/redux/actions/audit';
import { showError } from 'src/redux/actions/snackbars';
import { getUser } from 'src/redux/actions/users';
import { PagingTableFilter } from 'src/redux/reducers/tables';
import ListResult from 'src/types/ListResult';
import { AccountAuditUserIdFields, HiddenEntities, HiddenFields, SpecialAccountAuditFields } from 'src/types/model/Audit';
import Header from '../../header/Header';
import PagingTable, { PagingTableRef } from '../../pagingTable/PagingTable';
import Tips from '../tips/Tips';
import styles from './Audits.module.scss';

type Props = RouteComponentProps<{ accountId: string }>;

export default function Audits(props: Props) {

    const { accountId } = props.match.params;
    const tableRef = useRef<PagingTableRef<Data, PagingTableFilter>>(null);
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.users);
    const { isAdmin, isRegionalManager, isSuperAdmin } = useUserRoles();

    if (!isAdmin && !isRegionalManager && !isSuperAdmin) {
        dispatch(showError('This page is not available to current user'));
        return null;
    }

    const getQuery = async (): Promise<ListResult<Data>> => {
        const data: Data[] = [];
        const audits = await dispatch(getAccountAllHistory(Number(accountId)));
        for (const audit of audits) {
            const { updatedAt, details, prev, entity } = audit;
            //skip hidden entity
            if (HiddenEntities.includes(entity)) {
                continue;
            }
            let updatedBy = users[audit.updatedBy];
            if (!updatedBy) {
                updatedBy = await dispatch(getUser(audit.updatedBy));
            }

            for (let change in details) {
                //skip hidden change field
                if (HiddenFields.includes(change)) {
                    continue;
                }
                let changeFrom: string | null = prev?.[change] ?? null;
                let changeTo: string = details[change]; //must present
                const tips: DataTips = {} as DataTips;
                const date = moment(updatedAt).format('DD/MM/YY');
                const changeBy = updatedBy?.email ?? 'n/a';
                // tips.changeBy = updatedBy?.email ?? 'n/a';
                if (AccountAuditUserIdFields.includes(change)) {
                    if (changeFrom) {
                        let user = users[changeFrom];
                        if (!user) {
                            user = await dispatch(getUser(changeFrom));
                        }
                        changeFrom = user?.name ?? 'n/a';
                        tips.changeFrom = user?.email ?? 'n/a';
                    }
                    if (changeTo && changeTo !== '') {
                        let user = users[changeTo];
                        if (!user) {
                            user = await dispatch(getUser(changeTo));
                        }
                        changeTo = user?.name ?? 'n/a';
                        tips.changeTo = user?.email ?? 'n/a';
                    }
                }

                if (SpecialAccountAuditFields.includes(change)) {
                    if (!changeFrom) {
                        changeFrom = '(New entity created)';
                    }
                    if (changeTo === '') {
                        changeTo = '(Entity was deleted)';
                    }
                }

                // if (audit.entity !== 'Account') {
                //     tips.change = audit.entity.replace(/([A-Z])/g, " $1");
                // }

                if (changeFrom && /^https?:\/\//.test(changeFrom)) {
                    tips.changeFrom = changeFrom;
                    changeFrom = '(URL change)';
                }

                if (/^https?:\/\//.test(changeTo)) {
                    tips.changeTo = changeTo;
                    changeTo = '(URL change)';
                }
                //customized change field name
                switch (change) {
                    case 'pctOwned':
                        change = 'percentageOwned';
                        changeFrom = changeFrom === '' ? '' : `${changeFrom}%`;
                        changeTo = changeTo === '' ? '' : `${changeTo}%`;
                        break;
                    default:
                        break;
                }
                data.push({
                    id: audit.id + Math.random(),
                    change,
                    entity,
                    changeBy,
                    changeFrom: changeFrom === '' ? '(Empty)' : changeFrom,
                    changeTo: changeTo === '' ? '(Empty)' : changeTo,
                    date,
                    updatedAt,
                    tips,
                });
            }
        }
        data.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        return {
            items: data,
            limit: data.length
        };
    };

    return (
        <div className={styles.container}>
            <Header title={`Change logs`} containerClass={styles.header} />

            <PagingTable<Data, PagingTableFilter>
                id={"audits-of-account"}
                tableRef={tableRef}
                columns={columns}
                getData={getQuery}
                options={{
                    headerStyle: {
                        padding: 5,
                    },
                }}
            />
        </div>
    );
}

type Data = {
    id: number,
    date: string,
    entity: string,
    change: string,
    changeFrom: string | null,
    changeTo: string,
    changeBy: string,
    updatedAt: string,
    tips: DataTips,
}

type DataTips = {
    changeFrom?: string,
    changeTo?: string,
    changeBy?: string,
    change?: string,
}

const columns: Column<Data>[] = [
    {
        title: 'Date',
        field: 'date',
        customSort: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        cellStyle: {
            padding: 5
        },
    },
    {
        title: 'Section',
        render: c => (<span>{nameBuilder(c.entity)}</span>),
        customSort: (a, b) => nameBuilder(a.entity).toUpperCase().localeCompare(nameBuilder(b.entity).toUpperCase()),
        cellStyle: {
            padding: 5
        },
    },
    {
        title: 'Field Updated',
        render: c => (<span>{nameBuilder(c.change.replace(/(^\w)/, v => v.toUpperCase()))}</span>),
        customSort: (a, b) => nameBuilder(a.change.replace(/(^\w)/, v => v.toUpperCase())).localeCompare(nameBuilder(b.change.replace(/(^\w)/, v => v.toUpperCase()))),
        cellStyle: {
            padding: 5
        },
    },
    {
        title: 'Updated from',
        render: c => (
            <div className={styles.with_tip}>
                <span>{c.changeFrom ?? 'n/a'}</span>
                {c.tips.changeFrom && <Tips icon={<InfoOutlinedIcon fontSize="small" />} tips={c.tips.changeFrom} />}
            </div>
        ),
        customSort: (a, b) => ((a.changeFrom ?? 'n/a').localeCompare(b.changeFrom ?? 'n/a')),
        cellStyle: {
            padding: 5
        },
    },
    {
        title: 'Updated To',
        render: c => (
            <div className={styles.with_tip}>
                <span>{c.changeTo}</span>
                {c.tips.changeTo && <Tips icon={<InfoOutlinedIcon fontSize="small" />} tips={c.tips.changeTo} />}
            </div>
        ),
        customSort: (a, b) => a.changeTo.localeCompare(b.changeTo),
        cellStyle: {
            padding: 5
        },
    },
    {
        title: 'User',
        render: c => (
            <div className={styles.with_tip}>
                <span>{c.changeBy}</span>
                {c.tips.changeBy && <Tips icon={<InfoOutlinedIcon fontSize="small" />} tips={c.tips.changeBy} />}
            </div>
        ),
        customSort: (a, b) => a.changeBy.localeCompare(b.changeBy),
        cellStyle: {
            padding: 5
        },
    }
];

function nameBuilder(name: string) {
    return name.replace(/([A-Z])/g, " $1").replace('Saa S', 'SaaS').replace('F T E', 'FTE');
}