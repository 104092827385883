import { Column } from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountSearchControl from "src/components/widgets/account/search/AccountSearchControl";
import AccountReviewDialog from "src/components/widgets/accountReviewDialog/AccountReviewDialog";
import AssignManagerDialog from "src/components/widgets/assignManagerDialog/AssignManagerDialog";
import Button from "src/components/widgets/button/Button";
import { DialogRef } from "src/components/widgets/dialog/Dialog";
import RegionsSelect from "src/components/widgets/input/RegionsSelect";
import Options, { OptionButton } from "src/components/widgets/more/Options";
import useUserRoles from "src/hooks/useUserRoles";
import AccountsAPI from "src/redux/actions/api/accounts";
import { AccountStatus, AccountType, PaymentType } from "src/types/model/AmotaiAccount";
import { simpleArrayCompare } from "src/util/utils";
import useDispatch from "../../../../../hooks/useDispatch";
import { getClientBuyers } from "../../../../../redux/actions/clientsBuyers";
import { PagingTableFilter } from "../../../../../redux/reducers/tables";
import ListResult from "../../../../../types/ListResult";
import BuyerClient from "../../../../../types/model/BuyerClient";
import Header from "../../../../widgets/header/Header";
import PagingTable, { PagingTableRef } from "../../../../widgets/pagingTable/PagingTable";
import styles from "./ClientsBuyersOverview.module.scss";

export default function ClientsBuyersOverview() {
    const dispatch = useDispatch();
    const history = useHistory();
    const reviewDialogRef = useRef<DialogRef | null>(null);
    const [buyer, setUpdatingBuyer] = useState<BuyerClient>();
    const tableRef = useRef<PagingTableRef<BuyerClient>>(null);
    const [buyersCnt, setBuyersCnt] = useState<number>(0);
    const [keyword, setKeyword] = useState<string | null | undefined>(null);
    const { isSuperAdmin } = useUserRoles();
    const basedRegionsRef = useRef<string[]>([]);
    const assignAdminDialogRef = useRef<DialogRef>(null);

    useEffect(() => {
        (async () => {
            const cnt = await AccountsAPI.getAccountsCount({ type: AccountType.BUYER_CLIENT });
            setBuyersCnt(cnt);
        })();
    }, []);

    useEffect(() => {
        if (keyword) {
            tableRef.current?.refresh();
        }
    }, [tableRef, keyword]);

    const getQuery = async (queryFilter: PagingTableFilter): Promise<ListResult<BuyerClient>> => {
        const { limit, cursor } = queryFilter;
        const request = { basedRegions: basedRegionsRef.current, type: AccountType.BUYER_CLIENT, keyword };
        return dispatch(getClientBuyers(request, limit, cursor));
    };
    const onChangeStatusPress = (buyer: BuyerClient) => {
        setUpdatingBuyer(buyer);
        reviewDialogRef.current?.show();
    };
    const onEditPress = (buyer?: BuyerClient) => {
        if (buyer) {
            history.push(`/buyers/${buyer.id}`);
        }
    };
    const onAnyBuyerChange = useCallback(async () => {
        tableRef?.current?.refresh();
        const cnt = await AccountsAPI.getAccountsCount({ type: AccountType.BUYER_CLIENT });
        setBuyersCnt(cnt);
    }, [tableRef, setBuyersCnt]);

    const optionButtons = (data: BuyerClient): Array<OptionButton<BuyerClient>> => {
        const options: Array<OptionButton<BuyerClient>> = [
            {
                onPress: () => onEditPress(data),
                buttonText: "View/Edit",
            }];
        if (isSuperAdmin) {
            options.push(
                {
                    onPress: () => onChangeStatusPress(data),
                    buttonText: "Change status",
                },
                {
                    onPress: () => onAssignAdminManager(data),
                    buttonText: "Assign Buyer Manager",
                    // disabled: !!data.assignedTo,
                }
            );
        }
        return options;

    };

    const onAssignAdminManager = (buyer: BuyerClient) => {
        setUpdatingBuyer(buyer);
        assignAdminDialogRef.current?.show();
    };

    const TABLE_COLUMNS: Column<BuyerClient>[] = [
        {
            title: "Business Name",
            field: "name"
        },
        {
            title: "Primary Contact",
            field: "mainContact.name"
        },
        {
            title: "Phone",
            render: (c: BuyerClient) => {
                const { mainContact } = c;
                if (!mainContact) {
                    return <div />;
                }
                const { phoneNumber, mobileNumber } = mainContact;
                const phone = phoneNumber ?? mobileNumber;
                if (!phone) {
                    return <div />;
                }
                return (
                    <div>
                        {phone.charAt(0) === "0" ? phone : `0${phone}`}
                    </div>
                );
            }
        },
        {
            title: "Email",
            field: "mainContact.email"
        },
        {
            title: "SaaS Plan",
            render: buyer => buyer.saaSPlanName ?? "Unavailable",
            customFilterAndSearch: (term, buyer) => {
                const field: string = buyer.saaSPlanName ?? "Unavailable";
                const escapeRegex = (term ?? "").replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
                return new RegExp(escapeRegex, "i").test(field);
            },
            customSort: (a, b) => (a.saaSPlanName ?? "Unavailable").localeCompare(b.saaSPlanName ?? "Unavailable")
        },
        {
            title: "Payment Method",
            field: "paymentType",
            render: buyer => buyer.paymentType ?? PaymentType.STRIPE
        },
        {
            title: "Status",
            render: buyer => buyer.status === AccountStatus.DISABLED ? 'ON_HOLD' : buyer.status,
            customSort: (a, b) => {
                const statusA = a.status === AccountStatus.DISABLED ? 'ON_HOLD' : a.status;
                const statusB = b.status === AccountStatus.DISABLED ? 'ON_HOLD' : b.status;
                return statusA.localeCompare(statusB);
            },
        },
        {
            render: buyer => (
                <Options<BuyerClient> data={buyer} optionButtons={optionButtons(buyer)} />
            ),
            disableClick: true
        }
    ];

    const onRegionsSelected = (basedRegions: string[]) => {
        if (!simpleArrayCompare(basedRegions, basedRegionsRef.current)) {
            basedRegionsRef.current = basedRegions;
            tableRef.current?.refresh();
        }
    };

    const goBack = useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [history]);

    return (
        <div>
            <div className={styles.back} onClick={goBack}>&lt;&lt; Back</div>
            <Header title={"Buyers Overview"}
                action={
                    <Button to={`/export/${AccountType.BUYER_CLIENT.toLowerCase()}`}>
                        CSV Export
                    </Button>
                } />
            <PagingTable<BuyerClient, PagingTableFilter>
                id={"client-buyer"}
                title={`Total client-buyers: ${buyersCnt}`}
                tableRef={tableRef}
                options={{ toolbar: true }}
                onRowClick={(_, data) => onEditPress(data)}
                columns={TABLE_COLUMNS}
                getData={getQuery}
                topTools={
                    <div className={styles.controls}>
                        <AccountSearchControl setKeyword={setKeyword} placeholder={`Search all ${buyersCnt} Buyers`} />
                        <RegionsSelect onFinish={onRegionsSelected} containerClassName={styles.select} />
                    </div>
                }
            />
            <AccountReviewDialog ref={reviewDialogRef} onStatusSubmit={onAnyBuyerChange} account={buyer} />
            <AssignManagerDialog ref={assignAdminDialogRef} account={buyer} />
        </div>
    );
}

