import { Actions, Types } from "../actions/businessReference";
import BusinessReference from "../../types/model/BusinessReference";

const initialState: {[key: string]: BusinessReference[]} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function businessReferences(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BUSINESS_REFERENCES: {
            const { payload } = action as ActionValueTypes<Types.SET_BUSINESS_REFERENCES>;
            if (!payload?.length) {
                return {
                    ...state
                };
            }

            const account = payload[0]?.account;
            return {
                ...state,
                [account]: payload,
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
}