import { useFormikContext } from "formik";
import React from "react";
import styles from "./Regions.module.scss";
import Checkbox from "../checkBox/CheckBox";

export type RegionsProps = {
    disabled?: boolean;
    name?: string;
}

export default function Regions(props: RegionsProps) {

    const { disabled, name } = props;

    const { values, setFieldValue } = useFormikContext<object & {
        regions: string[];
        assignedRegions?: string[]
    }>();

    const regions = values.assignedRegions || values.regions || [];

    const onCheckBoxValueChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newRegions = [...regions];
        const region = e.target.value;
        if (!checked) {
            const index = newRegions.indexOf(region);
            newRegions.splice(index, 1);
        } else {
            newRegions.push(region);
        }
        const fieldName = name || "regions";
        setFieldValue(fieldName, newRegions);
    };

    return (
        <div className={styles.regions}>
            <span>Regions</span>
            <div className={styles.regions_list}>
                <Checkbox label={"Nationwide"}
                    value={"nationwide"}
                    disabled={disabled}
                    checked={regions.includes("nationwide")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Tai Tokerau - Northland"}
                    value={"northland"}
                    disabled={disabled}
                    checked={regions.includes("northland")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Tāmaki-makau-rau - Auckland"}
                    value={"auckland"}
                    disabled={disabled}
                    checked={regions.includes("auckland")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Waikato"}
                    value={"waikato"}
                    disabled={disabled}
                    checked={regions.includes("waikato")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Moana-a-Toi - Bay of Plenty"}
                    value={"bay_of_plenty"}
                    disabled={disabled}
                    checked={regions.includes("bay_of_plenty")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Matau-a-Māui - Hawkes Bay"}
                    value={"hawkes_bay"}
                    disabled={disabled}
                    checked={regions.includes("hawkes_bay")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Whanga-nui-a-Tara - Wellington"}
                    value={"wellington"}
                    disabled={disabled}
                    checked={regions.includes("wellington")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Tai-o-Aorere - Tasman"}
                    value={"tasman"}
                    disabled={disabled}
                    checked={regions.includes("tasman")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Ōtākou - Otago"}
                    value={"otago"}
                    disabled={disabled}
                    checked={regions.includes("otago")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Tai Poutini - West coast"}
                    value={"west_coast"}
                    disabled={disabled}
                    checked={regions.includes("west_coast")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Waitaha - Canterbury"}
                    value={"canterbury"}
                    disabled={disabled}
                    checked={regions.includes("canterbury")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Murihiku - Southland"}
                    value={"southland"}
                    disabled={disabled}
                    checked={regions.includes("southland")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Tauihu-o-te-waka - Marlborough"}
                    value={"marlborough"}
                    disabled={disabled}
                    checked={regions.includes("marlborough")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Te Tai Rāwhiti - Gisborne"}
                    value={"gisborne"}
                    disabled={disabled}
                    checked={regions.includes("gisborne")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Taranaki"}
                    value={"taranaki"}
                    disabled={disabled}
                    checked={regions.includes("taranaki")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Manawatū-Whanganui"}
                    value={"manawatū-whanganui"}
                    disabled={disabled}
                    checked={regions.includes("manawatū-whanganui")}
                    onChange={onCheckBoxValueChange} />
                <Checkbox label={"Whakatū - Nelson"}
                    value={"nelson"}
                    disabled={disabled}
                    checked={regions.includes("nelson")}
                    onChange={onCheckBoxValueChange} />
            </div>
        </div>
    );
}


