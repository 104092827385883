import { Field, useField } from "formik";
import { FormControlLabel } from "@material-ui/core";
import React from "react";
import { find } from "lodash";
import styles from "./FormikCheckboxGroup.module.scss";
import { StyledCheckbox } from "../checkBox/CheckBox";

export function CheckboxGroupItem({ field, form, label, ...otherProps }: any) {
    const { name, value: formikValue } = field;
    const values = formikValue || [];
    // const values = [...formikValue];

    let index: number;
    let checked;

    if (typeof (otherProps.value) === "object") {
        // field value is an object, expected to have an id property
        index = values.indexOf(find(values, (o: any) => o.id === otherProps.value.id));
        checked = !!(values.filter((v: any) => v.id === otherProps.value.id) || [])[0];
    } else {
        index = values.indexOf(otherProps.value);
        checked = values.indexOf(otherProps.value) !== -1;
    }

    const handleChange = () => {
        if (index === -1) {
            values.push(otherProps.value);
        } else {
            values.splice(index, 1);
        }
        form.setFieldTouched(name, true);
        form.setFieldValue(name, values);
        if (otherProps.sideEffect) {
            otherProps.sideEffect();
        }
    };

    return (
        <FormControlLabel
            control={(
                <StyledCheckbox checked={checked}
                                onChange={handleChange}
                                disabled={otherProps.disabled} />
            )}
            label={label} />
    );
}

type CheckboxGroupProps = {
    name: string;
    // keyedItems?: { label: string, value: string }[];
    stringItems?: string[];
    disabled?: boolean;
}

export default function FormikCheckboxGroup(props: CheckboxGroupProps) {
    const { name, stringItems, disabled } = props;

    const [, meta] = useField(name);
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    // if (keyedItems) {
    //     return (
    //         <>
    //             {keyedItems.map((item) => (
    //                 <FastField component={CheckboxGroupItem}
    //                            name={name}
    //                            disabled={disabled}
    //                            label={item.label}
    //                            value={item.value} />
    //             ))}
    //             {showError && <div className={styles.error}>{fieldError}</div>}
    //         </>
    //     )
    // }

    return (
        <>
            {stringItems?.map((item, idx) => (
                <Field key={idx}
                       component={CheckboxGroupItem}
                       name={name}
                       label={item}
                       disabled={disabled}
                       value={item} />
            ))}
            {showError && <div className={styles.error}>{fieldError}</div>}
        </>
    );
}