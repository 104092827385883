import React from 'react';
import styles from './RSVPs.module.scss';
import Header from 'src/components/widgets/header/Header';
import { RouteComponentProps } from 'react-router-dom';
import useSelector from 'src/hooks/useSelector';
import AmotaiEvent, { EventRSVP } from 'src/types/model/AmotaiEvent';
import PagingTable from 'src/components/widgets/pagingTable/PagingTable';
import { Column } from 'material-table';
import ListResult from 'src/types/ListResult';
import { getEventRsvps } from 'src/redux/actions/events';
import { PagingTableFilter } from 'src/redux/reducers/tables';
import useDispatch from 'src/hooks/useDispatch';
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";

type RouteParams = {
    eventId: string;
}

type Filter = {} & PagingTableFilter

export default function RSVPs(props: RouteComponentProps<RouteParams>) {
    const { eventId } = props.match.params;

    const dispatch = useDispatch();
    const event: AmotaiEvent = useSelector(state => state.events[eventId]) || {};

    const columns: Column<EventRSVP>[] = [
        {
            title: "Date",
            field: "invitedAt",
            render: rsvp => new Date(rsvp.invitedAt).toLocaleDateString('en-NZ')
        },
        {
            title: "Name",
            field: "attendeeName",
        },
        {
            title: "Email",
            field: "attendeeEmail"
        },
        {
            title: "Business",
            field: "businessName"
        },
        {
            title: "Invited By",
            field: "invitedByUser",
            render: rsvp => rsvp.invitedByUser?.name ?? ''
        }
    ];

    const getQuery = async (queryFilter: Filter): Promise<ListResult<EventRSVP>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getEventRsvps(eventId, limit, cursor));
    };

    return <div className={styles.container}>
        <Header title={event.name}
        />
        <SectionHeader title={"RSVPs"} />
        <PagingTable<EventRSVP, Filter> id={'rsvps'}
            columns={columns}
            getData={getQuery} />
    </div>;
}