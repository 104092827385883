import Card from "src/types/model/Card";
import get from "./util/get";

function getCards(accountId: number): Promise<Card[]> {
    return get(`v1/accounts/${accountId}/cards`);
}

function getSingleCard(accountId: number, cardId: string): Promise<Card> {
    return get(`v1/accounts/${accountId}/cards/${cardId}`);
}

const CardsAPI = {
    getCards,
    getSingleCard,
};

export default CardsAPI;