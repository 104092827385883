import { Category, SubCategory, SubSubCategory } from "src/types/model/Category";
import CategoryAPI from "./api/category";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_MAIN_CATEGORIES = "categories:SET_MAIN_CATEGORIES",
    SET_SUB_CATEGORIES = "categories:SET_SUB_CATEGORIES",
    SET_SUB_SUB_CATEGORIES = "categories:SET_SUB_SUB_CATEGORIES"
}

export const setMainCategories = createAction<Category[]>(Types.SET_MAIN_CATEGORIES);
export const setSubCategories = createAction<SubCategory[]>(Types.SET_SUB_CATEGORIES);
export const setSubSubCategories = createAction<SubSubCategory[]>(Types.SET_SUB_SUB_CATEGORIES);

export const Actions = {
    [Types.SET_MAIN_CATEGORIES]: setMainCategories,
    [Types.SET_SUB_CATEGORIES]: setSubCategories,
    [Types.SET_SUB_SUB_CATEGORIES]: setSubSubCategories
};

export type Actions = typeof Actions;

export const getMainCategories = wrapper(CategoryAPI.getMainCategories, {
    action: setMainCategories
});

export const getSubCategories = wrapper(CategoryAPI.getSubCategories, {
    action: setSubCategories
});

export const getSubSubCategories = wrapper(CategoryAPI.getSubCategories, {
    action: setSubSubCategories
});

export const createCategory = wrapper(CategoryAPI.createOrUpdateCategory, {
    action: setMainCategories
});

export const deleteCategory = wrapper(CategoryAPI.deleteCategory, {
    action: setMainCategories
});