import React, { useMemo } from "react";
import { FieldValidator } from "formik";
import moment from "moment";
import useFormikField, { FormikFieldExtraProps } from "../../../hooks/useFormikField";
import DatePicker, { DatePickerProps } from "./DatePicker";
import styles from './FormikDatePicker.module.scss';

export type FormikDatePickerProps = {
    name: string;
    validate?: FieldValidator;
    dateStringOnly?: boolean;
    dateStringFormat?: 'DD/MM/YYYY' | 'YYYY-MM-DD';
} & Omit<DatePickerProps, "name" | "value" | "error" | "variant"> & FormikFieldExtraProps;

export default function FormikDatePicker(props: FormikDatePickerProps) {
    const { name, validate, dateStringOnly, dateStringFormat, ...otherProps } = props;
    const { value, disabled, showError, helperText, field, formik } = useFormikField(props);
    const onChange = (date: number) => {
        props.onChange?.(date);
        if (dateStringOnly) {
            formik.setFieldValue(field.name, moment(date).format(dateStringFormat ?? "YYYY-MM-DD"));
        } else {
            formik.setFieldValue(field.name, date);
        }
    };
    const pickerValue = useMemo((): number => {
        let time: number = new Date(value).getTime();
        if (Number.isNaN(time)) {
            time = moment(value, dateStringFormat ?? "YYYY-MM-DD").valueOf(); // is this a formatted date string?
            if (Number.isNaN(time)) {
                time = new Date().getTime(); // fallback to now
            }
        }
        return time;
    }, [value, dateStringFormat]);
    return (<>
        <DatePicker
            {...otherProps}
            field={field}
            value={pickerValue}
            onChange={onChange}
            disabled={disabled}
            error={showError}
            helperText={helperText}
        />
        <div className={styles.error_text}>{helperText}</div>
    </>);
}