import AmotaiUser from "../../types/model/AmotaiUser";
import { Actions, Types } from "../actions/users";

const initialState: {
    regionalManagers: { [key: string]: AmotaiUser },
} = {
    regionalManagers: {},
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function managers(state = initialState, action: ActionTypes) {
    switch (action.type) {

        case Types.SET_REGIONAL_MANAGERS: {
            const regionalManagersPayload = (action as ActionValueTypes<Types.SET_REGIONAL_MANAGERS>).payload!;
            const newState = { ...state };
            regionalManagersPayload?.items?.forEach((regionalManager: AmotaiUser) => {
                newState.regionalManagers[regionalManager.id] = regionalManager;
            });
            return newState;
        }

        case Types.SET_REGIONAL_MANAGER: {
            const payload = (action as ActionValueTypes<Types.SET_REGIONAL_MANAGER>).payload!;
            const newState = { ...state };
            newState.regionalManagers[payload.id] = payload;
            return newState;
        }

        case Types.SET_PAGING_REGIONAL_MANAGER: {
            const regionalManagersPayload = (action as ActionValueTypes<Types.SET_PAGING_REGIONAL_MANAGER>).payload!.items;
            const newState = { ...state };
            regionalManagersPayload.forEach((regionalManager: AmotaiUser) => {
                newState.regionalManagers[regionalManager.id] = regionalManager;
            });
            return newState;
        }

        default: {
            return state;
        }
    }
}