import SaaSSubscription from "../../types/model/SaaSSubscription";
import SaaSSubscriptionsAPI from "./api/saaSSubscriptions";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_SUBSCRIPTION = "saaSSubscriptions:SET_SUBSCRIPTION",
}

export const setSaaSSubscription = createAction<SaaSSubscription>(Types.SET_SUBSCRIPTION);

export const Actions = {
    [Types.SET_SUBSCRIPTION]: setSaaSSubscription,
};

export type Actions = typeof Actions;

export const getSaaSSubscription = wrapper(SaaSSubscriptionsAPI.getSubscriptionForAccount, {
    action: setSaaSSubscription
});

export const updateSubscriptionAdmin = wrapper(SaaSSubscriptionsAPI.updateSubscriptionAdmin, {
    action: setSaaSSubscription
});

export const updateNextBillingDateAdmin = wrapper(SaaSSubscriptionsAPI.updateNextBillingDateAdmin, {
    action: setSaaSSubscription
});

export const createSubscriptionAdmin = wrapper(SaaSSubscriptionsAPI.createSubscriptionAdmin, {
    action: setSaaSSubscription
});