import React from "react";
import { Route, Switch } from "react-router-dom";
import classNames from "classnames";
import ExportView from "src/components/widgets/account/export/ExportView";
import Toolbar from "../../widgets/toolbar/Toolbar";
import styles from "./Dashboard.module.scss";
import ClientBuyerDetail from "./routes/clientBuyerDetail/ClientBuyerDetail";
import ClientsBuyersOverview from "./routes/clientsBuyersOverview/ClientsBuyersOverview";
import AmotaiEvents from "./routes/eventsOverview/AmotaiEvents";
import Reporting from "./routes/reporting/Reporting";
import SaaSPlans from "./routes/saasPlans/SaaSPlans";
import SupplierDetail from "./routes/supplierDetail/SupplierDetail";
import SuppliersOverview from "./routes/suppliersOverview/SuppliersOverview";
import AdminUsers from "./routes/users/AdminUsers";
import Overview from "./routes/overview/Overview";
import AmotaiEvent from "./routes/event/AmotaiEvent";
import Categories from "./routes/categories/Categories";
import SubCategories from "./routes/categories/subCategories/SubCategories";
import SubSubCategories from "./routes/categories/subSubCategories/SubSubCategories";
import ViewOnlyUsers from "./routes/users/ViewOnlyUsers";
import RegionalManagers from "./routes/users/RegionalManagers";
import RegionalManagersDetail from "./routes/regionalManagersDetail/RegionalManagersDetail";
import Users from "./routes/users/Users";

export default function Dashboard() {
    const contentClassname = classNames(styles.dashboard_window_content, { [styles.dashboard_window_content_fullwidth]: window.location.pathname === "/dashboard" });
    return (
        <div className={styles.dashboard}>
            <Toolbar />
            <div className={styles.dashboard_window}>
                <div className={contentClassname}>
                    <Switch>
                        <Route path={"/dashboard/suppliers"} component={Overview} />
                        <Route path={"/users/users"} component={Users} />
                        <Route path={"/users/admin"} component={AdminUsers} />
                        <Route path={"/users/view-only"} component={ViewOnlyUsers} />
                        <Route path={"/users/regional-managers/:regionalManagerId"} component={RegionalManagersDetail} />
                        <Route path={"/users/regional-managers"} component={RegionalManagers} />
                        <Route path={"/suppliers/:supplierId"} component={SupplierDetail} />
                        <Route path={"/suppliers"} component={SuppliersOverview} />
                        <Route path={"/saas"} component={SaaSPlans} />
                        <Route path={"/reporting"} component={Reporting} />
                        <Route path={"/export/:account_type"} component={ExportView} />
                        <Route path={"/events/:eventId"} component={AmotaiEvent} />
                        <Route path={"/events"} component={AmotaiEvents} />
                        <Route path={"/buyers/:clientBuyerId"} component={ClientBuyerDetail} />
                        <Route path={"/buyers"} component={ClientsBuyersOverview} />
                        <Route exact path={"/categories"} component={Categories} />
                        <Route exact path={"/categories/:categoryId"} component={SubCategories} />
                        <Route exact path={"/categories/:categoryId/sub/:subCategoryId"} component={SubSubCategories} />
                        <Route path={"/"} component={Overview} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}
