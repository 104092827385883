import { Action, applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import root, { ApplicationState } from "../reducers/root";

export default function configureStore() {
    const store = createStore<{}, Action, {}, ApplicationState>(
        root,
        applyMiddleware(thunk)
    );

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
}
