import ListResult from "../../types/ListResult";
import AmotaiUser, { SystemRole } from "../../types/model/AmotaiUser";
import UsersAPI from "./api/users";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_USER = "users:SET_USER",
    SET_USERS = "users:SET_USERS",
    DEL_USER = "users:DEL_USER",
    SET_REGIONAL_MANAGERS = "managers:SET_REGIONAL_MANAGERS",
    SET_REGIONAL_MANAGER = "managers:SET_REGIONAL_MANAGER",
    SET_PAGING_REGIONAL_MANAGER = "managers:SET_PAGING_REGIONAL_MANAGER",
    SET_INDUCTABLE_USERS = "users:SET_INDUCTABLE_USERS",
}

const setUser = createAction<AmotaiUser>(Types.SET_USER);
const setUsers = createAction<ListResult<AmotaiUser>>(Types.SET_USERS);
const delUser = createAction<AmotaiUser>(Types.DEL_USER);
const setRegionalManagers = createAction<ListResult<AmotaiUser>>(Types.SET_REGIONAL_MANAGERS);
const setRegionalManager = createAction<AmotaiUser>(Types.SET_REGIONAL_MANAGER);
const setPagingRegionalManagers = createAction<ListResult<AmotaiUser>>(Types.SET_PAGING_REGIONAL_MANAGER);
const setInductableUsers = createAction<Array<AmotaiUser>>(Types.SET_INDUCTABLE_USERS);


export const Actions = {
    [Types.SET_USER]: setUser,
    [Types.SET_USERS]: setUsers,
    [Types.DEL_USER]: delUser,
    [Types.SET_REGIONAL_MANAGERS]: setRegionalManagers,
    [Types.SET_REGIONAL_MANAGER]: setRegionalManager,
    [Types.SET_PAGING_REGIONAL_MANAGER]: setPagingRegionalManagers,
    [Types.SET_INDUCTABLE_USERS]: setInductableUsers,
};
export type Actions = typeof Actions;

export const setManager = (u: AmotaiUser) => {
    const role = u.systemRole;
    if (role === SystemRole.REGIONAL_MANAGER) {
        return setRegionalManager(u);
    }
    return setUser(u);
};

export const getManager = wrapper(UsersAPI.getUser, {
    action: setManager
});

export const getUser = wrapper(UsersAPI.getUser, {
    action: setUser
});

export const getRegionalManager = wrapper(UsersAPI.getUser, {
    action: setRegionalManager,
});

export const getUsers = wrapper(UsersAPI.getUsers, {
    action: setUsers,
});

export const getAccountUsers = wrapper(UsersAPI.getAccountUsers);

export const updateAccountUser = wrapper(UsersAPI.updateAccountUser);

export const createUser = wrapper(UsersAPI.createUser, {
    action: setUser
});

export const getRegionalManagers = wrapper(UsersAPI.getRegionalManagers, {
    action: setRegionalManagers
});

export const updateUserById = wrapper(UsersAPI.updateUserById, {
    action: setUser
});
export const deleteUserFromAccount = wrapper(UsersAPI.deleteUserFromAccount, {
    action: delUser
});

export const inviteToAccount = wrapper(UsersAPI.inviteToAccount, {
    action: setUser
});

export const deleteUserWithoutAccount = wrapper(UsersAPI.deleteUserWithoutAccount, {
    action: delUser
});

export const updateRegionalManager = wrapper(UsersAPI.updateUserById, { action: setRegionalManager });

export const getPagingRegionalManagersFull = wrapper(UsersAPI.getPagingRegionalManagersFull, {
    action: setPagingRegionalManagers
});

export const getInductableUsers = wrapper(UsersAPI.getInductableUsers, { action: setInductableUsers });

export const assignAccountsToManager = wrapper(UsersAPI.assignAccountsToManager, {
    action: data => {
        const { manager } = data;
        return setRegionalManager(manager);
    }
});