import MaterialTable, { MaterialTableProps } from "material-table";
import React from "react";
import styles from "./Table.module.scss";
import { PageSizeOptions } from "src/util/constants";

export type TableProps<T extends object> = MaterialTableProps<T>;

export default function Table<T extends object = any>(props: TableProps<T>) {
    const { options, components, ...otherProps } = props;
    // const selectionData = (otherProps.data as T[] | undefined)?.map(d => ({ ...d, tableData: { checked: true } })) ?? [];
    return (
        <MaterialTable {...otherProps}
            // data={options?.selection ? selectionData : otherProps.data}
            components={{
                ...components,
                Container: (ContainerProps: any) => <div {...ContainerProps} className={styles.table} />,
            }}
            options={{
                toolbar: false,
                showTitle: false,
                draggable: false,
                search: false,
                pageSizeOptions: PageSizeOptions,
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                ...options,
            }} />
    );
}
