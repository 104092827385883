import { Column } from "material-table";
import React, { useRef, useState, useMemo, useCallback } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import moment from "moment";
import ListResult from "src/types/ListResult";
import useDispatch from "src/hooks/useDispatch";
import { updateUserById, getRegionalManager } from "src/redux/actions/users";
import { showError } from "src/redux/actions/snackbars";
import { PagingTableFilter } from "../../../../redux/reducers/tables";
import Header from "../../header/Header";
import PagingTable, { PagingTableRef } from "../../pagingTable/PagingTable";
import styles from "./Businesses.module.scss";
import AmotaiAccount, { AccountType } from "../../../../types/model/AmotaiAccount";
import Button from "../../button/Button";
import AccountsAPI from "../../../../redux/actions/api/accounts";
import useSelector from "../../../../hooks/useSelector";
import useMountEffect from "../../../../hooks/useMountEffect";
import AssignBusinessDialog from "../../assignBusinessDialog/AssignBusinessDialog";
import { DialogRef } from "../../dialog/Dialog";
import AmotaiUser, { UserStatus } from "src/types/model/AmotaiUser";
import useUserRoles from "src/hooks/useUserRoles";
import { BusinessRouteParams } from "src/components/routes/dashboard/routes/regionalManagersDetail/RegionalManagersDetail";

type BusinessesProps = {
    type: AccountType;
} & RouteComponentProps<BusinessRouteParams>;

export default function Businesses(props: BusinessesProps) {
    const { type } = props;
    const assignBusinessDialogRef = useRef<DialogRef>(null);
    const { regionalManagerId } = props.match.params;
    const { isSuperAdmin, isAdmin, isRegionalManager } = useUserRoles();
    const regionalManager = useSelector((state => regionalManagerId ? state.managers.regionalManagers[regionalManagerId] : null));
    const [manager, setManager] = useState<AmotaiUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const history = useHistory();
    const tableRef = useRef<PagingTableRef<AmotaiAccount>>(null);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        setLoading(true);
        if (regionalManagerId) {
            if (isSuperAdmin || isAdmin || isRegionalManager) {
                if (regionalManager) {
                    setManager(regionalManager);
                } else {
                    const rm = await dispatch(getRegionalManager(regionalManagerId));
                    setManager(rm);
                }
            } else {
                //uneligible
                history.replace('/');
            }
        } else {
            //you should not be here
            history.replace('/');
        }
        setLoading(false);
    });

    const onBusinessClick = (business: AmotaiAccount) => {
        if (business.type === AccountType.BUYER_CLIENT) {
            history.push(`/buyers/${business.id}`);
        } else if (business.type === AccountType.SUPPLIER) {
            history.push(`/suppliers/${business.id}`);
        }
    };

    const queryData = async (filter: PagingTableFilter): Promise<ListResult<AmotaiAccount>> => {
        const { limit } = filter;
        if (regionalManagerId) {
            const items = await AccountsAPI.getUserAssignedAccounts(regionalManagerId);
            return { items: items.filter(a => a.type === type), limit };
        }
        return Promise.resolve({ items: [], limit });
    };
    const TABLE_COLUMNS: Column<AmotaiAccount>[] = [
        {
            title: "Name",
            render: business => (
                <Button plainLink onClick={() => onBusinessClick(business)}>{business.name}</Button>
            ),
            customSort: (a, b) => (a.name ?? '').localeCompare(b.name ?? '')
        },
        {
            title: "Registration date",
            render: s => moment(s.createdAt).format("DD/MM/YYYY"),
            customSort: (a, b) => (a.createdAt ?? '').localeCompare(b.createdAt ?? '')
        },
        {
            title: "Inducted",
            render: s => s.inductedAt ? "Inducted" : "Not inducted",
            // eslint-disable-next-line no-nested-ternary
            customSort: (a, b) => a.inductedAt ? -1 : (b.inductedAt ? 1 : 0)
        },
        {
            title: "Induction date",
            render: s => s.inductedAt || "Not inducted",
            customSort: (a, b) => (a.inductedAt ?? '').localeCompare(b.inductedAt ?? '')
        },
        {
            title: "Regions",
            render: s => s.regions?.join(", "),
            customSort: (a, b) => (a.regions?.join(', ') ?? '').localeCompare(b.regions?.join(', ') ?? '')
        },
        {
            title: "Account status",
            field: "status",
            customSort: (a, b) => (a.status?.toString() ?? '').localeCompare(b.status?.toString() ?? '')
        },
    ];

    const activateManager = useCallback(async () => {
        if (!manager) {
            return;
        }
        try {
            setUpdating(true);
            const rm = await dispatch(updateUserById(manager.id, { status: UserStatus.ACTIVE }));
            setManager(rm);
        } catch (error) {
            dispatch(showError(error.message));
        } finally {
            setUpdating(false);
        }
    }, [manager, dispatch]);

    const actionButton = useMemo(() => {
        if (!isSuperAdmin || !manager) {
            return null;
        }
        switch (manager.status) {
            case UserStatus.ACTIVE: {
                return (
                    <Button plain onClick={() => assignBusinessDialogRef.current?.show()}>
                        {`Assign ${type === AccountType.SUPPLIER ? 'Supplier' : 'Buyer'} Business`}
                    </Button>
                );
            }
            case UserStatus.PENDING_INDUCTION: {
                return <Button loading={updating} plain onClick={activateManager}>Activate regional manager</Button>;
            }
            default:
                return null;
        }
    }, [isSuperAdmin, manager, assignBusinessDialogRef, updating, activateManager, type]);

    const goBack = useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [history]);

    return manager ?
        (
            <div className={styles.users}>
                <div className={styles.back} onClick={goBack}>&lt;&lt; Back</div>
                <Header title={manager.name} subtitle={type === AccountType.SUPPLIER ? 'Suppliers' : 'Buyers'} action={actionButton} />
                <PagingTable<AmotaiAccount, PagingTableFilter>
                    id={`rm-business-${type}`}
                    isLoading={loading}
                    getData={queryData}
                    columns={TABLE_COLUMNS}
                    tableRef={tableRef}
                />
                <AssignBusinessDialog ref={assignBusinessDialogRef}
                    onClose={() => tableRef.current?.refresh()}
                    manager={manager}
                    accountType={type}
                />
            </div>
        )
        :
        <></>;
}
