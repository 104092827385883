import Preference from 'src/types/model/Preference';
import AmotaiUser from "../../types/model/AmotaiUser";
import UserAPI from "./api/user";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_ME = "user:SET_ME",
    SET_PREF = "user:SET_PREF",
    SET_PREFS = "user:SET_PREFS",
    DEL_PREF = "user:DEL_PREF",
}

export const setMe = createAction<AmotaiUser | null>(Types.SET_ME);
const setPref = createAction<Preference>(Types.SET_PREF);
const setPrefs = createAction<Preference[]>(Types.SET_PREFS);
const delPref = createAction<{ prefId: number }>(Types.DEL_PREF);

export const Actions = {
    [Types.SET_ME]: setMe,
    [Types.SET_PREF]: setPref,
    [Types.SET_PREFS]: setPrefs,
    [Types.DEL_PREF]: delPref,
};
export type Actions = typeof Actions;

export const getMe = wrapper(UserAPI.getMe, { action: setMe });
export const updateMe = wrapper(UserAPI.updateMe, { action: setMe });
export const reinviteUser = wrapper(UserAPI.reinviteUser);
export const createPref = wrapper(UserAPI.createPref, { action: setPref });
export const getMyPrefs = wrapper(UserAPI.getMyPrefs, { action: setPrefs });
export const deleteMyPref = wrapper(UserAPI.deleteMyPref, {
    action: (_, params) => {
        const prefId = params[0];
        return delPref({ prefId });
    }
});