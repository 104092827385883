import AmotaiUser, { SystemRole } from 'src/types/model/AmotaiUser';
import ListResult from "../../../types/ListResult";
import AmotaiAccount, { InviteUserDTO } from '../../../types/model/AmotaiAccount';
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getUser(userId: string): Promise<AmotaiUser> {
    return get(`/v1/admin/users/${userId}`);
}

function getUsers(limit: number, cursor?: string | null, systemRole?: SystemRole): Promise<ListResult<AmotaiUser>> {
    return get("/v1/admin/users", {
        params: {
            limit,
            cursor,
            systemRole,
        },
    });
}

function updateUserById(userId: string, user: Partial<AmotaiUser>): Promise<AmotaiUser> {
    return put(`v1/admin/users/${userId}`, user);
}

function createUser(user: AmotaiUser): Promise<AmotaiUser> {
    return post("v1/admin/users", user);
}

function getAccountUsers(accountId: number, limit: number, cursor?: string | null): Promise<ListResult<AmotaiUser>> {
    return get(`/v1/accounts/${accountId}/users`, {
        params: {
            limit,
            cursor,
        },
    });
}

function updateAccountUser(accountId: number, userId: string, userAccount: AmotaiUser): Promise<AmotaiAccount> {
    return put(`/v1/accounts/${accountId}/users/${userId}`, userAccount);
}

function deleteUserFromAccount(accountId: number, userId: string): Promise<AmotaiUser> {
    return del(`v1/accounts/${accountId}/users/${userId}`);
}

function deleteUserWithoutAccount(userId: string): Promise<AmotaiUser> {
    return del(`v1/admin/users/${userId}`);
}

function assignAccountsToManager(userId: string, accountIds: number[]): Promise<{ manager: AmotaiUser, accounts: AmotaiAccount[] }> {
    return post(`v1/admin/users/${userId}/accounts`, accountIds);
}

function getRegionalManagers(limit: number = 1000, cursor?: string | null): Promise<ListResult<AmotaiUser>> {
    return get("v1/admin/users/regional", {
        params: {
            limit,
            cursor
        }
    });
}

function getPagingRegionalManagersFull(limit: number, cursor?: string | null): Promise<ListResult<AmotaiUser>> {
    return get('v1/admin/users/regional/full', {
        params: {
            limit,
            cursor,
        }
    });
}

function getInductableUsers(): Promise<Array<AmotaiUser>> {
    return get('/v1/admin/users/inductable');
}

function inviteToAccount(accountId: number, user: InviteUserDTO): Promise<AmotaiUser> {
    return post(`/v1/accounts/${accountId}/users`, user);
}

function getResetPasswordLink(userId: string): Promise<{ resetLink: string }> {
    return get(`/v1/admin/users/${userId}/reset-password`);
}

const UsersAPI = {
    getUser,
    getUsers,
    getAccountUsers,
    assignAccountsToManager,
    getRegionalManagers,
    updateAccountUser,
    updateUserById,
    createUser,
    deleteUserFromAccount,
    inviteToAccount,
    deleteUserWithoutAccount,
    getPagingRegionalManagersFull,
    getInductableUsers,
    getResetPasswordLink,
};

export default UsersAPI;
