import { Column } from "material-table";
import React, { useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useAsyncEffect from "src/hooks/useAsyncEffect";
import useSelector from "src/hooks/useSelector";
import useUserRoles from "src/hooks/useUserRoles";
import AccountsAPI from "src/redux/actions/api/accounts";
import AmotaiAccount from "src/types/model/AmotaiAccount";
import AmotaiUser, { AccountRole } from "src/types/model/AmotaiUser";
import useDispatch from "../../../../hooks/useDispatch";
import { showSuccess } from "../../../../redux/actions/snackbars";
import { reinviteUser } from "../../../../redux/actions/user";
import { getAccountUsers, updateAccountUser } from "../../../../redux/actions/users";
import { PagingTableFilter } from "../../../../redux/reducers/tables";
import ListResult from "../../../../types/ListResult";
import Button from "../../button/Button";
import ChangeAccountMainContactDialog from "../../dialog/accountUserDialog/ChangeAccountMainContactDialog";
import CreateUpdateAccountUserDialog from "../../dialog/accountUserDialog/CreateUpdateAccountUserDialog";
import DeleteAccountUserDialog from "../../dialog/accountUserDialog/DeleteAccountUserDialog";
import { DialogRef } from "../../dialog/Dialog";
import FormDialog, { FormDialogRef } from "../../formDialog/FormDialog";
import Header from "../../header/Header";
import FormikSelect from "../../input/FormikSelect";
import Options from "../../more/Options";
import PagingTable, { PagingTableRef } from "../../pagingTable/PagingTable";
import styles from "./Users.module.scss";

type RouteParams = {
    accountId: string;
}
export type UsersProps = {} & RouteComponentProps<RouteParams>;


type Filter = {} & PagingTableFilter

export default function Users(props: UsersProps) {

    const { match } = props;
    const { params } = match;
    const accountId = Number(params.accountId);
    const { isSuperAdmin } = useUserRoles();
    const dispatch = useDispatch();
    const history = useHistory();
    const accounts = { ...useSelector(state => state.clientsBuyers), ...useSelector(state => state.suppliers) };
    const createUpdateDialogRef = useRef<DialogRef>(null);
    const deleteDialogRef = useRef<DialogRef>(null);
    const updateContactDialogRef = useRef<DialogRef>(null);
    const userRoleDialog = useRef<FormDialogRef>(null);
    const table = useRef<PagingTableRef<AmotaiUser>>(null);
    const [selectedUser, setSelectedUser] = useState<AmotaiUser | null>(null);
    const [account, setAccount] = useState<AmotaiAccount>();
    const [admins, setAdmins] = useState<{ [key: string]: AmotaiUser }>({});

    useAsyncEffect(async () => {
        let _account = accounts[accountId];
        if (!_account) {
            _account = await AccountsAPI.getAccountById(accountId);
        }
        if (!_account) {
            history.push('/');
        }
        const accountAdmins = await AccountsAPI.getAccountAdmins(accountId);
        const _admins = { ...admins };
        accountAdmins.sort((a, b) => a.name.localeCompare(b.name)).forEach(a => {
 _admins[a.id] = a; 
});
        setAdmins(_admins);
        setAccount(_account);
    }, []);

    const onEditRolePress = (user: AmotaiUser) => {
        setSelectedUser(user);
        userRoleDialog.current?.show();
    };

    const onResendPress = async (user: AmotaiUser) => {
        await dispatch(reinviteUser(user.email)).then(() => {
            dispatch(showSuccess("Invitation email re-sent"));
        });
    };

    const onRemovePress = (user: AmotaiUser) => {
        setSelectedUser(user);
        deleteDialogRef.current?.show();
    };

    const onEditUserPress = (user: AmotaiUser) => {
        setSelectedUser(user);
        createUpdateDialogRef.current?.show();
    };

    const onInviteUserPress = () => createUpdateDialogRef.current?.show();

    const onUpdateMainContactPress = () => updateContactDialogRef.current?.show();

    const TABLE_COLUMNS: Column<AmotaiUser>[] = [
        {
            title: "Email",
            field: 'email',
        },
        {
            title: "Main contact",
            render: user => <span>{user.id === account?.mainContact?.id ? "√" : ''}</span>
        },
        {
            title: "Phone",
            field: "phoneNumber"
        },
        {
            title: "Mobile",
            field: "mobileNumber"
        },
        {
            title: "Job Title",
            field: "jobTitle"
        },
        {
            title: "Role",
            field: "accountRole"
        },
        {
            render: user => (
                <Options<AmotaiUser> data={user}
                    optionButtons={[
                        {
                            onPress: () => onEditRolePress(user),
                            buttonText: "Change role",
                            disabled: user.id === account?.mainContact?.id,
                        },
                        {
                            onPress: () => onResendPress(user),
                            buttonText: "Resend verification email",
                        },
                        {
                            onPress: () => onEditUserPress(user),
                            buttonText: 'Edit user',
                        },
                        {
                            onPress: () => onRemovePress(user),
                            red: true,
                            buttonText: "Remove user",
                            disabled: user.id === account?.mainContact?.id,
                        }
                    ]}
                />
            )
        }
    ];
    const getQuery = async (queryFilter: Filter): Promise<ListResult<AmotaiUser>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getAccountUsers(accountId, limit, cursor));
    };

    const updateAction = async (user: AmotaiUser) => {
        const data = await dispatch(updateAccountUser(accountId, user.id, user));
        table.current?.refresh();
        return data;
    };

    const onClose = () => {
        setSelectedUser(null);
    };

    const onFinish = () => {
        table.current?.refresh();
        setSelectedUser(null);
    };

    const onAccountUpdateFinish = (account: AmotaiAccount) => {
        table.current?.refresh();
        if (account) {
            setAccount(account);
        }
    };

    return (
        <div className={styles.users}>
            <Header title={"Users"}
                action={<>
                    <Button uppercase onClick={onInviteUserPress}>Invite user</Button>
                    <Button uppercase onClick={onUpdateMainContactPress}>Change main contact</Button>
                </>}
            />
            <PagingTable<AmotaiUser, Filter>
                tableRef={table}
                id={"suppliers"}
                // onRowClick={onRowPress}
                columns={TABLE_COLUMNS}
                getData={getQuery} />

            <FormDialog<Partial<AmotaiUser>>
                dialogRef={userRoleDialog}
                updateHeader={"Update User Role"}
                updateAction={updateAction}
                isUpdating
                onClose={onClose}
                initialValues={selectedUser || {}}>
                {() => {
                    if (isSuperAdmin) {
                        return (
                            <FormikSelect name={"accountRole"}
                                label={"Permission"}
                                options={[
                                    {
                                        name: "User",
                                        value: AccountRole.USER,
                                    },
                                    {
                                        name: "Admin",
                                        value: AccountRole.ADMIN,
                                    }
                                ]} />
                        );
                    }
                    return <span>Your account doesn&#39;t have permission to modify user roles</span>;
                }}
            </FormDialog>
            <CreateUpdateAccountUserDialog
                ref={createUpdateDialogRef}
                accountId={accountId}
                onFinish={onFinish}
                user={selectedUser}
                onClose={() => setSelectedUser(null)}
            />
            <DeleteAccountUserDialog
                ref={deleteDialogRef}
                accountId={accountId}
                user={selectedUser}
                onFinish={onFinish}
            />
            {account && <ChangeAccountMainContactDialog ref={updateContactDialogRef}
                account={account}
                onFinish={onAccountUpdateFinish}
                admins={admins} />}
        </div>
    );
}
