import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getAccountStatusChangeHistory } from 'src/redux/actions/audit';
import AmotaiAccount, { AccountStatus } from 'src/types/model/AmotaiAccount';
import { AccountStatusAudit } from 'src/types/model/Audit';
import { getAccountStatusHistory } from 'src/util/utils';
import styles from './AccountHistory.module.scss';

type Props = {
    account: AmotaiAccount
}

export default function AccountHistory(props: Props) {
    const { account } = props;
    const accountId = account.id;
    const dispatch = useDispatch();
    const accountStatusAudits = useSelector<AccountStatusAudit[]>(state => state.audits.account.status[accountId] as AccountStatusAudit[]);
    useEffect(() => {
        (async () => {
            if (!accountStatusAudits) {
                await dispatch(getAccountStatusChangeHistory(accountId));
            }
        })();
    }, [accountId, dispatch, accountStatusAudits]);

    const statuses = useMemo(() => getAccountStatusHistory(accountStatusAudits), [accountStatusAudits]);

    const statusClass = classnames(
        styles.pill,
        {
            [styles.pending_review]: account.status === AccountStatus.PENDING_REVIEW,
            [styles.active]: account.status === AccountStatus.ACTIVE,
            [styles.on_hold]: account.status === AccountStatus.DISABLED,
            [styles.declined]: account.status === AccountStatus.DECLINED,
            [styles.deleted]: account.status === AccountStatus.DELETED,
        }
    );

    const statusText: string = useMemo(() => {
        let text = '';
        if (!account) {
            return text;
        }
        switch (account.status) {
            case AccountStatus.ACTIVE:
                text = 'Active';
                break;
            case AccountStatus.PENDING_REVIEW:
                text = 'Pending review';
                break;
            case AccountStatus.DISABLED:
                text = `On hold ${statuses.onholdAt ? `since ${moment(statuses.onholdAt).format("DD/MM/YYYY")}` : ''}`;
                break;
            case AccountStatus.DECLINED:
                text = `Declined ${statuses.declinedAt ? `on ${moment(statuses.declinedAt).format("DD/MM/YYYY")}` : ''}`;
                break;
            case AccountStatus.DELETED:
                text = `Deleted ${statuses.deletedAt ? `on ${moment(statuses.deletedAt).format("DD/MM/YYYY")}` : ''}`;
                break;
            case AccountStatus.AWAITING_PAYMENT:
                text = `Awaiting payment`;
                break;
            default:
                text = 'Unknown';
                break;
        }
        return text;
    }, [account, statuses]);

    return (
        <div className={styles.container}>
            <div className={styles.pill}>Registration date: {moment(account.createdAt).format("DD/MM/YYYY")}</div>
            {statuses.approvedAt &&
                <div className={styles.pill}>Approval date: {moment(statuses.approvedAt).format("DD/MM/YYYY")}</div>}
            <div className={statusClass}>{statusText}</div>
        </div>
    );
}