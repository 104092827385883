import axios from "axios";
import qs from "../../../../util/qs";
import { getAuth } from "firebase/auth";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    paramsSerializer: (params) => {
        return qs.stringify(params);
    },
});

axiosInstance.interceptors.request.use(async (request: any) => {

    if (!request.headers) {
        request.headers = {};
    }

    request.headers.Authorization = await getAuth().currentUser?.getIdToken();

    return request;
});

export default axiosInstance;
