import React from "react";
import { Route, Switch } from "react-router-dom";
import Button from "src/components/widgets/button/Button";
import SideBarContainer from "src/components/widgets/sideBarContainer/SideBarContainer";
import { AccountStatus, AccountType } from "src/types/model/AmotaiAccount";
import styles from "./Overview.module.scss";
import Businesses from "./routes/businesses/Businesses";
import RegionSuppliers from "./routes/region/RegionSuppliers";
import RMs from "./routes/rms/RMs";
import Suppliers from "./routes/suppliers/Suppliers";

export default function Overview() {

    return (
        <SideBarContainer className={styles.supplier}
            sideBar={(
                <>
                    <Button to={`/dashboard/suppliers`}>Supplier Overview</Button>
                    <Button to={"/dashboard/rm-suppliers"}>RM - Suppliers</Button>
                    <Button to={"/dashboard/rm-buyers"}>RM - Buyers</Button>
                    <div className={styles.spacer} />
                    <Button to={"/dashboard/pendings"}>Pending Review</Button>
                    <Button to={"/dashboard/on-hold"}>On hold</Button>
                    <Button to={"/dashboard/declined"}>Declined</Button>
                    <Button to={"/dashboard/deleted"}>Deleted</Button>
                </>
            )}>
            <Switch>
                <Route path={"/dashboard/suppliers/:region"} component={RegionSuppliers} />
                <Route path={"/dashboard/suppliers"} component={Suppliers} />
                <Route path={"/dashboard/rm-suppliers"} component={() => <RMs type={AccountType.SUPPLIER} />} />
                <Route path={"/dashboard/pendings"} component={() => (
                    <Businesses
                        status={AccountStatus.PENDING_REVIEW}
                        title="Pending review"
                        subtitle="New businesses pending review"
                    />
                )}
                />
                <Route path={"/dashboard/on-hold"} component={() => (
                    <Businesses status={AccountStatus.DISABLED}
                        title="On hold"
                        subtitle="On hold businesses"
                    />
                )}
                />
                <Route path={"/dashboard/declined"} component={() => (
                    <Businesses status={AccountStatus.DECLINED}
                        title="Declined"
                        subtitle="Declined businesses"
                    />
                )}
                />
                <Route path={"/dashboard/deleted"} component={() => (
                    <Businesses status={AccountStatus.DELETED}
                        title="Deleted"
                        subtitle="Deleted businesses"
                    />
                )}
                />
                <Route path={"/dashboard/rm-buyers"} component={() => <RMs type={AccountType.BUYER_CLIENT} />} />
                <Route path={"/dashboard"} component={Suppliers} />
                <Route path={"/"} component={Suppliers} />
            </Switch>
        </SideBarContainer>
    );

}
