/* eslint-disable no-param-reassign */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Ref, useEffect, useRef } from "react";

export default function useForwardRef<T>(forwardRef?: Ref<T>) {
    const innerRef = useRef<T>(null);
    useEffect(() => {
        if (!forwardRef) {
            return;
        }
        if (typeof forwardRef === "function") {
            forwardRef(innerRef.current);
        } else {
            // @ts-ignore
            // noinspection JSConstantReassignment
            forwardRef.current = innerRef.current;
        }
    });
    return innerRef;
}
