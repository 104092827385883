import React, { forwardRef, Ref, useState } from "react";
import AmotaiUser from "src/types/model/AmotaiUser";
import AmotaiAccount from "src/types/model/AmotaiAccount";
import AccountsAPI from "src/redux/actions/api/accounts";
import Dialog, { DialogRef } from "../Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import styles from "./AccountUserDialog.module.scss";
import Button from "../../button/Button";
import useDispatch from "../../../../hooks/useDispatch";
import { showError, showSuccess } from "../../../../redux/actions/snackbars";
import Select from "../../input/Select";

type UpdateAccountMainContactDialogProps = {
    onFinish?: (account: AmotaiAccount) => void;
    onClose?: () => void;
    account: AmotaiAccount;
    admins: { [key: string]: AmotaiUser };
};

function UpdateAccountMainContactDialog(props: UpdateAccountMainContactDialogProps, ref: Ref<DialogRef>) {
    const { account, admins, onFinish, onClose } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [mainContact, setMainContact] = useState<AmotaiUser>();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const dispatch = useDispatch();

    const onCancelClick = () => {
        onClose?.();
        dialogRef.current?.hide();
    };

    const onMainContactChange = (userId: string) => {
        if (admins[userId]) {
            setMainContact(admins[userId]);
        }
    };

    const onUpdate = async () => {
        if (!mainContact) {
            return;
        }
        setLoading(true);

        try {
            const _account = await AccountsAPI.updateAccountMainContact(account.id, mainContact.id);
            onFinish?.(_account);
            onCancelClick();
            dispatch(showSuccess("Account main contact updated successfully."));
        } catch (e) {
            dispatch(showError(e.message));
        }
        setLoading(false);
    };

    return (
        <Dialog dialogRef={dialogRef} disableBackdropClick={loading} header={"CHANGE ACCOUNT MAIN CONTACT"}>
            <div className={styles.dialog_form}>
            <Select onChange={onMainContactChange} defaultValue={account.mainContact?.id}
                        options={Object.values(admins).map(a => ({ label: a.name, value: a.id }))}
                        label={"Account main contact"} />
                <div className={styles.dialog_footer}>
                    <Button loading={loading} onClick={onUpdate}>{"Update"}</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default forwardRef(UpdateAccountMainContactDialog);
