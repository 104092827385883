import get from "./util/get";
import post from "./util/post";
import put from "./util/put";
import BusinessOwner from "../../../types/model/BusinessOwner";

function getOwners(accountId: number): Promise<BusinessOwner[]> {
    return get(`/v1/accounts/${accountId}/owners`);
}

function createOwners(accountId: number, values: BusinessOwner[]): Promise<BusinessOwner[]> {
    return post(`/v1/accounts/${accountId}/owners`, values);
}

function updateOwners(accountId: number, values: BusinessOwner[]): Promise<BusinessOwner[]> {
    return put(`/v1/accounts/${accountId}/owners`, values);
}

const BusinessOwnerAPI = {
    getOwners,
    createOwners,
    updateOwners
};

export default BusinessOwnerAPI;