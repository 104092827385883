import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Button from "../../widgets/button/Button";
import FormikInput from "../../widgets/input/FormikInput";
import styles from "./ForgotPassword.module.scss";
import Logo from "../../../resources/images/svg/amotai-logo-black.svg";
import Header from "../../widgets/header/Header";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import useDispatch from "../../../hooks/useDispatch";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

interface ForgotPasswordFormValues {
    email: string;
}

const ForgotPasswordValidationSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email("Must be a valid email address")
        .required("Required"),
});

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const onForgotPasswordSubmit = async ({ email }: ForgotPasswordFormValues) => {
        console.log("submit", email);
        setLoading(true);
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                dispatch(showSuccess("Password reset email sent. Please check inbox or spam folder."));
                setLoading(false);
            })
            .catch(() => {
                dispatch(showError("There was an error resetting your password. Please try again."));
                setLoading(false);
            });
    };

    return (
        <div className={styles.login}>
            <div className={styles.inner}>
                <img className={styles.logo} src={Logo} alt={"Amotai"} />
                <Header title={"Forgot password"} />
                <Formik<ForgotPasswordFormValues>
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={onForgotPasswordSubmit} validationSchema={ForgotPasswordValidationSchema}
                    enableReinitialize>
                    {({ dirty, isValid, isSubmitting }) => (
                        <Form className={styles.form}>
                            <FormikInput name={"email"} label={"Email"} disabled={isSubmitting}
                                className={styles.input} />
                            <Button type={"submit"} disabled={!dirty || !isValid} loading={loading}>Send reset
                                link</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
