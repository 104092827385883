/* eslint-disable no-nested-ternary */
import { InputAdornment } from "@material-ui/core";
import { Form, Formik } from "formik";
import { Column } from "material-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "src/components/widgets/button/Button";
import FormikCheckBox from "src/components/widgets/checkBox/FormikCheckBox";
import Dialog, { DialogRef } from "src/components/widgets/dialog/Dialog";
import FormikInput from "src/components/widgets/input/FormikInput";
import FormikSelect from "src/components/widgets/input/FormikSelect";
import PagingTable, { PagingTableRef } from "src/components/widgets/pagingTable/PagingTable";
import useDispatch from "src/hooks/useDispatch";
import { createSaaSPlan, getPlans, updateSaaSPlan } from "src/redux/actions/saaSPlans";
import { PagingTableFilter } from "src/redux/reducers/tables";
import ListResult from "src/types/ListResult";
import SaaSPlan, { allPlanStatus, InvoiceInterval, SaaSPlanStatus } from "src/types/model/SaaSPlan";
import { currency } from "src/util/utils";
import * as Yup from "yup";
import Header from "../../../../widgets/header/Header";
import FormikNumberInput from "../../../../widgets/input/FormikNumberInput";
import FormikBooleanRadio from "./components/FormikBooleanRadio";
import styles from "./SaaSPlans.module.scss";

const SaasPlanValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    amount: Yup.number().required("Required"),
    maxUsers: Yup.mixed().required("Required"),
    eventDiscount: Yup.number().integer('Event discount should be an integer number').lessThan(101).required("Required"),
    fullProfile: Yup.boolean().required("Required"),
    maxContacts: Yup.mixed().required("Required"),
    displayBadge: Yup.boolean().required("Required"),
});

export default function SaaSPlans() {

    const dialogRef = useRef<DialogRef>(null);
    const tableRef = useRef<PagingTableRef<SaaSPlan>>(null);
    const dispatch = useDispatch();
    const [toEdit, setToEdit] = useState<PlanToEdit>();
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [, setError] = useState<string>();
    const [altPressed, setAltPressed] = useState<boolean>(false);

    const planColumns: Column<SaaSPlan>[] = [
        {
            title: "Plan Name",
            field: "name",
            render: sp => <><Button plainLink onClick={() => onEdit(sp)}>{sp.name}</Button>{altPressed && <span>{sp.id}</span>}</>
        },
        {
            title: "Monthly amount",
            render: ({ amount }) => `$ ${currency((amount / 100).toFixed(2))}`
        },
        {
            title: "Annual amount",
            render: ({ invoiceAnnualAmount }) => `$ ${currency(((invoiceAnnualAmount ?? 0) / 100).toFixed(2))}`
        },
        {
            title: "Max users",
            field: "maxUsers",
            render: ({ maxUsers }) => maxUsers > 25 ? `26+` : `${maxUsers}`
        },
        {
            title: "Max Contacts",
            field: "maxContacts",
            render: ({ maxContacts }) => maxContacts > 25 ? `26+` : `${maxContacts}`
        },
        {
            title: "Invoice frequency",
            field: "invoiceInterval",
        },
        {
            title: "Status",
            field: "status",
            customSort: (a, b) => a.status === SaaSPlanStatus.ACTIVE ? -1 : (b.status === SaaSPlanStatus.ACTIVE ? 1 : 0)
        },
    ];
    const maxContactOptions = useMemo((): string[] => {
        const options: string[] = [];
        for (let i = 1; i < 26; i++) {
            options.push(`${i}`);
        }
        options.push("26+");
        return options;
    }, []);
    const maxUserOptions = useMemo((): string[] => {
        const options: string[] = [];
        for (let i = 1; i < 26; i++) {
            options.push(`${i}`);
        }
        options.push("26+");
        return options;
    }, []);

    useEffect(() => {
        const handler = (ev: MouseEvent) => {
            setAltPressed(ev.altKey);
        };
        window.addEventListener('mousemove', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
        };
    }, []);

    const getQuery = async (queryFilter: PagingTableFilter): Promise<ListResult<SaaSPlan>> => {
        const { limit, cursor } = queryFilter;
        const { items, ...rest } = await dispatch(getPlans(limit, cursor));
        return {
            ...rest,
            items: items.sort((a, b) => a.status === SaaSPlanStatus.ACTIVE ? -1 : (b.status === SaaSPlanStatus.ACTIVE ? 1 : 0))
        };
    };

    const onEdit = (plan: SaaSPlan) => {
        const { maxContacts: _maxContacts, maxUsers: _maxUsers, amount: _amount, invoiceAnnualAmount: _invoiceAnnualAmount, ...rest } = plan;

        const maxContacts = _maxContacts >= 1000000 ? '26+' : String(_maxContacts);
        const maxUsers = _maxUsers >= 1000000 ? '26+' : String(_maxUsers);
        const amount = _amount / 100;
        let invoiceAnnualAmount: number | undefined;
        if (_invoiceAnnualAmount) {
            invoiceAnnualAmount = _invoiceAnnualAmount / 100;
        }

        setToEdit({ ...rest, maxContacts, maxUsers, amount, invoiceAnnualAmount });
        setEditing(true);
        dialogRef.current?.show();
    };

    const onCreate = () => {
        setToEdit(undefined);
        setEditing(false);
        dialogRef.current?.show();
    };

    const handleSubmit = async (raw: PlanToEdit) => {
        const { maxContacts: _maxContacts, maxUsers: _maxUsers, eventDiscount, ...rest } = raw;
        const maxUsers = _maxUsers === "26+" ? 1000000 : Number(_maxUsers);
        const maxContacts = _maxContacts === "26+" ? 1000000 : Number(_maxContacts);
        const amount = Math.round(rest.amount * 100);
        rest.amount = amount;
        if (rest.invoicable) {
            const invoiceAnnualAmount = Math.round(rest.invoiceAnnualAmount! * 100);
            rest.invoiceAnnualAmount = invoiceAnnualAmount;
            rest.invoiceInterval = InvoiceInterval.ANNUAL; //set as annually for invoice
        }
        const data: SaaSPlan = {
            ...rest,
            eventDiscount: Number(eventDiscount),
            maxContacts,
            maxUsers
        };
        console.log("Creating or updating plan: ", data);
        try {
            setLoading(true);
            if (editing) {
                await dispatch(updateSaaSPlan(rest.id, data));
            } else {
                await dispatch(createSaaSPlan(data));
            }
            tableRef.current?.refresh();
            setLoading(false);
            dialogRef.current?.hide();
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const onCancel = () => {
        dialogRef.current?.hide();
        setEditing(false);
        setToEdit(undefined);
        setError(undefined);
    };

    return (
        <>
            <Header title={"SaaS Plans"}
                action={(<Button onClick={onCreate} loading={loading}>Create plan</Button>)} />
            <PagingTable<SaaSPlan, PagingTableFilter>
                tableRef={tableRef}
                id={"saaSPlans"}
                columns={planColumns}
                getData={getQuery} />
            <Dialog dialogRef={dialogRef} onClose={onCancel}
                header={!editing ? "CREATE NEW SAAS PLAN" : "EDIT SAAS PLAN"}>
                <Formik<PlanToEdit> initialValues={toEdit ?? ({ fullProfile: false, displayBadge: false, status: "ACTIVE" } as PlanToEdit)}
                    onSubmit={handleSubmit}
                    validationSchema={SaasPlanValidationSchema}>
                    {({ isValid, dirty, values }) => (
                        <Form className={styles.plan_form}>
                            <FormikInput name={"name"} label={"Plan Name"} required
                                inputClassname={styles.full_input} />
                            <FormikNumberInput prefix={"$"} name={"amount"} currency
                                label={"Amount per month"}
                                inputClassname={styles.full_input} />
                            <FormikInput name={"eventDiscount"} label={"Event Discount"}
                                inputClassname={styles.full_input} number
                                required InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={"end"}>%</InputAdornment>
                                    )
                                }} />
                            <div className={styles.row}>
                                <FormikSelect name={"maxUsers"} defaultValue={"3"} label={"Max Users"}
                                    options={maxUserOptions} required />
                                <FormikBooleanRadio label={"Display Aukōkiri Badge"} fieldName="displayBadge"
                                    disabled={!/Aukōkiri/i.test(values.name)} />
                            </div>
                            <div className={styles.row}>
                                <FormikSelect name={"maxContacts"} defaultValue={"3"} label={"Max Contacts"}
                                    options={maxContactOptions} required />
                                <FormikBooleanRadio label={"Profile Type"} fieldName="fullProfile" trueLabel="Full"
                                    falseLabel="Basic" />
                            </div>
                            <div className={styles.row}>
                                <FormikCheckBox name="invoicable" defaultChecked={false}
                                    label="This plan can be invoiced" />
                            </div>
                            <div className={styles.row}>
                                {values.invoicable && (
                                    <FormikNumberInput
                                        prefix="$"
                                        name="invoiceAnnualAmount"
                                        currency
                                        label={"Amount per annum (invoice)"}
                                        inputClassname={styles.full_input}
                                    />)}
                            </div>
                            <FormikSelect name={"status"} label={"Status"} options={allPlanStatus}
                                defaultValue={SaaSPlanStatus.ACTIVE} required />
                            {/* {!!error && <span className={styles.error_block}>{error}</span>} */}
                            <div className={styles.footer}>
                                <Button type={"submit"} disabled={loading || !dirty || !isValid}
                                    loading={loading}>{editing ? "Update" : "Create"}</Button>
                                <div />
                                <Button regular plainLink onClick={onCancel} disabled={loading}>Cancel</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}

type PlanToEdit = Omit<SaaSPlan, 'maxUsers' | 'maxContacts'> & {
    maxUsers: string;
    maxContacts: string;
}
