import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState, useCallback, useRef, useEffect } from "react";
import useDispatch from "src/hooks/useDispatch";
import { useHistory } from "react-router-dom";
import { PagingTableFilter } from "src/redux/reducers/tables";
import ListResult from "src/types/ListResult";
import PagingTable, { PagingTableRef } from "src/components/widgets/pagingTable/PagingTable";
import { Column } from "material-table";
import Dialog, { DialogRef } from "src/components/widgets/dialog/Dialog";
import AmotaiEvent from "../../../../../types/model/AmotaiEvent";
import { getEvents, deleteEvent } from "../../../../../redux/actions/events";
import styles from "./AmotaiEvents.module.scss";
import Header from "../../../../widgets/header/Header";
import Button from "../../../../widgets/button/Button";

export type EventsProps = {}

export default function AmotaiEvents(props: EventsProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = useRef<PagingTableRef<AmotaiEvent, PagingTableFilter>>(null);
    const [onGoing, setOnGoing] = useState<boolean | null>(null);
    // const onGoing = useRef<boolean|null>(null);
    const [eventDelete, setEventDelete] = useState<AmotaiEvent>();
    const delDiagRef = useRef<DialogRef | null>(null);
    const pastColumns: Column<AmotaiEvent>[] = [
        {
            title: "Event name",
            field: "name",
            render: event => <span className={styles.single_link} onClick={() => viewDetails(event.id)}>{event.name}</span>
        },
        {
            title: "Date",
            field: "date",
            render: event => new Date(event.date).toLocaleDateString("en-NZ")
        },
        {
            title: "Price",
            render: event => `$${((event.price ?? 0) / 100).toFixed(2)}`
        },
        {
            title: "Location",
            field: "address"
        },
        {
            title: "RSVP",
            field: "rsvpCnt"
        }
    ];
    const currentColumns: Column<AmotaiEvent>[] = pastColumns.concat([
        {
            title: "",
            render: event => (
                <div className={styles.links}>
                    <Button plainLink onClick={() => viewDetails(event.id)}>View/Edit</Button>
                    <IconButton onClick={() => onDelete(event)}> <DeleteIcon /> </IconButton>
                </div>
            )
        }
    ]);
    const getQuery = useCallback(async (queryFilter: PagingTableFilter): Promise<ListResult<AmotaiEvent>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getEvents(onGoing ?? true, limit, cursor));
    }, [onGoing, dispatch]);

    const viewDetails = useCallback((eventId: string) => {
        history.push(`/events/${eventId}`);
    }, [history]);

    const onDelete = useCallback((event: AmotaiEvent) => {
        setEventDelete(event);
        delDiagRef.current?.show();
    }, [delDiagRef]);

    const doDelete = useCallback(async () => {
        delDiagRef.current?.hide();
        await dispatch(deleteEvent(eventDelete!.id));
    }, [eventDelete, delDiagRef, dispatch]);

    const toggleView = useCallback(() => {
        setOnGoing(onGoing === null ? false : !onGoing);
    }, [onGoing]);

    useEffect(() => {
        if (onGoing !== null) {
            tableRef.current?.refresh();
        }
    }, [onGoing, tableRef]);

    return (
        <div className={styles.events}>
            <Header title={"Events"}
                action={(<>
                    <Button plain={onGoing ?? true} onClick={toggleView}>{!(onGoing ?? true) ? "Current events" : "Past events"}</Button>
                    {(onGoing ?? true) && <Button to={"/events/new"}>Create event</Button>}
                </>)} />

            <PagingTable<AmotaiEvent, PagingTableFilter> id={"events"} tableRef={tableRef}
                columns={(onGoing ?? true) ? currentColumns : pastColumns} getData={getQuery} />


            <Dialog className={styles.dialog}
                dialogRef={delDiagRef}
                positiveText={"Delete"}
                onPositivePress={doDelete}
                negativeText={"Cancel"}
                onNegativePress={() => delDiagRef.current?.hide()}
                header={"DELETE EVENT"}
            >
                <div>Are you sure you would like to delete <b>{eventDelete?.name}</b>? <br />This action cannot be undone.</div>
            </Dialog>
        </div>
    );
}

export const isPast = (date: number | string | null | undefined): boolean => {
    if (!date) {
 return true; 
}
    const now = new Date().setHours(0, 0, 0, 0);
    return now > new Date(date).getTime();
};