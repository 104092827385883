/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import { Form, Formik, FormikValues } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loading from "src/components/routes/loading/Loading";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import AlertBar from "src/components/widgets/alertBar/AlertBar";
import Button from "src/components/widgets/button/Button";
import FormikCheckBox from "src/components/widgets/checkBox/FormikCheckBox";
import Dialog, { DialogRef } from "src/components/widgets/dialog/Dialog";
import Header from "src/components/widgets/header/Header";
import FormikDatePicker from 'src/components/widgets/input/FormikDatePicker';
import FormikSelect from "src/components/widgets/input/FormikSelect";
import { SelectOption } from "src/components/widgets/input/Select";
import SectionHeader from "src/components/widgets/sectionHeader/SectionHeader";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import useUserRoles from "src/hooks/useUserRoles";
import SaaSSubscriptionsAPI from "src/redux/actions/api/saaSSubscriptions";
import { getPlans } from "src/redux/actions/saaSPlans";
import { createSubscriptionAdmin, getSaaSSubscription, updateNextBillingDateAdmin, updateSubscriptionAdmin } from "src/redux/actions/saaSSubscriptions";
import { showError, showSuccess } from "src/redux/actions/snackbars";
import { AccountStatus } from "src/types/model/AmotaiAccount";
import BuyerClient from "src/types/model/BuyerClient";
import SaaSPlan, { CustomizedFor, InvoiceInterval, Period, SaaSPlanStatus } from "src/types/model/SaaSPlan";
import SaaSSubscription, { SaaSSubscriptionUpdateRequest, SubscriptionPaymentType } from "src/types/model/SaaSSubscription";
import { currency } from "src/util/utils";
import * as Yup from "yup";
import styles from "./Billing.module.scss";
import Cards from "./cards/Cards";
import Transactions from "./transactions/Transactions";

const changeValidSchema = Yup.object({
    paymentType: Yup.string().required(),
    planId: Yup.string().required()
});

const updateNextBillingDateSchema = Yup.object({
    extendTo: Yup.date()
        .required('Next billing date is required')
        .min(moment().add('days', 1).format('YYYY-MM-DD'), 'Next billing date should be after today')
});

export default function Billing(props: RouteComponentProps<{ clientBuyerId: string }>) {

    const id = Number(props?.match?.params?.clientBuyerId);
    const account = useSelector<BuyerClient>((state) => state.clientsBuyers[id]);
    const subscription = useSelector<SaaSSubscription | null>((state: any) => state.saaSSubscriptions[id]);
    const plans = useSelector(state => state.saaSPlans);
    const subCreateOrUpdateFormRef = useRef<FormikValues | null>(null);
    const billingDateUpdateFormRef = useRef<FormikValues | null>(null);
    const billingDateDialog = useRef<DialogRef>(null);
    const confirmBillingDateDialog = useRef<DialogRef>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { isSuperAdmin } = useUserRoles();
    const [formDirty, setFormDirty] = useState<boolean>(false);
    const [altPressed, setAltPressed] = useState<boolean>(false);
    const [chargeLoading, setChargeLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (!subscription) {
                    await dispatch(getSaaSSubscription(id));
                }
                if (Object.values(plans).length === 0) {
                    await dispatch(getPlans(500));
                }
            } catch (error) {
                dispatch(showError(error.message ?? 'Failed to load account billing information'));
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handler = (ev: MouseEvent) => {
            setAltPressed(ev.altKey);
        };
        window.addEventListener('mousemove', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
        };
    }, []);


    const handleSubmit = useCallback(async (request: SaaSSubscriptionUpdateRequest & { upgradeNow: boolean }) => {
        if (subscription) {
            try {
                setLoading(true);
                // let upgradeLater = true; //default
                let upgradeLater = !request.upgradeNow;
                if (subscription?.plan.customizedFor === CustomizedFor.MandateAgency) {
                    upgradeLater = false; // upgrade immediately after
                }
                await dispatch(updateSubscriptionAdmin(id, { ...request, upgradeLater }));
                dispatch(showSuccess('Subscription has been updated'));
            } catch (error) {
                console.error('Failed to update', error);
                dispatch(showError(`Failed to update ${error.message}`));
            } finally {
                setLoading(false);
            }
        } else {
            try {
                setLoading(true);
                await dispatch(createSubscriptionAdmin(id, request.planId, request.paymentType === SubscriptionPaymentType.INVOICE));
                dispatch(showSuccess('Subscription has been created'));
            } catch (error) {
                console.error('Failed to create', error);
                dispatch(showError(`Failed to create subscription: ${error.message}`));
            } finally {
                setLoading(false);
            }
        }
    }, [id, dispatch, subscription]);

    const toggleEdit = useCallback(async () => {
        if (editing && subCreateOrUpdateFormRef.current?.dirty) {
            //on save
            subCreateOrUpdateFormRef.current?.handleSubmit();
        }
        setEditing(!editing);
    }, [editing, subCreateOrUpdateFormRef]);

    const buttonText = useMemo(() => {
        if (!editing) {
            return 'Edit';
        }
        if (formDirty) {
            return 'Save';
        }
        return 'Cancel';
    }, [editing, formDirty]);

    const planOptions = useMemo((): SelectOption[] => {
        return Object.values(plans)
            .filter(p => p.status === SaaSPlanStatus.ACTIVE || (subscription && p.id === subscription?.plan.id))
            .map(p => ({ value: `${p.id}`, label: p.customizedFor === CustomizedFor.MandateAgency ? `${p.name} (Mandate agency)` : p.name }));
    }, [plans, subscription]);

    const subscriptionPaymentInfo = useMemo(() => {
        if (!subscription) {
            return null;
        }
        const subscriptionPaymentType = subscription.subscriptionPaymentType ?? SubscriptionPaymentType.STRIPE;
        const paymentTypeText = subscriptionPaymentType === SubscriptionPaymentType.INVOICE ? 'Invoice' : 'Automatic payments';
        let nextPaymentTypeText = null;
        if (subscription.nextPaymentType) {
            nextPaymentTypeText = subscription.nextPaymentType === SubscriptionPaymentType.INVOICE ? 'Invoice' : 'Automatic payments';
        }
        let paymentIntervalText = '';
        if (subscriptionPaymentType === SubscriptionPaymentType.STRIPE) {
            paymentIntervalText = subscription.plan.period === Period.ANNUAL ? 'year' : 'month';
        } else {
            paymentIntervalText = subscription.plan.invoiceInterval === InvoiceInterval.ANNUAL ? 'year' : 'month';
        }
        return { paymentTypeText, paymentIntervalText, nextPaymentTypeText };
    }, [subscription]);

    const formInitValues = useMemo((): SaaSSubscriptionUpdateRequest & { upgradeNow: boolean } => {
        const upgradeLater = false;
        const plan = subscription?.upgradePlan ??
            (subscription?.plan) ??
            Object.values(plans).filter(p => p.status === SaaSPlanStatus.ACTIVE)[0];
        const paymentType = subscription?.subscriptionPaymentType ??
            (plan?.invoicable ? SubscriptionPaymentType.INVOICE : SubscriptionPaymentType.STRIPE);
        return {
            upgradeLater,
            paymentType,
            planId: plan?.id,
            upgradeNow: false
        };
    }, [subscription, plans]);

    const submitUpdateBillingForm = useCallback(() => {
        confirmBillingDateDialog.current?.hide();
        billingDateDialog.current?.hide();
        billingDateUpdateFormRef.current?.handleSubmit();
    }, [billingDateDialog, confirmBillingDateDialog, billingDateUpdateFormRef]);

    const onExtendUpdate = async ({ extendTo }: { extendTo: string }) => {
        if (!subscription?.id) {
            return;
        }
        setLoading(true);
        try {
            await dispatch(updateNextBillingDateAdmin(subscription.id, extendTo));
        } catch (error) {
            dispatch(showError(error.message ?? 'Failed to update the next billing date'));
        }
        setLoading(false);
    };
    const showChargeButton = useMemo(() => {
        if (!subscription) {
            return false;
        }
        if (!isSuperAdmin) {
            return false;
        }
        const { termEndsAt } = subscription;
        return moment(termEndsAt).isBefore(new Date()) && account.status === AccountStatus.ACTIVE && altPressed;
    }, [subscription, altPressed, account, isSuperAdmin]);

    const chargeForOneTerm = async () => {
        if (chargeLoading || !subscription) {
            return;
        }
        setChargeLoading(true);
        try {
            await SaaSSubscriptionsAPI.adminChargeSubscription(subscription.id);
            dispatch(showSuccess('Charge success!'));
            await dispatch(getSaaSSubscription(id));
        } catch (error) {
            console.log(error);
            dispatch(showError(`Failed to charge: ${error.message}`));
        } finally {
            setChargeLoading(false);
        }
    };

    const billAmount = useMemo(() => {
        if (!subscription) {
            return 'na';
        }
        const { plan, subscriptionPaymentType } = subscription;
        let ret: string = `$${currency(((subscription?.plan?.amount ?? 0) / 100).toFixed(2))} per month`;
        if (subscriptionPaymentType === SubscriptionPaymentType.INVOICE) {
            ret = `$${currency(((plan.invoiceAnnualAmount ?? 0) / 100).toFixed(2))} per annum`;
        }
        return ret.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, [subscription]);

    if (loading) {
        return <Loading />;
    }

    return (<>
        <Header title={`${account.name}`}
            action={isSuperAdmin ? (
                <Button uppercase loading={loading}
                    onClick={toggleEdit}>{buttonText}</Button>
            ) : undefined}
            subnodes={<AccountHistory account={account} />} />
        <SectionHeader title="Subscription" />
        {(subscription?.upgradePlan || subscription?.nextPaymentType) && <AlertBar content={(
            <span>
                <span>This account is currently on </span>
                <strong>{subscription?.plan.name}</strong>
                <span> with payment type </span>
                <strong>{subscriptionPaymentInfo?.paymentTypeText}</strong>.
                <span> This account will be updated to </span>
                <strong>{(subscription.upgradePlan ?? subscription.plan).name}</strong>
                {subscriptionPaymentInfo?.nextPaymentTypeText &&
                    <span> with payment type <strong>{subscriptionPaymentInfo.nextPaymentTypeText}</strong></span>}
                <span> on </span>
                <strong>{moment(subscription.termEndsAt).format("DD MMMM yyyy")}</strong>
            </span>
        )}
        />}

        {!editing ?
            (
                subscription ?
                    (
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <span className={styles.label}>Plan name</span>
                                <span>
                                    {subscription?.plan.customizedFor === CustomizedFor.MandateAgency
                                        ? `${subscription.plan.name} (Mandated agency)`
                                        : subscription?.plan.name}
                                </span>
                            </div>
                            <div className={styles.item}>
                                <span className={styles.label}>Billing method</span>
                                <span>{subscriptionPaymentInfo?.paymentTypeText}</span>
                            </div>
                            {subscription.subscriptionPaymentType !== SubscriptionPaymentType.INVOICE &&
                                <div className={styles.item}>
                                    <span className={styles.label}>Next billing date</span>
                                    <span className={classNames({ [styles.clickable_item]: isSuperAdmin, [styles.disabled]: chargeLoading })}
                                        onClick={isSuperAdmin && !chargeLoading ? () => billingDateDialog.current?.show() : undefined}
                                        title="Click to change the next billing date">
                                        {`${moment(subscription?.termEndsAt).format("DD/MM/YYYY")}${isSuperAdmin ? ' >>' : ''}`}
                                    </span>
                                    {showChargeButton &&
                                        <span className={classNames(styles.clickable_item, styles.red, { [styles.disabled]: chargeLoading })}
                                            onClick={chargeForOneTerm}
                                            title={`Click to charge account one term, after charge next billing date will be ${moment(subscription.termEndsAt).add(1, 'month').format("DD/MM/YYYY")}`}
                                        >
                                            Charge for one term
                                        </span>}
                                </div>}
                            <div className={styles.item}>
                                <span className={styles.label}>Bill amount</span>
                                <span>{billAmount}</span>
                            </div>
                        </div>
                    ) :
                    (
                        <div className={styles.items}>No plan subscribed</div>
                    )
            ) :
            (
                <div className={styles.form_section}>
                    <Formik<SaaSSubscriptionUpdateRequest & { upgradeNow: boolean }> innerRef={ref => {
                        subCreateOrUpdateFormRef.current = ref;
                    }}
                        validationSchema={changeValidSchema}
                        enableReinitialize
                        initialValues={formInitValues}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue, dirty }) => {
                            if (dirty !== formDirty) {
                                setFormDirty(dirty);
                            }
                            const plan = plans[values.planId] as SaaSPlan | undefined;
                            const paymentMethodOptions: SelectOption[] = [{ label: 'Automatic payments', value: SubscriptionPaymentType.STRIPE }];
                            if (plan?.invoicable) {
                                paymentMethodOptions.push({ label: `Invoiced annually`, value: SubscriptionPaymentType.INVOICE });
                            }
                            const onPlanChange = (e: any) => {
                                const plan = plans[e.target.value];
                                if (!plan?.invoicable) {
                                    setFieldValue('paymentType', SubscriptionPaymentType.STRIPE);
                                }
                            };
                            return (
                                <Form>
                                    <FormikSelect name="planId" label="Plan name"
                                        options={planOptions}
                                        containerClassName={classNames(styles.half_input, styles.first)}
                                        onChange={onPlanChange}
                                    />
                                    <FormikSelect name="paymentType" label="Billing method"
                                        options={paymentMethodOptions}
                                        containerClassName={styles.half_input}
                                    />
                                    <FormikCheckBox name="upgradeNow" label="Take effect immediately" />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        {id && <Cards accountId={Number(id)} />}
        {id && <Transactions accountId={Number(id)} />}
        <Dialog dialogRef={billingDateDialog} header="Change next billing date">
            <Formik
                innerRef={ref => {
                    billingDateUpdateFormRef.current = ref;
                }}
                initialValues={{ extendTo: subscription?.termEndsAt }}
                validationSchema={updateNextBillingDateSchema}
                onSubmit={onExtendUpdate}>
                {({ isValid, dirty }) => {
                    return (
                        <Form className={styles.billing_date_form}>
                            <FormikDatePicker
                                dateFormat="yyyy-MM-dd"
                                label="Next billing date"
                                dateStringOnly
                                name={"extendTo"}
                                className={styles.date_picker} />
                            <Button disabled={!isValid || !dirty}
                                className={styles.button}
                                fullWidth
                                type="button"
                                onClick={() => confirmBillingDateDialog.current?.show()}
                                loading={loading}>
                                Update
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
            <Dialog dialogRef={confirmBillingDateDialog} header="Confirm you wish to proceed with updating this payment">
                <div className={styles.confirm_dialog}>
                    <span>The payment will be updated until the date you have selected</span>
                    <Button
                        className={styles.button}
                        fullWidth
                        type="button"
                        onClick={submitUpdateBillingForm}
                        loading={loading}>
                        Confirm to update
                    </Button>
                </div>
            </Dialog>
        </Dialog>
    </>);
}
