import React from 'react';
import Button, { ButtonProps } from "../button/Button";
import { MenuItem, ButtonGroup, Popper, ClickAwayListener, MenuList } from '@material-ui/core';
import styles from './ButtonMenu.module.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type Props = {
    name: string;
    items: {
        name: string,
        onClick: () => void
    }[];
    onPrimaryClick: () => void;
    subMenuLoading?: boolean;
} & ButtonProps
export default function ButtonMenu(props: Props) {
    const { name, items, onPrimaryClick, subMenuLoading, ...buttonProps } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <div>
            <ButtonGroup ref={anchorRef}>
                <Button {...buttonProps} onClick={onPrimaryClick}>{name}</Button>
                <Button className={styles.secondary} {...buttonProps} loading={buttonProps.loading || subMenuLoading} onClick={handleToggle}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} disablePortal placement="bottom-end">
                <div className={styles.menu}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {items.map(({ name, onClick }) => (
                                <MenuItem key={name} onClick={() => {
 setOpen(false); onClick(); 
}}>
                                    {name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </div>
            </Popper>
        </div>
    );


}