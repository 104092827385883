import React from "react";
import Header from "../../../../widgets/header/Header";
import styles from "./Reporting.module.scss";

export type ReportingProps = {}

export default function Reporting(props: ReportingProps) {
    return (
        <div className={styles.reporting}>
            <Header title={"Reporting"} />
        </div>
    );
}
