import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Dialog, { DialogRef } from "../../../../../widgets/dialog/Dialog";
import useSelector from "../../../../../../hooks/useSelector";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import { createCategory, getSubCategories, getSubSubCategories } from "../../../../../../redux/actions/categories";
import { showError } from "../../../../../../redux/actions/snackbars";
import { Category, CategoryType } from "../../../../../../types/model/Category";
import Loading from "../../../../loading/Loading";
import Header from "../../../../../widgets/header/Header";
import Button from "../../../../../widgets/button/Button";
import Table from "../../../../../widgets/table/Table";
import Options from "../../../../../widgets/more/Options";
import AddCategoryDialog from "../../../../../widgets/addCategoryDialog/AddCategoryDialog";
import DeleteCategoryDialog from "../../../../../widgets/deleteCategoryDialog/DeleteCategoryDialog";
import styles from "../subCategories/SubCategories.module.scss";
import FormikInput from "../../../../../widgets/input/FormikInput";

function SubSubCategories() {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [editingSubSub, setEditingSubSub] = useState<Category | null>(null);
    const addSubSubCategoryDialog = useRef<DialogRef>(null);
    const deleteDialogRef = useRef<DialogRef>(null);
    const editSubSubDialogRef = useRef<DialogRef>(null);
    const subCategories = useSelector((state) => state.categories.sub);
    const subSubCategories = useSelector((state) => state.categories.subSub);
    const { subCategoryId, categoryId } = useParams<{ subCategoryId: string, categoryId: string }>();
    const [subCategory] = subCategories.filter((c) => c.id === Number(subCategoryId || ""));

    const dispatch = useDispatch();

    useMountEffect(async () => {
        try {
            await dispatch(getSubCategories(Number(categoryId)));
            await dispatch(getSubSubCategories(Number(subCategoryId)));
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    });

    const onUpdateSubNameSubmit = async (values: any) => {
        try {
            await dispatch(createCategory({ ...subCategory, name: values.name, type: CategoryType.SUB }));
        } catch (e) {
            console.log("err", e.message);
        }
    };

    const onUpdateSubSubNameSubmit = async (values: any) => {
        try {
            await dispatch(createCategory({ ...editingSubSub, name: values.name, type: CategoryType.SUB_SUB }));
            await dispatch(getSubSubCategories(Number(subCategoryId)));
            editSubSubDialogRef.current?.hide();
        } catch (e) {
            console.log("err", e.message);
        }
    };

    const onAddClick = () => {
        addSubSubCategoryDialog.current?.show();
    };

    const onDeletePress = (category: Category) => {
        setSelectedCategory(category);
        deleteDialogRef.current?.show();
    };

    const onEditSubSubPress = (category: Category) => {
        setEditingSubSub(category);
        editSubSubDialogRef.current?.show();
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Header title={"Sub-sub categories"} action={<Button onClick={onAddClick}>Add sub-sub category</Button>} />
            <Formik initialValues={{ name: subCategory?.name }} onSubmit={onUpdateSubNameSubmit}
                validationSchema={Yup.object({ name: Yup.string().required() })}>
                {({ isValid, dirty, isSubmitting }) => {
                    return (
                        <Form>
                            <div className={styles.edit_name_wrapper}>
                                <FormikInput name={"name"} label={"Edit Sub-Category name"} />
                                <Button type={"submit"} disabled={!dirty || !isValid || isSubmitting}>Submit</Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <Table columns={[
                { title: "ID", field: "id" },
                { title: "Name", field: "name" },
                {
                    render: s => (
                        <div style={{ textAlign: "right" }}>
                            <Options<Category> data={s}
                                optionButtons={[
                                    {
                                        onPress: () => onEditSubSubPress(s),
                                        buttonText: "Edit name",
                                    },
                                    {
                                        onPress: () => onDeletePress(s),
                                        buttonText: "Delete",
                                    }
                                ]}
                            />
                        </div>
                    )
                }
            ]} data={subSubCategories} options={{ pageSize: 50 }} />

            <Dialog dialogRef={editSubSubDialogRef}>
                <Formik initialValues={{ name: editingSubSub?.name }} onSubmit={onUpdateSubSubNameSubmit}
                    validationSchema={Yup.object({ name: Yup.string().required() })}>
                    {({ isValid, dirty, isSubmitting }) => {
                        return (
                            <Form>
                                <div className={styles.edit_name_wrapper}>
                                    <FormikInput name={"name"} label={"Edit Sub-Category name"} />
                                    <Button type={"submit"} disabled={!dirty || !isValid || isSubmitting}>Submit</Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
            <AddCategoryDialog ref={addSubSubCategoryDialog} header={"Add new sub-sub category"}
                type={CategoryType.SUB_SUB} parent={subCategoryId} />
            <DeleteCategoryDialog ref={deleteDialogRef} type={CategoryType.SUB_SUB} parent={subCategoryId}
                category={selectedCategory} />
        </div>
    );
}

export default SubSubCategories;
