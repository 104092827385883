import React, { forwardRef, Ref, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Dialog, { DialogRef } from "../dialog/Dialog";
import useForwardRef from "../../../hooks/useForwardRef";
import FormikInput from "../input/FormikInput";
import Button from "../button/Button";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import { createCategory, getSubCategories, getSubSubCategories } from "../../../redux/actions/categories";
import { CategoryType } from "../../../types/model/Category";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required")
});

function AddCategoryDialog(props: any, ref: Ref<DialogRef>) {
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onSubmit = async (values: { name: string }) => {
        try {
            setLoading(true);
            await dispatch(createCategory({
                name: values.name,
                type: props.type || CategoryType.MAIN,
                parent: props.parent || null
            }));

            if (props.parent) {
                if (props.type === CategoryType.SUB) {
                    await dispatch(getSubCategories(props.parent));
                } else if (props.type === CategoryType.SUB_SUB) {
                    await dispatch(getSubSubCategories(props.parent));
                }
            }

            setLoading(false);
            dialogRef.current?.hide();
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    return (
        <Dialog dialogRef={dialogRef} header={props.header || "Add new category"} disableBackdropClick={loading} loading={loading}>
            <Formik initialValues={{
                name: ""
            }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                {(formikBag) => (
                    <form onSubmit={formikBag.handleSubmit}>
                        <FormikInput name={"name"} label={"Name"} />
                        <Button style={{ marginTop:"27px", width:"100%" }} fullWidth type={"submit"} loading={loading}>Save</Button>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
}

export default forwardRef(AddCategoryDialog);