import AmotaiUser from "../../types/model/AmotaiUser";
import { Actions, Types } from "../actions/user";
import Preference from "src/types/model/Preference";

type Props = {
    user: AmotaiUser | null;
    preferences: { [type: string]: Preference[] };

}
const initialState: Props = {
    user: null,
    preferences: {},
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function user(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_ME: {
            const { payload } = action as ActionValueTypes<Types.SET_ME>;
            return {
                ...state,
                user: payload || null,
            };
        }
        case Types.SET_PREF: {
            const { payload } = action as ActionValueTypes<Types.SET_PREF>;
            const pf = payload!;
            const newState = { ...state };
            const prefs = newState.preferences[pf.type] ?? [];
            if (!prefs.find(p => p.id === pf.id)) {
                prefs.push(pf);
            }
            newState.preferences[pf.type] = prefs;
            return newState;
        }
        case Types.SET_PREFS: {
            const { payload } = action as ActionValueTypes<Types.SET_PREFS>;
            const pfs = payload!;
            const prefs: { [type: string]: Preference[] } = {};
            for (const pf of pfs) {
                const existing = prefs[pf.type] ?? [];
                existing.push(pf);
                prefs[pf.type] = existing;
            }
            return {
                ...state,
                preferences: prefs,
            };
        }
        case Types.DEL_PREF: {
            const { payload } = action as ActionValueTypes<Types.DEL_PREF>;
            const { prefId } = payload!;
            const prefs = { ...state.preferences };
            for (const type in prefs) {
                const pfs = prefs[type];
                if (pfs.find(p => p.id === prefId)) {
                    prefs[type] = pfs.filter(p => p.id !== prefId);
                    break;
                }
            }
            return {
                ...state,
                preferences: prefs,
            };
        }
        default: {
            return state;
        }
    }
}
