import { Column } from 'material-table';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/widgets/button/Button';
import Header from 'src/components/widgets/header/Header';
import PagingTable from 'src/components/widgets/pagingTable/PagingTable';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getPagingRegionalManagersFull } from 'src/redux/actions/users';
import { PagingTableFilter } from 'src/redux/reducers/tables';
import { VALID_ACCOUNT_STATUS, AccountType } from 'src/types/model/AmotaiAccount';
import AmotaiUser, { SystemRole } from 'src/types/model/AmotaiUser';
import styles from './RMs.module.scss';
import BuyerClient from 'src/types/model/BuyerClient';
import Supplier from 'src/types/model/Supplier';

type AssignedBusinessDetails = {
    inducted: Array<Supplier | BuyerClient>,
    uninducted: Array<Supplier | BuyerClient>,
    total: number,
}
export default function RMs({ type }: { type: AccountType }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);

    const getAssignedBusinesses = useCallback((rm: AmotaiUser): AssignedBusinessDetails => {
        const result = {} as AssignedBusinessDetails;
        const { inductedBusiness, uninductedBusiness } = rm;
        result.inducted = inductedBusiness?.filter(a => a.type === type) ?? [];
        result.uninducted = uninductedBusiness?.filter(a => a.type === type) ?? [];
        result.total = result.inducted.length + result.uninducted.length;
        return result;
    }, [type]);

    const columns = useMemo((): Column<AmotaiUser>[] => {
        const col: Column<AmotaiUser>[] = [
            {
                title: "Regional Manager",
                field: "name"
            },
            {
                title: 'Assigned Businesses',
                render: u => getAssignedBusinesses(u).total,
                customSort: (a, b) => getAssignedBusinesses(a).total - getAssignedBusinesses(b).total
            },
            {
                title: "Outstanding Inductions",
                render: u => (getAssignedBusinesses(u).uninducted.filter(b => VALID_ACCOUNT_STATUS.includes(b.status)).length),
                customSort: (a, b) =>
                    (getAssignedBusinesses(a).uninducted.filter(b => VALID_ACCOUNT_STATUS.includes(b.status)).length)
                    - (getAssignedBusinesses(b).uninducted.filter(b => VALID_ACCOUNT_STATUS.includes(b.status)).length)
            },
        ];
        if (user?.systemRole && [SystemRole.SUPER_ADMIN, SystemRole.ADMIN, SystemRole.REGIONAL_MANAGER].includes(user.systemRole)) {
            col.push({
                render: u => <Button plainLink onClick={() => history.push(`/users/regional-managers/${u.id}`)}>View</Button>
            });
        }
        return col;
    }, [user, getAssignedBusinesses, history]);

    const getData = (filter: PagingTableFilter) => {
        const { limit, cursor } = filter;
        return dispatch(getPagingRegionalManagersFull(limit, cursor));
    };

    return (
        <div className={styles.container}>
            <Header title={`RM - ${type === AccountType.SUPPLIER ? 'Suppliers' : 'Buyers'}`} />
            <PagingTable<AmotaiUser, PagingTableFilter> id={`regional-manager-${type}`} columns={columns} getData={getData} />
        </div>
    );
}