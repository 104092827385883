import React from 'react';
import styles from './EventDescription.module.scss';
import { useFormikContext } from "formik";
import RichEditorViewer from 'src/components/widgets/input/RichEditorViewer';
import RichEditor from 'src/components/widgets/input/RichEditor';

type Props = {
    disabled?: boolean;
    label?: string;
    name: string;
}

export default function EventDescription({ disabled, label, name }: Props) {
    const { values, setFieldValue } = useFormikContext<object & {[key:string]: string}>();

    const onValueChange = (value: string) => {
        console.log('value change:', { value });
        setFieldValue(name, value);
    };

    return  <div className={styles.container}>
                <span>{label}</span>
                {disabled ? 
                    <RichEditorViewer value={values[name] ?? ''} /> :
                    <RichEditor onValueChange={onValueChange} defaultValue={values[name] ?? ''} />
                }
            </div>;
}