import classNames from "classnames";
import React, { ReactNode } from "react";
import Loading from "../../routes/loading/Loading";
import styles from "./SideBarContainer.module.scss";

export type SideBarContainerProps = {
    sideBar: ReactNode;
    loading?: boolean;
    className?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function SideBarContainer(props: SideBarContainerProps) {
    const { className, sideBar, children, loading, ...otherProps } = props;

    const containerClassName = classNames(styles.side_bar_container, className);

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <div {...otherProps} className={containerClassName}>
            <div className={styles.side_bar}>
                {sideBar}
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
}
