import React, { useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Audits from "src/components/widgets/account/audits/Audits";
import AmotaiConnections from "src/components/widgets/account/connections/AmotaiConnections";
import Notes from "src/components/widgets/account/notes/Notes";
import useUserRoles from "src/hooks/useUserRoles";
import { getSupplier } from "src/redux/actions/suppliers";
import Supplier from "src/types/model/Supplier";
import useAsyncEffect from "../../../../../hooks/useAsyncEffect";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import Users from "../../../../widgets/account/users/Users";
import Button from "../../../../widgets/button/Button";
import SideBarContainer from "../../../../widgets/sideBarContainer/SideBarContainer";
import Loading from "../../../loading/Loading";
import BusinessCategory from "./routes/businessCategory/BusinessCategory";
import Details from "./routes/details/Details";
import Employment from "./routes/employment/Employment";
import FinanceAndLegal from "./routes/financialAndLegal/FinancialAndLegal";
import Ownership from "./routes/ownership/Ownership";
import Profile from "./routes/profile/Profile";
import styles from "./SupplierDetail.module.scss";

type RouteParams = {
    supplierId: string;
}
export type SupplierProps = {} & RouteComponentProps<RouteParams>

export default function SupplierDetail(props: SupplierProps) {

    const { match } = props;
    const { params } = match;
    const { supplierId } = params;

    const dispatch = useDispatch();
    const supplier = useSelector((state) => state.suppliers[supplierId]);
    const hasSupplier = !!supplier;
    const [loading, setLoading] = useState(!hasSupplier);
    const { isAdmin, isRegionalManager, isSuperAdmin } = useUserRoles();

    useAsyncEffect(async () => {
        if (hasSupplier) {
            return;
        }
        await dispatch(getSupplier(supplierId));
        setLoading(false);
    });


    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <SideBarContainer className={styles.supplier}
            sideBar={(
                <>
                    <Button to={`/suppliers/${supplierId}`}>Business Details</Button>
                    <Button to={`/suppliers/${supplierId}/profile`}>Business Profile</Button>
                    <Button to={`/suppliers/${supplierId}/category`}>Business Category</Button>
                    <Button to={`/suppliers/${supplierId}/ownership`}>Ownership</Button>
                    <Button to={`/suppliers/${supplierId}/finance`}>Finance and Legal</Button>
                    <Button to={`/suppliers/${supplierId}/employment`}>Employment</Button>
                    <Button to={`/suppliers/${supplierId}/users`}>Users</Button>
                    <Button to={`/suppliers/${supplierId}/connections`}>Connections</Button>
                    <Button to={`/suppliers/${supplierId}/notes`}>Notes</Button>
                    {(isAdmin || isRegionalManager || isSuperAdmin) && <Button to={`/suppliers/${supplierId}/change-logs`}>Change logs</Button>}
                </>
            )}>
            <Switch>
                <Route path={"/suppliers/:accountId/employment"} component={Employment} />
                <Route path={"/suppliers/:accountId/profile"} component={Profile} />
                <Route path={"/suppliers/:accountId/notes"} component={() => <Notes<Supplier> amotaiAccount={supplier} />} />
                <Route path={"/suppliers/:accountId/connections"} component={() => <AmotaiConnections<Supplier> amotaiAccount={supplier} />} />
                <Route path={"/suppliers/:accountId/category"} component={BusinessCategory} />
                <Route path={"/suppliers/:accountId/users"} component={Users} />
                <Route path={"/suppliers/:accountId/finance"} component={FinanceAndLegal} />
                <Route path={"/suppliers/:accountId/ownership"} component={Ownership} />
                <Route path={"/suppliers/:accountId/change-logs"} component={Audits} />
                <Route path={"/suppliers/:accountId"} component={Details} />
            </Switch>
        </SideBarContainer>
    );
}
