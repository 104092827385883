import axios from "axios";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';

type Data = {
    [key: string]: string,
}
type Info = {
    businessName: string,
    projectName: string,
    date: string,
}
type Props = {
    data: Data[],
    info: Info,
    filename?: string,
    sheetname?: string,
}
const defaultFont = {
    name: 'Poppins',
    size: 8,
};
const footerFont = {
    ...defaultFont,
    size: 7,
};
const max_cell_width = 50;
const default_row_height = 15;
const body_row_starts = 6;

export default async function exportXLS(props: Props) {
    const { data, info, filename, sheetname } = props;
    try {
        if (!data) {
            throw new Error("Cannot create Excel file, no data provided");
        }
        const book = new Excel.Workbook();
        const worksheet = book.addWorksheet(sheetname ?? 'report', {
            pageSetup: {
                paperSize: 9, //A4
                orientation: 'landscape',
            },
            headerFooter: {
                oddFooter: 'Page &P of &N',
            },
        });
        const lastCol = Object.keys(data[0]).length;
        worksheet.properties.defaultRowHeight = default_row_height;
        worksheet.views = [
            { state: 'frozen', xSplit: 1, ySplit: body_row_starts }
        ];
        // worksheet.autoFilter = {
        //     from: getCellname(body_row_starts, 1),
        //     to: getCellname(body_row_starts, lastCol),
        // };
        writeHeader(worksheet, lastCol, info);
        writeBody(worksheet, data);
        writeFooter(worksheet, data.length + 8);
        const imageBuf = await axios.get('/excel_logo.png', { responseType: 'arraybuffer' });
        const imageId = book.addImage({
            buffer: imageBuf.data,
            extension: 'png'
        });
        worksheet.addImage(imageId, {
            tl: { col: 0.5, row: 0.5 },
            ext: { width: 376, height: 90 }
        });
        await saveFile(`${filename ?? 'export'}_${moment().format('YYYY-MM-DD')}.xlsx`, book);
    } catch (error) {
        throw new Error(`Failed to export xls: ${error.message}`);
    }
}

/**
 * this will format header area with bg-color of {argb: 'ff00354e'}
 * will add value "business name" to row 2 at end col
 * will add value "project name" to row 3 at end col
 * will add date format as "dd/mm/yyyy" to row 4 at end col
 */
function writeHeader(
    worksheet: Excel.Worksheet,
    lastCol: number,
    header: { businessName: string, projectName: string, date: string }
) {
    if (lastCol > 26 * 26) {
        throw new Error('Too many columns');
    }
    for (let row = 1; row <= 5; row++) {
        for (let col = 1; col <= lastCol; col++) {
            const headerCell = worksheet.getCell(getCellname(row, col));
            headerCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff00354e' },
            };
            headerCell.font = {
                ...defaultFont,
                color: { argb: 'ffffffff' },
            };
            headerCell.alignment = {
                horizontal: 'right',
            };

            if (col === lastCol) {
                if (row === 2) {
                    headerCell.value = `${header.businessName}    `;
                } else if (row === 3) {
                    headerCell.value = `${header.projectName}    `;
                } else if (row === 4) {
                    headerCell.value = `${header.date}    `;
                }
            }
        }
    }

}

function writeBody(
    worksheet: Excel.Worksheet,
    data: Data[]
) {
    data.splice(0, 0, data[0]);
    const colWidths: { [key: number]: number } = {};
    for (let row = body_row_starts; row < data.length + body_row_starts; row++) {
        const i = row - body_row_starts;
        const cols = i === 0 ? Object.keys(data[i]) : Object.values(data[i]);
        const lastCol = cols.length;
        for (let col = 1; col <= lastCol; col++) {
            const cell = worksheet.getCell(getCellname(row, col));
            const column = worksheet.getColumn(col);
            const value = cols[col - 1];
            cell.value = value;
            const exist = colWidths[col] ?? 0;
            const width = Math.max(value.length * 1.2, exist);
            if (width > exist) {
                colWidths[col] = width;
            }
            column.width = width;
            if (width > max_cell_width) {
                column.width = max_cell_width;
                cell.alignment = { wrapText: true };
                worksheet.getRow(row).height = Math.ceil(width / max_cell_width) * 12;
            }
            if (i === 0) {
                cell.font = {
                    ...defaultFont,
                    bold: true,
                };
            } else {
                cell.font = defaultFont;
            }
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }
    }
}

function writeFooter(worksheet: Excel.Worksheet, rowStarts: number) {
    const footers = [
        `If you are not the person to whom this report is the intended recipient, be aware that any use, reproduction, or distribution of this report in part or full is strictly prohibited. If you received this in error, `,
        `contact the sender and immediately delete the report and any associated attachments. While we have made every attempt to ensure that the information contained in this report is current and `,
        `accurate, Amotai is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this report is provided “as is”, with no guarantee of the `,
        `completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including but not limited to warranties of `,
        `performance, merchantability and fitness for a particular purpose In no event will Amotai nor Auckland Council, be liable to you or anyone else for any decision made or action taken in reliance on `,
        `the information in this report or for any consequential, special or similar damages, even if advised of the possibility of such damages.`
    ];
    const header = worksheet.getCell(rowStarts, 1);
    header.font = {
        ...defaultFont,
        bold: true,
    };
    header.value = 'Disclosure';
    for (let i = 0; i < footers.length; i++) {
        const row = rowStarts + 1 + i;
        const cell = worksheet.getCell(row, 1);
        cell.font = footerFont;
        cell.value = footers[i];
        worksheet.getRow(row).height = 10;
    }
}

async function saveFile(fileName: string, workbook: Excel.Workbook) {
    const xls64 = await workbook.xlsx.writeBuffer();
    saveAs(
        new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        fileName
    );
}

function getCellname(row: number, col: number) {
    const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
    const ci1 = col % 26;
    const ci0 = Math.floor(col / 26) - (ci1 === 0 ? 1 : 0);
    const c0 = ci0 === 0 ? '' : ALPHABET[ci0 - 1];
    const c1 = ci1 === 0 ? 'Z' : ALPHABET[ci1 - 1];
    return `${c0}${c1}${row}`;
}
