import IconButton from "@material-ui/core/IconButton";
import MenuRounded from "@material-ui/icons/MenuRounded";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useDispatch from "../../../hooks/useDispatch";
import { setMe } from "../../../redux/actions/user";
import Button from "../button/Button";
import Popover, { PopoverRef } from "../popover/Popover";
import styles from "./Toolbar.module.scss";
import { Link } from "react-router-dom";
import Logo from "../../../resources/images/svg/amotai-logo-white.svg";
import useSelector from "../../../hooks/useSelector";
import useUserRoles from "src/hooks/useUserRoles";
import { getAuth, signOut } from "firebase/auth";

export type ToolbarProps = {}

export default function Toolbar() {
    const { isSuperAdmin } = useUserRoles();
    const user = useSelector(state => state.user.user);
    const isRegionalManager = user?.systemRole === "REGIONAL_MANAGER";

    const dispatch = useDispatch();
    const menu = useRef<PopoverRef>(null);

    const onMenuPress = (ev: React.MouseEvent<HTMLButtonElement>) => {
        menu.current?.show(ev.currentTarget);
    };

    const onLogOutPress = async () => {
        await dispatch(setMe(null));
        const auth = getAuth();
        await signOut(auth);
    };

    const onNavItemPress = useCallback(() => {
        menu.current?.hide();
    }, [menu]);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 766);
    const onWindowResize = useCallback(() => {
        if (!isMobile && window.innerWidth < 766) {
            setIsMobile(true);
        } else if (isMobile && window.innerWidth >= 766) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [onWindowResize]);

    const onCMSClick = useCallback(() => {
        onNavItemPress();
        window.location.href = 'https://amotai.sanity.studio/';
    }, [onNavItemPress]);


    return (
        <div className={styles.toolbar}>
            <div className={styles.inner}>
                <Link to={"/"} className={styles.logo}><img src={Logo} alt={"Amotai"} /></Link>
                <div className={styles.menu}>
                    <Button className={styles.nav_link} onClick={onNavItemPress} to={"/dashboard/suppliers"}>Dashboard</Button>
                    <Button className={styles.nav_link} onClick={onNavItemPress} to={"/suppliers"}>Suppliers</Button>
                    <Button className={styles.nav_link} onClick={onNavItemPress} to={"/buyers"}>Buyers</Button>
                    <IconButton onClick={onMenuPress} style={{ color: "#FFFFFF", }}>
                        <MenuRounded />
                    </IconButton>

                    <Popover popoverRef={menu}
                        className={styles.popover_menu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}>


                        {/*<Button className={styles.nav_link} onClick={onNavItemPress} to={"/events"}>Events</Button>*/}
                        {isRegionalManager &&
                            <Button className={styles.nav_link} onClick={onNavItemPress}
                                to={`/users/regional-managers/${user!.id}`}>My Profile</Button>}
                        {isSuperAdmin &&
                            <Button className={styles.nav_link} onClick={onNavItemPress} to={"/users/admin"}>Admin
                                Users</Button>}
                        {isSuperAdmin &&
                            <Button className={styles.nav_link} onClick={onNavItemPress} to={"/users/view-only"}>View Only
                                Users</Button>}
                        {isSuperAdmin &&
                            <Button className={styles.nav_link} onClick={onNavItemPress} to={"/users/regional-managers"}>Regional
                                Managers</Button>}
                        {isSuperAdmin &&
                            <Button className={styles.nav_link} onClick={onNavItemPress} to={"/saas"}>SaaS Plans</Button>}
                        {isSuperAdmin && <Button className={styles.nav_link} onClick={onNavItemPress}
                            to={"/categories"}>Categories</Button>}
                        {isSuperAdmin && <Button className={styles.nav_link} onClick={onCMSClick}>CMS</Button>}
                        {/*<Button className={styles.nav_link} onClick={onNavItemPress} to={"/reporting"}>Reporting</Button>*/}
                        <Button className={styles.nav_link} onClick={onLogOutPress}>Log Out</Button>
                    </Popover>
                </div>
            </div>
        </div>
    );
}
