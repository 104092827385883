import React, { forwardRef, Ref, useRef, useState, useMemo } from "react";
import { Column } from "material-table";
import { Form, Formik } from "formik";
import Dialog, { DialogRef } from "../dialog/Dialog";
import useForwardRef from "../../../hooks/useForwardRef";
import Button from "../button/Button";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import useSelector from "../../../hooks/useSelector";
import AmotaiAccount, { AccountType } from "../../../types/model/AmotaiAccount";
import PagingTable, { PagingTableRef } from "../pagingTable/PagingTable";
import { PagingTableFilter } from "../../../redux/reducers/tables";
import ListResult from "../../../types/ListResult";
import AccountsAPI from "../../../redux/actions/api/accounts";
import styles from "./AssignBusinessDialog.module.scss";
import FormikSelect from "../input/FormikSelect";
import FormikInput from "../input/FormikInput";
import { REGIONS } from "../../../util/constants";
import Loading from "../../routes/loading/Loading";
import AmotaiUser from "../../../types/model/AmotaiUser";
import { assignAccountsToManager } from "src/redux/actions/users";

type Props = {
    manager: AmotaiUser,
    onClose: () => void,
    accountType: AccountType,
}

function AssignBusinessDialog(props: Props, ref: Ref<DialogRef>) {
    const { manager, onClose, accountType } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const tableRef = useRef<PagingTableRef<AmotaiAccount>>(null);
    const regionalManagers = useSelector((state => state.managers.regionalManagers));
    const [selectedBusinesses, setSelectedBusinesses] = useState<AmotaiAccount[]>([]);
    const [resultLimit, setResultLimit] = useState(50);

    const onBusinessSelect = (businesses: AmotaiAccount[]) => {
        setSelectedBusinesses(businesses);
    };

    const getQuery = async (queryFilter: any): Promise<ListResult<AmotaiAccount>> => {
        const { limit, assignedTo, region, name } = queryFilter;
        return AccountsAPI.getFilteredAccounts(assignedTo, region, accountType, name, undefined, limit);
    };

    const regions = REGIONS.map((region) => {
        return ({
            label: region,
            value: region
        });
    });

    const managerOptions = useMemo(() => {
        const _managers = Object.values(regionalManagers).map((manager) => ({
            label: manager.name ?? manager.email,
            value: manager.id
        }));
        _managers.push({ label: "Unassigned", value: "unassigned" });
        return _managers;
    }, [regionalManagers]);

    const TABLE_COLUMNS: Column<AmotaiAccount>[] = [
        {
            title: "Business Name",
            field: 'name'
        },
        {
            title: "Regions of operation",
            render: business => (
                <div>
                    {(business.regions || []).map((region, i) => {
                        return (
                            <span key={i}>
                                {region}
                                <br />
                            </span>
                        );
                    })}
                </div>
            ),
            customSort: (a, b) => (a.regions ?? []).join().localeCompare((b.regions ?? []).join())
        },
        {
            title: "Regional Manager",
            render: business => (
                <span>
                    {regionalManagers[business.assignedTo]?.name}
                </span>
            )
        },
        {
            title: "Account status",
            field: "status"
        },
    ];

    const onAssignBusinesses = async () => {
        const accountsToAssign = selectedBusinesses.map((business) => {
            return business.id;
        });
        try {
            setLoading(true);
            await dispatch(assignAccountsToManager(manager.id, accountsToAssign));
            setLoading(false);

            dialogRef.current?.hide();
            onClose();
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const getData = (values: any) => {
        return ({
            name: values.name,
            limit: resultLimit,
            assignedTo: values.assignedTo,
            region: values.region
        });
    };

    const onSubmit = async (values: any) => {
        setLoading(true);

        await getQuery({
            name: values.name,
            limit: resultLimit,
            assignedTo: values.assignedTo,
            region: values.region
        });

        setLoading(false);
    };



    return (
        <Dialog dialogRef={dialogRef} containerClassName={styles.dialog_container} className={styles.dialog}
            header={`Assign ${accountType === AccountType.SUPPLIER ? 'Supplier' : 'Buyer'} Business`} disableBackdropClick={loading}
            loading={loading}>

            <Formik
                onSubmit={onSubmit}

                initialValues={
                    {
                        name: "",
                        assignedTo: "",
                        region: "",
                    }
                }>

                {({ values }) => (
                    <Form>
                        <div style={{ display: "flex", alignItems: "flex-end", marginRight: 12 }}>
                            <FormikInput name={"name"} placeholder={"Search by business name"}
                                inputClassname={styles.formik_input} />
                            <FormikSelect name={"region"} label={"Region"} containerClassName={styles.formik_input}
                                options={regions} />
                            <FormikSelect name={"assignedTo"} label={"Assigned to"}
                                containerClassName={styles.formik_input}
                                options={managerOptions} />
                            <Button type={"submit"} style={{ marginLeft: 10 }}>Search</Button>

                        </div>
                        {loading ?
                            <Loading />
                            : (
                                <PagingTable<AmotaiAccount, PagingTableFilter> getData={() => getQuery(getData(values))}
                                    onChangeRowsPerPage={pageSize => setResultLimit(pageSize)}
                                    onSelectionChange={(rowData) => onBusinessSelect(rowData)}
                                    options={{
                                        selection: true,
                                        showSelectAllCheckbox: false,
                                    }}
                                    id={`assign_business_table-${accountType}`}
                                    tableRef={tableRef}
                                    columns={TABLE_COLUMNS} />
                            )}
                        <Button plain type={"reset"} onClick={() => onSubmit(values)}>Clear search</Button>

                    </Form>
                )}
            </Formik>


            <Button onClick={onAssignBusinesses} disabled={loading || selectedBusinesses.length === 0}>
                {`Assign ${accountType === AccountType.SUPPLIER ? 'Supplier' : 'Buyer'} Business`}
            </Button>
        </Dialog>

    );
}

export default forwardRef(AssignBusinessDialog);