import Audit from "src/types/model/Audit";
import { AuditPayload, AuditTypes } from '../actions/audit';

type State = {
    account: {
        status: { [accountId: number]: Audit[] },
        all: { [accountId: number]: Audit[] },
    }
}
type Action = {
    type: AuditTypes,
    payload: any,
}
const initialState: State = {
    account: {
        status: {},
        all: {},
    }
};

export default function audits(state: State = initialState, action: Action) {
    switch (action.type) {
        case AuditTypes.SET_ACCOUNT_STATUS_AUDITS: {
            const { accountId, audits } = action.payload as AuditPayload[AuditTypes.SET_ACCOUNT_STATUS_AUDITS];
            const next = { ...state };
            next.account.status[accountId] = audits;
            return next;
        }
        case AuditTypes.SET_ACCOUNT_ALL_AUDITS: {
            const { accountId, audits } = action.payload as AuditPayload[AuditTypes.SET_ACCOUNT_ALL_AUDITS];
            const next = { ...state };
            next.account.all[accountId] = audits;
            return next;
        }
        case AuditTypes.SET_ALL_ACCOUNT_STATUS_AUDITS: {
            const audits = action.payload as AuditPayload[AuditTypes.SET_ALL_ACCOUNT_STATUS_AUDITS];
            const status: { [id: number]: Audit[] } = {};
            for (const audit of audits) {
                if (audit.entity !== 'Account') {
                    continue;
                }
                if (status[audit.entityKey]) {
                    status[audit.entityKey].push(audit);
                } else {
                    status[audit.entityKey] = [audit];
                }
            }
            const next = { ...state };
            next.account.status = status;
            return next;
        }
        default:
            return state;
    }
}