import React, { useState, useCallback } from 'react';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';
import styles from "./Uploader.module.scss";
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { v4 as uuid } from "uuid";
import { ref, getStorage, uploadBytes, getDownloadURL } from "firebase/storage";

type UploaderProps = {
    label: string,
    disabled: boolean,
    title?: string
}

export default function Uploader(props: UploaderProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [fileObject, setFileObject] = useState<FileObject | null>(null);
    const [, setLoading] = useState<boolean>(false);
    const storage = getStorage();

    const title = props.title ?? `Add image`;
    const className = classNames(styles.link, { [styles.disabled]: props.disabled });

    const { values, setFieldValue } = useFormikContext<object & {
        imgUrl: string;
    }>();

    const addImage = useCallback(() => {
        if (!props.disabled) {
            setOpen(true);
        }
    }, [props.disabled]);

    const onDelete = () => {
        setFileObject(null);
    };

    const onAdd = (newFileObjs: FileObject[]) => {
        const newFile = newFileObjs[0];
        if (!!newFile) {
            console.log('File: ', fileObject);
            setFileObject(newFile);
        }
    };

    const onSave = async () => {
        setLoading(true);
        const fileRef = ref(storage, `uploads/${uuid()}`);
        const file: File | undefined = fileObject?.file;
        if (!!file) {
            await uploadBytes(fileRef, file);
            const url = await getDownloadURL(fileRef);
            setFieldValue('imgUrl', url);
            setLoading(false);
            setOpen(false);
        }
    };

    return (<div className={styles.uploader}>
        <span>{props.label}</span>
        <button className={className} onClick={addImage} type="button">{values.imgUrl || fileObject?.file.name || title}</button>
        <DropzoneDialogBase acceptedFiles={['image/*']}
            dialogTitle={title}
            filesLimit={1}
            fileObjects={!!fileObject ? [fileObject] : []}
            open={open}
            onAdd={onAdd}
            onDelete={onDelete}
            onClose={() => setOpen(false)}
            onSave={onSave}
            showPreviewsInDropzone
            showPreviews={false}
            showAlerts={false}

        />
    </div>);

}