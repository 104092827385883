import { AccountType } from "./AmotaiAccount";
import AmotaiUser from "./AmotaiUser";

export enum EventStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
}
export enum RSVPStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
}
// export interface InvitedByUser {
//     name: string,
//     phoneNumber: string;
//     email: string;
//     description: string;
//     eventId: string;
//     rsvpId: string;
// }
export interface EventRSVP {
    id: string;
    status: RSVPStatus;
    invitedBy: string; //invited by user id
    invitedAt: number|string; //timestamp in millisenconds or datetime string
    event: string; //event id
    attendeeEmail: string; 
    attendeeName: string;
    businessName: string;
    invitedByUser?: AmotaiUser;
}
export default interface AmotaiEvent {
    id: string;
    status: EventStatus;
    
    date: number;
    description: string;
    price: number;
    name: string;
    address: string;
    capacity: number;
    category: string;
    termsAndConditions: string;
    
    dateRsvp: number;
    imgUrl: string;
    link: string;
    eventFor: AccountType[];
    region: string;
    
    rsvps?: {[key:string]:EventRSVP};
    rsvpCnt?: number; //customized field, the count of rsvps
};;;;;;;;;;