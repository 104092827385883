import { Actions, Types } from "../actions/saaSSubscriptions";

const initialState = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function saaSSubscriptions(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_SUBSCRIPTION: {
            const { payload } = action as ActionValueTypes<Types.SET_SUBSCRIPTION>;
            const newState = { ...state };
            if (payload?.account) {
                newState[payload.account] = payload;
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}