/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import clientsBuyers from "./clientsBuyers";
import notes from "./notes";
import snackbars from "./snackbars";
import suppliers from "./suppliers";
import tables from "./tables";
import user from "./user";
import users from "./users";
import events from "./events";
import saaSPlans from "./saaSPlans";
import businessContacts from "./businessContacts";
import businessOwners from "./businessOwners";
import businessReferences from "./businessReferences";
import categories from "./categories";
import saaSSubscriptions from "./saaSSubscriptions";
import managers from "./managers";
import audits from './audits';

const reducers = {
    businessContacts,
    businessOwners,
    businessReferences,
    clientsBuyers,
    snackbars,
    suppliers,
    tables,
    user,
    users,
    managers,
    notes,
    events,
    saaSPlans,
    saaSSubscriptions,
    categories,
    audits,
};

const PERSIST_CONFIG: PersistConfig<any> = {
    key: "root",
    storage,
    whitelist: [],
};

const root = combineReducers(reducers);
const persistedReducer = persistReducer(PERSIST_CONFIG, root);

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
