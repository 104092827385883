import Button from "src/components/widgets/button/Button";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
import Loading from "src/components/routes/loading/Loading";
import FormikTextArea from "../../../../../../widgets/input/FormikTextArea";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import FormikNumberInput from "../../../../../../widgets/input/FormikNumberInput";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import styles from "../../SupplierDetail.module.scss";
import Header from "../../../../../../widgets/header/Header";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { showError } from "../../../../../../../redux/actions/snackbars";
import { updateSupplier } from "../../../../../../../redux/actions/suppliers";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import useUserRoles from "src/hooks/useUserRoles";

type RouteParams = {
    accountId: string;
}

type EmploymentValues = {
    totalFTE: string,
    maoriEmployees: string,
    pasifikaEmployees: string,
    nonMaleEmployees: string,
    employeesUnderTwentyFour: string,
    pathwaysProvided: string,
};

export const OrgEmployeesValidationSchema = Yup.object({
    totalFTE: Yup.number().required("Required"),
    maoriEmployees: Yup.number().required("Required"),
    pasifikaEmployees: Yup.number().required("Required"),
    nonMaleEmployees: Yup.number().required("Required"),
    employeesUnderTwentyFour: Yup.number().required("Required"),
    pathwaysProvided: Yup.string(),
});

export default function Employment(props: RouteComponentProps<RouteParams>) {
    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const id = props?.match?.params?.accountId;
    const suppliers = useSelector((state) => state.suppliers);
    const supplier = suppliers[id];
    const { isSuperAdmin } = useUserRoles();

    if (loading) {
        return <Loading />;
    }

    const onSubmit = async (values: EmploymentValues) => {
        try {
            setLoading(true);
            await dispatch(updateSupplier({
                ...supplier,
                ...values
            }));
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    };

    console.log(supplier);

    return (
        <Formik<EmploymentValues> initialValues={{
            totalFTE: supplier.totalFTE || "",
            maoriEmployees: supplier.maoriEmployees || "",
            pasifikaEmployees: supplier.pasifikaEmployees || "",
            nonMaleEmployees: supplier.nonMaleEmployees || "",
            employeesUnderTwentyFour: supplier.employeesUnderTwentyFour || "",
            pathwaysProvided: supplier.pathwaysProvided || "",
        }} onSubmit={onSubmit} validationSchema={OrgEmployeesValidationSchema}>
            {(formikBag) => {
                const toggleEdit = async () => {
                    if (editing && formikBag.dirty) {
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };

                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>

                        <Form>

                            <Header title={`${supplier.name}`}
                                action={isSuperAdmin ? (
                                    <Button uppercase loading={loading || formikBag.isSubmitting}
                                        onClick={toggleEdit}>{actionText}</Button>
                                ) : undefined}
                                subnodes={<AccountHistory account={supplier} />} />
                            <SectionHeader title={"Employment"} />
                            <div>
                                <div className={styles.form}>
                                    <div className={styles.form_section}>
                                        <FormikNumberInput name={"totalFTE"}
                                            inputClassname={styles.full_input}
                                            disabled={!editing}
                                            label={"Size of workforce (Total number of Employees)"}
                                            allowNegative={false}
                                        />
                                        <hr />
                                        <FormikInput name={"maoriEmployees"}
                                            disabled={!editing}
                                            inputClassname={classNames(styles.half_input, styles.first)}
                                            label={"Number of Maori employees"}
                                        />
                                        <FormikInput name={"pasifikaEmployees"}
                                            disabled={!editing}
                                            inputClassname={styles.half_input}
                                            label={"Number of Pasifika employees"}
                                        />
                                        <hr />
                                        <FormikInput name={"nonMaleEmployees"}
                                            disabled={!editing}
                                            inputClassname={classNames(styles.half_input, styles.first)}
                                            label={"Number of female employees"}
                                        />
                                        <FormikInput name={"employeesUnderTwentyFour"}
                                            disabled={!editing}
                                            inputClassname={styles.half_input}
                                            label={"Number of Employees under 24"}
                                        />
                                        <hr />
                                        <FormikTextArea name={"pathwaysProvided"}
                                            disabled={!editing}
                                            label={"Pathways provided"}
                                            className={styles.paragraph_input}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                );
            }}
        </Formik>
    );
}