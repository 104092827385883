import { Actions, Types } from "../actions/businessOwners";
import BusinessOwner from "../../types/model/BusinessOwner";

const initialState: {[key: string]: BusinessOwner[]} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function businessOwners(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BUSINESS_OWNERS: {
            const { payload } = action as ActionValueTypes<Types.SET_BUSINESS_OWNERS>;

            if (!payload || !payload.length) {
                return {
                    ...state
                };
            }

            const account = payload[0].account;
            return {
                ...state,
                [account]: payload
            };
        }
        case Types.UPD_BUSINESS_OWNERS: {
            const payload = action as ActionValueTypes<Types.UPD_BUSINESS_OWNERS>;
            if (!payload.payload) {
                return state;
            }
            const { accountId, owners } = payload.payload;
            return {
                ...state,
                [accountId]: owners,
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
}