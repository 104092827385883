import moment from 'moment';
import { AccountStatus, AccountType, PaymentType } from "src/types/model/AmotaiAccount";
import BusinessOwner from "src/types/model/BusinessOwner";
import BuyerClient from "src/types/model/BuyerClient";
import { Category, SubCategory, SubSubCategory } from "src/types/model/Category";
import Supplier, { OwnershipType } from "src/types/model/Supplier";
import { nameBuilder, phoneBuilder } from "./utils";

export type Data = {
    tableData: { checked: boolean },
} & ExportAccountData;

export type ExportAccountData = {
    approvedAt?: string,
    onholdAt?: string,
    declinedAt?: string,
    deletedAt?: string,
} & (Supplier | BuyerClient);

export type ExportHeaderInfo = {
    businessName: string;
    projectName: string;
    date: string;
}

export function buildCSVData(accountType: AccountType, fields: string[], selectedResults: Array<Data>): { [key: string]: string }[] {
    const csvDataArray: { [key: string]: string }[] = [];

    for (let i = 0; i < (selectedResults?.length ?? 0); i++) {
        const item = selectedResults![i];
        const csvItem: { [key: string]: string } = {};

        for (let j = 0; j < fields.length; j++) {
            let key = fields[j];
            if (/^(maincontact#)/i.test(key)) {
                const { mainContact } = item;
                let subf = key.replace(/^(maincontact#)/i, '').toLowerCase();
                if (subf === 'mobile' || subf === 'phone') {
                    subf = `${subf}Number`;
                }
                const value = mainContact?.[subf] ?? '';
                csvItem[nameBuilder(key)] = value;
            } else if (key === "generalPhoneNumber") {
                const value = phoneBuilder(item[key] as string | undefined);
                key = nameBuilder(key);
                csvItem[key] = `${value ?? ""}`;
            } else if (accountType === AccountType.BUYER_CLIENT && key === "category") { //non supplier
                const value = item[key] as Category | undefined;
                key = nameBuilder(key);
                csvItem[key] = value?.name ?? "";
            } else if (key === "mainCategories") { //supplier
                const value = (item[key] ?? []) as Category[];
                key = nameBuilder(key);
                csvItem[key] = value.map(v => v.name).join(',') ?? "";
            } else if (key === "subCategories") {
                const value = item[key] as SubCategory[] | undefined;
                key = nameBuilder(key);
                csvItem[key] = value?.map(c => c.name).join(",") ?? "";
            } else if (key === "subSubCategories") {
                const value = item[key] as SubSubCategory[] | undefined;
                key = nameBuilder(key);
                csvItem[key] = value?.map(c => c.name).join(",") ?? "";
            } else if (key === "otherInsurances") {
                const value = item[key] as { [k: string]: string } | undefined;
                key = nameBuilder(key);
                const values: string[] = [];
                Object.keys(value || {}).forEach((k: string) => {
                    const v: string = (value || {})[k];
                    if (Number.isNaN(Number(v))) {
                        values.push(`${k}: ${v}`);
                    } else {
                        values.push(`${k}: ${new Intl.NumberFormat("en-NZ", {
                            style: "currency",
                            currency: "NZD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }).format(Number(v))}`);
                    }
                });
                csvItem[`${key}`] = values.join(",");
            } else if (['healthAndSafetyQualifications', 'healthAndSafetyForSubContract', 'regions'].includes(key)) {
                const value = item[key] as string[] | undefined;
                key = nameBuilder(key);
                csvItem[key] = value?.join(", ") ?? "";
            } else if (key === 'regionBased') {
                const value = item[key] as string | undefined;
                key = nameBuilder(key);
                csvItem[key] = value ?? "";
            } else if (key === "saaSPlanName") {
                const value = item[key] ?? "No subscription binded" as string;
                key = 'SaaS plan name'; // hard coded;
                csvItem[key] = value;
            } else if (key === "paymentType") {
                let value = item[key] as PaymentType | string | undefined;
                key = nameBuilder(key);
                if (!value) {
                    value = `${PaymentType.STRIPE} (Not recorded)`;
                }
                csvItem[key] = value!;
            } else if (key === "createdAt") {
                const value: string = item[key];
                key = nameBuilder(key);
                csvItem[key] = new Date(value).toLocaleString("en-NZ", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit"
                });
            } else if (key === "status") {
                let value: string | undefined = item[key];
                key = nameBuilder(key);
                if (!value) {
                    value = "Unknown status";
                } else if (value === AccountStatus.DISABLED) {
                    value = "ON_HOLD";
                }
                csvItem[key] = value;
            } else if (key === "inductedAt") {
                let value: string | undefined = item[key];
                key = nameBuilder(key);
                if (!value) {
                    value = "Awaiting induction";
                }
                csvItem[key] = value;
            } else if (key === "inductedBy") {
                let value: string | undefined = item[key];
                key = nameBuilder(key);
                if (!value) {
                    value = "Inductor unavailable";
                }
                csvItem[key] = value;
            } else if (key === "assignedTo") {
                let value: string | undefined = item[key];
                key = nameBuilder(key);
                if (!value) {
                    value = "No RM assigned";
                }
                csvItem[key] = value;
            } else if (key === "reviewMessage") {
                let value: string | null = item[key];
                key = nameBuilder("declineMessage");
                if (!value) {
                    value = "NA";
                }
                csvItem[key] = value;
            } else if (key === "physicalAddress") {
                const value: { formattedAddress: string | undefined } | undefined = item.physicalAddressComponents;
                const address = value?.formattedAddress ?? item.physicalAddress;
                key = nameBuilder('physicalAddress');
                if (address) {
                    csvItem[key] = address;
                }
            } else if (key === "businessOwners") {
                const businessOwners: BusinessOwner[] | undefined = item[key];
                key = nameBuilder(key);
                csvItem[key] = (businessOwners || []).map((bo: BusinessOwner) => {
                    return `${bo.name} - ${bo.pctOwned}% - ${bo.genderIdentity} - ${(bo.iwiAffiliations || []).join(", ")} - ${(bo.pasifikaAffiliations || []).join(", ")}`;
                }).join("\n");
            } else if (key === "iwiAffiliations" || key === "pasifikaAffiliations") {
                const value = [...new Set(item[key])];
                key = nameBuilder(key);
                csvItem[key] = value.join(", ");
            } else if (key === 'ownershipType') {
                const raw = item[key] as OwnershipType | undefined;
                let value: string;
                switch (raw) {
                    case OwnershipType.MaoriOwned:
                        value = 'Māori owned business criteria met';
                        break;
                    case OwnershipType.PasifikaOwned:
                        value = 'Pasifika owned business criteria met';
                        break;
                    case OwnershipType.MaoriPasifikaOwned:
                        value = 'Both Māori & Pasifika owned business criteria met';
                        break;
                    case OwnershipType.MaoriPasifikaCombined:
                        value = 'Māori & Pasifika ownership combined to meet threshold criteria';
                        break;
                    default:
                        value = 'Not verified yet';
                        break;
                }
                key = nameBuilder('ownershipVerified');
                csvItem[key] = value;
            } else if (key === statusDate.approvalDate) {
                const value = item.approvedAt ? moment(item.approvedAt).format("DD/MM/YYYY") : 'n/a';
                key = nameBuilder(key);
                csvItem[key] = value;
            } else if (key === statusDate.declinedDate) {
                const value = item.declinedAt ? moment(item.declinedAt).format("DD/MM/YYYY") : 'n/a';
                key = nameBuilder(key);
                csvItem[key] = value;
            } else if (key === statusDate.deletionDate) {
                const value = item.deletedAt ? moment(item.deletedAt).format("DD/MM/YYYY") : 'n/a';
                key = nameBuilder(key);
                csvItem[key] = value;
            } else if (key === statusDate.onHoldDate) {
                const value = item.onholdAt ? moment(item.onholdAt).format("DD/MM/YYYY") : 'n/a';
                key = nameBuilder(key);
                csvItem[key] = value;
            } else {
                const value = item[key] ?? "";
                key = nameBuilder(key);
                csvItem[key] = value;
            }
        }
        csvDataArray.push(csvItem);
    }
    return csvDataArray;
}


/** Pre-defined fields */

/* special fields that don't exist in model */
export const statusDate = {
    onHoldDate: 'onHoldDate',
    approvalDate: 'approvalDate',
    declinedDate: 'declinedDate',
    deletionDate: 'deletionDate',
};

/*
 To developers:
 sort the following 4 fields content order to sort the export columns order;
 E.g. put "name" as the first element and then the first columns on export sheet will be "name"
*/

export const supplier_default_checked = [
    "name",
    // "mainContact", //name, email, mobile
    "mainContact#name",
    "mainContact#email",
    "mainContact#mobile",
    "regions",
    "regionBased",
    "mainCategories",
    "subCategories",
    "subSubCategories",
    "website",
    "healthAndSafetyQualifications",
    "otherInsurances",
    "publicLiabilityInsurance",
    "professionalIndemnity",
    "ownershipType",
];

export const buyer_default_checked = [
    "name",
    "mainContact#name",
    "mainContact#email",
    "mainContact#mobile",
    "regions",
    "regionBased",
    "category",
];

export const supplier_extra = [
    "legalName",
    "status",
    "createdAt",
    "inductedAt",
    "inductedBy",
    "assignedTo",
    "reviewMessage",
    "legalStructure",
    "physicalAddress",
    "totalFTE",
    "turnoverInLastFinancialYear",
    "mainContact#title",
    "mainContact#phone",
    "generalPhoneNumber",
    "generalEmail",
    "pasifikaAffiliations",
    "iwiAffiliations",
    "nzbn",
    "nzCompaniesNumber",
    "yearEstablished",
    "facebookLink",
    "twitterLink",
    "youtubeLink",
    "instagramLink",
    "linkedInLink",
    "pinterestLink",
    "maoriEmployees",
    "pasifikaEmployees",
    "nonMaleEmployees",
    "employeesUnderTwentyFour",
    "businessOwners",
    "postalAddress",
    statusDate.deletionDate,
    statusDate.declinedDate,
    statusDate.approvalDate,
    statusDate.onHoldDate,
];

export const buyer_extra = [
    "status",
    "createdAt",
    "inductedAt",
    "inductedBy",
    "reviewMessage",
    "legalStructure",
    "saaSPlanName",
    "mainContact#title",
    "mainContact#phone",
    "paymentType",
    "size",
    "annualTurnover",
    "annualAddressableSpend",
    "approxAnnualIndirectSpend",
    "healthAndSafetyForSubContract",
    "minimumLevelOfInsuranceForSubContract",
    "postalAddress",
    "physicalAddress",
    statusDate.deletionDate,
    statusDate.declinedDate,
    statusDate.approvalDate,
    statusDate.onHoldDate,
];

/** End of pre-defined fields */