import createAction from "./helpers/createAction";
import AmotaiEvent, { EventRSVP } from "src/types/model/AmotaiEvent";
import wrapper from "./helpers/wrapper";
import EventAPI from "./api/events";
import ListResult from "src/types/ListResult";

export enum Types {
    SET_EVENTS = "events:SET_EVENTS",
    SET_EVENT = "events:SET_EVENT",
    DEL_EVENT = 'events:DEL_EVENT',
    SET_RSVPS = 'events:SET_RSVPS'
}

export const setEvent = createAction<AmotaiEvent>(Types.SET_EVENT);
export const setEvents = createAction<ListResult<AmotaiEvent>>(Types.SET_EVENTS);
export const delEvent = createAction<AmotaiEvent>(Types.DEL_EVENT);
export const setRsvps = createAction<ListResult<EventRSVP>>(Types.SET_RSVPS);

export const Actions = {
    [Types.SET_EVENT]: setEvent,
    [Types.SET_EVENTS]: setEvents,
    [Types.DEL_EVENT]: delEvent,
    [Types.SET_RSVPS]: setRsvps
};
export type Actions = typeof Actions;

export const getEvent = wrapper(EventAPI.getEvent, { 
    action: setEvent 
});

export const getEvents = wrapper(EventAPI.getEvents, {
    action: setEvents 
});
export const createOrUpdateEvent = wrapper(EventAPI.createOrUpdateEvent, {
    action: setEvent
});
export const deleteEvent = wrapper(EventAPI.deleteEvent, {
    action: delEvent
});
export const getEventRsvps = wrapper(EventAPI.getEventRsvps, {
    action: setRsvps
});