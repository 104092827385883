import React, { useState } from "react";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import _values from "lodash/values";
import { Form, Formik } from "formik";
import BusinessContactFieldArray from "src/components/widgets/formikFields/BusinessContactFieldArray";
import styles from "../../../supplierDetail/SupplierDetail.module.scss";
import Header from "../../../../../../widgets/header/Header";
import Button from "../../../../../../widgets/button/Button";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import useSelector from "../../../../../../../hooks/useSelector";
import BusinessContact from "../../../../../../../types/model/BusinessContact";
import { showError } from "../../../../../../../redux/actions/snackbars";
import useDispatch from "../../../../../../../hooks/useDispatch";
import FormikFileUpload from "../../../../../../widgets/input/FormikFileUpload";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import { getBusinessContacts, updateBusinessContacts } from "../../../../../../../redux/actions/businessContacts";
import Loading from "../../../../../loading/Loading";
import { updateClientBuyer } from "../../../../../../../redux/actions/clientsBuyers";
import { getSaaSSubscription } from "../../../../../../../redux/actions/saaSSubscriptions";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import useUserRoles from "src/hooks/useUserRoles";

type RouteParams = {
    clientBuyerId: string;
}

type BuyerBusinessProfileValues = {
    logo: string;
    description: string;
    businessContacts: BusinessContact[]
}

const validationSchema = Yup.object({
    logo: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    businessContacts: Yup.array().of(Yup.object({
        name: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        email: Yup.string().email("Not a valid email address").required("Required"),
        phoneNumber: Yup.string().required("Required"),
        avatar: Yup.string()
    }))
});

export default function Profile(props: RouteComponentProps<RouteParams>) {
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const id: any = props?.match?.params?.clientBuyerId;
    const account = useSelector((state) => state.clientsBuyers[id]);
    const subscription = useSelector((state: any) => state.saaSSubscriptions[id]);
    const dispatch = useDispatch();
    const businessContactObjects = useSelector((state) => state.businessContacts.businessContactObjects);
    const businessContacts = _values(businessContactObjects).filter((contact: BusinessContact) => contact.account === Number(id));
    const { isSuperAdmin } = useUserRoles();

    useMountEffect(async () => {
        if (!businessContacts.length) {
            await dispatch(getBusinessContacts(Number(id)));
        }

        if (!subscription) {
            await dispatch(getSaaSSubscription(Number(id)));
        }

        setLoading(false);
    });

    const onSubmit = async (values: BuyerBusinessProfileValues) => {
        try {
            setLoading(true);
            await dispatch(updateClientBuyer({
                ...account,
                ...values
            }));
            await dispatch(updateBusinessContacts(Number(id), values.businessContacts.map((b) => ({
                ...b, account: Number(id)
            }))));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const initialValues = {
        logo: account.logo || "",
        description: account.description || "",
        businessContacts: businessContacts || [],
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(formikBag) => {
                const toggleEdit = async () => {
                    if (editing && formikBag.dirty) {
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };

                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <Form className={styles.form}>
                        <Header title={`${account.name}`}
                            action={isSuperAdmin ? (
                                <Button uppercase loading={loading || formikBag.isSubmitting}
                                    onClick={toggleEdit}>{actionText}</Button>
                            ) : undefined}
                            subnodes={<AccountHistory account={account} />} />
                        <SectionHeader title={"Business Profile"} />
                        <div className={styles.form_section}>
                            <FormikFileUpload name={"logo"}
                                labelClassName={styles.file_input}
                                preview={account.logo || ""}
                                label={"Business logo"}
                                disabled={!editing}
                                imageClassName={styles.logo_image} />
                            <FormikInput name={"description"}
                                label={"Business description"}
                                multiline
                                disabled={!editing}
                                className={styles.paragraph_input} />
                        </div>
                        <div className={styles.form_section}>
                            <SectionHeader title={"Preferred contacts"} />
                            <BusinessContactFieldArray max={subscription?.plan?.maxContacts} disabled={!editing} />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
