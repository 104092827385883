import { FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/styles/withStyles";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./CheckBox.module.scss";

export const StyledCheckbox = withStyles({
    root: {
        fontFamily: "Poppins, sans serif",
        color: '#CDCDCD',
        '&$checked': {
            color: '#008997',
        },
    },
    checked: {},
})((props: MuiCheckboxProps) => <MuiCheckbox color={"default"} {...props} />);

export type CheckboxProps = {
    label?: ReactNode;
    labelClassName?: string;
    labelProps?: Omit<FormControlLabelProps, "control" | "label">;
} & Omit<MuiCheckboxProps, "name" | "error">;

export default function CheckBox(props: CheckboxProps) {
    const { label, labelProps, disabled, ...otherProps } = props;
    const className = classNames(styles.check_box, props.className);
    const labelClassName = classNames(styles.check_box_container, props.labelClassName);
    return (
        <FormControlLabel
            {...(labelProps || {})}
            className={labelClassName}
            control={<MuiCheckbox {...otherProps} className={className} />}
            label={label}
            disabled={disabled}
        />
    );
}

