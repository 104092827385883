import React, { forwardRef, Ref, useState } from "react";
import AmotaiAccount from "src/types/model/AmotaiAccount";
import AccountsAPI from "src/redux/actions/api/accounts";
import Dialog, { DialogRef } from "../Dialog";
import styles from "./AccountDialog.module.scss";
import Button from "../../button/Button";
import useForwardRef from "../../../../hooks/useForwardRef";
import useDispatch from "../../../../hooks/useDispatch";
import { showError } from "../../../../redux/actions/snackbars";

type DeleteAccountDialogProps = {
    account: AmotaiAccount;
    onFinish?: () => void;
    onCancelClick?: () => void;
};

function DeleteAccountDialog(props: DeleteAccountDialogProps, ref: Ref<DialogRef>) {
    const { account, onFinish } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onSubmit = async () => {
        setLoading(true);

        if (account) {
            try {
                await AccountsAPI.deleteAccountByAdmin(account.id);
                onFinish?.();
            } catch (e) {
                dispatch(showError(e.message));
            }
        }
        setLoading(false);
        dialogRef.current?.hide();
    };

    const onCancelClick = () => {
        if (props.onCancelClick) {
            props.onCancelClick();
        }
        dialogRef.current?.hide();
    };

    return (
        <Dialog dialogRef={dialogRef} disableBackdropClick={loading}
                header={"DELETE ACCOUNT?"}>
            <div className={styles.dialog_form}>
                Are you sure you would like to delete <b>{account.name}</b>? <br />
                This action cannot be undone.
                <div className={styles.dialog_footer}>
                    <Button onClick={onSubmit}
                            loading={loading}>Delete</Button>
                    <span />
                    <Button plainLink onClick={onCancelClick} disabled={loading}>Cancel</Button>
                </div>
            </div>

        </Dialog>
    );
}

export default forwardRef(DeleteAccountDialog);
