import MuiDialog, { DialogProps as MuiDialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import classNames from "classnames";
import React, { ReactNode, Ref, useImperativeHandle, useState } from "react";
import Button, { ButtonProps } from "../button/Button";
import ErrorBlock from "../errorBlock/ErrorBlock";
import styles from "./Dialog.module.scss";

export type DialogProps = {
    children: ReactNode;

    dialogRef: Ref<DialogRef>;
    className?: string;
    containerClassName?: string;

    loading?: boolean;
    initialShown?: boolean;
    onClose?: () => void;

    header?: string;
    error?: string | null;

    closeOnNegative?: boolean;

    positiveText?: string;
    positiveButtonProps?: ButtonProps;
    onPositivePress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

    negativeText?: string;
    negativeButtonProps?: ButtonProps;
    onNegativePress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

    neutralText?: string;
    neutralButtonProps?: ButtonProps;
    onNeutralPress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

} & Omit<MuiDialogProps, "open">;

export type DialogRef = {
    show: () => void;
    hide: () => void;
};

export default function Dialog(props: DialogProps) {
    const {
        dialogRef,
        children,
        header,
        error,
        loading,
        closeOnNegative,

        positiveText,
        positiveButtonProps,
        onPositivePress,

        negativeText,
        negativeButtonProps,

        neutralText,
        neutralButtonProps,
        onNeutralPress,
    } = props;


    const { initialShown } = props;
    const [shown, setShown] = useState(initialShown || false);

    const onNegativePress = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (closeOnNegative) {
            setShown(false);
        }
        props.onNegativePress?.(e);
    };

    const onClose = () => {
        setShown(false);
        props.onClose?.();
    };

    useImperativeHandle(dialogRef, () => ({
        show: () => {
            setShown(true);
        },
        hide: () => {
            setShown(false);
        },
    }));

    const container = classNames(styles.dialog_container, props.containerClassName);
    const className = classNames(styles.dialog, props.className);
    return (
        <MuiDialog
            className={container}
            classes={{
                paper: className,
            }}
            open={shown}
            onClose={onClose}>
            {header && <div className={styles.header}>
                <span>{header}</span>
                <div/>
                </div>}
            {(children || error) && (
                <DialogContent className={styles.content}>
                    {children}
                    <ErrorBlock error={error} />
                </DialogContent>
            )}
            {(positiveText || negativeText || neutralText) && (
                <DialogActions className={styles.buttons}>
                    {neutralText ? (
                        <Button {...neutralButtonProps} onClick={onNeutralPress} plain disabled={loading}>
                            {neutralText}
                        </Button>
                    ) : (
                        <div />
                    )}
                    {(positiveText || negativeText) && (
                        <div className={styles.buttonsRight}>
                            {negativeText && (
                                <Button {...negativeButtonProps}
                                        plain
                                        onClick={onNegativePress} disabled={loading}>
                                    {negativeText}
                                </Button>
                            )}
                            {positiveText && (
                                <Button {...positiveButtonProps} onClick={onPositivePress} loading={loading}>
                                    {positiveText}
                                </Button>
                            )}
                        </div>
                    )}
                </DialogActions>
            )}
        </MuiDialog>
    );
}
