import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from "formik";
import React from "react";
import classNames from "classnames";
import styles from "../../routes/dashboard/routes/supplierDetail/SupplierDetail.module.scss";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import Button from "../button/Button";
import FormikNumberInput from "../input/FormikNumberInput";
import FormikInput from "../input/FormikInput";
import { FinanceAndLegalValues } from "../../routes/dashboard/routes/supplierDetail/routes/financialAndLegal/FinancialAndLegal";


type OtherInsurancesFieldArrayProps = {
    disabled?: boolean
    otherInsurances?: { name: string, value: string }[];
}

export default function OtherInsurancesFieldArray(props: OtherInsurancesFieldArrayProps) {
    const { disabled } = props;

    const renderInsuranceRow = (insurance: { name: string, value: string }, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <div className={styles.insurance_row}>
            <FormikInput name={`otherInsurances[${index}].name`} label={"Insurance name"}
                         disabled={disabled}
                         inputClassname={classNames(styles.first, styles.half_input)} />
            <FormikNumberInput name={`otherInsurances[${index}].value`} label={"Insurance value"} prefix={"$"}
                               allowNegative={false}
                               disabled={disabled}
                               inputClassname={styles.half_input} />
            {!disabled &&
            <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove other insurance</Button>}
        </div>
    );

    const { values }: FormikProps<FinanceAndLegalValues> = useFormikContext();

    return (
        <FieldArray name={"otherInsurances"}
                    render={(arrayHelpers) => (
                        <>
                            <div className={styles.other_insurance_heading}>
                                <span>Other Insurances (Amount and type)</span>
                                <img src={Tooltip} alt={"Help"}
                                     data-tip={"Specify any other business insurances you hold and the sum of the insurance. For example, commercial vehicle insurance"} />
                            </div>

                            {values?.otherInsurances?.length > 0
                                ? (
                                    <>
                                        {values.otherInsurances.map((insurance, index) => renderInsuranceRow(insurance, index, arrayHelpers))}
                                        {!disabled && (
                                            <div className={styles.add_button}>
                                                <Button plainLink
                                                        onClick={() => arrayHelpers.push({
                                                            name: "",
                                                            value: "",
                                                        })}>+ Add other insurance</Button>
                                            </div>
                                        )}
                                    </>
                                )
                                : (
                                    <>
                                        {disabled ? <span>No other insurances</span> : (
                                            <div className={styles.add_button}>
                                                <Button plainLink
                                                        onClick={() => arrayHelpers.push({
                                                            name: "",
                                                            value: "",
                                                        })}>+ Add other insurance</Button>
                                            </div>
                                        )}
                                    </>
                                )}
                        </>
                    )} />
    );
}