import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styles from "../login/Login.module.scss";
import Logo from "../../../resources/images/svg/amotai-logo-black.svg";
import FormikInput from "../../widgets/input/FormikInput";
import Button from "../../widgets/button/Button";
import useDispatch from "../../../hooks/useDispatch";
import { useHistory } from "react-router-dom";
import useMountEffect from "src/hooks/useMountEffect";
import qs from "../../../util/qs";
import { showError } from "../../../redux/actions/snackbars";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, updatePassword } from "firebase/auth";

type ConfirmEmailValues = {
    password: string,
    confirmPassword: string
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password")], "Passwords don't match")
});

export default function InvitedUser() {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useMountEffect(() => {
        const auth = getAuth();
        if (!isSignInWithEmailLink(auth, window.location.href)) {
            history.push("/");
        }
    });

    //you were missing this function lol
    const onLoginSubmit = async (values: ConfirmEmailValues) => {
        const { email } = qs.parse(window.location.search);
        setLoading(true);
        const auth = getAuth();
        try {
            const userRecord = await signInWithEmailLink(auth, decodeURIComponent(email), window.location.href);
            updatePassword(userRecord.user, values.password);
            //not sure if you're sending an edm for this
            // dispatch(sendWelcomeEdm());
            history.push("/account");
        } catch (e) {
            dispatch(showError(e.message));
        }
    };

    return (
        <div className={styles.login}>
            <div className={styles.inner}>
                <img className={styles.logo} src={Logo} alt={"Amotai"} />
                <p>You have been invited to access the Amotai Admin panel. Please create a password for your account
                    below.</p>
                <Formik<ConfirmEmailValues> initialValues={{
                    password: "",
                    confirmPassword: ""
                }} onSubmit={onLoginSubmit} validationSchema={validationSchema}>
                    {(formProps) => (
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className={styles.login_form}>
                                <FormikInput name={"password"} label={"Create Password"} type={"password"} />
                                <FormikInput name={"confirmPassword"} label={"Re-enter Password"}
                                    type={"password"} />
                            </div>
                            <div className={styles.button_container}>
                                <Button type={"submit"} loading={loading}>Set password</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}