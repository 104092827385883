import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useSelector from "../../../../../hooks/useSelector";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useDispatch from "../../../../../hooks/useDispatch";
import { getMainCategories } from "../../../../../redux/actions/categories";
import Loading from "../../../loading/Loading";
import Table from "../../../../widgets/table/Table";
import Options from "../../../../widgets/more/Options";
import { Category } from "../../../../../types/model/Category";
import { DialogRef } from "../../../../widgets/dialog/Dialog";
import DeleteCategoryDialog from "../../../../widgets/deleteCategoryDialog/DeleteCategoryDialog";
import Header from "../../../../widgets/header/Header";
import Button from "../../../../widgets/button/Button";
import AddCategoryDialog from "../../../../widgets/addCategoryDialog/AddCategoryDialog";

export default function Categories() {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const categories = useSelector(state => state.categories.main);
    const addDialogRef = useRef<DialogRef>(null);
    const deleteDialogRef = useRef<DialogRef>(null);

    useMountEffect(async () => {
        await dispatch(getMainCategories());
        setLoading(false);
    });

    const onEditPress = (category: Category) => {
        history.push(`/categories/${category.id}`);
    };

    const onDeletePress = (category: Category) => {
        setSelectedCategory(category);
        deleteDialogRef.current?.show();
    };

    const onAddCategoryPress = () => {
        addDialogRef.current?.show();
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Header title={"Categories"} action={<Button onClick={onAddCategoryPress}>Add Category</Button>} />
            <Table columns={[
                { title: "ID", field: "id" },
                { title: "Name", field: "name" },
                {
                    render: s => (
                        <div style={{ textAlign: "right" }}>
                            <Options<Category> data={s}
                                               optionButtons={[
                                                {
                                                    onPress: () => onEditPress(s),
                                                    buttonText: "View/Edit",
                                                },
                                                {
                                                    onPress: () => onDeletePress(s),
                                                    buttonText: "Delete",
                                                }
                                            ]} />
                        </div>

                    )
                }
            ]} data={categories} options={{ pageSize: 50 }} />

            <AddCategoryDialog ref={addDialogRef} />
            <DeleteCategoryDialog ref={deleteDialogRef} category={selectedCategory} />
        </div>
    );
}
