import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Button from "../../widgets/button/Button";
import FormikInput from "../../widgets/input/FormikInput";
import styles from "./Login.module.scss";
import Logo from "../../../resources/images/svg/amotai-logo-black.svg";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

type FormValues = {
    email: string;
    password: string;
};

export const ValidationSchema = Yup.object<FormValues>({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string().required("Required"),
});

export default function Login() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async ({ email, password }: FormValues) => {
        setLoading(true);
        setError(null);
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const onForgotPasswordPress = () => {
        history.push("/forgot-password");
    };

    return (
        <div className={styles.login}>
            <div className={styles.inner}>
                <img className={styles.logo} src={Logo} alt={"Amotai"} />
                <Formik
                    enableReinitialize
                    onSubmit={onSubmit}
                    validationSchema={ValidationSchema}
                    initialValues={{
                        email: "",
                        password: "",
                    }}>
                    {({ dirty, isValid }) => (
                        <Form className={styles.form}>
                            <FormikInput name={"email"} label={"Email"} placeholder={"name@email.com"} />
                            <FormikInput name={"password"} label={"Password"} type={"password"}
                                placeholder={"••••••••"} />
                            {error && <span>{error}</span>}
                            <Button type={"submit"} disabled={!dirty || !isValid} loading={loading}>
                                Login
                            </Button>

                        </Form>
                    )}
                </Formik>
                <Button plainLink disableRipple onClick={onForgotPasswordPress}>
                    Forgot password
                </Button>
            </div>
        </div>
    );
}
