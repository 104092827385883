import Audit from "src/types/model/Audit";
import get from "./util/get";

function getAccountStatusChangeHistory(accountId: number): Promise<Audit[]> {
    return get(`v1/audits/accounts/${accountId}/status`);
}

function getAccountChangeHistory(accountId: number): Promise<Audit[]> {
    return get(`v1/audits/accounts/${accountId}`);
}

function getAllAccountsStatusHistory(): Promise<Audit[]> {
    return get('v1/audits/accounts/status');
}

const AuditAPI = {
    getAccountStatusChangeHistory,
    getAccountChangeHistory,
    getAllAccountsStatusHistory,
};

export default AuditAPI;