import AddressAPI from "./api/address";
import wrapper from "./helpers/wrapper";
import createAction from "./helpers/createAction";

export enum Types {
    SET_SUGGESTION = "address:SET_SUGGESTION"
}

export const setSuggestion = createAction<string>(Types.SET_SUGGESTION);

export const Actions = {
    [Types.SET_SUGGESTION]: setSuggestion
};

export type Actions = typeof Actions;

export const getAddressSuggestion = wrapper(AddressAPI.getAddressSuggestion);
