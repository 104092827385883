import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Button from "../../../../widgets/button/Button";
import SideBarContainer from "../../../../widgets/sideBarContainer/SideBarContainer";
import styles from "./RegionalManagersDetail.module.scss";
import Businesses from "../../../../widgets/account/businesses/Businesses";
import RMDetails from "./routes/details/RMDetails";
import { AccountType } from "src/types/model/AmotaiAccount";

export type BusinessRouteParams = {
    regionalManagerId: string;
}
type RegionalManagerProps = RouteComponentProps<BusinessRouteParams>

export default function RegionalManagersDetail(props: RegionalManagerProps) {
    const { regionalManagerId } = props.match.params;

    return (
        <SideBarContainer className={styles.client_buyer}
            sideBar={(
                <>
                    <Button to={`/users/regional-managers/${regionalManagerId}/suppliers`}>Businesses - Suppliers</Button>
                    <Button to={`/users/regional-managers/${regionalManagerId}/buyers`}>Businesses - Buyers</Button>
                    <Button to={`/users/regional-managers/${regionalManagerId}/details`}>Details</Button>
                </>
            )}>
            <Switch>
                <Route path={"/users/regional-managers/:regionalManagerId/details"} component={RMDetails} />
                <Route exact path={"/users/regional-managers/:regionalManagerId"} component={
                    (props: RouteComponentProps<BusinessRouteParams>) => <Businesses {...props} type={AccountType.SUPPLIER} />
                } />
                <Route path={"/users/regional-managers/:regionalManagerId/suppliers"} component={
                    (props: RouteComponentProps<BusinessRouteParams>) => <Businesses {...props} type={AccountType.SUPPLIER} />
                } />
                <Route path={"/users/regional-managers/:regionalManagerId/buyers"} component={
                    (props: RouteComponentProps<BusinessRouteParams>) => <Businesses {...props} type={AccountType.BUYER_CLIENT} />
                } />
            </Switch>
        </SideBarContainer>
    );
}
