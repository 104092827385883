import { Column } from "material-table";
import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { string } from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import {
    createUser,
    deleteUserFromAccount,
    deleteUserWithoutAccount,
    getRegionalManagers,
    updateUserById
} from "../../../../../redux/actions/users";
import { PagingTableFilter } from "../../../../../redux/reducers/tables";
import { REGIONS_OBJECTS } from "../../../../../util/constants";
import AmotaiUser, { SystemRole, UserStatus } from "../../../../../types/model/AmotaiUser";
import Button from "../../../../widgets/button/Button";
import Header from "../../../../widgets/header/Header";
import PagingTable, { PagingTableRef } from "../../../../widgets/pagingTable/PagingTable";
import styles from "./Users.module.scss";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import { useHistory } from "react-router-dom";
import FormikInput from "src/components/widgets/input/FormikInput";
import FormikSelect from "../../../../widgets/input/FormikSelect";
import useUserRoles from "src/hooks/useUserRoles";

const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter a first name"),
    lastName: Yup.string().required("Please enter a last name"),
    email: Yup.string().trim().email().required("Please enter an email"),
    assignedRegions: Yup.array(string()).required("Select a region"),
});

export default function RegionalManagers() {

    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = useRef<PagingTableRef<AmotaiUser, PagingTableFilter>>(null);
    const editDialog = useRef<DialogRef>(null);
    const delDialog = useRef<DialogRef>(null);
    const { isSuperAdmin: isEligible } = useUserRoles();
    const [isNew, setIsNew] = useState<boolean>(false);
    const [toEdit, setToEdit] = useState<AmotaiUser>();
    const [toDelete, setToDelete] = useState<AmotaiUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const onInvite = useCallback(() => {
        setIsNew(true);
        editDialog.current?.show();
    }, [editDialog]);
    const onView = (user: AmotaiUser) => {
        history.push(`/users/regional-managers/${user.id}`);
    };
    const onCancelEdit = useCallback(() => {
        setToEdit(undefined);
        setError(undefined);
        editDialog.current?.hide();
    }, [setToEdit, editDialog]);

    const onDelete = useCallback((user: AmotaiUser) => {
        setToDelete(user);
        delDialog.current?.show();
    }, [setToDelete, delDialog]);

    const onCancelDelete = useCallback(() => {
        setToDelete(undefined);
        delDialog.current?.hide();
    }, [setToDelete, delDialog]);

    const doDelete = useCallback(async () => {
        if (!toDelete) {
            onCancelDelete();
            return;
        }
        toDelete.status = UserStatus.DISABLED;
        setLoading(true);
        try {
            const accountId = toDelete.account;
            if (!!accountId) {
                await dispatch(deleteUserFromAccount(toDelete.account, toDelete.id));
            } else {
                await dispatch(deleteUserWithoutAccount(toDelete.id));
            }
            setLoading(false);
            onCancelDelete();
            tableRef.current?.refresh();
        } catch (error) {
            console.log("Failed to delete", error);
            setLoading(false);
        }
    }, [toDelete, onCancelDelete, dispatch, setLoading]);

    const handleSubmit = useCallback(async (raw: AmotaiUser) => {
        const data = Object.assign(raw) as AmotaiUser;
        data.name = `${data.firstName} ${data.lastName}`;
        data.systemRole = SystemRole.REGIONAL_MANAGER;
        data.status = UserStatus.PENDING_INDUCTION;
        setLoading(true);
        console.log("Going to create or update user:", data);
        try {
            if (isNew) {
                //create user
                await dispatch(createUser(data));
            } else {
                await dispatch(updateUserById(data.id, data));
            }
            setLoading(false);
            onCancelEdit();
            setError(undefined);
            tableRef.current?.refresh();
        } catch (err) {
            console.log("Failed to create or update a user", err);
            setLoading(false);
            setError(err.message);
        }
    }, [isNew, setLoading, dispatch, onCancelEdit, setError]);

    const getQuery = (filter: PagingTableFilter) => {
        const { cursor, limit } = filter;
        return dispatch(getRegionalManagers(limit, cursor));
    };

    const regions = REGIONS_OBJECTS;

    const columns: Column<AmotaiUser>[] = [
        {
            title: "Name",
            field: "name"
        },
        {
            title: "Email",
            field: "email"
        },
        {
            title: "Region",
            render: u => (
                <div>
                    {(u.assignedRegions || []).map((region) => {
                        return (
                            <span key={`${region}-region`}>
                                {(Object.values(REGIONS_OBJECTS).find(r => r.value === region)?.label || "")}
                                <br />
                            </span>
                        );
                    })}
                </div>
            )
        },
        {
            title: "Account Status",
            field: "status"
        },
        {
            title: "Businesses",
            render: u => u.assignedBusinessCount || 0
        },
        {
            render: u => <Button plainLink className={styles.button} onClick={() => onView(u)}>View/Edit</Button>
        },
        {
            render: u => <Button plainLink className={styles.button} onClick={() => onDelete(u)}>Delete</Button>
        },
    ];
    if (!isEligible) {
        history.replace("/");
    }

    return (
        <div>
            <Header title={"Regional Managers Overview"}
                action={(
                    <Button onClick={onInvite}>Invite User</Button>
                )}
            />
            <PagingTable<AmotaiUser> id="regional_managers"
                columns={columns}
                tableRef={tableRef}
                getData={getQuery}
            />
            <Dialog dialogRef={editDialog} header={!isNew ? "EDIT USER" : "INVITE NEW REGIONAL MANAGER"}
                onClose={onCancelEdit}>
                <Formik<AmotaiUser> initialValues={toEdit ?? {
                    firstName: "",
                    lastName: "",
                    email: "",
                    assignedRegions: [""],
                } as AmotaiUser}
                    validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ dirty, isValid, values }) => (
                        <Form className={styles.form}>
                            <FormikInput name={"firstName"} label={"First name"} required
                                inputClassname={styles.full_input} />
                            <FormikInput name={"lastName"} label={"Last name"} required
                                inputClassname={styles.full_input} />
                            <FormikInput name={"email"} label={"Email"} required inputClassname={styles.full_input} />

                            <FormikSelect name={"assignedRegions"} label={"Regions"}
                                multiple
                                errorStyle={{ color: "red", fontSize: 12, marginLeft: 12 }}
                                options={regions} required />

                            {/*<FormikSelect name={"systemRole"} label={"Permissions"} options={options} required*/}
                            {/*              containerClassName={styles.full_input}/>*/}
                            {/*<FormikSelect name={"status"} label={"Account Status"}*/}
                            {/*              options={[{label: "Active", value: UserStatus.ACTIVE}]} required*/}
                            {/*              containerClassName={styles.full_input}/>*/}


                            <div className={styles.footer}>
                                <Button type={"submit"} disabled={loading || !dirty || !isValid}
                                    loading={loading}>{isNew ? "Invite" : "Save"}</Button>
                                <div />
                                <Button regular plainLink onClick={onCancelEdit} disabled={loading}>Cancel</Button>
                            </div>
                            {!!error && <div className={styles.error}>{error}</div>}
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Dialog dialogRef={delDialog}
                positiveText={"Delete"}
                onPositivePress={doDelete}
                negativeText={"Cancel"}
                onNegativePress={onCancelDelete}
                header={"DELETE USER"}
            >
                <div className={styles.delete_block}>
                    Are you sure you would like to delete this user?
                    This user will be removed from account if exists and will be deleted.
                    The action cannot be undone.
                </div>
            </Dialog>
        </div>
    );
};
