import React, { useState } from "react";
import { InputLabel, Select as SelectMui, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import styles from "./Select.module.scss";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";

export type SelectOption = { name?: string, label?: string, value: any };

type SelectProps = {
    containerClassName?: string,
    label?: string,
    tooltip?: string,
    defaultValue?: string,
    options: Array<string|SelectOption>,
    onChange: (v: string) => void,
    width?: number,
    height?: number,
    noPadding?: boolean,
    mr?: number,
    required?: boolean,
    disabled?: boolean
}
export default function Select(props: SelectProps) {
    const { containerClassName, defaultValue, options, disabled, width, mr, noPadding, label, tooltip, required, height, onChange } = props;

    const [value, setValue] = useState<string | undefined>();
    const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const text = e.target.value as string;
        setValue(text);
        onChange(text);
    };
    const className = classNames(styles.padding, containerClassName, { [styles.noPadding]: noPadding });
    const selectClass = classNames(styles.select);
    return (
        <div className={className} style={{ width, marginRight: mr }}>
            {label && (
                <div className={styles.label_tooltip_container}>

                    <InputLabel shrink htmlFor={"input"}>
                        {label}
                    </InputLabel>
                    {tooltip && (
                        <div data-tip={tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}

                </div>


            )}

            <SelectMui required={required} className={selectClass} onChange={handleChange} value={value ?? defaultValue} disabled={disabled}
                       classes={{ select: selectClass }} style={{ height }} fullWidth>
                {options.map(option => {
                    let v = null;
                    let l = null;
                    if (typeof option === 'string') {
                        v = option;
                        l = option;
                    } else {
                        v = option.value;
                        l = option.label;
                    }
                    return <MenuItem key={`key_${option}`} value={v}>{l}</MenuItem>;
                })}
            </SelectMui>
            <ReactTooltip
            place={"top"}
            effect={"solid"}
            multiline
            backgroundColor={"#F1F1F2"}
            borderColor={"#E6E7E8"}
            textColor={"#000000"}
            clickable
            />
        </div>
    );
}