import React, { forwardRef, Ref, useState, useMemo, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Supplier from "src/types/model/Supplier";
import BuyerClient from "src/types/model/BuyerClient";
import { AccountType } from "src/types/model/AmotaiAccount";
import Dialog, { DialogRef } from "../dialog/Dialog";
import useForwardRef from "../../../hooks/useForwardRef";
import Button from "../button/Button";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import FormikSelect from "../input/FormikSelect";
import { getRegionalManagers, assignAccountsToManager } from "../../../redux/actions/users";
import useSelector from "../../../hooks/useSelector";
import AmotaiUser, { UserStatus } from "src/types/model/AmotaiUser";
import { updateSupplierAssignedTo } from "src/redux/actions/suppliers";
import { updateBuyerAssignedTo } from "src/redux/actions/clientsBuyers";
import useUserRoles from "src/hooks/useUserRoles";

const validationSchema = Yup.object().shape({
    manager: Yup.string().required("Required")
});

type Props = {
    account?: Supplier | BuyerClient,
    onFinish?: (manager: AmotaiUser) => void,
}


function AssignManagerDialog(props: Props, ref: Ref<DialogRef>) {
    const { account, onFinish } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const { isSuperAdmin } = useUserRoles();
    const dispatch = useDispatch();
    const regionalManagers = useSelector(state => state.managers.regionalManagers);

    const managersOptions = useMemo(() => {
        let options: { label: string, value: string }[] = [];
        if (loading) {
            return options;
        }
        options = Object.values(regionalManagers)
            .filter(m => m.status === UserStatus.ACTIVE)
            .map(m => ({ label: m.name ?? m.email, value: m.id })).sort((a, b) => a.label.localeCompare(b.label));
        return options;

    }, [regionalManagers, loading]);

    useEffect(() => {
        (async () => {
            try {
                if (isSuperAdmin && Object.keys(regionalManagers).length === 0) {
                    setLoading(true);
                    await dispatch(getRegionalManagers(5000));
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                dispatch(showError(error.message));
            }
        })();
        //eslint-disable-next-line
    }, [])

    const onSubmit = async (values: { manager: string }) => {
        if (!account) {
            return;
        }
        try {
            setLoading(true);
            const { manager } = await dispatch(assignAccountsToManager(values.manager, [account.id]));
            const accountId = account.id;
            const managerId = manager.id;
            if (account.type === AccountType.SUPPLIER) {
                dispatch(updateSupplierAssignedTo({ accountId, managerId }));
            } else {
                dispatch(updateBuyerAssignedTo({ accountId, managerId }));
            }
            onFinish?.(manager);
            setLoading(false);
            dialogRef.current?.hide();
        } catch (e) {
            console.log(values, account.id);
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    return (
        <Dialog
            dialogRef={dialogRef}
            header={account?.type === AccountType.SUPPLIER ? 'Assign Regional Manager' : 'Buyer Manager'}
            disableBackdropClick={loading}
            loading={loading}
        >
            <Formik initialValues={{ manager: "" }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {(formikBag) => (
                    <form onSubmit={formikBag.handleSubmit}>
                        <FormikSelect
                            name={"manager"}
                            label={account?.type === AccountType.SUPPLIER ? "Select Regional Manager" : 'Select Buyer Manager'}
                            options={managersOptions}
                        />
                        <Button style={{ marginTop: "27px", width: "100%" }} fullWidth type={"submit"}
                            loading={loading}>Save</Button>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
}

export default forwardRef(AssignManagerDialog);