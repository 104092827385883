import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Button from 'src/components/widgets/button/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styles from './RegionSuppliers.module.scss';
import Select from 'src/components/widgets/input/Select';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getAllRegions, getRegionSuppliers } from 'src/redux/actions/suppliers';
import { Column } from 'material-table';
import Supplier from 'src/types/model/Supplier';
import Loading from 'src/components/routes/loading/Loading';
import PagingTable, { PagingTableRef } from 'src/components/widgets/pagingTable/PagingTable';
import { PagingTableFilter } from 'src/redux/reducers/tables';
import ListResult from 'src/types/ListResult';
import { RegionAccounts } from 'src/types/model/AmotaiAccount';

const columns:Column<Supplier>[] = [
    {
        title: "Business Name",
        field: "name"
    },
    {
        title: "Primary Contact",
        field: "mainContact.name"
    },
    {
        title: "Location",
        field: "physicalAddressComponents.formattedAddress"
    },
    {
        render: s => <Button plainLink to={`/suppliers/${s.id}`}>View</Button>
    }
];

export default function RegionSuppliers(props: RouteComponentProps<{region: string}>) {
    const history = useHistory();
    const dispatch = useDispatch();
    const regionSuppliersMap = useSelector(state => state.suppliers.regionSuppliers);
    const [regionSuppliers, setRegionSuppliers] = useState<RegionAccounts[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const selectedRegion = props.match.params.region;
    const tableRef = useRef<PagingTableRef<Supplier>>(null);

    useEffect(() => {
        (async() => {
            try {
                if (!regionSuppliersMap) {
                    const regionSuppliers = await dispatch(getAllRegions());
                    setRegionSuppliers(regionSuppliers);
                } else {
                    setRegionSuppliers(Object.values(regionSuppliersMap));
                }
            } catch (error) {
                console.log('ERROR:', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, regionSuppliersMap]);

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef, selectedRegion]);
    
    const allRegions = useMemo(() => regionSuppliers.map(rs => rs.region), [regionSuppliers]);

    const onRegionChange = useCallback((region:string) => {
        history.replace(`/dashboard/suppliers/${region}`);
    }, [history]);

    const getData = (queryFilter: PagingTableFilter): Promise<ListResult<Supplier>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getRegionSuppliers(selectedRegion, limit, cursor))
                .then(d => Promise.resolve(d.data))
                .catch(Promise.reject);
    };

    if (loading) {
        return <Loading />;
    }

    return <div className={styles.container}>
        <Button plainLink onClick={() => history.goBack()}><ChevronLeftIcon />Back to Suppliers</Button>
        <Select containerClassName={styles.select} label="Region" options={allRegions} onChange={onRegionChange} defaultValue={selectedRegion} />

        <PagingTable<Supplier, PagingTableFilter>
                id={"suppliers"}
                tableRef={tableRef}
                columns={columns}
                getData={getData} />
    </div>;
}