import React, { useCallback, useMemo, useState, ReactNode, KeyboardEvent } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate, useSlate, RenderElementProps, RenderLeafProps, ReactEditor } from "slate-react";
import { createEditor, Editor, Transforms, Node } from "slate";
import { withHistory } from "slate-history";
import Box from "@material-ui/core/Box";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import CodeIcon from "@material-ui/icons/Code";
import H1Icon from "@material-ui/icons/LooksOne";
import H2Icon from "@material-ui/icons/LooksTwo";
import H3Icon from '@material-ui/icons/Looks3';
import H4Icon from '@material-ui/icons/Looks4';
import H5Icon from '@material-ui/icons/Looks5';
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Divider from "@material-ui/core/Divider";

const HOTKEYS: any = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code"
};
type Props = {
    defaultValue?: string,
    onValueChange: (value: string) => void
}
const initValue = [{ type: "paragraph", children: [{ text: "" }] }];

enum Format {
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
    code = 'code',
    quote = 'quote',
    numbered = 'numbered',
    bulleted = 'bulleted',
    list = 'list',
    heading1 = 'heading1',
    heading2 = 'heading2',
    heading3 = 'heading3',
    heading4 = 'heading4',
    heading5 = 'heading5',
}

export default function RichEditor({ defaultValue, onValueChange }: Props) {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const [value, setValue] = useState<Node[]>(!!defaultValue ? deserialize(defaultValue) : initValue);

    const onChange = (value: Node[]) => {
        setValue(value);
        onValueChange(serialize(value));
    };
    return (
        <Box p={1} m={2} border={1} borderColor="grey.500" borderRadius={4}>
            <Slate editor={editor}
                value={value}
                onChange={onChange} >
                <Toolbar>
                    <MarkButton format={Format.bold}>
                        <FormatBoldIcon />
                    </MarkButton>
                    <MarkButton format={Format.italic}>
                        <FormatItalicIcon />
                    </MarkButton>
                    <MarkButton format={Format.underline}>
                        <FormatUnderlinedIcon />
                    </MarkButton>
                    <MarkButton format={Format.code}>
                        <CodeIcon />
                    </MarkButton>
                    <BlockButton format={Format.heading1}>
                        <H1Icon />
                    </BlockButton>
                    <BlockButton format={Format.heading2}>
                        <H2Icon />
                    </BlockButton>
                    <BlockButton format={Format.heading3}>
                        <H3Icon />
                    </BlockButton>
                    <BlockButton format={Format.heading4}>
                        <H4Icon />
                    </BlockButton>
                    <BlockButton format={Format.heading5}>
                        <H5Icon />
                    </BlockButton>
                    <BlockButton format={Format.quote}>
                        <FormatQuoteIcon />
                    </BlockButton>
                    <BlockButton format={Format.numbered}>
                        <FormatListNumberedIcon />
                    </BlockButton>
                    <BlockButton format={Format.bulleted}>
                        <FormatListBulletedIcon />
                    </BlockButton>
                </Toolbar>
                <Box pl={1}>
                    <Editable renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder="Start from here…"
                        spellCheck
                        autoFocus
                        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                            for (const hotkey in HOTKEYS) {
                                //@ts-ignore
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault();
                                    const mark = HOTKEYS[hotkey];
                                    toggleMark(editor, mark);
                                }
                            }
                        }} />
                </Box>
            </Slate>
        </Box>
    );
};

export const Element = ({ attributes, children, element }: RenderElementProps) => {
    switch (element.type) {
        case Format.quote:
            return <blockquote {...attributes}>{children}</blockquote>;
        case Format.bulleted:
            return <ul {...attributes}>{children}</ul>;
        case Format.numbered:
            return <ol {...attributes}>{children}</ol>;
        case Format.list:
            return <li {...attributes}>{children}</li>;
        case Format.heading1:
            return <h1 {...attributes}>{children}</h1>;
        case Format.heading2:
            return <h2 {...attributes}>{children}</h2>;
        case Format.heading3:
            return <h3 {...attributes}>{children}</h3>;
        case Format.heading4:
            return <h4 {...attributes}>{children}</h4>;
        case Format.heading5:
            return <h5 {...attributes}>{children}</h5>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.code) {
        children = <code>{children}</code>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, children }: { format: Format, children: ReactNode }) => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <ToggleButton
                value={format}
                selected={isBlockActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleBlock(editor, format);
                }}
                style={{ lineHeight: 1 }}
            >
                {children}
            </ToggleButton>
        </Box>
    );
};

const MarkButton = ({ format, children }: { format: Format, children: ReactNode }) => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <ToggleButton
                value={format}
                selected={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}
                style={{ lineHeight: 1 }}
            >
                {children}
            </ToggleButton>
        </Box>
    );
};

const Toolbar = ({ children }: { children: ReactNode }) => (<>
    <Box display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap" >
        {children}
    </Box>
    <Box pt={2}>
        <Divider variant="middle" />
    </Box>
</>);

const LIST_TYPES = [Format.numbered, Format.bulleted];

const isBlockActive = (editor: ReactEditor, format: Format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format
    });
    return !!match;
};

const isMarkActive = (editor: ReactEditor, format: Format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const toggleBlock = (editor: ReactEditor, format: Format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type as Format),
        split: true
    });

    Transforms.setNodes(editor, {
        // eslint-disable-next-line no-nested-ternary
        type: isActive ? "paragraph" : isList ? Format.list : format
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: ReactEditor, format: Format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};
const serialize = (value: Node[]) => {
    return JSON.stringify(value);
};

const deserialize = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (error) {
        return initValue;
    }
};