import moment from "moment/moment";

export const REGIONS = [
    "Nationwide",
    "Te Tai Tokerau - Northland",
    "Tāmaki-makau-rau - Auckland",
    "Waikato",
    "Te Moana-a-Toi - Bay of Plenty",
    "Te Matau-a-Māui - Hawkes Bay",
    "Te Whanga-nui-a-Tara - Wellington",
    "Te Tai-o-Aorere - Tasman",
    "Ōtākou - Otago",
    "Te Tai Poutini - West coast",
    "Waitaha - Canterbury",
    "Murihiku - Southland",
    "Te Tauihu-o-te-waka - Marlborough",
    "Te Tai Rāwhiti - Gisborne",
    "Taranaki",
    "Manawatū-Whanganui",
    "Whakatū - Nelson",
]
    .sort((a, b) => a.localeCompare(b));

export const REGIONS_NO_NATIONWIDE = [
    "Te Tai Tokerau - Northland",
    "Tāmaki-makau-rau - Auckland",
    "Waikato",
    "Te Moana-a-Toi - Bay of Plenty",
    "Te Matau-a-Māui - Hawkes Bay",
    "Te Whanga-nui-a-Tara - Wellington",
    "Te Tai-o-Aorere - Tasman",
    "Ōtākou - Otago",
    "Te Tai Poutini - West coast",
    "Waitaha - Canterbury",
    "Murihiku - Southland",
    "Te Tauihu-o-te-waka - Marlborough",
    "Te Tai Rāwhiti - Gisborne",
    "Taranaki",
    "Manawatū-Whanganui",
    "Whakatū - Nelson",
]
    .sort((a, b) => a.localeCompare(b));

export const REGIONS_OBJECTS = [
    {
        label: "Nationwide",
        value: "nationwide"
    },
    {
        label: "Te Tai Tokerau - Northland",
        value: "northland"
    },
    {
        label: "Tāmaki-makau-rau - Auckland",
        value: "auckland"
    },
    {
        label: "Waikato",
        value: "waikato"
    },
    {
        label: "Te Moana-a-Toi - Bay of Plenty",
        value: "bay_of_plenty"
    },
    {
        label: "Te Matau-a-Māui - Hawkes Bay",
        value: "hawkes_bay"
    },
    {
        label: "Te Tai-o-Aorere - Tasman",
        value: "tasman"
    },
    {
        label: "Ōtākou - Otago",
        value: "otago"
    },
    {
        label: "Te Tai Poutini - West coast",
        value: "west_coast"
    },
    {
        label: "Waitaha - Canterbury",
        value: "canterbury"
    },
    {
        label: "Murihiku - Southland",
        value: "southland"
    },
    {
        label: "Te Tauihu-o-te-waka - Marlborough",
        value: "marlborough"
    },
    {
        label: "Te Tai Rāwhiti - Gisborne",
        value: "gisborne"
    },
    {
        label: "Taranaki",
        value: "taranaki"
    },
    {
        label: "Manawatū-Whanganui",
        value: "manawatū-whanganui"
    },
    {
        label: "Whakatū - Nelson",
        value: "nelson"
    },
];


export const PASIFIKA_GROUPS = [
    "None",
    "Cook Islands",
    "Fiji",
    "Hawaii",
    "Nauru",
    "Niue",
    "Samoa",
    "Tokelau",
    "Tonga",
    "Tuvalu",
    "Kiribati",
    "Micronesia",
    "Papua New Guinea",
    "Tahiti",
    "Vanuatu",
    "Solomon Islands",
    "Small island states of Micronesia",
];


export const IWI_GROUPS = [
    "Kāti Māmoe",
    "Maungaharuru Tangitū",
    "Moriori",
    "Muaūpoko",
    "Ngā Pōtiki ā Tamapahore",
    "Ngā Rauru",
    "Ngā Ruahine",
    "Ngāi Tahu / Kāi Tahu",
    "Ngāi Tai (Tauranga Moana/Mātaatua)",
    "Ngāi Tai ki Tāmaki",
    "Ngāi Takoto",
    "Ngāi Tāmanuhiri",
    "Ngāi Te Ohuake (Rangitīkei)",
    "Ngāpuhi",
    "Ngāpuhi ki Whaingaroa-Ngāti Kahu ki Whaingaroa",
    "Ngāti Apa (Rangitīkei)",
    "Ngāi Te Rangi",
    "Ngāti Apa ki Te Rā Tō",
    "Ngāti Awa",
    "Ngāti Hako",
    "Ngāti Haua (Taumarunui)",
    "Ngāti Haua (Waikato)",
    "Ngāti Hauiti (Rangitīkei",
    "Ngāti Hei",
    "Ngāti Hīkairo",
    "Ngāti Hine (Te Tai Tokerau)",
    "Ngāti Hineuru",
    "Ngāti Ira",
    "Ngāti Kahu",
    "Ngāti Kahungunu ki Heretaunga",
    "Ngāti Kahungunu ki Tamakinui a Rua",
    "Ngāti Kahungunu ki Tamatea",
    "Ngāti Kahungunu ki Te Wairoa",
    "Ngāti Kahungunu ki Te Whanganui-a-Orotu",
    "Ngāti Kahungunu ki Wairarapa",
    "Ngāti Kauwhata",
    "Ngāti Kearoa / Ngāti Tuarā",
    "Ngāti Koata",
    "Ngāti Korokī Kahukura",
    "Ngāti Kuia",
    "Ngāti Kurī",
    "Ngāti Mākino",
    "Ngāti Manawa",
    "Ngāti Maniapoto",
    "Ngāti Manuhiri",
    "Ngāti Maru (Hauraki)",
    "Ngāti Maru (Taranaki)",
    "Ngāti Mutunga (Taranaki)",
    "Ngāti Mutunga (Wharekauri/Chatham Islands)",
    "Ngāti Pāhauwera",
    "Ngāti Paoa",
    "Ngāti Pikiao (Te Arawa)",
    "Ngāti Porou",
    "Ngāti Porou ki Harataunga ki Mataora",
    "Ngāti Pūkenga",
    "Ngāti Pūkenga ki Waiau",
    "Ngāti Rāhiri Tumutumu",
    "Ngāti Rākaipaaka",
    "Ngāti Rangi (Ruapehu, Whanganui)",
    "Ngāti Ranginui",
    "Ngāti Rangiteaorere (Te Arawa)",
    "Ngāti Rangitihi (Te Arawa)",
    "Ngāti Rangiwewehi (Te Arawa)",
    "Ngāti Rārua",
    "Ngāti Raukawa (Horowhenua/Manawatū)",
    "Ngati Rehua",
    "Ngāti Rongomai (Te Arawa)",
    "Ngāti Ruanui",
    "Ngāti Ruapani ki Waikaremoana",
    "Ngāti Tahu-Ngāti Whaoa (Te Arawa)",
    "Ngāti Tama (Taranaki)",
    "Ngāti Tama (Te Waipounamu/South Island)",
    "Ngāti Tama ki Te Upoko o Te Ika (Te Whanganui-a-Tara/Wellington)",
    "Ngāti Tamakōpiri (Rangitīkei)",
    "Ngāti Tamaoho",
    "Ngāti Tamaterā",
    "Ngāti Tara Tokanui",
    "Ngāti Tarāwhai (Te Arawa)",
    "Ngāti Te Ata",
    "Ngāti Tiipa",
    "Ngāti Toarangatira (Te Waipounamu/South Island)",
    "Ngāti Toarangatira (Te Whanganui-a-Tara/Wellington)",
    "Ngāti Tukorehe",
    "Ngāti Tūwharetoa (ki Taupō)",
    "Ngāti Tūwharetoa ki Kawerau",
    "Ngāti Wai",
    "Ngāti Whakaue (Te Arawa)",
    "Ngāti Whanaunga",
    "Ngāti Whare",
    "Ngāti Whātua (not Ōrākei or Kaipara)",
    "Ngāti Whātua o Kaipara",
    "Ngāti Whātua o Ōrākei",
    "Ngāti Whitikaupeka (Rangitīkei)",
    "Pakakohi",
    "Patukirikiri",
    "Rangitāne (Manawatū)",
    "Rangitāne (Te Matau-a-Māui/Hawke's Bay/Wairarapa)",
    "Rangitāne (Te Waipounamu/South Island",
    "Rangitāne o Tamaki nui ā Rua",
    "Raukawa (Waikato)",
    "Rereahu",
    "Rongomaiwahine (Te Māhia)",
    "Rongowhakaata",
    "Tamahaki (Ruapehu, Waimarino)",
    "Tamakana (Ruapehu, Waimarino)",
    "Tangāhoe",
    "Tapuika (Te Arawa)",
    "Taranaki",
    "Te Aitanga ā Hauiti",
    "Te Aitanga-a-Māhaki",
    "Te Ākitai-Waiohua",
    "Te Ati Haunui-a-Pāpārangi",
    "Te Atiawa (Taranaki",
    "Te Atiawa (Te Waipounamu/South Island)",
    "Te Atiawa (Te Whanganui-a-Tara/Wellington)",
    "Te Atiawa ki Whakarongotai",
    "Te Aupōuri",
    "Te Hika o Pāpāuma",
    "Te Kawerau ā Maki",
    "Te Paatu",
    "Te Rarawa",
    "Te Roroa",
    "Te Upokorehe",
    "Te Uri-o-Hau",
    "Te Whānau-ā-Apanui",
    "Te Whanau a Kai",
    "Tūhoe",
    "Tūhourangi (Te Arawa)",
    "Uenuku (Ruapehu, Waimarino)",
    "Uenuku-Kōpako (Te Arawa)",
    "Waikato Tainui",
    "Waitaha (Te Arawa)",
    "Waitaha (Te Waipounamu/South Island)",
    "Whakatōhea",
];

export const SUPPLIER_TURNOVER = [
    "Under $200k",
    "$200-$500k",
    "$500k-$800k",
    "$800k-$1m",
    "$1m-$2m",
    "$2m-$5m",
    "$5m-$10m",
    "$10m-$15m",
    "$15m-$20m",
    "$20m+",
];

export const BUYER_TURNOVER = [
    "<$10m",
    "$10 - 25m",
    "$25 - 50m",
    "$50 - 100m",
    "$100 - 200m",
    "$200 - 400m",
    "$400 - $600m",
    "$600m - $1b",
    "$1b+",
];

export const INSURANCES = [
    "$2 million or less",
    "$5 million",
    "$5-10 million",
    "$10 million plus",
    "None"
];

export const HEALTH_AND_SAFETY = [
    "None",
    "Impac",
    "Sitesafe",
    "ISNetworld",
    "ISO 45001",
    "OHSAS 18001",
    "SiteWise",
    "SHE",
    "Tōtika",
    "Telarc ISO 45001",
    "Other"
];

export const CONSTRUCTION_CATEGORIES = {
    "Carpentry/ Building": [
        "Block work",
        "Carpentry",
        "Carpentry BWIC",
        "Carpentry external",
        "Carpentry internal",
        "Carpentry - Trims",
        "Ceilings",
        "Grid suspended ceilings",
        "Paneltec cladding and soffits",
        "Raised floors",
        "Specialist cladding",
        "Specialist exterior cladding",
        "Stairs",
        "Steel stud",
        "Suspended ceilings",
    ],
    "Concrete Works": [
        "Beams",
        "Cladding panels",
        "Concrete cutting",
        "Concrete placing & finishing",
        "Concrete pumping",
        "Concrete work",
        "Floors",
        "Panel grouting",
        "Piling",
        "Precast concrete",
        "Precast installation",
        "Precast placement",
        "Precast sundries",
        "Precast supply",
        "Proprietary flooring - Comflor",
        "Proprietary flooring  - Precast",
        "Stairs",
        "Structural panels",
    ],
    "Earthworks & Drainage": [
        "Drainage",
        "Drain laying",
        "Excavation",
        "Earthworks & Filling",
    ],
    "Electrical Services": [
        "Appliances",
        "CCTV (Security)",
        "Data",
        "Electrical Services",
        "Lifts",
        "Telecommunications",
    ],
    "Environmental Services & Landscaping": [
        "Environmental services",
        "Greenwall",
        "Hard landscaping",
        "Soft landscaping",
        "Tree care",
    ],
    "Equipment, Labour & Service Hire": [
        "Access Equipment",
        "Crane Crew",
        "Crane Hire",
        "Fall Arrest",
        "Halt Master Traffic Management",
        "Hi-ab Transport",
        "Hoist Hire",
        "Labour Hire",
        "Mechanical",
        "Monkeytoe walkways, ladders, etc",
        "P&G - Survey",
        "Roof Safety Harness / Anchors",
        "Scaffolding",
        "Scaffolding Erection",
        "Security",
        "Shoreload Propping",
        "Surveying",
        "Traffic Management",
    ],
    "Fire Protection": [
        "Fire Protection",
        "Fire Stopping",
    ],
    "Fit-outs": [
        "Bathroom Pods",
        "Commercial Kitchen",
        "Operable Walls",
        "Proprietary Partitions",
        "WC Partitions",
    ],
    "Flooring": [
        "Carpet/Carpet tiles",
        "Floor Coverings",
        "Tiling & Stone",
        "Vinyl",
    ],
    "Glazing": [
        "Façade - Cladding/ Glazing Continued",
        "Façade - Glazing",
        "Glazing",
    ],
    "Hardware": [
        "Balustrades / Juralco Handrails",
        "Blinds & Curtains",
        "Hardware",
        "Hardware installation",
        "Roller Shutter Doors & Garage Doors",
    ],
    "Insulation": [
        "Insulation",
        "Insulation installation",
    ],
    "Joinery": [
        "Curtain Wall / Aluminium Doors & Windows",
        "Doors & Roller Doors",
        "Expansion (seismic) joints",
        "Joinery",
        "Joinery doors",
        "Timber Doors & Frame Supply",
    ],
    "Painting, GIB Fixing & Plastering": [
        "Intumescent Painting",
        "Painting",
        "Plastering",
        "Plasterboard Linings",
        "Solid Plaster",
        "Waterproofing",
        "Weatherline Gib RAB",
    ],
    "Plumbing & Gas Fitting": [
        "Gas Fitting",
        "Plumbing",
    ],
    "Roading Treatments, Services & Accessories": [
        "Asphalte Waterproofing",
        "Linemarking",
        "Mastic asphalting and similar treatments",
        "Signage",
        "Wheelstops, corner protection",
    ],
    "Roofing": [
        "Roofing - Warm Roof",
        "Membrane Roofing",
    ],
    "Siteworks Paving & Accessories": [
        "Entry mats",
        "Coloured concrete",
        "Concrete grinding, polishing, sealing",
        "Nosings",
        "Tactile indicators",
        "Siteworks Paving",
        "Specialist Concrete Finishes",
    ],
    "Steel & Metal Works": [
        "Reinforcing Steel",
        "Reo - Fix Only",
        "Rock Anchors",
        "Site Welding",
        "Structural Steelwork",
        "Tanking",
        "Metalwork",
        "Metal Decking",
        "Metal Roof & Wall Cladding",
    ],
};

export const CATEGORIES = {
    "Construction": { ...CONSTRUCTION_CATEGORIES },
    "Construction Consultancy Services": [
        "Construction Consultancy",
        "Building & property",
        "Inspections",
        "Project Management",
        "Tendering/ Bid Management",
        "Procurement",
        "Business Performance",
        "Project Risk Assessment",
        "Health & Safety",
    ],
    "Design": [
        "Architectural Design",
        "Cultural Intelligence",
    ],
    "Digital Content, Media & Marketing": [
        "Marketing, Advertising & Sales",
        "Digital Marketing",
        "Strategy",
        "Developing Campaigns",
        "Production/ Content (Design, Video & Photography)",
        "PR & Media",
        "Creative Services (Design)",
        "Television Production",
        "Journalism",
        "Articles",
    ],
    "Environmental": [
        "Resource Management",
        "Environmental project planning",
    ],
    "Event Management": [
        "Conference & Events"
    ],
    "Finance & Insurance": [
        "Finance/ Accounting",
        "Credit Management & Budgeting",
        "Financial Strategic Business Planing",
        "Financial Business Coaching & Training",
        "Financing",
        "Insurance",
    ],
    "Food & Accommodation": [
        "Food",
        "Beverage",
        "Catering",
        "Venue & Accommodation",
    ],
    "IT & Technology": [
        "Software Development",
        "IT",
        "Websites",
        "Applications",
        "IT Hardware",
        "Communications & Internet",
        "Data & Security",
    ],
    "Law, Employment & Training": [
        "Talent Recruitment",
        "Employment",
        "Education, Training & Development",
        "Youth",
        "Business Training",
        "Law",
        "Policy",
    ],
    "Retail, Sales & Manufacturing": [
        "Furniture",
        "Sculptures",
        "Plants & Floral Products",
        "Arts & Crafts",
        "Gifts",
        "Apparel",
        "Embroidering",
        "Promotional Products",
        "PPE",
        "Electronics",
        "Household Products",
        "Health & Beauty",
        "Hardware",
        "Transport",
        "Manufacturing",
    ],
    "Services": [
        "Real Estate",
        "Heath Care & Health Care Consultancy",
    ],
    "Tourism": [
        "Guided Tours & experiences",
        "Travel Agency",
    ],
    "Transport & Warehousing": [
        "Relocation",
        "Transport & Deliveries",
        "Transport Hire",
        "Transport & Equipment Repairs",
    ],
};

export const LEGAL_STRUCTURE = [
    "Limited Liability Company",
    "Partnership",
    "Sole Trader",
    "Trust",
    "Charitable Trust",
    "Incorporated Society",
    "Local Government Entity",
    "Central Government Agency"
];

export const ANNUAL_ADDRESSABLE_SPEND = [
    "<$10m",
    "$10-25m",
    "$25-50m",
    "$50-75m",
    "$75-100m",
    "$100-200m",
    "$200-400m",
    "$400-$500m",
    "$500m+",
];

export const ANNUAL_INDIRECT_SPEND = [
    "<$5m",
    "$5-10m",
    "$10-20m",
    "$20-50m",
    "$50-75m",
    "$75-100m",
    "$100-200m",
    "$200-500m",
    "$500m+",
];

export const GENDER_OPTIONS = [
    "Male",
    "Female",
    'Gender Diverse',
    "Prefer not to say"
];


export enum ACCOUNT_ROLE {
    USER = "USER",
    ADMIN = "ADMIN"
}

export const YearOptions = () => {
    const years = [];
    for (let i = moment().year(); i > 1800; i--) {
        years.push(i.toString());
    }
    return years;
};

export const PageSizeOptions = [5, 10, 25, 50, 100, 500];