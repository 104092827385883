/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import qsLib from "qs";

const qs = {
    stringify: (params: any): string => qsLib.stringify(params, { arrayFormat: "comma", skipNulls: true, }),
    parse: (search: string): any => qsLib.parse(search, { ignoreQueryPrefix: true }),
};

export default qs;
