import classnames from 'classnames';
import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./Header.module.scss";

export type HeaderProps = PropsWithChildren<{
    title: string;
    subtitle?: string;
    action?: ReactNode;
    large?: boolean;
    subnodes?: ReactNode;
    containerClass?: string;
}>;

export default function Header(props: HeaderProps) {
    const { title, subtitle, action, subnodes, containerClass } = props;


    return (
        <div className={classnames(styles.container, containerClass)}>
            <div className={styles.header}>
                <div className={styles.content}>
                    <h3>{title}</h3>
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
                <div className={styles.action}>{action}</div>
            </div>
            <div>{subnodes}</div>
        </div>
    );
}
