import { InputLabel } from '@material-ui/core';
import classNames from "classnames";
import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import { SelectOption } from './Select';
import styles from './Select.module.scss';

type Props = {
    label?: string,
    tooltip?: string,
    options: Array<SelectOption | string>,
    onChange: (v: string) => void,
    disabled?: boolean,
    containerClassName?: string,
    defaultValue?: string,
    value: string | SelectOption,
    placeholder?: string,
}
export default function InputSelect(props: Props) {
    const { options, label, tooltip, onChange, disabled, containerClassName, defaultValue, value, placeholder } = props;
    const handleChange = (option: SelectOption) => {
        console.log('selected', option.value);
        onChange?.(option.value);
    };
    const selectOptions = useMemo(() => {
        return options.map(o => typeof o === 'string' ? { label: o, value: o } : o);
    }, [options]);

    const selectedValue = useMemo(() => {
        if (typeof value === 'string') {
            return selectOptions.find(o => o.value === value);
        }
        return value; //return option type or undefined if not string type
    }, [value, selectOptions]);

    return (
        <div className={classNames(styles.padding, containerClassName)}>
            {label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {label}
                    </InputLabel>
                    {tooltip && (
                        <div data-tip={tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}
            <ReactSelect
                placeholder={placeholder || 'Start typing or select from drop-down'}
                options={selectOptions}
                onChange={handleChange}
                isDisabled={disabled}
                defaultInputValue={defaultValue}
                value={selectedValue ?? value}
            />
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );
}