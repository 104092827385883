import React, { forwardRef, Ref, useState } from "react";
import AmotaiUser from "src/types/model/AmotaiUser";
import Dialog, { DialogRef } from "../Dialog";
import styles from "./AccountUserDialog.module.scss";
import Button from "../../button/Button";
import useForwardRef from "../../../../hooks/useForwardRef";
import useDispatch from "../../../../hooks/useDispatch";
import { deleteUserFromAccount } from "../../../../redux/actions/users";
import { showError } from "../../../../redux/actions/snackbars";

type DeleteAccountUserDialogProps = {
    user: AmotaiUser | null;
    accountId: number;
    onFinish?: () => void;
    onCancelClick?: () => void;
};

function DeleteAccountUserDialog(props: DeleteAccountUserDialogProps, ref: Ref<DialogRef>) {
    const { user, accountId, onFinish } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onSubmit = async () => {
        setLoading(true);

        if (user) {
            try {
                await dispatch(deleteUserFromAccount(accountId, user.id));
            } catch (e) {
                dispatch(showError(e.message));
            }
        }

        onFinish?.();

        setLoading(false);
        dialogRef.current?.hide();
    };

    const onCancelClick = () => {
        if (props.onCancelClick) {
            props.onCancelClick();
        }
        dialogRef.current?.hide();
    };

    return (
        <Dialog dialogRef={dialogRef} disableBackdropClick={loading}
                header={"DELETE USER?"}>
            <div className={styles.dialog_form}>
                Are you sure you would like to delete <b>{user?.firstName}</b>? <br />This action cannot be
                undone.
                <div className={styles.dialog_footer}>
                    <Button onClick={onSubmit}
                            loading={loading}>Delete</Button>
                    <span />
                    <Button plainLink onClick={onCancelClick} disabled={loading}>Cancel</Button>

                </div>
            </div>

        </Dialog>
    );
}

export default forwardRef(DeleteAccountUserDialog);
