import React, { useCallback, useEffect, ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import { InputLabel, MenuItem, Select as SelectMui, SelectProps, Checkbox, ListItemText } from "@material-ui/core";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "./Select.module.scss";
import { SelectOption } from "./Select";

type InputProps = {
    name: string;
    onChange?: (e: React.ChangeEvent) => void;
    onClose?: (e: React.ChangeEvent<{}>) => void;
    options: Array<string | SelectOption>;
    label?: string;
    defaultValue?: string;
    containerClassName?: string;
    tooltip?: string;
    multiple?: boolean;
    placeholder?: string;
    errorStyle?: object;
    noPadding?: boolean;
    width?: number;
    mr?: string;
    height?: number;
} & Omit<SelectProps, "name" | "value" | "error" | "onChange" | "onClose">;

export default function FormikSelect(props: InputProps) {
    const { name, options, multiple, placeholder, errorStyle, defaultValue, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta, helper] = fieldProps;
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = props.disabled || isSubmitting;
    const error = showError ? fieldError : null;

    useEffect(() => {
        if (!field.value && defaultValue) {
            helper.setValue(defaultValue);
        }
    }, [field.value, defaultValue, helper]);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (multiple && e.target) {
                const value = e.target.value as unknown as any[];
                //@ts-ignore
                e.target.value = value.filter(v => v !== '');
            }
            field.onChange(e);
            props.onChange?.(e);
        },
        [field, props.onChange, multiple],
    );

    const className = classNames(styles.padding, otherProps.containerClassName, { [styles.noPadding]: otherProps.noPadding });
    const selectClass = classNames(styles.select);

    const renderValue = useCallback(
        (value: string | string[]): ReactNode => {
            if (!multiple) {
                const t = options.find(o => typeof o === 'string' ? value === o : String(value) === o.value);
                return typeof t === 'string' ? t : (t?.name ?? t?.label);
            }
            const r = (value as string[])
                .map(v => {
                    const opt = options.find(o => typeof o === 'string' ? v === o : o.value === v);
                    return typeof opt === 'string' ? opt : (opt?.name ?? opt?.label);
                });
            return r;            
        }, [multiple, options]);

    return (
        <div className={className} style={{ width: props.width, marginRight: props.mr }}>
            {props.label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {props.label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}

            <SelectMui {...field}
                value={field.value ?? defaultValue}
                required={props.required}
                multiple={multiple}
                className={selectClass}
                onChange={onChange}
                classes={{ select: selectClass }}
                style={{ height: props.height }}
                disabled={disabled}
                renderValue={renderValue}
                onClose={props.onClose}
                fullWidth>
                {options.map((option, idx: number) => typeof option === "string" ?
                    (<MenuItem key={`key_${option}_${idx}`} value={option}>
                        {!multiple ? option :
                            <>
                                <Checkbox checked={field?.value.indexOf(option) > -1} />
                                <ListItemText primary={option} />
                            </>}
                    </MenuItem>)
                    :
                    (<MenuItem key={`key_${option.name ?? option.label}_${idx}`} value={option.value}>
                        {!multiple ? option.name ?? option.label :
                            <>
                                <Checkbox checked={field?.value.indexOf(option.value) > -1} />
                                <ListItemText primary={option.name ?? option.label} />
                            </>
                        }
                    </MenuItem>)
                )}
            </SelectMui>
            {showError && error && <div style={errorStyle}>{error}</div>}
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );
}
