import React, { ChangeEvent, useState } from "react";
import { useField, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import FileUpload, { FileUploadProps } from "../fileUpload/FileUpload";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

type FormikFileUploadProps = {
    name: string;
} & Omit<FileUploadProps, "name">

export default function FormikFileUpload(props: FormikFileUploadProps) {
    const { name, ...otherProps } = props;
    const fieldProps = useField(name);
    const { isSubmitting } = useFormikContext();
    const [preview, setPreview] = useState<string>(props.preview || "");
    const [loading, setLoading] = useState<boolean>();
    const storage = getStorage();

    const [field, meta, helpers] = fieldProps;
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;
    const disabled = props.disabled || isSubmitting;

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files && files.length) {
            try {
                setLoading(true);

                for (let i = 0; i < files.length; i++) {
                    const fileRef = ref(storage, `uploads/${uuid()}`);
                    await uploadBytes(fileRef, files[i]);
                    const url = await getDownloadURL(fileRef);
                    setPreview(url);
                    helpers.setValue(url);
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
            }
        }

        props.onChange?.(e);
    };

    return (
        <FileUpload {...otherProps}
            {...field}
            loading={loading}
            disabled={disabled}
            helperText={fieldError}
            showError={showError}
            preview={preview}
            onChange={onChange}
        />
    );
}