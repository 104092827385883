import LuxonUtils from "@date-io/luxon";
import { unstable_createMuiStrictModeTheme, CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../redux/store/configureStore";
import theme from "../styles/theme";
import Main from "./Main";
import "./App.module.scss";
import SnackbarContainer from "./widgets/snackbar/SnackbarContainer";

export const { store, persistor } = configureStore();

const muiTheme = unstable_createMuiStrictModeTheme(theme);

export default function App() {
  return (
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <StylesProvider injectFirst>
                      <ThemeProvider theme={muiTheme}>
                          <CssBaseline />
                          <SnackbarContainer>
                              <Main />
                          </SnackbarContainer>
                      </ThemeProvider>
                  </StylesProvider>
              </MuiPickersUtilsProvider>
          </PersistGate>
      </Provider>
  );
}
