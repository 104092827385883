import { Column } from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountSearchControl from 'src/components/widgets/account/search/AccountSearchControl';
import Button from 'src/components/widgets/button/Button';
import { DialogRef } from "src/components/widgets/dialog/Dialog";
import RegionsSelect from "src/components/widgets/input/RegionsSelect";
import Options, { OptionButton } from "src/components/widgets/more/Options";
import useAsyncEffect from "src/hooks/useAsyncEffect";
import useUserRoles from "src/hooks/useUserRoles";
import AccountsAPI from "src/redux/actions/api/accounts";
import { AccountStatus, AccountType } from "src/types/model/AmotaiAccount";
import { simpleArrayCompare } from "src/util/utils";
import useDispatch from "../../../../../hooks/useDispatch";
import { getSuppliers } from "../../../../../redux/actions/suppliers";
import { PagingTableFilter } from "../../../../../redux/reducers/tables";
import ListResult from "../../../../../types/ListResult";
import Supplier from "../../../../../types/model/Supplier";
import AccountReviewDialog from "../../../../widgets/accountReviewDialog/AccountReviewDialog";
import AssignManagerDialog from "../../../../widgets/assignManagerDialog/AssignManagerDialog";
import Header from "../../../../widgets/header/Header";
import PagingTable, { PagingTableRef } from "../../../../widgets/pagingTable/PagingTable";
import styles from './SuppliersOverview.module.scss';

export default function SuppliersOverview() {
    const reviewDialogRef = useRef<DialogRef>(null);
    const assignRMDialogRef = useRef<DialogRef>(null);
    const { isSuperAdmin } = useUserRoles();
    const tableRef = useRef<PagingTableRef<object>>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [supplier, setUpdatingSupplier] = useState<Supplier>();
    const [suppliersCnt, setSuppliersCnt] = useState<number>(0);
    const [keyword, setKeyword] = useState<string | undefined | null>(null);
    const basedRegionsRef = useRef<string[]>([]);

    useAsyncEffect(async () => {
        const cnt = await AccountsAPI.getAccountsCount({ type: AccountType.SUPPLIER });
        setSuppliersCnt(cnt);
    });

    useEffect(() => {
        if (keyword) {
            tableRef.current?.refresh();
        }
    }, [tableRef, keyword]);

    const queryData = useCallback(async (queryFilter: PagingTableFilter): Promise<ListResult<Supplier>> => {
        const { limit, cursor } = queryFilter;
        const request = { basedRegions: basedRegionsRef.current, type: AccountType.SUPPLIER, keyword };
        const result = await dispatch(getSuppliers(request, limit, cursor));
        return result as ListResult<Supplier>;
    }, [keyword, basedRegionsRef, dispatch]);

    const onEditPress = (supplier?: Supplier) => {
        if (supplier) {
            history.push(`/suppliers/${supplier.id}`);
        }
    };

    const onChangeStatusPress = (s: Supplier) => {
        setUpdatingSupplier(s);
        reviewDialogRef?.current?.show();
    };

    const onAssignRmPress = (s: Supplier) => {
        setUpdatingSupplier(s);
        assignRMDialogRef?.current?.show();
    };

    const optionButtons = (data: Supplier): Array<OptionButton<Supplier>> => {
        const options: Array<OptionButton<Supplier>> = [
            {
                onPress: () => onEditPress(data),
                buttonText: "View/Edit",
            },
        ];
        if (isSuperAdmin) {
            options.push(
                {
                    onPress: () => onChangeStatusPress(data),
                    buttonText: "Change status",
                },
                {
                    onPress: () => onAssignRmPress(data),
                    buttonText: "Assign RM",
                    // disabled: !!data.assignedTo,
                }
            );
        }
        return options;
    };

    const onAnySupplierChange = useCallback(async () => {
        tableRef?.current?.refresh();
        const cnt = await AccountsAPI.getAccountsCount({ type: AccountType.SUPPLIER });
        setSuppliersCnt(cnt);
    }, [tableRef, setSuppliersCnt]);

    const TABLE_COLUMNS: Column<Supplier>[] = [
        {
            title: "Business Name",
            field: "name"
        },
        {
            title: "Primary Contact",
            field: "mainContact.name"
        },
        {
            title: "Phone",
            render: (s: Supplier) => {
                const { mainContact } = s;
                if (!mainContact) {
                    return <div />;
                }
                const { phoneNumber, mobileNumber } = mainContact;
                const phone = phoneNumber ?? mobileNumber;
                if (!phone) {
                    return <div />;
                }
                return (
                    <div>
                        {phone.charAt(0) === "0" ? phone : `0${phone}`}
                    </div>
                );
            }
        },
        {
            title: "Email",
            field: "mainContact.email"
        },
        {
            title: "Status",
            render: supplier => supplier.status === AccountStatus.DISABLED ? 'ON_HOLD' : supplier.status,
            customSort: (a, b) => {
                const statusA = a.status === AccountStatus.DISABLED ? 'ON_HOLD' : a.status;
                const statusB = b.status === AccountStatus.DISABLED ? 'ON_HOLD' : b.status;
                return statusA.localeCompare(statusB);
            },
        },
        {
            render: s => (
                <Options<Supplier> data={s}
                    optionButtons={optionButtons(s)}
                />
            ),
            disableClick: true
        }
    ];

    const onRegionsSelected = (basedRegions: string[]) => {
        if (!simpleArrayCompare(basedRegions, basedRegionsRef.current)) {
            basedRegionsRef.current = basedRegions;
            tableRef.current?.refresh();
        }
    };

    const goBack = useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [history]);

    return (
        <>
            <div className={styles.back} onClick={goBack}>&lt;&lt; Back</div>
            <Header title={"Suppliers Overview"}
                action={
                    <Button to={`/export/${AccountType.SUPPLIER.toLowerCase()}`}>
                        CSV Export
                    </Button>
                }
            />
            <PagingTable<Supplier, PagingTableFilter>
                id={"suppliers"}
                title={`Total suppliers: ${suppliersCnt}`}
                tableRef={tableRef}
                columns={TABLE_COLUMNS}
                options={{ toolbar: true }}
                onRowClick={(_, data) => onEditPress(data)}
                getData={queryData}
                topTools={
                    <div className={styles.controls}>
                        <AccountSearchControl setKeyword={setKeyword} placeholder={`Search all ${suppliersCnt} Suppliers`} />
                        <RegionsSelect onFinish={onRegionsSelected} containerClassName={styles.select} />
                    </div>
                }
            />
            <AccountReviewDialog ref={reviewDialogRef} onStatusSubmit={onAnySupplierChange} account={supplier} />
            <AssignManagerDialog ref={assignRMDialogRef} account={supplier} />
        </>
    );
}
