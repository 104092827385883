import { InputLabel, TextField, TextFieldProps } from "@material-ui/core";
import classNames from "classnames";
import { isNaN, useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "./Input.module.scss";

type InputProps = {
    name: string;
    inputClassname?: string,
    noPadding?: boolean,
    label?: string,
    onKeyDown?: (e: React.KeyboardEvent) => void;
    tooltip?: string,
    placeholder?: string,
    width?: number,
    height?: number,
    mr?: number,
    number?: boolean,
} & Omit<TextFieldProps, "name" | "value" | "error">;

export default function FormikInput(props: InputProps) {
    const { name, inputClassname, noPadding, label, number, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;

    const disabled = otherProps?.disabled || isSubmitting;
    const helperText = meta.error ?? otherProps?.helperText;

    const className = classNames(styles.padding, { [styles.noPadding]: noPadding }, inputClassname);
    const inputClass = classNames(styles.input);

    const InputProps = {
        "classes": { root: inputClass }
    };

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            props.onKeyDown?.(e);
        },
        [props.onKeyDown],
    );

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (number && isNaN(Number(e.target.value))) {
            return;
        }
        field.onChange(e);
        props.onChange?.(e);
    }, [field, props.onChange, number]);

    return (
        <div className={className} style={{ width: props.width, marginRight: props.mr }}>
            {label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}

            <TextField
                classes={{ root: styles.formik_input }}
                {...otherProps}
                {...field}
                onChange={onChange}
                onKeyDown={onKeyDown}
                error={!!meta.error}
                disabled={disabled}
                helperText={helperText} />
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );
}
