import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useUserRoles from "src/hooks/useUserRoles";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { showError } from "../../../../../../../redux/actions/snackbars";
import { updateMe } from "../../../../../../../redux/actions/user";
import { getManager, updateRegionalManager } from "../../../../../../../redux/actions/users";
import AmotaiUser, { UserStatus } from "../../../../../../../types/model/AmotaiUser";
import Button from "../../../../../../widgets/button/Button";
import Header from "../../../../../../widgets/header/Header";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import Regions from "../../../../../../widgets/regions/Regions";
import Loading from "../../../../../loading/Loading";
import styles from "./RMDetails.module.scss";


type RouteParams = {
    regionalManagerId: string;
}

export type RegionalManagerFormValues = {
    firstName: string;
    lastName: string;
    email: string;
    assignedRegions: string[];
    status: UserStatus;
}

const RegionalManagerValidationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    assignedRegions: Yup.array().min(1, "At least one region is required"),
});

export default function RMDetails(props: RouteComponentProps<RouteParams>) {
    const id = props?.match?.params?.regionalManagerId;
    const { isSuperAdmin, isRegionalManager, user: me } = useUserRoles();
    const managerToView: AmotaiUser | undefined = useSelector((state => state.managers.regionalManagers[id]));
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (!id) {
            history.replace('/');
        } else if (!managerToView) {
            dispatch(getManager(id));
        }
    }, [history, dispatch, id, managerToView]);

    const meAsManager = useMemo(() => {
        return isRegionalManager && me && me.id === managerToView?.id;
    }, [isRegionalManager, me, managerToView]);

    const initialValues: RegionalManagerFormValues = {
        firstName: managerToView?.firstName || "",
        lastName: managerToView?.lastName || "",
        email: managerToView?.email || "",
        assignedRegions: managerToView?.assignedRegions || [],
        status: managerToView?.status
    };

    const onSubmit = async (values: RegionalManagerFormValues) => {
        const { assignedRegions, status, firstName, lastName, email, ...submitValues } = managerToView!;
        const updateValues = {
            status: values.status,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            assignedRegions: values.assignedRegions,
            ...submitValues
        };
        try {
            setLoading(true);
            if (isSuperAdmin) { //update by super admin
                await dispatch(updateRegionalManager(id,
                    updateValues
                ));
            } else if (meAsManager) { //udpate me
                await dispatch(updateMe(updateValues));
            }
            setSubmitting(false);
            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
        }
    };

    const goBack = useCallback(() => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/');
        }
    }, [history]);

    if (submitting || loading) {
        return <Loading />;
    }

    return (
        <>
            <Formik<RegionalManagerFormValues> validationSchema={RegionalManagerValidationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {(formikBag: FormikProps<any>) => {
                    const toggleEdit = async () => {
                        if (editing && formikBag.dirty) {
                            await onSubmit(formikBag.values);
                        }
                        setEditing(!editing);
                        if (!editing) {
                            formikBag.resetForm();
                        }
                    };
                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }
                    return (
                        <Form>
                            <div className={styles.back} onClick={goBack}>&lt;&lt; Back</div>
                            <Header title={`${managerToView?.name}`}
                                action={((isSuperAdmin || meAsManager) && managerToView?.id === id) ? (
                                    <Button uppercase loading={submitting || formikBag.isSubmitting}
                                        onClick={toggleEdit}>{actionText}</Button>
                                ) : undefined}
                                subtitle={`Member since ${moment(managerToView?.createdAt).format("DD/MM/YYYY")}`} />
                            <div className={styles.form}>
                                <FormikInput name={"firstName"}
                                    label={"First Name"}
                                    inputClassname={classNames(styles.half_input, styles.first)}
                                    disabled={!editing} />
                                <FormikInput name={"lastName"}
                                    label={"Last Name"}
                                    inputClassname={styles.half_input}
                                    disabled={!editing} />
                            </div>
                            <div className={styles.form}>
                                <FormikInput name={"email"}
                                    label={"Email"}
                                    inputClassname={styles.full_input}
                                    disabled={!editing} />
                            </div>
                            <div className={classNames(styles.half_input, styles.first)}>
                                <Regions disabled={!editing} name={"assignedRegions"} />
                            </div>

                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}
