import ErrorIcon from '@material-ui/icons/Error';
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import { Column } from "material-table";
import React, { useCallback, useRef, useState } from "react";
import useUserRoles from "src/hooks/useUserRoles";
import { PagingTableFilter } from "src/redux/reducers/tables";
import AmotaiAccount from "src/types/model/AmotaiAccount";
import * as Yup from "yup";
import useDispatch from "../../../../hooks/useDispatch";
import { createUpdateNote, deleteNote, getNotes } from "../../../../redux/actions/notes";
import Note, { CreateUpdateRequest } from "../../../../types/model/Note";
import Button from "../../button/Button";
import FormikCheckBox from "../../checkBox/FormikCheckBox";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import Header from "../../header/Header";
import FormikInput from "../../input/FormikInput";
import Options from "../../more/Options";
import PagingTable, { PagingTableRef } from "../../pagingTable/PagingTable";
import AccountHistory from "../history/AccountHistory";
import styles from "./Notes.module.scss";

export type NotesProps<T> = { amotaiAccount: T };
export default function Notes<T extends AmotaiAccount>({ amotaiAccount }: NotesProps<T>) {

    const accountId = amotaiAccount.id;
    const dispatch = useDispatch();
    const tableRef = useRef<PagingTableRef<Note, PagingTableFilter>>(null);
    const [loading, setLoading] = useState(false);
    const [toUpdate, setToUpdate] = useState<Note>();
    const [toDelete, setToDelete] = useState<Note>();
    const editDialog = useRef<DialogRef | null>(null);
    const delDialog = useRef<DialogRef | null>(null);
    const [dialogHeader, setDialogHeader] = useState<string>("NEW NOTE");
    const { isAdmin, isSuperAdmin, isRegionalManager } = useUserRoles();

    const onNew = useCallback(() => {
        setDialogHeader("NEW NOTE");
        editDialog.current?.show();
    }, [setDialogHeader, editDialog]);

    const onEdit = useCallback((note: Note) => {
        setDialogHeader("EDIT NOTE");
        setToUpdate(note);
        editDialog.current?.show();
    }, [setDialogHeader, setToUpdate, editDialog]);

    const onCancelEdit = useCallback(() => {
        setToUpdate(undefined);
        setLoading(false);
        editDialog.current?.hide();
    }, [setToUpdate, setLoading, editDialog]);

    const doCreateOrUpdate = useCallback(async (request: CreateUpdateRequest) => {
        setLoading(true);
        const payload: Note = toUpdate ? { ...toUpdate, ...request } : request as Note;
        await dispatch(createUpdateNote(accountId, payload));
        tableRef.current?.refresh();
        onCancelEdit();
    }, [setLoading, dispatch, accountId, toUpdate, onCancelEdit]);

    const onDelete = useCallback((note: Note) => {
        setToDelete(note);
        delDialog.current?.show();
    }, [setToDelete, delDialog]);

    const onCancelDelete = useCallback(() => {
        setToDelete(undefined);
        setLoading(false);
        delDialog.current?.hide();
    }, [setToDelete, setLoading, delDialog]);

    const doDelete = useCallback(async () => {
        setLoading(true);
        if (toDelete) {
            await dispatch(deleteNote(accountId, toDelete.id));
            tableRef.current?.refresh();
        }
        onCancelDelete();
    }, [setLoading, dispatch, accountId, onCancelDelete, toDelete]);

    const getQuery = ({ limit, cursor }: PagingTableFilter) => {
        return dispatch(getNotes(accountId, limit, cursor));
    };

    const columns: Column<Note>[] = [
        {
            title: "Date created",
            render: ({ createdAt }) => {
                return DateTime.fromISO(createdAt).toFormat("DD");
            },
            customSort: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        },
        {
            title: "Last updated",
            render: ({ updatedAt }) => {
                return DateTime.fromISO(updatedAt).toFormat("DD");
            },
            customSort: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        },
        {
            title: "Last updated by",
            field: "lastUpdatedBy.name",
            render: ({ lastUpdatedBy }) => lastUpdatedBy?.name ?? lastUpdatedBy?.email,
            customSort: ({ lastUpdatedBy: a }, { lastUpdatedBy: b }) =>
                (a?.name ?? a?.email ?? '').toLowerCase().localeCompare((b?.name ?? b?.email ?? '').toLowerCase()),
        },
        {
            title: "Note details",
            field: "details",
        },
        {
            title: 'Flagged',
            render: n => n.flagged ? <ErrorIcon /> : null,
            width: '10%',
            customSort: (a, b) => String(a.flagged).localeCompare(String(b.flagged)),
        },
        {
            render: n => <Options<Note> data={n} optionButtons={[
                {
                    onPress: () => onEdit(n),
                    buttonText: "Edit",
                },
                {
                    onPress: () => onDelete(n),
                    buttonText: "Delete",
                }
            ]} />
        }
    ];

    return (
        <div className={styles.notes}>
            <Header title={`${amotaiAccount.name}`}
                action={isAdmin || isSuperAdmin || isRegionalManager ? <Button onClick={onNew}>Add Note</Button> : undefined}
                subnodes={<AccountHistory account={amotaiAccount} />} />

            <PagingTable<Note, PagingTableFilter>
                id={"account-notes"}
                columns={columns}
                tableRef={tableRef}
                title={"Notes on account"}
                options={{ showTitle: true, toolbar: true }}
                isLoading={loading}
                getData={getQuery} />

            <Dialog dialogRef={editDialog}
                header={dialogHeader}>
                <Formik initialValues={toUpdate ?? { details: "", flagged: false }}
                    validationSchema={Yup.object({ details: Yup.string().required("Required") })}
                    onSubmit={doCreateOrUpdate}>
                    {({ dirty, isValid, isSubmitting }) => (
                        <Form>
                            <FormikInput name={"details"} multiline rows={5} placeholder={"Add new note here..."} />
                            <FormikCheckBox
                                name="flagged"
                                label={<div className={styles.dialog_checkbox}><ErrorIcon /><span>Flag note on CSV export</span></div>}
                            />
                            <div className={styles.dialog_btns}>
                                <Button regular
                                    disabled={!dirty || !isValid}
                                    type={"submit"}
                                    loading={isSubmitting || loading}>Save</Button>
                                <div />
                                <Button plainLink onClick={onCancelEdit}>Cancel</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Dialog dialogRef={delDialog}
                header={"DELETE NOTE"}>
                <div className={styles.dialog_content}>
                    <p>Are you sure you would like to delete this note? This action can not be undone.</p>
                    <div className={styles.dialog_btns}>
                        <Button regular disabled={loading} loading={loading} onClick={doDelete}>Delete</Button>
                        <div />
                        <Button plainLink onClick={onCancelDelete}>Cancel</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
