export enum SaaSPlanStatus {
    ACTIVE = "ACTIVE",
    ARCHIVED = "ARCHIVED"
}

export const allPlanStatus = [SaaSPlanStatus.ACTIVE, SaaSPlanStatus.ARCHIVED];

export enum LogoType {
    NONE = "NONE",
    BUYER = "BUYER",
    CHAMPION = "CHAMPION"
}

export enum SaaSPlanPeriod {
    ANNUAL = "ANNUAL",
    MONTHLY = "MONTHLY"
}

export default interface SaaSPlan {
    id: number;
    status: SaaSPlanStatus;
    createdAt: string;
    name: string;
    amount: number;
    maxUsers: number;
    eventDiscount: number;
    fullProfile: boolean;
    logoType: LogoType;
    maxContacts: number;
    displayBadge: boolean;
    invoicable: boolean;
    period: Period;
    invoiceAnnualAmount?: number;
    invoiceInterval: InvoiceInterval;
    customizedFor: CustomizedFor;
};;;;;;;;;;

export interface UpdateSaaSPlanRequest {
    invoicable: boolean;
}

export enum Period {
    ANNUAL = 'ANNUAL',
    MONTHLY = 'MONTHLY'
}

export enum InvoiceInterval {
    ANNUAL = 'ANNUAL',
    MONTHLY = 'MONTH'
}

export enum CustomizedFor {
    MandateAgency = 'MandateAgency',
}