import instance from "./instance";

export default function del(url: string, delData?: any, params?: {}): Promise<any> {
    return instance
        .delete(url, {
            params,
            data: delData,
        })
        .then((data) => data.data);
}
