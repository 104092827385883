import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import { InputLabel } from "@material-ui/core";
import useMountEffect from "../../../hooks/useMountEffect";
import styles from "./Input.module.scss";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";

type Props = {
    name: string;
    label?: string;
    noPadding?: boolean;
    inputClassname?: string;
    tooltip?: string;
    helperText?: string;
    defaultValue?: number;

    phone?: boolean;
    currency?: boolean;
    prefix?: string;
    formatMask?: string;

    disabled?: boolean;
    max?: number;
    allowNegative?: boolean;
    onChange?: () => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
};

export default function FormikNumberInput(props: Props) {
    const {
        name,
        label,
        noPadding,
        inputClassname,
        tooltip,
        helperText: propsHelperText,
        defaultValue,
        phone,
        currency,
        prefix,
        formatMask,
        disabled,
        max,
        allowNegative,
        onChange: propsOnChange,
        onKeyDown: propsOnKeyDown
    } = props;
    const { isSubmitting } = useFormikContext();
    const [field, meta, helpers] = useField(name);

    const showError = meta.touched && !!meta.error;
    const error = showError ? meta.error : null;

    const dis = disabled || isSubmitting;
    const helperText = showError ? error : propsHelperText;

    const className = classNames(styles.padding, { [styles.noPadding]: noPadding }, inputClassname);

    useMountEffect(() => {
        if (!field.value) {
            helpers.setValue(defaultValue);
        }
    });

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            propsOnKeyDown?.(e);
        },
        [propsOnKeyDown],
    );

    const onChange = (values: any) => {
        if (phone) {
            helpers.setValue(values.value);
        } else {
            helpers.setValue(values.floatValue);
        }
        if (propsOnChange && values.floatValue) {
            propsOnChange();
        }
    };

    return (
        <div className={className}>
            {label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {label}
                    </InputLabel>
                    {tooltip && (
                        <div data-tip={tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}
            <NumberFormat
                classes={{ root: styles.formik_input }}
                value={field.value}
                disabled={dis}
                customInput={TextField}
                onValueChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={() => helpers.setTouched(true)}
                displayType={"input"}
                thousandSeparator={!phone}
                prefix={prefix}
                format={formatMask}
                decimalScale={currency ? 2 : 0}
                // fixedDecimalScale={currency}
                error={!!error}
                helperText={helperText}
                isAllowed={(values) => {
                    const { floatValue } = values;
                    if (floatValue && max !== undefined) {
                        return floatValue > 0 && floatValue <= max;
                    }
                    return true;
                }}
                allowNegative={allowNegative}
                allowLeadingZeros={phone}
            />
        </div>
    );
}
