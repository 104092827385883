import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React from "react";
import classNames from "classnames";
import styles from "../../routes/dashboard/routes/supplierDetail/SupplierDetail.module.scss";
import Button from "../button/Button";
import FormikFileUpload from "../input/FormikFileUpload";
import FormikInput from "../input/FormikInput";
import FormikNumberInput from "../input/FormikNumberInput";


type BusinessContactFieldArrayProps = {
    disabled?: boolean;
    max?: number;
};


export type BusinessContact = {
    name: string;
    title: string;
    email: string;
    phoneNumber: string;
    avatar: string;
}

export default function BusinessContactFieldArray(props: BusinessContactFieldArrayProps) {
    const { values }: any = useFormikContext();
    const { disabled, max } = props;
    const contactsLength = values?.businessContacts?.length;
    const atMaxContacts = max ? contactsLength >= max : false;

    const renderBusinessContact = (contact: BusinessContact, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <div key={`businessContact.${index}`} className={classNames(styles.contact_row, styles.profile_contact)}>
            <div className={styles.contact_input_wrapper}>
                <FormikFileUpload name={`businessContacts[${index}].avatar`}
                                  id={`businessContacts[${index}].avatar`}
                                  label={"Upload a profile picture"}
                                  disabled={disabled}
                                  preview={contact.avatar || ""} />
                <FormikInput name={`businessContacts[${index}].name`}
                             inputClassname={classNames(styles.half_input, styles.first)}
                             label={"Contact name"} disabled={disabled} />
                <FormikInput name={`businessContacts[${index}].title`}
                             inputClassname={styles.half_input}
                             label={"Job title"} disabled={disabled} />
                <FormikInput name={`businessContacts[${index}].email`}
                             inputClassname={classNames(styles.half_input, styles.first, styles.no_margin_input)}
                             label={"Email address"} disabled={disabled} />
                <FormikNumberInput name={`businessContacts[${index}].phoneNumber`}
                                   formatMask={"(###) ###-#####"}
                                   phone
                                   inputClassname={classNames(styles.half_input, styles.no_margin_input)}
                                   label={"Mobile number"} disabled={disabled} />
            </div>
            {!disabled && (
                <Button className={styles.remove_contact_button}
                        plainLink
                        onClick={() => arrayHelpers.remove(index)}>
                    - Remove
                </Button>
            )}
        </div>
    );

    return (
        <FieldArray name={"businessContacts"}
                    render={(arrayHelpers) => (
                        contactsLength > 0
                            ? (
                                <>
                                    {values.businessContacts.map((contact: BusinessContact, index: number) => renderBusinessContact(contact, index, arrayHelpers))}
                                    <div className={styles.add_button}>
                                        {!disabled && !atMaxContacts && (
                                            <Button plainLink onClick={() => arrayHelpers.push({
                                                name: "",
                                                title: "",
                                                email: "",
                                                phoneNumber: ""
                                            })}>+ Add new contact</Button>
                                        )}
                                    </div>
                                </>
                            )
                            : (
                                <div className={styles.add_button}>
                                    {!disabled && (
                                        <Button plainLink onClick={() => arrayHelpers.push({
                                            name: "",
                                            title: "",
                                            email: "",
                                            phoneNumber: ""
                                        })}>+ Add new contact</Button>
                                    )}
                                </div>
                            )
                    )} />
    );
}