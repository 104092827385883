import Audit from "src/types/model/Audit";
import AuditAPI from "./api/audit";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum AuditTypes {
    SET_ACCOUNT_STATUS_AUDITS = 'audits:SET_ACCOUNT_AUDITS',
    SET_ALL_ACCOUNT_STATUS_AUDITS = 'audits:SET_ALL_ACCOUNT_STATUS_AUDITS',
    SET_ACCOUNT_ALL_AUDITS = 'audits:SET_ACCOUNT_ALL_AUDITS',
}

export type AuditPayload = {
    [AuditTypes.SET_ACCOUNT_STATUS_AUDITS]: { accountId: number, audits: Audit[] },
    [AuditTypes.SET_ACCOUNT_ALL_AUDITS]: { accountId: number, audits: Audit[] },
    [AuditTypes.SET_ALL_ACCOUNT_STATUS_AUDITS]: Audit[],
}

export const setAccountStatusAudits = createAction<AuditPayload[AuditTypes.SET_ACCOUNT_STATUS_AUDITS]>(AuditTypes.SET_ACCOUNT_STATUS_AUDITS);
const setAccountAllAudits = createAction<AuditPayload[AuditTypes.SET_ACCOUNT_ALL_AUDITS]>(AuditTypes.SET_ACCOUNT_ALL_AUDITS);
const setAllAccountStatusAudits = createAction<AuditPayload[AuditTypes.SET_ALL_ACCOUNT_STATUS_AUDITS]>(AuditTypes.SET_ALL_ACCOUNT_STATUS_AUDITS);

export const getAccountStatusChangeHistory = wrapper(
    AuditAPI.getAccountStatusChangeHistory, {
    action: (audits, params) => {
        const accountId = params[0];
        return setAccountStatusAudits({ accountId, audits });
    }
});

export const getAccountAllHistory = wrapper(
    AuditAPI.getAccountChangeHistory, {
    action: (audits, params) => {
        const accountId = params[0];
        return setAccountAllAudits({ accountId, audits });
    }
});

export const getAllAccountsStatusHistory = wrapper(
    AuditAPI.getAllAccountsStatusHistory,
    { action: setAllAccountStatusAudits }
);