import BuyerClient from "../../types/model/BuyerClient";
import { Actions, Types } from "../actions/clientsBuyers";

const initialState: {
    [key: number]: BuyerClient;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function clientsBuyers(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_CLIENT_BUYER: {
            const { payload } = action as ActionValueTypes<Types.SET_CLIENT_BUYER>;
            const clientBuyer = payload!;
            return {
                ...state,
                [clientBuyer.id]: clientBuyer,
            };
        }
        case Types.SET_CLIENT_BUYERS: {
            const { payload } = action as ActionValueTypes<Types.SET_CLIENT_BUYERS>;
            const newState = { ...state };
            payload!.items.forEach((clientBuyer) => {
                newState[clientBuyer.id] = clientBuyer;
            });

            return newState;
        }
        case Types.UPDATE_ASSIGNED_TO: {
            const { payload } = action as ActionValueTypes<Types.UPDATE_ASSIGNED_TO>;
            const { accountId, managerId } = payload!;
            const newState = { ...state };
            newState[accountId].assignedTo = managerId;
            return newState;
        }
        default: {
            return state;
        }
    }
}
