import { Category } from "src/types/model/Category";
import BuyerClient from "../../../types/model/BuyerClient";
import get from "./util/get";
import put from "./util/put";


function getClientBuyer(clientBuyerId: number | string): Promise<BuyerClient> {
    return get(`/v1/accounts/${clientBuyerId}`);
}

function updateClientBuyer(buyer: Omit<BuyerClient, 'category'> & { category: Category | null }): Promise<BuyerClient> {
    return put(`v1/accounts/buyer-client/${buyer.id}`, buyer);
}

function inductAccount(accountId: number, dto: { inductedAt?: string, inductedBy?: string }): Promise<BuyerClient> {
    return put(`v1/admin/account/${accountId}/induct`, dto);
}

const ClientsBuyersAPI = {
    getClientBuyer,
    updateClientBuyer,
    inductAccount
};

export default ClientsBuyersAPI;
