/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { RegionAccounts } from "src/types/model/AmotaiAccount";
import ListResult from "../../types/ListResult";
import Supplier from "../../types/model/Supplier";
import AccountsAPI from "./api/accounts";
import SuppliersAPI from "./api/suppliers";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_SUPPLIER = "suppliers:SET_SUPPLIER",
    SET_SUPPLIERS = "suppliers:SET_SUPPLIERS",
    SET_ALL_REGIONS = "suppliers:SET_REGION_SUPPLIERS",
    SET_REGION_SUPPLIERS = "suppliers:SET_REGION_SUPPLIER",
    UPDATE_ASSIGNED_TO = "suppliers:UPDATE_ASSIGNED_TO",
}

export const setSupplier = createAction<Supplier>(Types.SET_SUPPLIER);
export const setSuppliers = createAction<ListResult<Supplier>>(Types.SET_SUPPLIERS);
export const setRegionsSuppliers = createAction<Array<RegionAccounts>>(Types.SET_ALL_REGIONS);
export const setRegionSuppliers = createAction<{ region: string, data: ListResult<Supplier> }>(Types.SET_REGION_SUPPLIERS);
export const updateSupplierAssignedTo = createAction<{ accountId: number, managerId: string }>(Types.UPDATE_ASSIGNED_TO);


export const Actions = {
    [Types.SET_SUPPLIER]: setSupplier,
    [Types.SET_SUPPLIERS]: setSuppliers,
    [Types.SET_REGION_SUPPLIERS]: setRegionSuppliers,
    [Types.SET_ALL_REGIONS]: setRegionsSuppliers,
    [Types.UPDATE_ASSIGNED_TO]: updateSupplierAssignedTo,
};
export type Actions = typeof Actions;

export const getSupplier = wrapper(SuppliersAPI.getSupplier, {
    action: setSupplier,
});

export const getSuppliers = wrapper(AccountsAPI.queryAccountsByAdmin, {
    action: setSuppliers,
});

export const updateSupplier = wrapper(SuppliersAPI.updateSupplier, {
    action: setSupplier
});

export const inductSupplier = wrapper(SuppliersAPI.inductAccount, {
    action: setSupplier
});

export const getAllRegions = wrapper(SuppliersAPI.getRegionSuppliersOverview, {
    action: setRegionsSuppliers
});

export const getRegionSuppliers = wrapper(SuppliersAPI.getSuppliersInRegion, {
    action: setRegionSuppliers
});
