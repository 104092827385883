import { useFormikContext } from "formik";
import React from "react";
import CheckBox from "../../../../../../../widgets/checkBox/CheckBox";
import styles from "./EventFor.module.scss";
import { AccountType } from "src/types/model/AmotaiAccount";

export type EventTypeProps = {
    disabled?: boolean;
    label?: string;
}

export default function EventFor(props: EventTypeProps) {

    const { disabled } = props;

    const { values, setFieldValue } = useFormikContext<object & {
        eventFor: string[];
    }>();

    const eventFor = values.eventFor || [];

    const onCheckBoxValueChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newEventFor = [...eventFor];
        const { value } = e.target;
        if (!checked) {
            const index = newEventFor.indexOf(value);
            newEventFor.splice(index, 1);
        } else {
            newEventFor.push(value);
        }
        setFieldValue("eventFor", newEventFor);
    };

    return (
        <div className={styles.event}>
            <span>{props.label}</span>
            <div className={styles.event_list}>
                <CheckBox value={AccountType.SUPPLIER}
                    label={"Supplier"}
                    disabled={disabled}
                    checked={eventFor.includes(AccountType.SUPPLIER)}
                    onChange={onCheckBoxValueChange} />
                <CheckBox value={AccountType.BUYER_CLIENT}
                    label={"Buyer/Client"}
                    disabled={disabled}
                    checked={eventFor.includes(AccountType.BUYER_CLIENT)}
                    onChange={onCheckBoxValueChange} />
            </div>
        </div>
    );
}


