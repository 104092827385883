import React, { useState } from "react";
import { Column } from "material-table";
import useMountEffect from "src/hooks/useMountEffect";
import CardsAPI from "src/redux/actions/api/cards";
import Loading from "src/components/routes/loading/Loading";
import Table from "src/components/widgets/table/Table";
import Card from "src/types/model/Card";
import SectionHeader from "src/components/widgets/sectionHeader/SectionHeader";
import useDispatch from "src/hooks/useDispatch";
import { showError } from "src/redux/actions/snackbars";
import styles from "../Billing.module.scss";

type CardsProps = {
    accountId: number
}

export default function Cards(props: CardsProps) {
    const { accountId } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [cards, setCards] = useState<Card[]>([]);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        try {
            const cds = await CardsAPI.getCards(accountId);
            setCards(cds);
        } catch (error) {
            dispatch(showError('Failed to load account cards'));
        } finally {
            setLoading(false);
        }
    });

    const cardsColumn: Column<Card>[] = [
        {
            title: "Card number",
            render: c => <span>{c.last4}</span>
        },
        {
            title: "Cardholder",
            field: "name"
        },
        {
            title: "Expiry",
            field: "expiryDate"
        },
    ];

    if (loading) {
        return <Loading />;
    }
    
    return <div className={styles.section}>
            <SectionHeader title="Billing information" />
            <Table<Card> columns={cardsColumn}
                        data={cards}
                        options={{ paging: false }} />
    </div>;

}
