/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { FieldValidator, useField, useFormikContext } from "formik";

export type FormikFieldExtraProps = {
    name: string;
    disabled?: boolean;
    helperText?: string;
    validate?: FieldValidator;
};

export default function useFormikField<P extends FormikFieldExtraProps>(props: P) {

    const { name, validate } = props;

    const formik = useFormikContext();
    const { isSubmitting } = formik;
    const fieldProps = useField<string>({
        name,
        validate,
    });

    const [field, meta, helpers] = fieldProps;
    const { value } = field;

    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;
    const helperText = showError ? fieldError : props.helperText;

    const disabled = props.disabled ?? isSubmitting;

    return {
        value,
        helperText,
        showError,
        disabled,
        field,
        meta,
        helpers,
        formik,
    };
}
