/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Column } from "material-table";
import React, { useCallback, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import { getUsers, createUser, updateUserById, deleteUserFromAccount, deleteUserWithoutAccount } from "../../../../../redux/actions/users";
import { PagingTableFilter } from "../../../../../redux/reducers/tables";
import ListResult from "../../../../../types/ListResult";
import AmotaiUser, { SystemRole, UserStatus } from "../../../../../types/model/AmotaiUser";
import Button from "../../../../widgets/button/Button";
import Header from "../../../../widgets/header/Header";
import PagingTable, { PagingTableRef } from "../../../../widgets/pagingTable/PagingTable";
import styles from "./Users.module.scss";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import { useHistory } from "react-router-dom";
import FormikInput from "src/components/widgets/input/FormikInput";
import useUserRoles from "src/hooks/useUserRoles";

const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().trim().email().required(),
});

export default function ViewOnlyUsers() {

    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = useRef<PagingTableRef<AmotaiUser, PagingTableFilter>>(null);
    const editDialog = useRef<DialogRef>(null);
    const delDialog = useRef<DialogRef>(null);
    const { isSuperAdmin: isEligible } = useUserRoles();
    const [isNew, setIsNew] = useState<boolean>(false);
    const [toEdit, setToEdit] = useState<AmotaiUser>();
    const [toDelete, setToDelete] = useState<AmotaiUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const onInvite = useCallback(() => {
        setIsNew(true);
        editDialog.current?.show();
    }, [editDialog]);

    const onEdit = useCallback((user:AmotaiUser) => {
        setIsNew(false);
        setToEdit(user);
        editDialog.current?.show();
    }, [setToEdit, setIsNew, editDialog]);

    const onCancelEdit = useCallback(() => {
        setToEdit(undefined);
        setError(undefined);
        editDialog.current?.hide();
    }, [setToEdit, editDialog]);

    const onDelete = useCallback((user: AmotaiUser) => {
        setToDelete(user);
        delDialog.current?.show();
    }, [setToDelete, delDialog]);

    const onCancelDelete = useCallback(() => {
        setToDelete(undefined);
        delDialog.current?.hide();
    }, [setToDelete, delDialog]);

    const doDelete = useCallback(async () => {
        if (!toDelete) {
            onCancelDelete();
            return;
        }
        toDelete.status = UserStatus.DISABLED;
        setLoading(true);
        try {
            const accountId = toDelete.account;
            if (!!accountId) {
                dispatch(deleteUserFromAccount(toDelete.account, toDelete.id));
            } else {
                dispatch(deleteUserWithoutAccount(toDelete.id));
            }
            setLoading(false);
            onCancelDelete();
            tableRef.current?.refresh();
        } catch (error) {
            console.log('Failed to delete', error);
            setLoading(false);
        }
    }, [toDelete, onCancelDelete, dispatch, setLoading]);

    const getQuery = async (queryFilter: PagingTableFilter): Promise<ListResult<AmotaiUser>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getUsers(limit, cursor, SystemRole.SUPPLIER_VIEWER));
    };

    const handleSubmit = useCallback(async (raw: AmotaiUser) => {
        const data = Object.assign(raw) as AmotaiUser;
        data.name = `${data.firstName} ${data.lastName}`;
        data.systemRole = SystemRole.SUPPLIER_VIEWER;
        setLoading(true);
        console.log('Going to create or update user:', data);
        try {
            if (isNew) {
                //create user
                await dispatch(createUser(data));
            } else {
                await dispatch(updateUserById(data.id, data));
            }
            setLoading(false);
            onCancelEdit();
            setError(undefined);
            tableRef.current?.refresh();
        } catch (err) {
            console.log('Failed to create or update a user', err);
            setLoading(false);
            setError(err.message);
        }
    }, [isNew, setLoading, dispatch, onCancelEdit, setError]);

    const columns: Column<AmotaiUser>[] = [
        {
            title: "Name",
            field: "name"
        },
        {
            title: "Email",
            field: "email"
        },
        {
            title: "Status",
            field: "status"
        },
        {
            render: u => <Button plainLink onClick={() => onEdit(u)}>Edit</Button>
        },
        {
            render: u => <Button plainLink onClick={() => onDelete(u)}>Delete</Button>
        }
    ];
    if (!isEligible) {
        history.replace('/dashboard/suppliers');
    }
    return (
        <div>
            <Header title={"Amotai View Only Users"}
                    action={(
                        <Button onClick={onInvite}>Invite User</Button>
                    )}
            />
            <PagingTable<AmotaiUser, PagingTableFilter> options={{ paging: false }}
                id={"view_only_users"}
                tableRef={tableRef}
                columns={columns}
                getData={getQuery} />

            <Dialog dialogRef={editDialog} header={!isNew ? "EDIT USER" : "INVITE NEW USER"} onClose={onCancelEdit} >
                <Formik<AmotaiUser> initialValues={toEdit ?? { firstName: "", lastName: "", email: "", systemRole: SystemRole.USER } as AmotaiUser}
                                    validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ dirty, isValid }) => (
                        <Form className={styles.form}>
                            <FormikInput name={'firstName'} label={"First name"} required inputClassname={styles.full_input} />
                            <FormikInput name={'lastName'} label={"Last name"} required inputClassname={styles.full_input}  />
                            <FormikInput name={'email'} label={"Email"} required  inputClassname={styles.full_input} />
                            <div className={styles.footer}>
                                <Button type={"submit"} disabled={loading || !dirty || !isValid}
                                        loading={loading}>Save</Button>
                                <div />
                                <Button regular plainLink onClick={onCancelEdit} disabled={loading}>Cancel</Button>
                            </div>
                            {!!error && <div className={styles.error}>{error}</div>}
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Dialog dialogRef={delDialog}
                    positiveText={"Delete"}
                    onPositivePress={doDelete}
                    negativeText={"Cancel"}
                    onNegativePress={onCancelDelete}
                    header={"DELETE USER"}
            >
                <div className={styles.delete_block}>
                    Are you sure you would like to delete this user? 
                    This user will be removed from account if exists and will be deleted. 
                    The action cannot be undone.
                </div>
            </Dialog>
        </div>
    );
}
