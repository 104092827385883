/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import ListResult from "../../types/ListResult";
import BuyerClient from "../../types/model/BuyerClient";
import AccountsAPI from "./api/accounts";
import ClientsBuyersAPI from "./api/clientsBuyers";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_CLIENT_BUYER = "clientsBuyers:SET_CLIENT_BUYER",
    SET_CLIENT_BUYERS = "clientsBuyers:SET_CLIENT_BUYERS",
    UPDATE_ASSIGNED_TO = "clientBuyers:UPDATE_ASSIGNED_TO",
}

export const setClientBuyer = createAction<BuyerClient>(Types.SET_CLIENT_BUYER);
export const setClientBuyers = createAction<ListResult<BuyerClient>>(Types.SET_CLIENT_BUYERS);
export const updateBuyerAssignedTo = createAction<{ accountId: number, managerId: string }>(Types.UPDATE_ASSIGNED_TO);

export const Actions = {
    [Types.SET_CLIENT_BUYER]: setClientBuyer,
    [Types.SET_CLIENT_BUYERS]: setClientBuyers,
    [Types.UPDATE_ASSIGNED_TO]: updateBuyerAssignedTo,
};
export type Actions = typeof Actions;

export const getClientBuyer = wrapper(ClientsBuyersAPI.getClientBuyer, {
    action: setClientBuyer,
});

export const getClientBuyers = wrapper(AccountsAPI.queryAccountsByAdmin, {
    action: setClientBuyers,
});

export const updateClientBuyer = wrapper(ClientsBuyersAPI.updateClientBuyer, {
    action: setClientBuyer
});

export const inductClientBuyer = wrapper(ClientsBuyersAPI.inductAccount, {
    action: setClientBuyer
});
