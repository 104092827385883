import SaaSPlan from "src/types/model/SaaSPlan";
import SaaSPlanAPI from "./api/saaSPlans";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_PLANS = "saaSPlans:SET_PLANS",
    SET_PLAN = "saaSPlans:SET_PLAN",
}

export const setSaaSPlans = createAction<SaaSPlan[]>(Types.SET_PLANS);
export const setSaaSPlan = createAction<SaaSPlan>(Types.SET_PLAN);

export const Actions = {
    [Types.SET_PLANS]: setSaaSPlans,
    [Types.SET_PLAN]: setSaaSPlan,
};

export type Actions = typeof Actions;

export const getActiveSaaSPlans = wrapper(SaaSPlanAPI.getActivePlans, {
    action: setSaaSPlans
});
export const getPlans = wrapper(SaaSPlanAPI.getPlans, {
    action: result => setSaaSPlans(result.items)
});
export const createSaaSPlan = wrapper(SaaSPlanAPI.createSaaSPlan, {
    action: setSaaSPlan
});
export const updateSaaSPlan = wrapper(SaaSPlanAPI.updateSaaSPlan, {
    action: setSaaSPlan
});
