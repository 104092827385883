import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import React, { Ref } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export type ButtonProps = {
    to?: string;
    loading?: boolean;
    // href?: string;
    hash?: string;
    target?: string;
    rel?: string;
    plain?: boolean;
    plainLink?: boolean;
    white?: boolean;
    border?: boolean;
    borderwhite?: boolean;
    borderdark?: boolean;
    dark?: boolean;
    uppercase?: boolean;
    small?: boolean;
    center?: boolean;
    large?: boolean;
    regular?: boolean;
    long?: boolean;
    selected?: boolean;
    component?: string;
    secondary?: boolean;
    red?: boolean;
} & Omit<MuiButtonProps, 'href'>;

function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>) {
    const { children, to, loading, hash, plain, plainLink, white, border, borderwhite, borderdark, dark, uppercase, small, center, large, regular, long, selected, endIcon, variant, disabled, secondary, red, ...otherProps } = props;

    const history = useHistory();

    const isDisabled = disabled || loading;
    const className = classNames(
        styles.button,
        {
            [styles.default]: !plain && !border && !dark && !white,
            [styles.plain]: plain,
            [styles.plainLink]: plainLink,
            [styles.white]: white,
            [styles.border]: border,
            [styles.borderwhite]: borderwhite,
            [styles.borderdark]: borderdark,
            [styles.dark]: dark,
            [styles.uppercase]: uppercase,
            [styles.center]: center,
            [styles.large]: large,
            [styles.regular]: regular,
            [styles.long]: long,
            [styles.selected]: selected,
            [styles.disabled]: isDisabled,
            [styles.red]: red,
        },
        props.className,
    );

    let size: "small" | "medium" | "large" | undefined;
    if (small) {
        size = "small";
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (to) {
            e.preventDefault();
            history.push(to);
        }
        props.onClick?.(e);
    };


    return (
        <MuiButton {...otherProps}
                   component={to ? "a" : "button"}
                   href={to || undefined}
                   onClick={onClick}
                   disabled={isDisabled}
                   className={className}
                   size={size}
                   variant={variant || "text"}
                   buttonRef={ref}>
            {loading ? <Spinner small white={!plain} /> : children}
        </MuiButton>
    );
}

export default React.forwardRef(Button);
