/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import useFormikField, { FormikFieldExtraProps } from "../../../hooks/useFormikField";
import CheckBox, { CheckboxProps } from "./CheckBox";

export type FormikCheckboxProps = {
    name: string;
} & Omit<CheckboxProps, "name" | "value" | "error"> & FormikFieldExtraProps;

export default function FormikCheckBox(props: FormikCheckboxProps) {
    const { name, ...otherProps } = props;
    const { value, disabled, field } = useFormikField(props);

    const checked = !!value;
    const checkedValue = checked ? "checked" : "";

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, checkedVal: boolean) => {
        props.onChange?.(e, checkedVal);
        field.onChange(e);
    };

    return (
        <CheckBox {...otherProps}
                  {...field}
                  onChange={onChange}
                  checked={checked}
                  value={checkedValue}
                  disabled={disabled} />
    );
}
