import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./SectionHeader.module.scss";

export type HeaderProps = PropsWithChildren<{
    title:string;
    subtitle?: string;
    action?: ReactNode;
    large?: boolean;
}>;

export default function SectionHeader(props: HeaderProps) {
    const { title, subtitle, action } = props;


    return (
        <div className={styles.header}>
            <div className={styles.content}>
                    <h5>{title}</h5>
                    <span className={styles.subtitle}>{subtitle}</span>
            </div>
            <div className={styles.action}>{action}</div>
        </div>
    );
}
