import { capitalize } from "lodash";
import React, { useState } from "react";
import Button from "src/components/widgets/button/Button";
import PagingTable from "src/components/widgets/pagingTable/PagingTable";
import SectionHeader from "src/components/widgets/sectionHeader/SectionHeader";
import useDispatch from "src/hooks/useDispatch";
import useMountEffect from "src/hooks/useMountEffect";
import useSelector from "src/hooks/useSelector";
import TransactionsAPI from "src/redux/actions/api/transactions";
import { getSaaSSubscription } from "src/redux/actions/saaSSubscriptions";
import { showError } from "src/redux/actions/snackbars";
import { PagingTableFilter } from "src/redux/reducers/tables";
import SaaSSubscription from "src/types/model/SaaSSubscription";
import Transaction, { ReceiptParams } from "src/types/model/Transaction";
import { encodeSpecialChars } from "src/util/url";
import { currency } from "src/util/utils";
import styles from "../Billing.module.scss";

type Props = {
    accountId: number
}

export default function Transactions({ accountId }: Props) {
    const [, setDownloadingReceipt] = useState<string>("");
    const dispatch = useDispatch();
    const buyer = useSelector((state) => state.clientsBuyers[accountId]);
    const subscription = useSelector<SaaSSubscription>(state => state.saaSSubscriptions[accountId]);

    useMountEffect(async () => {
        await dispatch(getSaaSSubscription(buyer?.id));
    });

    const handleReceiptDownload = (id: string, params: ReceiptParams) => {
        setDownloadingReceipt(id);
        const siteURLParam = encodeURI(`${window.location.origin}/receipt?params=${encodeSpecialChars(JSON.stringify(params))}`);
        const url = `${process.env.REACT_APP_PDF_SERVICE_URL}/pdf?url=${siteURLParam}&title=amotai-receipt`;
        window.open(url);
    };

    const getQuery = (filter: PagingTableFilter) => {
        const { limit, cursor } = filter;
        return TransactionsAPI.getAccountTransactions({ cursor, limit, accountId });
    };

    const renderDownloadLink = (t: Transaction) => {
        if (buyer) {
            const receiptParams: ReceiptParams = {
                businessName: buyer.name,
                businessAddress: buyer.physicalAddressComponents?.formattedAddress,
                invoiceDate: t.created,
                invoiceNo: t.id,
                reference: buyer.stripeCustomerId,
                saasPlan: subscription?.plan?.name,
                metaData: t.metadata,
                total: t.amount - (t.amountRefunded ?? 0),
            };
            const failure = {
                failed: (t.failureCode ?? '').length > 0,
                message: t.failureMessage,
            };
            const fullRefunded = t.refunded && t.amount === t.amountRefunded;
            let btnText = `Download receipt`;
            if (failure.failed) {
                btnText = failure.message;
            } else if (fullRefunded) {
                btnText = 'Refunded';
            }
            return (
                <Button plainLink
                    disabled={failure.failed || fullRefunded}
                    onClick={() => handleReceiptDownload(t.id, receiptParams)}
                        className={styles.download_link}>
                    {btnText}
                </Button>
            );
        }

        return dispatch(showError("There was an error downloading your receipt"));
    };

    const transactionsColumn = [
        {
            title: "Date",
            render: (t: Transaction) => t.created ? new Date(t.created).toLocaleDateString("en-NZ") : "n/a"
        },
        {
            title: "Card",
            field: "last4"
        },
        {
            title: "Type",
            render: (t: Transaction) => capitalize(t.type || "")
        },
        {
            title: "Amount",
            render: (t: Transaction) => t.amount ? `${t.currency.toUpperCase()} $${currency((t.amount / 100).toFixed(2))}` : "$0"
        },
        {
            render: renderDownloadLink
        }
    ];

    return (<div className={styles.section}>
        <SectionHeader title="Billing history" />
        <PagingTable<Transaction, PagingTableFilter>
                    id="transaction"
                    getData={getQuery}
                    columns={transactionsColumn} 
        />
    </div>);
}