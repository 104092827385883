import { Formik } from "formik";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import useUserRoles from "src/hooks/useUserRoles";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { showError } from "../../../../../../../redux/actions/snackbars";
import { updateSupplier } from "../../../../../../../redux/actions/suppliers";
import { INSURANCES, SUPPLIER_TURNOVER } from "../../../../../../../util/constants";
import Button from "../../../../../../widgets/button/Button";
import OtherInsurancesFieldArray from "../../../../../../widgets/formikFields/OtherInsurancesFieldArray";
import Header from "../../../../../../widgets/header/Header";
import FormikSelect from "../../../../../../widgets/input/FormikSelect";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import Loading from "../../../../../loading/Loading";
import styles from "../../SupplierDetail.module.scss";


type RouteParams = {
    accountId: string;
}

export type FinanceAndLegalValues = {
    turnoverInLastFinancialYear: string,
    professionalIndemnity: string,
    publicLiabilityInsurance: string,
    disclosures: string,
    otherInsurances: { name: string, value: string }[]
}


export const OrgFinancialsValidationSchema = Yup.object({
    turnoverInLastFinancialYear: Yup.string().required("Required"),
    professionalIndemnity: Yup.string().required("Required"),
    publicLiabilityInsurance: Yup.string().required("Required"),
    otherInsurances: Yup.array().of(Yup.object({
        name: Yup.string(),
        value: Yup.string(),
    })),
});

export default function FinanceAndLegal(props: RouteComponentProps<RouteParams>) {
    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const suppliers = useSelector((state) => state.suppliers);
    const id = props?.match?.params?.accountId;
    const supplier = suppliers[id];
    const dispatch = useDispatch();
    const { isSuperAdmin } = useUserRoles();

    const onSubmit = async (values: FinanceAndLegalValues) => {
        try {
            setLoading(true);
            const otherInsurances: { [name: string]: string } = {};
            values.otherInsurances.forEach((o) => {
                otherInsurances[o.name] = o.value;
            });
            await dispatch(updateSupplier({
                ...supplier,
                ...values,
                otherInsurances,
            }));
            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    const getOtherInsurancesInitialValues = () => {
        return Object.keys(supplier.otherInsurances || {}).map((o) => {
            return {
                name: o,
                value: (supplier.otherInsurances || {})[o]
            };
        });
    };

    return (
        <Formik<FinanceAndLegalValues> initialValues={{
            turnoverInLastFinancialYear: supplier.turnoverInLastFinancialYear || "",
            professionalIndemnity: supplier.professionalIndemnity || "",
            publicLiabilityInsurance: supplier.publicLiabilityInsurance || "",
            disclosures: supplier.disclosures || "",
            otherInsurances: getOtherInsurancesInitialValues() || [{ name: "", value: "" }]
        }} validationSchema={OrgFinancialsValidationSchema} onSubmit={onSubmit}>
            {(formikBag) => {
                const toggleEdit = async () => {
                    if (editing && formikBag.dirty) {
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };
                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>
                        <div className={styles.form}>
                            <form onSubmit={formikBag.handleSubmit}>
                                <Header title={`${supplier.name}`}
                                    action={isSuperAdmin ? (
                                        <Button uppercase loading={loading || formikBag.isSubmitting}
                                            onClick={toggleEdit}>{actionText}</Button>
                                    ) : undefined}
                                    subnodes={<AccountHistory account={supplier} />} />
                                <SectionHeader title={"Finance and Legal"} />
                                <div>
                                    <div className={styles.form}>
                                        <FormikSelect name={"turnoverInLastFinancialYear"}
                                            disabled={!editing}
                                            label={"Annual turnover"}
                                            options={SUPPLIER_TURNOVER}
                                        />
                                        <FormikSelect name={"professionalIndemnity"}
                                            disabled={!editing}
                                            label={"Professional Indemnity Insurance"}
                                            options={INSURANCES}
                                        />
                                        <FormikSelect name={"publicLiabilityInsurance"}
                                            disabled={!editing}
                                            label={"Public Liability"}
                                            options={INSURANCES}
                                        />
                                        <OtherInsurancesFieldArray disabled={!editing} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                );
            }}
        </Formik>
    );

}