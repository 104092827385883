import AmotaiUser from "../../types/model/AmotaiUser";
import { Actions, Types } from "../actions/users";

const initialState: {
    users: { [key: string]: AmotaiUser },
    inductableUsers?: AmotaiUser[],
} = {
    users: {}
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function users(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_USER: {
            const { payload } = action as ActionValueTypes<Types.SET_USER>;
            const user = payload!;
            const newState = { ...state };
            newState.users[user.id] = user;
            return newState;
        }
        case Types.SET_USERS: {
            const { payload } = action as ActionValueTypes<Types.SET_USERS>;

            const newState = { ...state };
            payload!.items.forEach((user) => {
                newState.users[user.id] = user;
            });

            return newState;
        }
        case Types.DEL_USER: {
            const user = (action as ActionValueTypes<Types.DEL_USER>).payload!;
            const newState = { ...state };
            delete newState.users[user.id];
            return newState;
        }
        case Types.SET_INDUCTABLE_USERS: {
            const inductableUsers = (action as ActionValueTypes<Types.SET_INDUCTABLE_USERS>).payload!;
            return {
                ...state,
                inductableUsers,
            };
        }

        default: {
            return state;
        }
    }
}
