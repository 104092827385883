import Note from "../../../types/model/Note";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import ListResult from "src/types/ListResult";


function getNotes(accountId: number, limit?: number, cursor?: string | null): Promise<ListResult<Note>> {
    return get(`v1/admin/accounts/${accountId}/notes`, { params: { limit, cursor } });
}

function createUpdateNote(accountId: number, note: Note): Promise<Note> {
    return post(`v1/admin/accounts/${accountId}/notes`, note);
}

function deleteNote(accountId: number, noteId: number): Promise<Note[]> {
    return del(`v1/admin/accounts/${accountId}/notes/${noteId}`);
}

function getAllNotes(flagged?: boolean): Promise<Note[]> {
    return get('v1/admin/notes', { params: { flagged } });
}

const NotesAPI = {
    getNotes,
    createUpdateNote,
    deleteNote,
    getAllNotes,
};

export default NotesAPI;
