import get from "./util/get";
import { Category } from "../../../types/model/Category";
import post from "./util/post";
import del from "./util/del";

function getMainCategories() {
    return get("/v1/categories");
}

function getSubCategories(categoryId: number) {
    return get(`/v1/admin/categories/${categoryId}/sub`);
}

function createOrUpdateCategory(data: Partial<Category>) {
    return post("/v1/admin/categories", data);
}

function deleteCategory(categoryId: number) {
    return del(`/v1/admin/categories/${categoryId}`);
}

const CategoryAPI = {
    getMainCategories,
    getSubCategories,
    createOrUpdateCategory,
    deleteCategory
};

export default CategoryAPI;