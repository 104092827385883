import React, { useRef, useState } from "react";
import AmotaiAccount from "src/types/model/AmotaiAccount";
import { Column } from "material-table";
import AmotaiConnection from "src/types/model/AmotaiConnection";
import useDispatch from "src/hooks/useDispatch";
import { PagingTableFilter } from "src/redux/reducers/tables";
import useSelector from "src/hooks/useSelector";
import { getBusinessContacts } from "src/redux/actions/businessContacts";
import useAsyncEffect from "src/hooks/useAsyncEffect";
import BusinessContactsAPI from "src/redux/actions/api/businessContacts";
import PagingTable, { PagingTableRef } from "../../pagingTable/PagingTable";
import Header from "../../header/Header";
import styles from "./AmotaiConnections.module.scss";
import AccountHistory from "../history/AccountHistory";

type Props<T> = {
    amotaiAccount: T
}
export default function AmotaiConnections<T extends AmotaiAccount>({ amotaiAccount }: Props<T>) {
    const dispatch = useDispatch();
    const businessContacts = useSelector(state => state.businessContacts.businessContactObjects);
    const tableRef = useRef<PagingTableRef<AmotaiConnection, PagingTableFilter>>(null);
    const [connectionsCnt, setConnectionsCnt] = useState<string | number>("-");

    useAsyncEffect(async () => {
        await dispatch(getBusinessContacts(amotaiAccount.id));
        const connectionsCnt = await BusinessContactsAPI.getConnectionsNumberInAccount(amotaiAccount.id);
        setConnectionsCnt(connectionsCnt);
    });

    const getQuery = (filter: PagingTableFilter) => {
        const { limit, cursor } = filter;
        return BusinessContactsAPI.getConnectionsInAccount(amotaiAccount.id, limit, cursor);
    };
    const columns: Column<AmotaiConnection>[] = [
        {
            title: "Contact viewed",
            render: c => businessContacts[c.businessContact]?.name
        },
        {
            title: "Viewed by",
            field: "viewerName"
        },
        {
            title: "Business name",
            field: "viewerAccountName"
        },
        {
            title: "Type",
            field: "viewerAccountType"
        },
        {
            title: "Date viewed",
            render: c => new Date(c.viewedAt).toLocaleDateString("en-NZ")
        },
        {
            title: "Time viewed",
            render: c => new Date(c.viewedAt).toLocaleTimeString("en-NZ")
        }
    ];
    return (
        <div className={styles.connections}>
            <Header title={`${amotaiAccount.name}`}
                subnodes={<AccountHistory account={amotaiAccount} />} />

            <PagingTable<AmotaiConnection, PagingTableFilter>
                id={"connections-in-account"}
                title={`Total connections to ${amotaiAccount.name}: ${connectionsCnt}`}
                tableRef={tableRef}
                options={{
                    toolbar: true,
                    search: true, searchFieldStyle: {}
                }}
                columns={columns}
                getData={getQuery} />
        </div>
    );
}