import { Actions, Types } from "../actions/businessContacts";
import BusinessContact from "../../types/model/BusinessContact";

const initialState: {
    businessContacts: BusinessContact[],
    businessContactObjects: {
        [id: number]: BusinessContact
    },
    mainContact: BusinessContact | null
} = {
    businessContacts: [],
    businessContactObjects: {},
    mainContact: null
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function businessContacts(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BUSINESS_CONTACTS: {
            const { payload } = action as ActionValueTypes<Types.SET_BUSINESS_CONTACTS>;
            const newState = initialState;

            if (!payload) {
                return state;
            }

            newState.businessContactObjects = {};

            payload.forEach((b: BusinessContact) => {
                newState.businessContactObjects[b.id] = b;
            });

            return newState;
        }
        default: {
            return state;
        }
    }
}