import React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { Field } from "formik";
import { InputLabel } from "@material-ui/core";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "./FormikTextArea.module.scss";

type Props = {
    name: string;
    disabled?: boolean;
    label?: string;
    tooltip?: string;
} & TextareaAutosizeProps;

export default function FormikTextArea (props: Props) {
    const { name, disabled, label, tooltip, ...otherProps } = props;

    return (
        <Field name={name}>
            {({ field, form, meta }: any) => {
                const showError = meta.touched && !!meta.error;
                const error = showError ? meta.error : null;
                const dis = disabled || form.isSubmitting;

                return (
                    <div className={styles.full_text_input}>
                        {label && (
                            <div className={styles.label_tooltip_container}>
                                <InputLabel shrink htmlFor={"input"}>
                                    {label}
                                </InputLabel>
                                {tooltip && (
                                    <div data-tip={tooltip}>
                                        <img src={Tooltip} alt={"Help"} />
                                    </div>
                                )}
                            </div>
                        )}
                        <TextareaAutosize disabled={dis} {...otherProps} {...field} className={styles.text_area_container} />
                        {showError && error && <div className={styles.error}>{error}</div>}
                    </div>
                );
            }}
        </Field>
    );
}