import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AccountHistory from "src/components/widgets/account/history/AccountHistory";
import DeleteAccountDialog from "src/components/widgets/dialog/accountDialog/DeleteAccountDialog";
import FormikDatePicker from "src/components/widgets/input/FormikDatePicker";
import Select from "src/components/widgets/input/Select";
import { getInductableUsers, getRegionalManagers } from "src/redux/actions/users";
import { AccountStatus, InductStatus } from "src/types/model/AmotaiAccount";
import AmotaiUser from "src/types/model/AmotaiUser";
import Supplier from "src/types/model/Supplier";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import { getBusinessReferences, updateBusinessReferences } from "../../../../../../../redux/actions/businessReference";
import { showError } from "../../../../../../../redux/actions/snackbars";
import { updateSupplier } from "../../../../../../../redux/actions/suppliers";
import Address from "../../../../../../../types/model/Address";
import BusinessReference from "../../../../../../../types/model/BusinessReference";
import {
    HEALTH_AND_SAFETY,
    LEGAL_STRUCTURE,
    REGIONS,
    REGIONS_NO_NATIONWIDE,
    YearOptions
} from "../../../../../../../util/constants";
import AssignManagerDialog from "../../../../../../widgets/assignManagerDialog/AssignManagerDialog";
import Button from "../../../../../../widgets/button/Button";
import { DialogRef } from "../../../../../../widgets/dialog/Dialog";
import BusinessReferenceFieldArray from "../../../../../../widgets/formikFields/BusinessReferenceFieldArray";
import Header from "../../../../../../widgets/header/Header";
import FormikAddressInput from "../../../../../../widgets/input/FormikAddressInput";
import FormikCheckboxGroup from "../../../../../../widgets/input/FormikCheckboxGroup";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import FormikSelect from "../../../../../../widgets/input/FormikSelect";
import SectionHeader from "../../../../../../widgets/sectionHeader/SectionHeader";
import Loading from "../../../../../loading/Loading";
import styles from "./Details.module.scss";

type RouteParams = {
    accountId: string;
}

export type SupplierFormValues = {
    name: string;
    legalStructure: string;
    legalName: string;
    yearEstablished: string;
    nzCompaniesNumber: string;
    nzbn: string;
    regions: string[];
    logo: string;
    description: string;
    postalAddress: string;
    physicalAddress: string;
    physicalAddressInput: string;
    physicalAddressComponents: Address;
    healthAndSafetyQualifications: string[];
    healthAndSafetyIncidences: string;
    businessReferences: BusinessReference[];
    inductedAt?: string;
    inductedBy?: string;
    regionBased: string;
};

const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    legalName: Yup.string().required("Required"),
    yearEstablished: Yup.string().required("Required"),
    nzCompaniesNumber: Yup.string().required("Required"),
    nzbn: Yup.string().required("Required"),
    regions: Yup.array().required("Required").min(1, "At least 1 is required"),
    logo: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    healthAndSafetyQualifications: Yup.string().required("Required").min(1),
    postalAddress: Yup.string(),
    physicalAddress: Yup.string(),
    physicalAddressInput: Yup.string(),
    physicalAddressComponents: Yup.object().required("Required"),
    healthAndSafetyIncidences: Yup.string().required("Required"),
    businessReferences: Yup.array().of(Yup.object({
        projectName: Yup.string(),
        client: Yup.string(),
        contactName: Yup.string(),
        contactEmail: Yup.string().email(),
        contactPhoneNumber: Yup.string(),
    })).min(1, "At least one reference is required"),
    regionBased: Yup.string().required("Required")
});

export default function Details(props: RouteComponentProps<RouteParams>) {
    const id = props?.match?.params?.accountId!; //id must be exists, parent component has already loaded
    const supplier: Supplier = useSelector((state) => state.suppliers[id]);
    const businessReferences = useSelector((state) => state.businessReferences[id]);
    const dispatch = useDispatch();
    const assignRmDialogRef = useRef<DialogRef>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [inductStatus, setInductStatus] = useState<string>(supplier.inductedAt ? InductStatus.INDUCTED : InductStatus.UNINDUCTED);
    const [inductHelperText, setInductHelperText] = useState<string>();
    const { isSuperAdmin, isAdmin, isRegionalManager } = useUserRoles();
    const regionalManagers = useSelector(state => state.managers.regionalManagers);
    const inductableUsers = useSelector(state => state.users.inductableUsers);
    const deleteDialog = useRef<DialogRef | null>(null);
    const history = useHistory();

    const [regionalManager, setRegionalManager] = useState<AmotaiUser>(regionalManagers[supplier.assignedTo]);

    useMountEffect(async () => {
        if (Object.keys(regionalManagers).length === 0) {
            const result = await dispatch(getRegionalManagers());
            const _regionalManager = result.items.find(m => m.id === supplier.assignedTo);
            if (_regionalManager) {
                setRegionalManager(_regionalManager);
            }
        }
        if (!businessReferences) {
            await dispatch(getBusinessReferences(Number(id)));
        }
        if (!inductableUsers) {
            dispatch(getInductableUsers()); //don't await as this value is not required immediately
        }
        setLoading(false);
    });

    const inductableUsersOption = useMemo((): Array<{ label: string, value: string }> => {
        if (!inductableUsers) {
 return []; 
}
        return inductableUsers
            .sort((a, b) => (a.name ?? 'z').localeCompare(b.name ?? 'z'))
            .map(u => ({
                label: `${u.name ?? '-'} (${u.email})`,
                value: u.id,
            })) ?? [];
    }, [inductableUsers]);

    const inductedBy = useMemo((): string | undefined => {
        return supplier.inductedBy ? inductableUsers?.find(u => u.id === supplier.inductedBy)?.name : undefined;
    }, [inductableUsers, supplier]);

    const accountStatusText = useMemo(() => {
        let status = supplier.status.toLowerCase().replace(/^\w/, v => v.toUpperCase()).replace(/_\w?/, v => v.toUpperCase()).replace('_', ' ');
        let message = '';
        switch (supplier.status) {
            case AccountStatus.DISABLED: //suspension message
                status = 'On Hold';
                message = `: ${supplier.suspensionMessage ?? 'No reason appended'}`;
                break;
            case AccountStatus.DECLINED: //review message
                message = `: ${supplier.reviewMessage ?? 'No reason appended'}`;
                break;
            default:
                break;
        }

        return <span>This account is <b>{status}</b>{message}</span>;
    }, [supplier]);

    const initialValues: SupplierFormValues = {
        name: supplier.name || "",
        legalStructure: supplier.legalStructure || "",
        legalName: supplier.legalName || "",
        yearEstablished: supplier.yearEstablished || "",
        nzCompaniesNumber: supplier.nzCompaniesNumber || "",
        nzbn: supplier.nzbn || "",
        regions: supplier.regions || [],
        logo: supplier.logo || "",
        description: supplier.description || "",
        postalAddress: supplier.postalAddress || "",
        physicalAddress: supplier.physicalAddress || "",
        physicalAddressInput: supplier.physicalAddressComponents?.formattedAddress ?? "",
        physicalAddressComponents: supplier.physicalAddressComponents,
        healthAndSafetyQualifications: supplier.healthAndSafetyQualifications || [],
        healthAndSafetyIncidences: supplier.healthAndSafetyIncidences || "",
        businessReferences: businessReferences || [],
        inductedAt: supplier.inductedAt,
        inductedBy: supplier.inductedBy,
        regionBased: supplier.regionBased || ""
    };

    const onSubmit = async (values: SupplierFormValues) => {
        try {
            setLoading(true);

            const { inductedBy, inductedAt } = values;
            await dispatch(updateSupplier({
                ...supplier,
                ...values,
                inductedAt,
                inductedBy,
            }));
            const references = values.businessReferences.map(r => ({ ...r, account: supplier.id }));
            await dispatch(updateBusinessReferences(supplier.id, references));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const onDeleteFinish = useCallback(() => {
        history.push('/suppliers');
    }, [history]);

    if (loading) {
        return <Loading />;
    }
    const disableFields = !editing || isAdmin || isRegionalManager;

    return (<>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(formikBag: FormikProps<SupplierFormValues>) => {
                const toggleEdit = async () => {
                    if (editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyQualifications)) {
                        setInductHelperText(undefined);
                        if (inductStatus === InductStatus.INDUCTED && (!formikBag.values.inductedAt || !formikBag.values.inductedBy)) {
                            setInductHelperText("Inducted at/by fields should not be empty");
                            return;
                        }
                        await onSubmit(formikBag.values);
                    }

                    setEditing(!editing);
                    if (!editing) {
                        formikBag.resetForm();
                    }
                };

                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyQualifications) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                const inductContainerClassnames = classNames(styles.induct_container, {
                    [styles.induct_active]: !!supplier.inductedAt && !editing,
                    [styles.induct_editing]: editing
                });

                const onAssignRegionalManager = () => {
                    assignRmDialogRef.current?.show();
                };

                const addRegionalManagerButton = !isSuperAdmin ?
                    null :
                    (
                        <Button plain className={styles.add_rm_button} onClick={onAssignRegionalManager}>
                            Assign a regional manager
                        </Button>
                    );

                return (
                    <Form className={styles.form}>
                        <Header title={`${supplier.name}`}
                            action={isSuperAdmin || isAdmin || isRegionalManager ? (
                                <Button uppercase loading={loading || formikBag.isSubmitting}
                                    onClick={toggleEdit}>{actionText}</Button>
                            ) : undefined}
                            subnodes={<AccountHistory account={supplier} />} />
                        <div className={styles.account_status}>
                            {accountStatusText}
                        </div>
                        <div className={inductContainerClassnames}>
                            <div className={styles.induct}>
                                <span>This account &nbsp;</span>

                                {editing ? (
                                    <div className={styles.pickers}>
                                        <Select options={[InductStatus.INDUCTED, InductStatus.UNINDUCTED]} noPadding
                                            containerClassName={styles.selector}
                                            defaultValue={supplier.inductedAt ? InductStatus.INDUCTED : InductStatus.UNINDUCTED}
                                            onChange={(iStatus: string) => {
                                                if (iStatus === InductStatus.UNINDUCTED) {
                                                    formikBag.setFieldValue("inductedAt", undefined);
                                                    formikBag.setFieldValue('inductedBy', undefined);
                                                }
                                                setInductStatus(iStatus);
                                            }} />
                                        {inductStatus === InductStatus.INDUCTED && (
                                            <div className={styles.induct_by_at}>
                                                <FormikDatePicker dateStringOnly dateStringFormat={"DD/MM/YYYY"}
                                                    name={"inductedAt"}
                                                    className={styles.date_picker}
                                                    onChange={() => setInductHelperText(undefined)} />
                                                <FormikSelect name={"inductedBy"} placeholder="please select"
                                                    options={inductableUsersOption}
                                                    containerClassName={styles.selector_by}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                        <div>
                                            <b><span>{supplier.inductedAt ? "was" : "has not been"}</span></b>
                                            <span>&nbsp; inducted</span>
                                            {!!supplier.inductedAt && <>
                                                <span>&nbsp; on &nbsp;<b>{supplier.inductedAt}</b></span>
                                                {!!inductedBy && <span>&nbsp; by &nbsp;<b>{inductedBy}</b></span>}
                                            </>}
                                        </div>
                                    )}
                            </div>
                            {inductHelperText && <span className={styles.induct_helper}>{inductHelperText}</span>}
                        </div>
                        {(isAdmin || isSuperAdmin || isRegionalManager) && (
                            <div className={styles.regional_manager_section}>
                                <SectionHeader title={"Regional Manager"} action={addRegionalManagerButton} />
                                <span>
                                    {(regionalManager || {}).name || "This account does not have a regional manager assigned."}
                                </span>
                            </div>
                        )}
                        <SectionHeader title={"Business Details"} />

                        <FormikInput name={"name"}
                            inputClassname={classNames(styles.half_input, styles.first)}
                            disabled={disableFields}
                            label={"Trading name of business"} />
                        <FormikSelect name={"legalStructure"}
                            noPadding
                            containerClassName={styles.half_input}
                            disabled={disableFields}
                            label={"Structure"}
                            options={LEGAL_STRUCTURE} />
                        <FormikInput name={"legalName"}
                            inputClassname={classNames(styles.half_input, styles.first)}
                            disabled={disableFields}
                            label={"Legal name of business"} />
                        <FormikSelect name={"yearEstablished"}
                            noPadding
                            containerClassName={styles.half_input}
                            disabled={disableFields}
                            label={"Year Established"}
                            options={YearOptions()} />
                        <FormikInput name={"nzCompaniesNumber"}
                            inputClassname={classNames(styles.half_input, styles.first)}
                            disabled={disableFields}
                            label={"NZ Companies no."} />
                        <FormikInput name={"nzbn"}
                            inputClassname={styles.half_input}
                            disabled={disableFields}
                            label={"NZ Business no."} />
                        <FormikInput name={"postalAddress"}
                            inputClassname={styles.full_input}
                            disabled
                            placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                            label={"Postal Address Old"}
                        />
                        <FormikInput name={"postalAddress"}
                            inputClassname={classNames(styles.full_input)}
                            placeholder={formikBag.values.postalAddress}
                            disabled={disableFields}
                            label={"Postal Address"} />
                        <FormikInput name={"physicalAddress"}
                            inputClassname={styles.full_input}
                            disabled
                            placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                            label={"Physical Address Old"}
                        />
                        <FormikAddressInput name={"physicalAddressComponents"}
                            formattedName={"physicalAddressInput"}
                            inputClassname={classNames(styles.full_input)}
                            disabled={disableFields}
                            label={"Physical Address"} />
                        <div className={styles.checkbox_container}>
                            <SectionHeader title={"Regions"} />
                            <div className={styles.checkbox_container}>
                                <div className={styles.heading}>
                                    <label>Regions of operation</label>
                                </div>
                                <FormikCheckboxGroup name={"regions"} disabled={disableFields} stringItems={REGIONS} />
                            </div>

                            <div className={styles.checkbox_container}>
                                <div className={styles.heading}>
                                    <label>Region based</label>
                                </div>
                                <FormikSelect name={"regionBased"} disabled={disableFields}
                                    options={REGIONS_NO_NATIONWIDE} />
                            </div>
                        </div>

                        <div className={styles.form_section}>
                            <SectionHeader title={"Project references"} />
                            <BusinessReferenceFieldArray disabled={!editing} />
                        </div>

                        <div className={classNames(styles.form_section, styles.health_safety_container)}>
                            <SectionHeader title={"Health and Safety qualifications"} />
                            <FormikCheckboxGroup name={"healthAndSafetyQualifications"}
                                disabled={!editing}
                                stringItems={HEALTH_AND_SAFETY} />
                            <FormikInput name={"healthAndSafetyIncidences"}
                                disabled={!editing}
                                multiline
                                inputClassname={styles.full_text_input}
                                label={"Any major health and safety incidences? If yes, please explain"} />
                        </div>
                        <AssignManagerDialog ref={assignRmDialogRef} account={supplier} onFinish={setRegionalManager} />

                        <div className={styles.form_section}>
                            <SectionHeader title="How did you hear about us" />
                            <div>{supplier.adOption ?? 'N/A'}</div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        {isSuperAdmin && supplier.status !== AccountStatus.DELETED &&
            <div style={{ textAlign: "right" }}>
                <Button red onClick={() => deleteDialog.current?.show()} disabled={!editing}>Delete account</Button>
            </div>}
        <DeleteAccountDialog ref={deleteDialog} account={supplier} onFinish={onDeleteFinish} />
    </>);
}
