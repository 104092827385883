import { Form, Formik, FormikProps } from "formik";
import React, { forwardRef, Ref, useState } from "react";
import { InviteUserDTO } from "src/types/model/AmotaiAccount";
import AmotaiUser, { AccountRole } from "src/types/model/AmotaiUser";
import * as Yup from "yup";
import useDispatch from "../../../../hooks/useDispatch";
import useForwardRef from "../../../../hooks/useForwardRef";
import { showError, showSuccess } from "../../../../redux/actions/snackbars";
import { inviteToAccount, updateAccountUser } from "../../../../redux/actions/users";
import Button from "../../button/Button";
import FormikInput from "../../input/FormikInput";
import FormikSelect from "../../input/FormikSelect";
import Dialog, { DialogRef } from "../Dialog";
import styles from "./AccountUserDialog.module.scss";

type Props = {
    user: AmotaiUser | null;
    onFinish?: (values: InviteUserDTO) => void;
    onClose?: () => void;
    accountId: number;
};

function CreateUpdateAccountUserDialog(props: Props, ref: Ref<DialogRef>) {
    const { accountId, onFinish, onClose, user } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const dialogRef = useForwardRef<DialogRef>(ref);

    const onCancelClick = () => {
        dialogRef.current?.hide();
    };

    const onSubmit = async (values: InviteUserDTO, formikBag: FormikProps<InviteUserDTO>) => {
        setLoading(true);

        try {
            if (user) {
                const { accountRole, ...rest } = values;
                await dispatch(updateAccountUser(accountId, user.id, { ...user, ...rest }));
            } else {
                const { email, ...rest } = values;
                await dispatch(inviteToAccount(accountId, { email: email.trim(), ...rest }));
            }

            onCancelClick();
            formikBag.resetForm();
            dispatch(showSuccess(`User ${user ? 'updated' : 'invited'} successfully.`));
            onFinish?.(values);
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    };

    const userValidation = Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: user ? Yup.string() : Yup.string().email("Must be a valid email").trim().required("Required"),
        mobileNumber: Yup.string().required("Required"),
        accountRole: user ? Yup.string() : Yup.string().required("Required"),
        jobTitle: Yup.string().required("Required")
    });

    return (
        <Dialog
            dialogRef={dialogRef}
            disableBackdropClick={loading}
            header={user ? "EDIT ACCOUNT USER" : "INVITE NEW USER"}
            onClose={onClose}
        >
            <div className={styles.dialog_form}>
                <Formik<InviteUserDTO>
                    initialValues={(user ?? { accountRole: AccountRole.USER }) as InviteUserDTO}
                    validationSchema={userValidation}
                    onSubmit={onSubmit}
                    isInitialValid={!!user}
                >
                    {(bag) => {
                        return (
                            <Form>
                                <FormikInput name={"firstName"} label={"First Name"} />
                                <FormikInput name={"lastName"} label={"Last Name"} />
                                {!user && <FormikInput name={"email"} label={"Email"} />}
                                <FormikInput name={"jobTitle"} label={"Job Title"} />
                                <FormikInput name={"mobileNumber"} label={"Mobile number"} number />
                                <FormikInput name={"phoneNumber"} label={"Phone number"} number />
                                {!user && (
                                    <>
                                        <div className={styles.permission}>
                                            <p>Account roles</p>
                                            <span><strong>Admins</strong> can access all parts of the business profile.<br /><strong>Users</strong> can only view the directory and their own profile. They can not view or update any business details.</span>
                                        </div>
                                        <FormikSelect name={"accountRole"}
                                            options={[AccountRole.USER, AccountRole.ADMIN]}
                                            label={"Account role"} />
                                    </>
                                )}
                                <div className={styles.dialog_footer}>
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        disabled={!bag.isValid}
                                    >
                                        {user ? "Update" : "Invite"}
                                    </Button>
                                    <span />
                                    <Button plainLink onClick={onCancelClick}
                                        disabled={loading}>Cancel</Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Dialog>
    );
}

export default forwardRef(CreateUpdateAccountUserDialog);
