import { Form, Formik } from "formik";
import { Column } from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "src/components/routes/loading/Loading";
import FormikInput from "src/components/widgets/input/FormikInput";
import useUserRoles from "src/hooks/useUserRoles";
import UsersAPI from "src/redux/actions/api/users";
import { showError } from "src/redux/actions/snackbars";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import {
    deleteUserFromAccount,
    deleteUserWithoutAccount,
    getUsers,
    updateUserById
} from "../../../../../redux/actions/users";
import { PagingTableFilter } from "../../../../../redux/reducers/tables";
import ListResult from "../../../../../types/ListResult";
import AmotaiUser, { SystemRole, UserStatus } from "../../../../../types/model/AmotaiUser";
import Button from "../../../../widgets/button/Button";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import Header from "../../../../widgets/header/Header";
import PagingTable, { PagingTableRef } from "../../../../widgets/pagingTable/PagingTable";
import styles from "./Users.module.scss";

const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().trim().email().required(),
});

export default function Users() {

    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = useRef<PagingTableRef<AmotaiUser, PagingTableFilter>>(null);
    const editDialog = useRef<DialogRef>(null);
    const delDialog = useRef<DialogRef>(null);
    const resetDialog = useRef<DialogRef>(null);
    const { isSuperAdmin: isEligible } = useUserRoles();
    const [toEdit, setToEdit] = useState<AmotaiUser>();
    const [toDelete, setToDelete] = useState<AmotaiUser>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [altPressed, setAltPressed] = useState<boolean>(false);
    const [resetPasswordLink, setResetPasswordLink] = useState<string>();

    useEffect(() => {
        const handler = (ev: MouseEvent) => {
            setAltPressed(ev.altKey);
        };
        window.addEventListener('mousemove', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
        };
    }, []);

    const onEdit = useCallback((user: AmotaiUser) => {
        setToEdit(user);
        editDialog.current?.show();
    }, [setToEdit, editDialog]);

    const onCancelEdit = useCallback(() => {
        setToEdit(undefined);
        setError(undefined);
        editDialog.current?.hide();
    }, [setToEdit, editDialog]);

    const onDelete = useCallback((user: AmotaiUser) => {
        setToDelete(user);
        delDialog.current?.show();
    }, [setToDelete, delDialog]);

    const onReset = async (user: AmotaiUser) => {
        setResetPasswordLink(undefined);
        resetDialog.current?.show();
        try {
            const { resetLink } = await UsersAPI.getResetPasswordLink(user.id);
            setResetPasswordLink(resetLink);
        } catch (error) {
            dispatch(showError(error.message));
        }
    };

    const onCancelDelete = useCallback(() => {
        setToDelete(undefined);
        delDialog.current?.hide();
    }, [setToDelete, delDialog]);

    const doDelete = useCallback(async () => {
        if (!toDelete) {
            onCancelDelete();
            return;
        }
        toDelete.status = UserStatus.DISABLED;
        setLoading(true);
        try {
            const accountId = toDelete.account;
            if (!!accountId) {
                dispatch(deleteUserFromAccount(toDelete.account, toDelete.id));
            } else {
                dispatch(deleteUserWithoutAccount(toDelete.id));
            }
            setLoading(false);
            onCancelDelete();
            tableRef.current?.refresh();
        } catch (error) {
            console.log("Failed to delete", error);
            setLoading(false);
        }
    }, [toDelete, onCancelDelete, dispatch, setLoading]);

    const getQuery = async (queryFilter: PagingTableFilter): Promise<ListResult<AmotaiUser>> => {
        const { limit, cursor } = queryFilter;
        return dispatch(getUsers(limit, cursor));
    };

    const handleSubmit = useCallback(async (raw: AmotaiUser) => {
        const data = Object.assign(raw) as AmotaiUser;
        data.name = `${data.firstName} ${data.lastName}`;
        setLoading(true);
        console.log("Going to create or update user:", data);
        try {
            await dispatch(updateUserById(data.id, data));
            setLoading(false);
            onCancelEdit();
            setError(undefined);
            tableRef.current?.refresh();
        } catch (err) {
            console.log("Failed to create or update a user", err);
            setLoading(false);
            setError(err.message);
        }
    }, [setLoading, dispatch, onCancelEdit, setError]);

    const columns: Column<AmotaiUser>[] = [
        {
            title: "Name",
            field: "name",
            render: u => <span>{altPressed ? `${u.name} (${u.id})` : u.name}</span>
        },
        {
            title: "Email",
            field: "email"
        },
        {
            title: "Permission",
            field: "systemRole"
        },
        {
            title: "Status",
            field: "status"
        },
        {
            render: u => <Button plainLink onClick={() => onEdit(u)}>Edit</Button>
        },
        {
            render: u => <Button plainLink onClick={() => onDelete(u)}>Delete</Button>
        },
        {
            render: u => altPressed && u.status !== UserStatus.DELETED ? <Button plainLink onClick={() => onReset(u)}>Reset Password</Button> : null
        }
    ];
    if (!isEligible) {
        history.replace("/");
    }
    return (
        <div>
            <Header title={"Amotai Users"} />
            <PagingTable<AmotaiUser, PagingTableFilter>
                options={{
                    toolbar: true,
                    search: true, searchFieldStyle: {}
                }}
                id={"users"}
                tableRef={tableRef}
                columns={columns}
                getData={getQuery} />

            <Dialog dialogRef={editDialog} header="EDIT USER" onClose={onCancelEdit}>
                <Formik<AmotaiUser> initialValues={toEdit ?? {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: SystemRole.USER
                } as AmotaiUser}
                    validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ dirty, isValid }) => (
                        <Form className={styles.form}>
                            <FormikInput name={"firstName"} label={"First name"} required
                                inputClassname={styles.full_input} />
                            <FormikInput name={"lastName"} label={"Last name"} required
                                inputClassname={styles.full_input} />
                            <FormikInput name={"email"} label={"Email"} required inputClassname={styles.full_input} />
                            <div className={styles.footer}>
                                <Button type={"submit"} disabled={loading || !dirty || !isValid}
                                    loading={loading}>Save</Button>
                                <div />
                                <Button regular plainLink onClick={onCancelEdit} disabled={loading}>Cancel</Button>
                            </div>
                            {!!error && <div className={styles.error}>{error}</div>}
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Dialog dialogRef={delDialog}
                positiveText={"Delete"}
                onPositivePress={doDelete}
                negativeText={"Cancel"}
                onNegativePress={onCancelDelete}
                header={"DELETE USER"}
            >
                <div className={styles.delete_block}>
                    Are you sure you would like to delete this user?
                    This user will be removed from account if exists and will be deleted.
                    The action cannot be undone.
                 </div>
            </Dialog>
            <Dialog dialogRef={resetDialog}
                header={"RESET USER PASSWORD"}
            >
                <div className={styles.reset_block}>
                    <p>
                        This will open a new window for you to reset the user password,
                        alternatively you can copy the reset link and send to the user manually.
                    </p>
                    {resetPasswordLink ?
                        <a href={resetPasswordLink} target="_blank" rel="noopener noreferrer">Reset Password</a>
                        : <Loading />
                    }
                </div>
            </Dialog>
        </div>
    );
}
