import SaaSPlan from "./SaaSPlan";

export enum SaaSSubscriptionStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    UPGRADING = "UPGRADING",
    CANCELLING = "CANCELLING`"
}

export default interface SaaSSubscription {
    id: number;
    status: string;
    account: string;
    plan: SaaSPlan;
    createdAt: string;
    termStartedAt: string;
    termEndsAt: string;
    startedAt: string;
    firstChargeAt: string;
    lastChargeAt: string;
    anchorDay: number;
    chargeAttempts: number;
    upgradeTaskName: string;
    upgradePlan: SaaSPlan | null;
    subscriptionPaymentType: SubscriptionPaymentType;
    nextPaymentType?: SubscriptionPaymentType;
};;;;;;;;;;

export enum SubscriptionPaymentType {
    STRIPE = 'STRIPE',
    INVOICE = 'INVOICE'
}

export interface SaaSSubscriptionUpdateRequest {
    paymentType: SubscriptionPaymentType;
    planId: number;
    upgradeLater: boolean;
}
