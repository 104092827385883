import { AccountStatus } from "src/types/model/AmotaiAccount";
import Audit from "src/types/model/Audit";

export function simpleArrayCompare(a: Array<string | number>, b: Array<string | number>): boolean {
    if (a.length !== b.length) {
        return false;
    }
    const unique = new Set([...a, ...b]);
    for (const u of unique) {
        const bothIncludes = a.includes(u) && b.includes(u);
        if (!bothIncludes) {
            return false;
        }
    }
    return true;
}

/**
 * check if any of the element in array-B is in the array-A
 * @param arrA 
 * @param arrB 
 */
export function strarrContainsAnyOfStrarr(arrA?: string[], arrB?: string[]) {
    if (!arrB?.length || !arrA?.length) {
        return false;
    }
    for (const b of arrB) {
        if (arrA.includes(b)) {
            return true;
        }
    }
    return false;
}
/**
 * This is only build for export functions, converting account fields into readable names
 * @param name field name
 */
export function nameBuilder(name: string): string {
    // customized names
    switch (name) {
        case 'regions':
            name = 'regionsOperating';
            break;
        case 'reviewMessage':
            name = 'declineMessage';
            break;
        case 'physicalAddressComponents':
            name = 'physicalAddress';
            break;
        case 'ownershipType':
            name = 'ownershipVerified';
            break;
        default:
            break;
    }
    if (/^(maincontact#)/i.test(name)) {
        name = name.replace('#', ' ');
    }
    let newName = "";
    for (let i = 0; i < name.length; i++) {
        let s = name.charAt(i);
        if (i === 0) {
            s = s.toUpperCase();
        } else if (s.toUpperCase() === s) {
            s = ` ${s.toLowerCase()}`;
        }
        newName += s;
    }
    return newName;
}

/**
 * add 0 to 21/22.. mobile phones if not exists
 * add 00 to mobile if it was starting with 64/61
 * @param phonelike a phonelike string
 */
export function phoneBuilder(phonelike: string | undefined | null) {
    if (!phonelike) {
        return '';
    }
    if (/^\d+$/.test(phonelike)) {
        return phonelike.replace(/^(2\d)/, "0$1").replace(/^(61|64)/, "00$1");
    }
    return phonelike;
}

export function getAccountStatusHistory(accountStatusAudits: Audit[]) {
    const approvedAt = accountStatusAudits?.filter(a => a.details.status === AccountStatus.ACTIVE)
        .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())[0]?.updatedAt;
    const onholdAt = accountStatusAudits?.filter(a => a.details.status === AccountStatus.DISABLED)
        .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())[0]?.updatedAt;
    const declinedAt = accountStatusAudits?.filter(a => a.details.status === AccountStatus.DECLINED)
        .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())[0]?.updatedAt;
    const deletedAt = accountStatusAudits?.filter(a => a.details.status === AccountStatus.DELETED)
        .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())[0]?.updatedAt;
    return {
        approvedAt,
        onholdAt,
        declinedAt,
        deletedAt,
    } as {
        approvedAt?: string,
        onholdAt?: string,
        declinedAt?: string,
        deletedAt?: string,
    };
}

export function currency(from: string) {
    return from.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}