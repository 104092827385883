import { Actions, Types } from "../actions/events";
import AmotaiEvent, { EventStatus } from "src/types/model/AmotaiEvent";

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

const initialState: {
    [key: string]: AmotaiEvent;
} = {};

export default function events(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_EVENTS:{
            const { payload } = action as ActionValueTypes<Types.SET_EVENTS>;
            const newState = { ...state };
            payload?.items.forEach(e => newState[e.id] = e);
            return newState;
        }
        case Types.SET_EVENT:{
            const { payload } = action as ActionValueTypes<Types.SET_EVENT>;
            const event = payload!;
            if (event.status !== EventStatus.ACTIVE) {
 return state; 
} //not going to load inactive events
            return {
                ...state,
                [event.id]: event
            };
        }
        case Types.DEL_EVENT: {
            const { payload } = action as ActionValueTypes<Types.DEL_EVENT>;
            const event = payload!;
            const stateObj = Object.assign(state);
            delete stateObj[event.id];
            return stateObj;
        }
        case Types.SET_RSVPS: {
            const { payload } = action as ActionValueTypes<Types.SET_RSVPS>;
            const rsvps = payload!;
            const newState = { ...state };
            rsvps.items.forEach(rsvp => {
                if (!!rsvp.event) {
                    const eventId = rsvp.event;
                    const rsvpId = rsvp.id;
                    const event = newState[eventId];
                    const rsvps = event.rsvps ?? {};
                    rsvps[rsvpId] = rsvp;
                    event.rsvps = rsvps;
                    event.rsvpCnt = (event.rsvpCnt ?? 0) + 1;
                    newState[eventId] = event;
                }
            });
            return newState;
        }
        default:
            return state;
    }
}