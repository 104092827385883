import React, { ChangeEvent } from "react";
import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import styles from "./FileUpload.module.scss";

export type FileUploadProps = {
    id?: string;
    label?: string;
    labelClassName?: string;
    imageClassName?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    accept?: string;
    disabled?: boolean;
    helperText?: string;
    preview?: string;
    showError?: boolean;
    loading?: boolean;
}

function FileUpload(props: FileUploadProps) {
    const { id, label, labelClassName, imageClassName, onChange, accept, disabled, helperText, preview, showError, loading } = props;

    return (
        <div className={classNames(styles.file_upload, labelClassName)}>
            <InputLabel htmlFor={id || "file"}>{label}</InputLabel>
            <input id={id || "file"}
                type={"file"}
                onChange={onChange}
                accept={accept || "*"}
                disabled={disabled || loading}
                multiple={false}
                className={styles.label} />
            {showError && helperText && <div className={styles.error}>{helperText}</div>}
            {preview && (
                <div className={classNames(styles.preview, imageClassName)}>
                    <div style={{ backgroundImage: `url(${preview})` }} className={styles.avatar_image} />
                </div>
            )}
        </div>
    );
}

export default FileUpload;