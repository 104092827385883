import React, { useState, useCallback } from "react";
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import classNames from "classnames";
import { TextFieldProps } from "@material-ui/core";
import { FieldInputProps } from "formik";
import { DateTime } from "luxon";
import styles from "./Input.module.scss";

export type DatePickerProps = {
    includeTime?: boolean;
    value?: number | null;
    onChange?: (date: number) => void;
    field?: FieldInputProps<string | number>;
    dateFormat?: 'yyyy-MM-dd' | 'dd/MM/yyyy';
    datetimeFormat?: 'yyyy-MM-dd HH:mm' | "dd/MM/yyyy HH:mm";
} & Omit<TextFieldProps, "name" | "value" | "variant" | "onChange" | "SelectProps" | "multiline">;

export default function DatePicker(props: DatePickerProps) {
    const { includeTime, value, label, InputProps, InputLabelProps, disabled, fullWidth, field, dateFormat, datetimeFormat } = props;

    const [selectedDate, setSelectedDate] = useState<DateTime | null>(value ? DateTime.fromMillis(value) : null);
    const className = classNames(styles.input, {
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
    }, props.className);

    const handleDateChange = useCallback((date: DateTime | null) => {
        setSelectedDate(date);
        if (date) {
            props.onChange?.(date.toMillis());
        }
    }, [props.onChange]);

    return includeTime ? (
        <KeyboardDateTimePicker
            variant={"inline"}
            format={datetimeFormat ?? "dd/MM/yyyy HH:mm"}
            minutesStep={5}
            {...field}
            fullWidth={fullWidth}
            value={selectedDate}
            disabled={disabled}
            label={label}
            className={className}
            InputLabelProps={{
                ...InputLabelProps,
                classes: {
                    ...InputLabelProps?.classes,
                    root: classNames(styles.label, InputLabelProps?.classes?.root),
                },
            }}
            InputProps={{
                ...InputProps,
                classes: {
                    ...InputProps?.classes,
                    root: classNames(styles.input_root, InputProps?.classes?.root),
                },
            }}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                "aria-label": "change date",
            }}
        />
    ) : (
        <KeyboardDatePicker
            disableToolbar
            variant={"inline"}
            format={dateFormat ?? "dd/MM/yyyy"}
            {...field}
            fullWidth={fullWidth}
            value={selectedDate}
            disabled={disabled}
            label={label}
            className={className}
            InputLabelProps={{
                ...InputLabelProps,
                classes: {
                    ...InputLabelProps?.classes,
                    root: classNames(styles.label, InputLabelProps?.classes?.root),
                },
            }}
            InputProps={{
                ...InputProps,
                classes: {
                    ...InputProps?.classes,
                    root: classNames(styles.input_root, InputProps?.classes?.root),
                },
            }}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                "aria-label": "change date",
            }}
        />
    );
}